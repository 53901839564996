import { GridSortItem } from "@mui/x-data-grid-premium";
import { Ordering } from "../../../../../../shared/reporting/api/biClient.types";
import { SortField } from "../../../Types";
import { TableField } from "../../hooks/TableField";

export const getSorting = (sortingFields: SortField[], fields: TableField[]) =>
  sortingFields.map((sf) => {
    const field = fields.find(
      (f) => f.dimension?.meta.name === sf.meta.name || f.measure?.meta.name === sf.meta.name
    )?.guid;
    return { field, sort: sf.config.ordering === Ordering.Descending ? "desc" : "asc" } as GridSortItem;
  });
