import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";

interface Props {
  loading: boolean;
  skip: number;
  take: number;
  totalCount: number;
}

export const RecordsCounter = (props: Props) => {
  const { loading, skip, take, totalCount } = props;

  const loadedCount = useMemo(() => {
    return Math.min(skip + take, totalCount);
  }, [skip, take, totalCount]);

  return (
    <Stack
      visibility={loading ? "hidden" : "visible"}
      flex={1}
      flexDirection="row"
      gap={0.5}
      alignItems="center"
      py={0.4}
      px={3}
    >
      <HorizontalFill />
      <Typography variant="caption" color="secondary">
        Records:
      </Typography>
      <Typography variant="caption">{loadedCount}</Typography>
      <Typography variant="caption">of</Typography>
      <Typography variant="caption">{totalCount}</Typography>
    </Stack>
  );
};

export default RecordsCounter;
