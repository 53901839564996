import { useState } from "react";
import ChangeReportNameButton from "./ChangeReportNameButton";
import ChangeReportNameField from "./ChangeReportNameField";
import { useSharedReportingLocalization } from "../../../../../shared/reporting/hooks/useLocalization";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { useDispatch } from "react-redux";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { Report } from "../../../../../shared/reporting/api/biClient.types";
import { formatString } from "../../../../../shared/utilities/stringFormatter";
import { currentReportActions } from "../../../../store/currentReportSlice";
import { useLocalization } from "../../../../../shared/hooks/useLocalization";
import { useBiApiClientProvider } from "../../../../../shared/reporting/contexts/BiApiClientProviderContext";
import { BiApiClient } from "../../../../contexts/ApiClientProviderContext";

interface Props {
  report: Report;
  showEditReportName: boolean;
  onRenamingChanged: (isRenaming: boolean) => void;
}

const ReportNameChanger = ({ report, onRenamingChanged, showEditReportName }: Props) => {
  const { common: commonLocale } = useLocalization();
  const { report: reportLocale } = useSharedReportingLocalization();
  const { sendNotificationError } = useNotificationContext();
  const { validateName, renameReport } = useBiApiClientProvider<BiApiClient>();

  const dispatch = useDispatch();

  const [isRenaming, setIsRenaming] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportNameIsNotValidError, setReportNameIsNotValidError] = useState<string>();

  const isValidReportName = async (name: string) => {
    const [result, error] = await withErrorHandling(validateName)({
      reportId: report.reportId,
      name,
      clientCode: report.clientCode,
    });

    if (error) {
      sendNotificationError(reportLocale.rename_error);
      return false;
    }
    if (result.nameExists) {
      setReportNameIsNotValidError(formatString(reportLocale.name_exist, name));
      return false;
    }
    setReportNameIsNotValidError(undefined);
    return true;
  };

  const changeReportName = async (name: string) => {
    const [result, error] = await withErrorHandling(renameReport)({
      reportId: report.reportId,
      name,
      clientCode: report.clientCode,
    });

    if (error) {
      sendNotificationError(reportLocale.rename_error);
    }
    return result;
  };

  const handleRename = async (name: string) => {
    setLoading(true);

    if (await isValidReportName(name)) {
      setReportNameIsNotValidError(undefined);
      const newReportName = await changeReportName(name);

      if (newReportName) {
        dispatch(currentReportActions.updateReport(newReportName));
        changeRenamingState(false);
      }
    }
    setLoading(false);
  };

  const changeRenamingState = (isRenaming: boolean) => {
    setIsRenaming(isRenaming);
    onRenamingChanged(isRenaming);
  };

  const handleReportNameChanged = (newReportName: string) => {
    if (newReportName.trim().length < 2) {
      setReportNameIsNotValidError(commonLocale.validation_min_length);
    } else {
      setReportNameIsNotValidError(undefined);
    }
  };

  return (
    <>
      {!isRenaming && <ChangeReportNameButton onClick={() => changeRenamingState(true)} hidden={!showEditReportName} />}
      {isRenaming && (
        <ChangeReportNameField
          reportName={report.name}
          onApply={handleRename}
          onClose={() => {
            changeRenamingState(false);
            setReportNameIsNotValidError(undefined);
          }}
          loading={loading}
          validationError={reportNameIsNotValidError}
          onReportNameChanged={handleReportNameChanged}
        />
      )}
    </>
  );
};

export default ReportNameChanger;
