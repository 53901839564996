import { useCallback, useState } from "react";
import { ChangeReportGroupRequest, Report } from "../../api/biClient.types";
import SaveReportFormWrapper from "./SaveReportFormWrapper";
import { FromReport } from "./FromReport";
import ReportGroupsConnector from "./ReportGroupsConnector";
import PromptDialog from "../../../components/dialog/PromptDialog";
import { useSharedReportingLocalization } from "../../hooks/useLocalization";

interface Props {
  report: FromReport;
  changeGroup: (report: ChangeReportGroupRequest) => Promise<Report | undefined>;
  onChanged: (report: Report) => void;
  onClose: () => void;
}

export default function ChangeReportGroup(props: Props) {
  const { report, changeGroup, onChanged, onClose } = props;

  const { report: locale } = useSharedReportingLocalization();

  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);

  const onSaveClicked = useCallback(
    async (_: unknown, groupId: string) => {
      setSaving(true);
      try {
        const resp = await changeGroup({ reportId: report.reportId, groupId, clientCode: report.clientCode });
        if (resp === undefined) {
          setError(locale.change_group_error);
        } else {
          onChanged(resp);
          onClose();
        }
      } catch (e) {
        setError(locale.change_group_error);
      } finally {
        setSaving(false);
      }
    },
    [changeGroup, report.reportId, report.clientCode, locale.change_group_error, onChanged, onClose]
  );

  return (
    <PromptDialog open={true}>
      <ReportGroupsConnector clientCode={report.clientCode}>
        <SaveReportFormWrapper
          report={report}
          error={error}
          title="Change Group"
          saveBtnTitle="Save"
          saving={saving}
          showLevel
          onClose={onClose}
          onSave={onSaveClicked}
        />
      </ReportGroupsConnector>
    </PromptDialog>
  );
}
