import { hasSpaceCharacter } from "./utilities/tokenizer";
import { Paper, Popper } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DimensionFormulaVariable, FunctionFormulaVariable } from "./utilities/variables";
import { getFunctionVariables } from "./utilities/variables.utils";
import IntellisenseItem from "./IntellisenseItem";
import { setCaretIndex } from "./utilities/caretPosition";
import { FormulaInputActions, FormulaInputState } from "./hooks/formulaInputState";
import { CustomMeasureReturnType } from "./hooks/useCustomMeasure";
import { findNode } from "./utilities/fillNodes";
import { createEmptyConditionItem } from "../utilities/createEmptyConditionItem";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";

interface Props {
  state: CustomMeasureReturnType;
  formulaState: FormulaInputState;
  actions: FormulaInputActions;
}

export const Intellisense = ({ state, formulaState, actions }: Props) => {
  const [searchText, setSearchText] = useState<string>();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [showPopup, setShowPopup] = useState(false);

  const tokenRef = useRef(state.currentToken);
  tokenRef.current = state.currentToken;

  const selectedIndexRef = useRef(selectedIndex);
  selectedIndexRef.current = selectedIndex;

  useEffect(() => {
    if (
      typeof state.currentToken?.value === "string" &&
      !!state.currentToken.text &&
      isTextLengthValid(state.currentToken.value.length)
    ) {
      setSearchText(state.currentToken.text);
      setShowPopup(true);
    } else {
      setSearchText(undefined);
      setShowPopup(false);
    }
  }, [state.currentToken, setSearchText]);

  useEffect(() => {
    setSelectedIndex(0);
    setShowPopup(true);
  }, [searchText]);

  const variables = useMemo(() => {
    // Dimensions are temporary disabled
    // return getDimensionVariables(state.dimensions).concat(getFunctionVariables(state.functions));
    return getFunctionVariables(state.functions);
  }, [state.functions]);

  const suggestions = useCallback(
    (searchText?: string) => {
      if (searchText === undefined || searchText === null) return [];
      return variables.filter((v) => v.caption.toLowerCase().includes(searchText.toLowerCase()));
    },
    [variables]
  );

  const suggestionsRef = useRef(suggestions(searchText));
  suggestionsRef.current = suggestions(searchText);

  useEffect(() => {
    if (selectedIndexRef.current > 0) {
      setSelectedIndex(selectedIndexRef.current - 1);
    }
  }, [formulaState.previous]);

  useEffect(() => {
    if (selectedIndexRef.current < suggestionsRef.current.length - 1) {
      setSelectedIndex(selectedIndexRef.current + 1);
    }
  }, [formulaState.next]);

  useEffect(() => {
    const suggest = suggestionsRef.current[selectedIndexRef.current];
    if (suggest === undefined) return;
    if (tokenRef.current?.element) {
      const relatedNode = findNode(state.formulaNodes, tokenRef.current);
      if (relatedNode !== undefined) {
        if (suggest instanceof DimensionFormulaVariable) {
          state.actions.updateFormulaNode(relatedNode, {
            conditions: [createEmptyConditionItem(cloneDeep(suggest.field))],
          });
        } else if (suggest instanceof FunctionFormulaVariable) {
          state.actions.updateFormulaNode(relatedNode, { function: suggest.field });
        }
      }
      tokenRef.current.element.textContent = hasSpaceCharacter(suggest.caption)
        ? `"${suggest.caption}"`
        : suggest.caption;
      setCaretIndex(tokenRef.current.element, tokenRef.current.element.innerText.length);
      actions.inserted();
      setShowPopup(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulaState.select]);

  useEffect(() => {
    setShowPopup(false);
  }, [formulaState.close]);

  if (!state.currentToken?.element) {
    return <></>;
  }
  if (!document.body.contains(state.currentToken.element)) {
    return <></>;
  }
  if (
    state.currentToken.value &&
    typeof state.currentToken.value === "string" &&
    !isTextLengthValid(state.currentToken.value.length)
  ) {
    return <></>;
  }
  if (suggestionsRef.current.length === 0) {
    return <></>;
  }

  return (
    <Popper
      open={showPopup}
      anchorEl={state.currentToken.element}
      placement="bottom-start"
      sx={{ bgcolor: "white", zIndex: 1400 }}
    >
      <Paper sx={{ ml: -4.5, mt: 0.5 }}>
        {suggestionsRef.current.map((s, index) => (
          <IntellisenseItem
            key={s.key}
            searchText={searchText || ""}
            suggestion={s}
            selected={selectedIndex === index}
            onSelect={() => {
              setSelectedIndex(index);
              actions.select();
            }}
          />
        ))}
      </Paper>
    </Popper>
  );
};

export default Intellisense;

const isTextLengthValid = (length: number) => {
  return !(length < 2);
};
