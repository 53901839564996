import { SvgIcon, SvgIconProps } from "@mui/material";

export const ExportImageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20, color: "transparent" }} viewBox="0 0 20 20" {...props}>
      <path
        d="M3.33398 13.333L7.15565 9.51134C7.4682 9.19889 7.89204 9.02336 8.33398 9.02336C8.77592 9.02336 9.19977 9.19889 9.51232 9.51134L13.334 13.333M11.6673 11.6663L12.989 10.3447C13.3015 10.0322 13.7254 9.8567 14.1673 9.8567C14.6093 9.8567 15.0331 10.0322 15.3456 10.3447L16.6673 11.6663M11.6673 6.66634H11.6757M5.00065 16.6663H15.0007C15.4427 16.6663 15.8666 16.4907 16.1792 16.1782C16.4917 15.8656 16.6673 15.4417 16.6673 14.9997V4.99967C16.6673 4.55765 16.4917 4.13372 16.1792 3.82116C15.8666 3.5086 15.4427 3.33301 15.0007 3.33301H5.00065C4.55862 3.33301 4.1347 3.5086 3.82214 3.82116C3.50958 4.13372 3.33398 4.55765 3.33398 4.99967V14.9997C3.33398 15.4417 3.50958 15.8656 3.82214 16.1782C4.1347 16.4907 4.55862 16.6663 5.00065 16.6663Z"
        stroke="#8E9BA9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default ExportImageIcon;
