import { Checkbox, Grid, useTheme } from "@mui/material";
import { useEffect, useMemo, useRef } from "react";
import { CustomMeasureReturnType, findNodeByTokenData } from "../../customMeasure/hooks/useCustomMeasure";
import { FormulaNode } from "../../customMeasure/utilities/fillNodes";
import { ChartOfAccountsContextItem, useChartOfAccountsContext } from "../contexts/ChartOfAccountsContext";
import { getFunctionPropertyItemUniqueId } from "../helpers/chartOfAccountsContextHelper";
import PropertyItemContainer from "./PropertyItemContainer";
import { PropertyName } from "./PropertyName";
import VariablePropertyItem from "./VariablePropertyItem";

interface Props {
  formulaNode: FormulaNode;
  state: CustomMeasureReturnType;
  index: number;
  parentNode: ChartOfAccountsContextItem | null;
}

export const FunctionPropertyItem = ({ formulaNode, state, parentNode, index }: Props) => {
  const { palette } = useTheme();
  const formulaContainerRef = useRef<HTMLDivElement>(null);
  const id = getFunctionPropertyItemUniqueId(index, formulaNode);

  const isSelectedParameter = useMemo(() => {
    if (state.selectedFormulaParameter === undefined) return false;

    return formulaNode.linkedNodes.some((ln) => findNodeByTokenData(ln, state.selectedFormulaParameter));
  }, [state.selectedFormulaParameter, formulaNode]);

  useEffect(() => {
    if (isSelectedParameter) {
      formulaContainerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedParameter]);

  const { root, onSelect, appendChild, getNode } = useChartOfAccountsContext();

  const currentNode = useMemo(() => {
    return getNode(id, parentNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentNode, id, root]);

  useEffect(() => {
    if (!parentNode) return;
    appendChild(id, parentNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, parentNode]);

  return (
    <PropertyItemContainer
      sx={{
        bgcolor: palette.background.grey,
        pb: 1,
        ...(formulaNode.highlighted === true ? { border: `1px solid ${palette.primary.main}` } : {}),
      }}
      ref={formulaContainerRef}
    >
      <Grid
        container
        sx={{
          display: "flex",
          px: 1,
          gap: 1.25,
          height: "28px",
          bgcolor: currentNode?.checked ? "rgba(0, 168, 102, 0.08)" : "white",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          borderBottom: "1px solid " + palette.divider,
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {currentNode && (
          <Checkbox
            indeterminate={currentNode.indeterminate}
            checked={currentNode.checked}
            sx={{ width: "28px", height: "28px" }}
            onChange={(e) => {
              onSelect(currentNode?.identifier, e.target.checked);
            }}
          />
        )}
        <PropertyName text={formulaNode.text} />
      </Grid>
      <Grid container component="ul" className="formulaTree" sx={{ flexDirection: "column", flex: 1 }}>
        {currentNode &&
          formulaNode.args?.map((arg, i) => {
            return (
              <VariablePropertyItem
                key={`${arg.key}_${i}`}
                formulaNode={arg}
                state={state}
                sx={{ mt: 1.5, flex: 1, width: "auto" }}
                parentNode={currentNode}
                index={i}
              />
            );
          })}
      </Grid>
    </PropertyItemContainer>
  );
};

export default FunctionPropertyItem;
