import { PieChart } from "devextreme-react";
import { Series } from "devextreme-react/pie-chart";
import { ChartProps } from "./ChartProps";
import ChartCore, { getBasicLegend, getBasicSerieLabel } from "./ChartCore";
import { useFieldsStateContext } from "./contexts/FieldsStateContext";
import formatArgumentLabel from "./utilities/argumentFormat";
import { useCallback } from "react";

export default function Pie(props: ChartProps) {
  const { valuesArea, settingsArea } = useFieldsStateContext();
  const updateRef = useCallback(
    (ref: PieChart) => {
      props.exportFunc(ref?.instance.exportTo.bind(ref.instance));
    },
    [props]
  );
  const renderSeries = () => {
    return props.args.map((arg) => {
      return (
        <Series
          key={arg.meta.name}
          argumentField={arg.meta.name}
          valueField={props.values[0]?.config.guid}
          name={props.values[0]?.config.guid}
          label={getBasicSerieLabel(props.values[0]?.config.guid, settingsArea, valuesArea, props.data)}
        />
      );
    });
  };

  return (
    <ChartCore {...props}>
      <PieChart
        ref={updateRef}
        dataSource={props.dataSource}
        legend={{
          ...getBasicLegend(settingsArea),
          customizeText: ({ pointName }) => formatArgumentLabel(props.args, pointName, props.data),
        }}
      >
        {renderSeries()}
      </PieChart>
    </ChartCore>
  );
}
