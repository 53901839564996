import { Box, Grid } from "@mui/material";
import PartContainer from "../../PartContainer";
import BuildProgressWrapper from "../../BuildProgressWrapper";
import PreviewActions from "../../common/PreviewActions";
import useGridStateBuilder from "../../tabular/hooks/useGridStateBuilder";
import { useLocalization } from "../../../../hooks/useLocalization";
import PreviewComponent from "../../tabular/preview/PreviewComponent";
import TableComponent from "./TableComponent";
import PreviewTabs from "../PreviewTabs";
import RequestCode from "./code/RequestCode";
import ResponseCode from "./code/ResponseCode";

const ReportEditor = () => {
  const { common: locale } = useLocalization();
  const state = useGridStateBuilder();

  return (
    <>
      <PartContainer sx={{ maxWidth: "24rem", minWidth: "24rem" }} caption={<Box pl="1.3rem">Builder</Box>}>
        <TableComponent />
      </PartContainer>
      <BuildProgressWrapper>
        <PreviewTabs
          GridView={
            <PartContainer
              sx={{ borderRight: "unset", p: "1.3rem", pt: 2, height: "100%" }}
              containerSx={{ position: "relative", gap: "1rem" }}
              caption={locale.report_preview_label}
              actions={<PreviewActions />}
            >
              <PreviewComponent state={state} />
            </PartContainer>
          }
          CodeView={
            <Grid
              container
              display={"grid"}
              width={"100%"}
              flexWrap={"nowrap"}
              minWidth={"45rem"}
              gap={3}
              p={"1.3rem"}
              overflow={"hidden"}
              gridTemplateRows={"minmax(20%, auto) minmax(20%, auto)"}
            >
              <RequestCode isLoading={state.loading} />
              <ResponseCode isLoading={state.loading} />
            </Grid>
          }
        />
      </BuildProgressWrapper>
    </>
  );
};

export default ReportEditor;
