import {
  AmountType,
  CalculateByField,
  CellProperties,
  ChartConfiguration,
  ConditionDescriptor,
  ConditionEqualityType,
  DrillDownColumn,
  FormatType,
  MeasureCell,
  MeasureConfigurations,
  MeasureUnitTable,
  MetaIcons,
  NumberFormatterOptions,
  PivotConfiguration,
  PivotValuesRequest,
  TabularConfiguration,
  TabularDataCell,
} from "../../shared/reporting/api/biClient.types";

import { AnyObject, EmptyObject } from "../../shared/types";

type NumericFormat = NumberFormatterOptions & {
  formatType: FormatType.Numeric;
};

type NoneFormat = {
  formatType: FormatType.None;
};

type DateFormat = {
  formatType: FormatType.Date;
};

type DateTimeFormat = {
  formatType: FormatType.DateTime;
};

type BlankIfZeroFormat = {
  formatType: FormatType.BlankIfZero;
};

type BlankIfFalseFormat = {
  formatType: FormatType.BlankIfFalse;
};

export type MetaFormat =
  | NoneFormat
  | NumericFormat
  | DateFormat
  | DateTimeFormat
  | BlankIfZeroFormat
  | BlankIfFalseFormat;

export type FormatterDescription = {
  title: string;
  subTitle: string | undefined;
  options: Partial<NumberFormatterOptions>;
};

export enum ItemDataType {
  "General" = "General",
  "Date" = "Date",
  "Numeric" = "Numeric",
}

export enum MetaItemType {
  "DIMENSION" = "DIMENSION",
  "MEASURE" = "MEASURE",
}

export enum MeasureUsage {
  General = "General",
  TabularOnly = "TabularOnly",
  PivotOnly = "PivotOnly",
}

export type MeasureDataset = {
  name: string;
  table: MeasureUnitTable;
  values: { [key: string]: string[] };
};

type MeasureCapabilities = {
  isConfigurable: boolean;
  customConditionsAllowed: boolean;
  dateRangeCustomization: boolean;
  changeAmountField: boolean;
  chartOfAccountsAllowed: boolean;
  transferredBalanceAllowed: boolean;
};

export type MeasureUnit = {
  measureBasedUnit: boolean;
  measureName?: string;
  filters?: ConditionDescriptor[];
  table?: MeasureUnitTable;
  calculateByField?: CalculateByField;
  amountType?: AmountType;
};

export type MetaDescriptorBase = {
  name: string;
  caption: string;
  type: ItemDataType;
  itemType: MetaItemType;
  icon: MetaIcons;
};

export type DimensionDescriptor = MetaDescriptorBase & {
  keyFieldName: string;
  isNew: boolean;
  availableFields: string[];
  displayValues: string[];
  dictionary: DimensionDictionary[];
  defaultFormat?: MetaFormat;
  glAllowed: boolean;
};

export type DimensionDictionary = { [key: string]: string };

export type MeasureDescriptor = MetaDescriptorBase & {
  capabilities: MeasureCapabilities;
  usage: MeasureUsage;
  defaultFormat: MetaFormat | undefined;
  units: { [key: string]: MeasureUnit };
  formula?: string;
  custom: boolean;
  group: string;
  customConditions?: ConditionDescriptor[];
  configuration?: MeasureConfigurations;
};

export type DimensionsStructure = {
  dimensions: string[];
  groups: DimensionGroup[];
};

export type DimensionGroup = {
  caption: string;
  dimensions: string[];
  groups?: DimensionGroup[];
};

export type MeasuresStructure = {
  names: string[];
  groups: MeasureGroup[];
};

export type MeasureGroup = {
  caption: string;
  names: string[];
};

export type MetaDataResponse = {
  dimensionsStructure: DimensionsStructure;
  dimensions: DimensionDescriptor[];
  measuresStructure: MeasuresStructure;
  measures: MeasureDescriptor[];
  functions: FunctionDescription[];
  measureGroups: string[];
};

export type FunctionDescription = {
  name: string;
  fullName: string;
  description: string;
  args: FunctionArgumentDescription[];
};

type FunctionArgumentDescription = {
  name: string;
  description: string;
};

export type TabularValueRequest = PivotValuesRequest & {
  hideAggregation?: boolean | undefined | null;
};

export type BuildReportRequest = BuildPivotReportRequest | BuildTabularReportRequest | BuildChartReportRequest;

export type RunPivotReportRequest = Pick<PivotConfiguration, "conditions"> & {
  reportId: string;
  sessionId: string;
  withDrilldown: boolean;
  useQueryLogging?: boolean;
};

export type BuildPivotReportRequest = Omit<PivotConfiguration, "reportNature"> & {
  reportId?: string;
  sessionId: string;
  cacheSessionId?: string;
  withDrilldown: boolean;
  useQueryLogging?: boolean;
};

export type RunApiReportRequest = RunTabularReportRequest;

export type RunTabularReportRequest = Pick<TabularConfiguration, "conditions" | "sort"> & {
  reportId: string;
  sessionId: string;
  withDrilldown: boolean;
  useQueryLogging?: boolean;
};

export type BuildApiReportRequest = BuildTabularReportRequest;

export type BuildTabularReportRequest = TabularConfiguration & {
  reportId?: string;
  sessionId: string;
  cacheSessionId?: string;
  withDrilldown: boolean;
  skip: number;
  take: number;
  calcTotalCount?: boolean;
  calcGroupTotals?: boolean;
  useQueryLogging?: boolean;
};

export type ExportParameters = {
  name: string;
  equality?: ConditionEqualityType;
  values: string[];
};

export type ExportConfig = {
  withParameters: boolean;
  parameters: ExportParameters[];
};

export type BuildExportRequest = {
  exportConfig: ExportConfig;
  buildConfig: BuildTabularReportRequest | BuildPivotReportRequest;
};

export type RunExportRequest = {
  exportConfig: ExportConfig;
  viewConfig: RunTabularReportRequest | RunPivotReportRequest;
};

export type RunChartReportRequest = Pick<ChartConfiguration, "conditions"> & {
  reportId: string;
  sessionId: string;
  withDrilldown: boolean;
  useQueryLogging?: boolean;
};

export type BuildChartReportRequest = Omit<ChartConfiguration, "reportNature"> & {
  reportId?: string;
  sessionId: string;
  cacheSessionId?: string;
  withDrilldown: boolean;
  useQueryLogging?: boolean;
};

export type PivotColumnResponse = {
  id?: "lines" | (string & EmptyObject);
  dimensionName: string;
  formattedValues: string[];
  value: string;
  columns: PivotColumnResponse[];
  toolTip?: string;
  measureGuid?: string;
};

export type PivotRowResponse = { [key: string]: PivotRowDimensionCell | PivotRowMeasureCell };

export type PivotRowDimensionCell = {
  dimensionName: string;
  formattedValues: string[];
  value: string;
  level: number;
  toolTip?: string;
  properties?: CellProperties;
  rowId: number;
};

export type PivotRowMeasureCell = MeasureCell & {
  id: string;
  value: number | undefined;
  formattedValue: string;
  level: number;
  drillDownId?: string;
};

export type PivotDataResponse = {
  etag: string;
  columns: PivotColumnResponse[];
  rows: PivotRowResponse[];
  grandTotals: PivotDataGrandTotals;
  loggingItems?: QueryLoggingItem[];
  failedMeasures: FailedMeasure[];
};

export type FailedMeasure = {
  name: string;
};

export type PivotDataGrandTotals = { [key: string]: PivotRowMeasureCell };

export type QueryLoggingItem = {
  queryText: string;
  result: AnyObject[];
  executionTime: number;
};

export type ChartResponse = {
  etag: string;
  result: ChartRowResponse[];
  loggingItems?: QueryLoggingItem[];
  failedMeasures: FailedMeasure[];
};

export type ChartRowResponse = { ["rowId"]: string } & { [key: string]: PivotRowDimensionCell | PivotRowMeasureCell };

export type DrillDownDataRequest = {
  skip: number;
  take: number;
  calcTotalCount?: boolean;
  table: MeasureUnitTable;
  allocated: boolean;
  columns: DrillDownColumn[];
  conditions: ConditionDescriptor[];
  sortingColumns: ColumnSorting[];
};

export type CashFlowExportRequest = {
  caption: string;
  drillDownInfo: IrrDrillDownInfo;
};

export type ChartOfAccountsExportRequest = {
  caption: string;
  sessionId: string;
  itemId: string;
  table: MeasureUnitTable;
  includedOnly: boolean;
};

export type DrillDownRow = Record<string, string>;

export type DrillDownDataResponse = {
  data: DrillDownRow[];
  totalCount?: number;
};

export type TabularRow = {
  id: string;
  data: Record<string, TabularDataCell>;
};

export type TabularDataResponse = {
  etag: string;
  data: TabularRow[];
  totalCount?: number;
  groupTotals: GroupTotal[];
  grandTotals: AnyObject;
  loggingItems?: QueryLoggingItem[];
  columns: TabularColumn[];
  failedMeasures: FailedMeasure[];
};

export type PublicTabularResponse = {
  rows: TabularRow;
  columns: TabularColumn[];
};

export type ApiReportResponse = TabularDataResponse & {
  publicResponse: PublicTabularResponse;
};

export type TabularGroupTotalsResponse = {
  groupTotals: GroupTotal[];
  failedMeasures: FailedMeasure[];
};

export type TabularColumn = {
  guid: string;
  failedToLoad: boolean;
  title: string | undefined;
};

export type GroupTotal = {
  filters: Record<string, MeasureCell>;
  values: Record<string, MeasureCell>;
};

export type ColumnSorting = DrillDownColumn & {
  sortAsc: boolean;
  caption: string | undefined;
};

export type ExportResponse = {
  token: string;
  fileProcessed: boolean;
  error?: boolean;
  errorText?: string;
};

export type CustomMeasureUnit = {
  variable: string;
  unit: MeasureUnit;
};

export type CustomMeasureDescription = {
  name: string;
  caption: string;
  group: string;
  formula: string;
  calculateBy: CalculateByField;
  units: CustomMeasureUnit[];
  defaultFormat?: NumberFormatterOptions;
  configuration: MeasureConfigurations;
  amountType: AmountType;
};

export type CrossFilterRequest = {
  dimensionName: string;
  conditions: ConditionDescriptor[];
  dimensionNames: string[];
  measureNames: string[];
};

export type CrossFilterResponse = {
  values: string[];
};

export type LedgerBasisRequest = {
  dimensionNames: string[];
  measureNames: string[];
};

export type LedgerBasisResponse = {
  transactional: boolean;
};

export type ChartOfAccounts = {
  gl: ChartOfAccountsItem[];
  memo: ChartOfAccountsItem[];
};

export type ChartOfAccountsItem = {
  accountNo: string;
  accountName: string;
  accountCategory: string;
  accountType: AccountType;
  accountSubcategory: string;
  incomeBalance: string;
  level: number;
};

export enum AccountType {
  Heading = "Heading",
  BeginTotal = "Begin-Total",
  EndTotal = "End-Total",
  Posting = "Posting",
  Total = "Total",
}
export enum CellDrillDownInfoType {
  Default = "Default",
  Irr = "Irr",
}

export interface ClientSettings {
  intercomAuthentication?: IntercomAuthSettings;
}

interface IntercomAuthSettings {
  intercomUserId: string;
  intercomUserHash: string;
  intercomUserSignedUp: string;
  intercomAppId: string;
}

export type DrillDownInfoResult = {
  item?: DrillDownInfo;
  notFound: boolean;
};

export type DrillDownInfo = {
  combined: CellDrillDownInfoTypes;
  items: CellDrillDownInfoTypes[];
};

export type CellDrillDownInfoTypes = CellDrillDownInfo | IrrDrillDownInfo;

export type CellDrillDownInfoBase = {
  conditions: ConditionDescriptor[];
  table: MeasureUnitTable;
  field: CalculateByField;
  allocated: boolean;
  amountType: AmountType;
};

export type CellDrillDownInfo = CellDrillDownInfoBase & {
  drillDownInfoType: CellDrillDownInfoType.Default;
};

export type IrrDrillDownInfo = CellDrillDownInfoBase & {
  drillDownInfoType: CellDrillDownInfoType.Irr;
  cashFlowValues?: CashFlowValue[];
  referenceDateFieldCaption?: string;
  amountFieldCaption?: string;
};

export type CashFlowValue = {
  date: string;
  amount: number;
  isNav: boolean;
  isBeginningOfPeriod?: boolean;
  description: string;
  dateFormatted: string;
  amountFormatted: string;
  drillDownInfo?: DrillDownInfo;
};

export type DrillDownCacheValidationResponse = {
  isCacheValid: boolean;
  expired: boolean;
};

export type ChartOfAccountsDataResponse = {
  items: ChartOfAccountsLedgerItem[];
};

export type ChartOfAccountsLedgerItem = {
  measureUnitTable: MeasureUnitTable;
  items: ChartOfAccountsDataItem[];
};

export type ChartOfAccountsDataItem = ChartOfAccountsItem & {
  amount: number | undefined;
  amountFormatted: string | undefined;
  conditions: ConditionDescriptor[];
  included: boolean;
};

export { MetaIcons };
