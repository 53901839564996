import { SvgIcon, SvgIconProps } from "@mui/material";

export default function IconDocument(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 49 61" fill="none" {...props}>
      <path
        d="M48.6629 60.2246H0.462891V0.724609H36.3629L48.6629 13.0246V60.2246ZM5.46289 55.2246H43.5629V15.1246L34.2629 5.82461H5.46289V55.2246Z"
        fill="#C7DBE5"
      />
      <path d="M46.163 18.3246H31.563V3.22461H36.563V13.3246H46.163V18.3246Z" fill="#C7DBE5" />
      <path d="M37.4631 26.125H11.6631V31.125H37.4631V26.125Z" fill="#C7DBE5" />
      <path d="M28 37H12V42H28V37Z" fill="#C7DBE5" />
    </SvgIcon>
  );
}
