import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PublicTabularResponse } from "../api/biApi.types";
import { RootState } from "./RootState";
import { createJson, createResponseConfig } from "../components/builder/api-report/editor/code/helpers/codeHelper";

export interface ApiReportState {
  responseJson: string;
}

const initialState: ApiReportState = {
  responseJson: "",
};

export const apiReportSlice = createSlice({
  name: "apiReport",
  initialState,
  reducers: {
    setResponse: (state, action: PayloadAction<PublicTabularResponse | undefined>) => {
      state.responseJson = createJson(createResponseConfig(action.payload));
    },
  },
});

export const apiReportActions = apiReportSlice.actions;

export const selectResponseJson = (state: RootState) => state.apiReport?.responseJson;

export default apiReportSlice.reducer;
