import { Stack, Tooltip, Typography } from "@mui/material";
import { UserMeasureConfigurations } from "../../../../../../shared/reporting/api/biClient.types";
import NoPivotIcon from "../../../../../icons/NoPivotIcon";
import ReverseSignIcon from "../../../../../icons/ReverseSignIcon";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { nameof } from "../../../../../../shared/utilities/typeHelper";
import TooltipWrapper from "../../../../../../shared/components/TooltipWrapper";

interface Props {
  config: UserMeasureConfigurations;
}

const displayIconFields = [
  { key: nameof<UserMeasureConfigurations>("standalone"), toolTipTitle: "Not Pivoted Field", Icon: NoPivotIcon },
  { key: nameof<UserMeasureConfigurations>("reverseSign"), toolTipTitle: "Reverse Sign", Icon: ReverseSignIcon },
];

const compactModeThreshold = 2;

const getIconFields = (config: UserMeasureConfigurations) => {
  return displayIconFields.reduce(
    (acc, dif) => {
      const field = config[dif.key];
      if (typeof field === "boolean" && field === true) {
        acc.push(dif);
      }
      return acc;
    },
    [] as typeof displayIconFields
  );
};

const FieldCaptionIconsSet = ({ config }: Props) => {
  const fields = getIconFields(config);
  const isCompactMode = fields.length > compactModeThreshold;

  return (
    <>
      {isCompactMode && (
        <TooltipWrapper
          title={
            <Stack useFlexGap spacing={0.5}>
              {fields.map(({ Icon, toolTipTitle }) => (
                <Stack useFlexGap key={toolTipTitle} sx={{ gap: 0.5, alignItems: "center", flexDirection: "row" }}>
                  <Icon />
                  <Typography variant="caption" fontWeight={500}>
                    {toolTipTitle}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          }
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              px: 0.75,
              border: "1px solid",
              borderColor: "divider",
              borderRadius: "5px",
              gap: 0.25,
              bgcolor: "background.grey",
            }}
          >
            <TuneRoundedIcon color="primary" />
            <Typography variant="caption" color="action.active">
              {fields.length}
            </Typography>
          </Stack>
        </TooltipWrapper>
      )}
      {!isCompactMode &&
        fields.map(({ Icon, toolTipTitle }) => (
          <Tooltip title={toolTipTitle} arrow key={toolTipTitle}>
            <Icon />
          </Tooltip>
        ))}
    </>
  );
};

export default FieldCaptionIconsSet;
