import { Box } from "@mui/material";
import { Status } from "../../../api/biClient.types";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import LiveStatus from "./LiveStatus";
import DraftStatus from "./DraftStatus";

interface Props {
  status: Status;
}

export default function StatusBox({ status }: Props) {
  return (
    <Box sx={{ display: "flex", gap: 0.625, alignItems: "center" }}>
      {status === Status.Live ? <LiveStatus /> : <DraftStatus />}
      <Box sx={{ width: 20, height: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <ArrowRightAltRoundedIcon />
      </Box>
      {status === Status.Live ? <DraftStatus /> : <LiveStatus />}
    </Box>
  );
}
