import { Button } from "@mui/material";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";

interface Props {
  title?: string;
  code: string;
}

const CopyButton = ({ title = "Copy", code }: Props) => {
  const { sendNotification } = useNotificationContext();

  return (
    <Button
      variant="outlined"
      color="secondary"
      startIcon={<CopyAllRoundedIcon />}
      onClick={() => {
        navigator.clipboard.writeText(code);
        sendNotification("Curl copied to clipboard");
      }}
    >
      {title}
    </Button>
  );
};

export default CopyButton;
