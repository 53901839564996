import { SvgIcon, SvgIconProps } from "@mui/material";

export default function DoughnutChartIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M11.387 6.43824C13.0823 6.70053 14.1357 7.71399 14.3986 9.40988C14.4236 9.56949 14.5605 9.68718 14.7223 9.68718H18.0201C18.1116 9.68718 18.1992 9.6488 18.2612 9.581C18.3233 9.51351 18.354 9.42299 18.3463 9.33151C18.0181 5.5214 15.2759 2.81942 11.4651 2.49059C11.3755 2.48227 11.2834 2.51331 11.2156 2.57534C11.1478 2.63739 11.1094 2.72503 11.1094 2.81684V6.11453C11.1094 6.27608 11.2271 6.41361 11.387 6.43824Z" />
      <path d="M18.0178 10.8232H14.6896C14.5262 10.8232 14.3881 10.942 14.3629 11.1031C14.2667 11.7245 14.1452 11.9877 13.7746 12.4957C13.6784 12.6271 13.6926 12.8092 13.8076 12.9244L16.1615 15.279C16.2235 15.341 16.3074 15.3758 16.3952 15.3758C16.3997 15.3758 16.4049 15.3758 16.4094 15.3755C16.5024 15.3717 16.5889 15.3287 16.6483 15.258C17.7013 14.0045 18.3472 12.5718 18.347 11.1822C18.3547 11.0898 18.3237 10.9985 18.2611 10.9304C18.1985 10.862 18.1101 10.8232 18.0178 10.8232Z" />
      <path d="M12.968 13.7867C12.8525 13.6714 12.6691 13.6569 12.5394 13.7537C11.8537 14.2554 11.2004 14.3667 10.3572 14.3667C8.16987 14.3667 6.39052 12.5873 6.39052 10.4C6.39052 8.43277 7.80186 6.78643 9.74647 6.48555C9.90787 6.4607 10.0267 6.3219 10.0267 6.15886V2.83071C10.0267 2.73805 9.98795 2.6496 9.91952 2.58697C9.8511 2.52403 9.762 2.4927 9.66773 2.50144C5.60546 2.85169 2.42383 6.32128 2.42383 10.4C2.42383 14.7744 5.98248 18.3334 10.3572 18.3334C12.1442 18.4225 13.8738 17.827 15.3007 16.628C15.3717 16.5683 15.4143 16.4818 15.4188 16.3892C15.4227 16.2968 15.3878 16.2068 15.322 16.1412L12.968 13.7867Z" />
    </SvgIcon>
  );
}
