import { Grid } from "@mui/material";
import { CellDrillDownInfoBase } from "../../../../../api/biApi.types";
import LedgerPart from "./LedgerPart";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";

interface Props {
  configuration: DrillDownConfiguration;
  info: CellDrillDownInfoBase;
}

export default function LedgerPage({ configuration, info }: Props) {
  return (
    <Grid container sx={{ flex: 1 }}>
      <LedgerPart configuration={configuration} info={info} />
    </Grid>
  );
}
