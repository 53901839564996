import { Autocomplete, Box, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { FieldWithOrder } from "../../../../../hooks/FieldWithOrder";
import { OptionField } from "./ColumnsOptions.types";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloseIconButton from "../../../../../../shared/components/CloseIconButton";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DimensionDescriptor } from "../../../../../api/biApi.types";
import { COLUMN_DD_ITEM_TYPE } from "./ColumnsOptions.types";

interface Props {
  field: FieldWithOrder;
  options: OptionField[];
  sx?: SxProps<Theme>;
  addShifting: boolean;
  draggingItemMovedFromInitialPosition: boolean;
  hideDraggingItem: boolean;
  onRemove: (field: FieldWithOrder) => void;
  onEndReordering: (field: FieldWithOrder) => void;
  onItemHovered: (field: FieldWithOrder) => void;
  onReplaceWith: (oldField: FieldWithOrder, newField: DimensionDescriptor) => void;
}

const ColumnItem = (props: Props) => {
  const {
    field,
    options,
    sx,
    onItemHovered,
    onRemove,
    addShifting,
    draggingItemMovedFromInitialPosition,
    hideDraggingItem,
    onEndReordering,
    onReplaceWith,
  } = props;

  const itemRef = useRef<HTMLDivElement>(null);

  const selectedOption = useMemo(() => options.find((o) => o.id === field.field.name), [options, field.field]);

  const hover = useCallback(
    (_: { field: FieldWithOrder }, monitor: DropTargetMonitor<{ field: FieldWithOrder }>) => {
      if (monitor.isOver()) {
        onItemHovered?.(field);
      }
    },
    [field, onItemHovered]
  );

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: COLUMN_DD_ITEM_TYPE,
    item: { field, options },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: (item) => onEndReordering(item.field),
  }));

  const [, drop] = useDrop(() => ({ accept: [COLUMN_DD_ITEM_TYPE], hover }), []);

  drag(drop(itemRef));

  useEffect(() => {
    dragPreview(getEmptyImage());
  }, [dragPreview]);

  const handleRemove = useCallback(() => {
    onRemove.call(this, field);
  }, [field, onRemove]);

  const handleReplaceField = useCallback(
    (_: React.SyntheticEvent<Element, Event>, newField: OptionField) => {
      if (newField?.field) {
        onReplaceWith.call(this, field, newField.field);
      }
    },
    [field, onReplaceWith]
  );

  return (
    <Stack
      component="div"
      role="Handle"
      ref={itemRef}
      data-index={field.order}
      data-is-dragging={isDragging}
      data-is-shifted={addShifting}
      sx={{
        display: hideDraggingItem ? "none" : "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 0.7,
        borderRadius: "4px",
        ":hover": {
          cursor: "pointer",
        },
        height: "37px",
        ...(!draggingItemMovedFromInitialPosition && {
          "&:hover": {
            bgcolor: !isDragging ? "rgba(0, 0, 0, 0.04)" : "inherit",
            "& .remove": {
              visibility: field.canBeRemoved ? "visible" : "hidden",
            },
          },
        }),
        ...sx,
      }}
      style={{
        ...(addShifting && {
          transform: "translate(0px, 37px)",
        }),
        ...(draggingItemMovedFromInitialPosition
          ? { transition: "transform 0.2s cubic-bezier(0.2, 0, 0, 1)" }
          : { transition: "none 0s ease 0s" }),
      }}
    >
      <DragIndicatorIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
      <Autocomplete
        disablePortal
        autoSelect
        sx={{
          flex: 1,
          fieldset: {
            borderColor: "#DDDFE2",
          },
        }}
        options={options}
        value={selectedOption}
        groupBy={groupBy}
        disableClearable={true}
        onChange={handleReplaceField}
        renderInput={(params) => <TextField {...params} />}
        renderOption={(props, op) => (
          <Box {...props} key={op.id} component="li">
            <Typography>{op.label}</Typography>
          </Box>
        )}
      />
      <CloseIconButton className="remove" onClick={handleRemove} sx={{ visibility: "hidden" }} />
    </Stack>
  );
};

function groupBy(option: OptionField) {
  return option.group;
}

export default ColumnItem;
