import { useRef, useEffect } from "react";

const useDebounce = <T extends unknown[], U>(
  callback: (...arg: T) => PromiseLike<U> | U,
  delay: number | undefined,
  cleanUp: boolean | undefined = false
) => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  function clearTimer() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }

  useEffect(() => (cleanUp ? clearTimer : undefined), [cleanUp]);

  return (...args: T): Promise<U> => {
    clearTimer();
    return new Promise((resolve) => {
      timeoutRef.current = setTimeout(() => resolve(callback(...args)), delay);
    });
  };
};

export default useDebounce;
