import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import { IconButton } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch } from "react-redux";
import { currentReportActions } from "../../../store/currentReportSlice";

export default function RefreshGridButton() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [className, setClassName] = React.useState("");

  const refreshReport = React.useCallback(() => {
    setClassName(classes.rotateIcon);
    dispatch(currentReportActions.refresh());
    setTimeout(() => {
      setClassName("");
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.rotateIcon]);

  return (
    <IconButton onClick={refreshReport}>
      <AutorenewRoundedIcon
        className={className}
        sx={(theme) => ({ "&:hover": { color: theme.palette.primary.main } })}
      />
    </IconButton>
  );
}

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 1s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  })
);
