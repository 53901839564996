import { Theme, Typography } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import { DataGridPremium, GridColDef, GridRowClassNameParams } from "@mui/x-data-grid-premium";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { generateGuid } from "../../../../../../shared/utilities/generateGuid";
import { nameof } from "../../../../../../shared/utilities/typeHelper";
import { CashFlowValue, IrrDrillDownInfo } from "../../../../../api/biApi.types";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";
import { drillDownActions } from "../../../../../store/drilldownSlice";

interface Props {
  configuration: DrillDownConfiguration;
  info: IrrDrillDownInfo;
}
export function CashFlowGrid({ configuration, info }: Props) {
  const dispatch = useDispatch();
  const records = useMemo((): CashFlowValueRecord[] => {
    return (info.cashFlowValues || []).map((v) => ({ ...v, id: generateGuid() }));
  }, [info.cashFlowValues]);

  const handleCellClick = useCallback(
    (row: CashFlowValueRecord) => {
      if (row.drillDownInfo === undefined) return;

      const newConfiguration = createSubDrillDown(row, configuration);
      if (newConfiguration !== undefined) {
        dispatch(drillDownActions.add(newConfiguration));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configuration]
  );

  const columns = useMemo((): GridColDef<CashFlowValueRecord>[] => {
    return [
      {
        field: nameof<CashFlowValueRecord>("description"),
        width: 150,
        renderHeader: () => <></>,
        renderCell: (params) => renderNav(params.row),
        ...getDefaultHeaderSettings(),
      },
      {
        field: nameof<CashFlowValueRecord>("dateFormatted"),
        flex: 1,
        headerName: info.referenceDateFieldCaption || "Date",
        ...getDefaultHeaderSettings(),
      },
      {
        field: nameof<CashFlowValueRecord>("amountFormatted"),
        headerName: info.amountFieldCaption || "Amount",
        width: 190,
        maxWidth: 210,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => <AmountCellRenderer row={params.row} onClick={handleCellClick}></AmountCellRenderer>,
        ...getDefaultHeaderSettings(),
      },
      {
        field: "id",
        renderHeader: () => <></>,
        renderCell: () => <></>,
        flex: 1.5,
        ...getDefaultHeaderSettings(),
      },
    ];
  }, [handleCellClick, info.amountFieldCaption, info.referenceDateFieldCaption]);

  const topPinnedRows = useMemo(() => {
    return records.filter((r) => r.isNav === true && r.isBeginningOfPeriod === true);
  }, [records]);
  const bottomPinnedRows = useMemo(() => records.filter((r) => r.isNav === true && !r.isBeginningOfPeriod), [records]);

  if (records.length === 0) {
    return <>No data</>;
  }
  return (
    <DataGridPremium<CashFlowValueRecord>
      getRowId={getRowId}
      getRowClassName={getRowClassName}
      rows={records}
      columns={columns}
      rowHeight={36}
      columnHeaderHeight={36}
      hideFooter
      pinnedRows={{ top: topPinnedRows, bottom: bottomPinnedRows }}
      rowSelection={false}
      sx={(theme) => ({
        ".MuiDataGrid-main": {
          flexGrow: 0,
        },
        ".MuiDataGrid-pinnedRows": {
          bgcolor: theme.palette.background.paper,
          "&.MuiDataGrid-pinnedRows--top": {
            boxShadow: "0px 1px 4px -2px rgba(0, 0, 0, 0.21)",
          },
          "&.MuiDataGrid-pinnedRows--bottom": {
            boxShadow: "0px -2px 4px -2px rgba(0, 0, 0, 0.21)",
          },
        },
        ".MuiDataGrid-container--top [role=row], .MuiDataGrid-container--bottom [role=row]": {
          "&.nav-row": {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        },
      })}
    />
  );
}

type CashFlowValueRecord = CashFlowValue & { id: string };

function getRowId(row: { id: string }) {
  return row.id;
}

function getRowClassName(params: GridRowClassNameParams<CashFlowValueRecord>): string {
  return params.row.isNav === true ? "nav-row" : "";
}

function renderNav(row: CashFlowValueRecord) {
  if (row.isNav === true) {
    return <Typography variant="subtitle2">{row.description}</Typography>;
  }
  return null;
}

function getDefaultHeaderSettings(): Partial<GridColDef> {
  return {
    aggregable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
  };
}

function AmountCellRenderer({
  row,
  onClick,
}: {
  row: CashFlowValueRecord;
  onClick: (row: CashFlowValueRecord) => void;
}) {
  const handleCellClick = useCallback(() => {
    onClick(row);
  }, [onClick, row]);

  const sx = useMemo((): SystemStyleObject<Theme> => {
    if (row.drillDownInfo === undefined) return {};
    return {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    };
  }, [row.drillDownInfo]);

  return (
    <Typography variant={row.isNav ? "subtitle2" : "body1"} align="right" sx={{ ...sx }} onClick={handleCellClick}>
      {row.amountFormatted}
    </Typography>
  );
}

function createSubDrillDown(row: CashFlowValueRecord, configuration: DrillDownConfiguration) {
  const measure = getSubDrillDownMeasure(configuration, row);

  const newConfiguration: DrillDownConfiguration = {
    id: generateGuid(),
    measure,
    cell: {
      value: row.amount,
      formattedValue: row.amountFormatted,
    },
    info: row.drillDownInfo,
    onlyLedger: false,
    chartOfAccounts: false,
  };

  return newConfiguration;
}

function getSubDrillDownMeasure(configuration: DrillDownConfiguration, row: CashFlowValueRecord) {
  const measure = cloneDeep(configuration.measure);
  if (measure !== undefined) {
    measure.config.customLabel = row.description;
  }

  return measure;
}
