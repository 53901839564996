import { ClientInfoBase } from "../api/clientTypes";

export function getOrganization(clientCode: string, clients: ClientInfoBase[]) {
  const client = clients.find((c) => c.clientCode === clientCode);
  if (!client) {
    return undefined;
  }
  if (client.type === "FundAdmin") {
    return client.clientCode;
  }
  const currentClientOrganizationCode =
    client.managedByClients.find((code) => !isEntriliaClientCode(code)) ?? client.managedByClients[0];
  return currentClientOrganizationCode;
}

const entriliaClientCodes = ["entrilia", "entrilia51"];
const entriliaManagedOrganizations = ["entriliapartners", "optimafs"];

export const isEntriliaClientCode = (clientCode: string) => entriliaClientCodes.includes(clientCode);
export const isEntriliaManagedOrganization = (clientCode: string) => entriliaManagedOrganizations.includes(clientCode);
