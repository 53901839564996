import { Grid, SxProps, Theme, Typography } from "@mui/material";

interface Props {
  text?: string;
  sx?: SxProps<Theme> | undefined;
}

export const PropertyName = ({ text, sx }: Props) => {
  return (
    <Grid container sx={{ ...sx, alignItems: "center" }}>
      <Typography variant="caption" sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    </Grid>
  );
};
