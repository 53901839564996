import { Divider, Grid, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import LoaderBox from "../../../../../shared/components/LoaderBox";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { MeasureDescriptor } from "../../../../api/biApi.types";
import { selectChartOfAccounts, selectMeasures, selectMeasuresStructure } from "../../../../store/metaDataSlice";
import SplitPane from "../../../common/split-pane/SplitPane";
import ExpandableDialog from "../../../ExpandableDialog";
import FormulaContainer from "../customMeasure/FormulaContainer";
import { useCustomMeasure } from "../customMeasure/hooks/useCustomMeasure";
import { ChartOfAccountsContextProvider } from "./contexts/ChartOfAccountsContext";
import LedgerAccountsContainer from "./LedgerAccountsContainer";
import MeasureSelector from "./MeasureSelector";
import { Properties } from "./properties/Properties";

interface ChartOfAccountsProps {
  initialMeasure: MeasureDescriptor;
  onClose: () => void;
}
export default function ChartOfAccounts({ initialMeasure, onClose }: ChartOfAccountsProps) {
  const storedMeasures = useSelector(selectMeasures);
  const measuresStructure = useSelector(selectMeasuresStructure);
  const chartOfAccounts = useSelector(selectChartOfAccounts);
  const [selectedMeasure, setSelectedMeasure] = useState<MeasureDescriptor>(initialMeasure);
  const state = useCustomMeasure({ measure: selectedMeasure });
  const onMeasureSelect = useCallback(
    (measure: MeasureDescriptor) => {
      setSelectedMeasure(measure);
    },
    [setSelectedMeasure]
  );

  return (
    <ExpandableDialog title={<Typography variant="h6">Chart of Accounts</Typography>} separateTitle onClose={onClose}>
      {state.dataLoadingError ? (
        <DataLoadingFailed text={state.dataLoadingError} sx={{ bgcolor: "white" }} />
      ) : (
        <LoaderBox loading={state.loading}>
          <Grid container sx={{ flex: 1, flexWrap: "nowrap", position: "relative" }}>
            <ChartOfAccountsContextProvider>
              <SplitPane initialPrimarySize="30%" minPrimarySize="350px" minSecondarySize="350px">
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "100%",
                    flexWrap: "nowrap",
                    pt: 2.5,
                    pb: 1.25,
                    pl: 3,
                    pr: 1.5,
                    gap: 2,
                  }}
                >
                  <MeasureSelector
                    measures={storedMeasures}
                    measuresStructure={measuresStructure}
                    measure={selectedMeasure}
                    onSelect={onMeasureSelect}
                  />
                  <FormulaContainer state={state} measure={selectedMeasure} editable={false} hideCheck />
                  <Divider sx={{ mr: -1 }} />
                  <ScrollableFlexContainer sx={{ "& .MuiBox-root": { overflowX: "hidden" } }}>
                    <Properties state={state} />
                  </ScrollableFlexContainer>
                </Grid>
                <LedgerAccountsContainer chartOfAccounts={chartOfAccounts} measure={selectedMeasure} />
              </SplitPane>
            </ChartOfAccountsContextProvider>
          </Grid>
        </LoaderBox>
      )}
    </ExpandableDialog>
  );
}
