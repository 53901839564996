import { Divider, Menu, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { ReportAccessType } from "../../api/biClient.types";
import { MenuItemStyled } from "../../../components/MenuItemStyled";

export interface AccessModeMenuProps {
  open: boolean;
  menuRef: HTMLDivElement | undefined | null;
  access: ReportAccessType;
  allowedAccesses: ReportAccessType[];
  hasRemove?: boolean;
  onSelect: (access: ReportAccessType) => void;
  onRemove?: () => void;
  onClose: () => void;
}

export function AccessModeMenu({
  open,
  menuRef,
  access,
  hasRemove,
  allowedAccesses,
  onSelect,
  onRemove,
  onClose,
}: AccessModeMenuProps) {
  const handleOnSelect = useCallback(
    (access: ReportAccessType) => {
      onSelect(access);
      onClose();
    },
    [onSelect, onClose]
  );

  return (
    <Menu open={open} anchorEl={menuRef} transformOrigin={{ horizontal: "left", vertical: "center" }} onClose={onClose}>
      {allowedAccesses?.includes(ReportAccessType.Owner) && (
        <AcessMenuItem
          mainText="Owner"
          subText="An owner with ultimate access"
          access={ReportAccessType.Owner}
          selected={access === ReportAccessType.Owner}
          onSelect={handleOnSelect}
        />
      )}
      <AcessMenuItem
        mainText="Full access"
        subText="Can edit and share with others"
        disabled={!allowedAccesses?.includes(ReportAccessType.FullAccess)}
        access={ReportAccessType.FullAccess}
        selected={access === ReportAccessType.FullAccess}
        onSelect={handleOnSelect}
      />
      <AcessMenuItem
        mainText="Can edit"
        subText="Can edit, but not share with others"
        disabled={!allowedAccesses?.includes(ReportAccessType.Write)}
        access={ReportAccessType.Write}
        selected={access === ReportAccessType.Write}
        onSelect={handleOnSelect}
      />
      <AcessMenuItem
        mainText="Can view"
        subText="Cannot edit or share with others"
        disabled={!allowedAccesses?.includes(ReportAccessType.Read)}
        access={ReportAccessType.Read}
        selected={access === ReportAccessType.Read}
        onSelect={handleOnSelect}
      />
      {allowedAccesses?.includes(ReportAccessType.NoAccess) && (
        <AcessMenuItem
          mainText="No access"
          subText=""
          access={ReportAccessType.NoAccess}
          selected={access === ReportAccessType.NoAccess}
          onSelect={handleOnSelect}
        />
      )}
      {hasRemove && [
        <Divider key={"hasremove-divider"} />,
        <MenuItemStyled key={"hasremove-item"} onClick={() => onRemove?.()}>
          <Typography variant="body1" color={"error"}>
            Remove
          </Typography>
        </MenuItemStyled>,
      ]}
    </Menu>
  );
}

interface AccessMenuItemProps {
  mainText: string;
  subText?: string;
  access: ReportAccessType;
  selected: boolean;
  disabled?: boolean;
  onSelect: (access: ReportAccessType) => void;
}
function AcessMenuItem({ mainText, subText, access, selected, disabled, onSelect }: AccessMenuItemProps) {
  return (
    <MenuItemStyled disabled={disabled} selected={selected} onClick={() => onSelect(access)}>
      <Stack>
        <Typography variant="body1">{mainText}</Typography>
        {subText && (
          <Typography
            variant="body2"
            color={"secondary"}
            sx={(theme) => ({ color: `${theme.palette.secondary.main} !important` })}
          >
            {subText}
          </Typography>
        )}
      </Stack>
    </MenuItemStyled>
  );
}
