import { AnyObject } from "../../../../../shared/types";
import { GroupTotal, TabularColumn, TabularRow } from "../../../../api/biApi.types";

export enum GridDataStateActionType {
  UPDATE_STATE = "UPDATE_STATE",
  LOADING_STARTED = "LOADING_STARTED",
  LOADING_ENDED = "LOADING_ENDED",
  LOADING_GROUPS_STARTED = "LOADING_GROUPS_STARTED",
  LOADING_GROUPS_ENDED = "LOADING_GROUPS_ENDED",
}

export type UpdateGridDataStateAction = {
  type: GridDataStateActionType.UPDATE_STATE;
  payload: Partial<GridDataState>;
};
export type LoadingStartedAction = {
  type: GridDataStateActionType.LOADING_STARTED;
};
export type LoadingEndedAction = {
  type: GridDataStateActionType.LOADING_ENDED;
};
export type GroupLoadingStartedAction = {
  type: GridDataStateActionType.LOADING_GROUPS_STARTED;
};
export type GroupLoadingEndedAction = {
  type: GridDataStateActionType.LOADING_GROUPS_ENDED;
};

export type ReducerAction =
  | UpdateGridDataStateAction
  | LoadingStartedAction
  | LoadingEndedAction
  | GroupLoadingStartedAction
  | GroupLoadingEndedAction;

export type GridDataState = {
  error?: string;
  rows: TabularRow[];
  groupTotals: GroupTotal[];
  grandTotals: AnyObject;
  totalCount: number;
  loading: number;
  groupTotalsLoading: number;
  requestCount: number;
  columns: TabularColumn[];
};

export const initialGridDataState: GridDataState = {
  grandTotals: {},
  groupTotals: [],
  groupTotalsLoading: 0,
  loading: 0,
  requestCount: 0,
  rows: [],
  totalCount: 0,
  columns: [],
};

export const gridDataStateReducer = (actualState: GridDataState, action: ReducerAction) => {
  switch (action.type) {
    case GridDataStateActionType.UPDATE_STATE: {
      const state: GridDataState = {
        ...actualState,
        ...action.payload,
      };
      return state;
    }
    case GridDataStateActionType.LOADING_STARTED: {
      const state: GridDataState = {
        ...actualState,
        loading: actualState.loading + 1,
      };
      return state;
    }
    case GridDataStateActionType.LOADING_ENDED: {
      const state: GridDataState = {
        ...actualState,
        loading: actualState.loading - 1,
      };
      return state;
    }
    case GridDataStateActionType.LOADING_GROUPS_STARTED: {
      const state: GridDataState = {
        ...actualState,
        groupTotalsLoading: actualState.groupTotalsLoading + 1,
      };
      return state;
    }
    case GridDataStateActionType.LOADING_GROUPS_ENDED: {
      const state: GridDataState = {
        ...actualState,
        groupTotalsLoading: actualState.groupTotalsLoading - 1,
      };
      return state;
    }
    default:
      return actualState;
  }
};
