import { Stack, SxProps, Theme, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useLocalization } from "../hooks/useLocalization";
import DataErrorIcon from "../icons/DataErrorIcon";

interface Props {
  title?: string;
  text?: string;
  sx?: SxProps<Theme>;
  bgColor?: string;
}

const DataLoadingFailed = ({ text, title, bgColor, sx }: Props) => {
  const { common: locale } = useLocalization();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
      flex={1}
      sx={{ backgroundColor: bgColor || grey[50], ...sx }}
    >
      <DataErrorIcon />
      <Stack spacing={1} alignItems="center" justifyContent="center">
        <Typography variant="h6">{title || locale.dataLoadingFailed.title}</Typography>
        <Typography>{text || locale.dataLoadingFailed.text}</Typography>
      </Stack>
    </Stack>
  );
};

export default DataLoadingFailed;
