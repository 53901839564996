import { ConditionConfiguration } from "../../../../shared/reporting/api/biClient.types";

export const resetMandatoryField = (
  fieldConfiguration: ConditionConfiguration,
  changes: Partial<ConditionConfiguration>
) => {
  if (changes.parameter === false && fieldConfiguration.mandatory === true) {
    changes.mandatory = false;
  }
  return changes;
};
