import { SvgIcon, SvgIconProps } from "@mui/material";

export const RenameReportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M7.33332 16L10.6667 12.6666H15.6667C16.125 12.6666 16.5175 12.8297 16.8442 13.1558C17.1703 13.4825 17.3333 13.875 17.3333 14.3333C17.3333 14.7916 17.1703 15.1841 16.8442 15.5108C16.5175 15.8369 16.125 16 15.6667 16H7.33332ZM2.33332 14.3333H3.49999L10.6875 7.14579L9.52082 5.97913L2.33332 13.1666V14.3333ZM14.25 5.93746L10.7083 2.43746L11.875 1.27079C12.1944 0.951348 12.5869 0.791626 13.0525 0.791626C13.5175 0.791626 13.9097 0.951348 14.2292 1.27079L15.3958 2.43746C15.7153 2.7569 15.8819 3.14246 15.8958 3.59413C15.9097 4.04524 15.7569 4.43051 15.4375 4.74996L14.25 5.93746ZM1.49999 16C1.26388 16 1.0661 15.92 0.906657 15.76C0.746657 15.6005 0.666656 15.4027 0.666656 15.1666V12.8125C0.666656 12.7013 0.68749 12.5938 0.729157 12.49C0.770823 12.3855 0.833323 12.2916 0.916656 12.2083L9.49999 3.62496L13.0417 7.16663L4.45832 15.75C4.37499 15.8333 4.28138 15.8958 4.17749 15.9375C4.07305 15.9791 3.96527 16 3.85416 16H1.49999Z" />
    </SvgIcon>
  );
};
export default RenameReportIcon;
