import { Stack } from "@mui/material";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Report,
  ReportConfiguration,
  ReportTemplate,
  ReportType,
} from "../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../shared/utilities/cloneDeep";
import { selectIsReportChanged, selectReportConfiguration } from "../../../store/currentReportSlice";
import { selectCompanies } from "../../../store/reportTemplatesSlice";
import { areAllFieldsEmpty, isNewReport } from "../../../utilities/Utilities";
import { VisualizationContextProvider } from "./context/VisualizationContext";
import ReportTypesContainer from "./ReportTypesContainer";
import SaveReportChangesDialog from "./SaveReportChangesDialog";
import SaveTemplateChangesDialog from "./SaveTemplateChangesDialog";
import UnsavedChangesDialog from "./UnsavedChangesDialog";
import VisualizationLabel from "./VisualizationLabel";

type ReportEntity = Report | ReportTemplate;

export interface VisualizationProps<T extends ReportEntity> {
  report: T;
  update: (report: T) => Promise<T | undefined>;
  onSelect: (newType: ReportType) => void;
  onSaveChangesSaved?: (report: T) => void;
}

export default function Visualization<T extends ReportEntity>({
  report,
  update,
  onSelect,
  children,
  onSaveChangesSaved,
}: PropsWithChildren<VisualizationProps<T>>) {
  const isReportChanged = useSelector(selectIsReportChanged);
  const reportConfiguration = useSelector(selectReportConfiguration);
  const companies = useSelector(selectCompanies);
  const [showUnsavedTooltip, setShowUnsavedTooltip] = useState(false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [newType, setNewType] = useState<ReportType>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [isConfigurationEmpty, setIsConfigurationEmpty] = useState<boolean>();

  const handleReportTypeClick = useCallback(
    (newType: ReportType, ref: HTMLButtonElement | null) => {
      if (report.reportType === newType) return;
      if (isConfigurationEmpty === false && isReportChanged === true && ref !== null) {
        setNewType(newType);
        setAnchorEl(ref);
        setShowUnsavedTooltip(true);
      } else {
        onSelect(newType);
      }
    },
    [report.reportType, isReportChanged, onSelect, isConfigurationEmpty]
  );

  const handleCloseUnsavedChangesDialog = useCallback(() => {
    setShowUnsavedTooltip(false);
    setAnchorEl(undefined);
  }, []);

  const handleSave = useCallback(async () => {
    if (!report || !reportConfiguration) return;
    const reportToUpdate = cloneDeep(report);
    reportToUpdate.configuration = reportConfiguration;

    if (isNewReport(reportToUpdate)) {
      setShowSaveDialog(true);
    } else {
      const updatedReport = await update(reportToUpdate);
      if (!updatedReport) {
        return;
      }
    }
    handleCloseUnsavedChangesDialog();
  }, [update, report, reportConfiguration, handleCloseUnsavedChangesDialog]);

  const handleContinue = useCallback(() => {
    if (newType !== undefined) onSelect(newType);
    setShowUnsavedTooltip(false);
    setAnchorEl(undefined);
  }, [newType, onSelect]);

  const handleCloseSaveChangesDialog = useCallback(() => {
    setShowSaveDialog(false);
  }, []);

  const handleSaveChangesSaved = useCallback(
    (report: ReportEntity) => {
      handleCloseSaveChangesDialog();
      onSaveChangesSaved?.(report as T);
    },
    [handleCloseSaveChangesDialog, onSaveChangesSaved]
  );

  useEffect(() => {
    const configuration = reportConfiguration as ReportConfiguration;
    if (configuration) {
      const { settings, ...fields } = configuration;
      const isConfigurationEmpty = fields && areAllFieldsEmpty(fields);
      setIsConfigurationEmpty(isConfigurationEmpty);
    }
  }, [reportConfiguration, report]);

  if (!report) return null;

  return (
    <VisualizationContextProvider
      report={report}
      companies={companies}
      reportConfiguration={reportConfiguration}
      isReportChanged={isReportChanged}
      onReportTypeClicked={handleReportTypeClick}
      isConfigurationEmpty={isConfigurationEmpty}
      showUnsavedTooltip={showUnsavedTooltip}
      showSaveDialog={showSaveDialog}
      unsavedChangesPopoverAnchor={anchorEl}
      onSaveChange={handleSave}
      onContinueUnsavedChanges={handleContinue}
      onCloseUnsavedChanges={handleCloseUnsavedChangesDialog}
      onCloseSaveChanges={handleCloseSaveChangesDialog}
      onSaveChangesSaved={handleSaveChangesSaved}
    >
      <Stack gap={2}>{children}</Stack>
    </VisualizationContextProvider>
  );
}

Visualization.Label = VisualizationLabel;
Visualization.ReportTypesContainer = ReportTypesContainer;
Visualization.UnsavedChangesPopover = UnsavedChangesDialog;
Visualization.SaveReportDialog = SaveReportChangesDialog;
Visualization.SaveTemplateDialog = SaveTemplateChangesDialog;
