import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, Grid, IconButton, Typography } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";

interface Props {
  title: string;
}

export const ExpandeGroup = ({ title, children }: PropsWithChildren<Props>) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <Grid container sx={(theme) => ({ flex: 1, border: "1px solid " + theme.palette.divider, px: 1 })}>
      <Grid container sx={{ alignItems: "center" }}>
        <Typography variant="subtitle1">{title}</Typography>
        <HorizontalFill />
        <IconButton color="secondary" onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Grid>
      <Collapse orientation="vertical" in={expanded} sx={{ width: "100%" }}>
        <Grid container py={1} gap={1}>
          {children}
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default ExpandeGroup;
