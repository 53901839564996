import { LoadingButton } from "@mui/lab";
import { Alert, Button, DialogActions, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { ApiResponse } from "../../../api/types";
import DialogHeader from "../../../components/dialog/DialogHeader";
import PromptDialog from "../../../components/dialog/PromptDialog";
import useRequest from "../../../hooks/useRequest";
import DeleteDocumentIcon from "../../../icons/DeleteDocumentIcon";
import { DeleteReportItem } from "../../api/biClient.types";
import { useSharedReportingLocalization } from "../../hooks/useLocalization";
import { FromReport } from "./FromReport";

interface Props {
  reports: FromReport[];
  doDelete: (reports: DeleteReportItem[]) => Promise<ApiResponse<unknown>>;
  onDeleted?: () => void;
  onClose: () => void;
}

export default function DeleteReport({ reports, doDelete, onClose, onDeleted }: Props) {
  const { report: locale } = useSharedReportingLocalization();

  const deleteReports = useCallback(
    () => doDelete(reports.map((r) => ({ clientCode: r.clientCode, reportId: r.reportId }))),
    [doDelete, reports]
  );

  const { request, error, isLoading: deleting } = useRequest(deleteReports);

  const handleDeleteReports = async () => {
    const [, error] = await request();
    if (!error) {
      onDeleted?.();
      onClose();
    }
  };

  const isSingleReport = reports.length === 1;

  return (
    <PromptDialog open={true} sx={{ width: 514, minHeight: 305 }}>
      <DialogHeader text="Delete Report" disabled={deleting} onClose={onClose} />
      {error && (
        <Alert sx={{ mb: ".5rem" }} severity="error">
          {locale.delete_error}
        </Alert>
      )}
      <Stack
        sx={{
          flexDirection: "column",
          flex: 1,
          gap: 3,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DeleteDocumentIcon />
        <Stack gap={1} alignItems="center">
          <Typography variant="h6" fontFamily="Roboto" color={(theme) => theme.palette.text.primary}>
            {isSingleReport ? `Delete ${reports.at(0)?.name}` : `Delete ${reports.length} reports`}
          </Typography>
          <Typography color={(theme) => theme.palette.text.secondary}>
            {isSingleReport ? locale.deleted_question : locale.deleted_question_plural}
          </Typography>
        </Stack>
      </Stack>

      <DialogActions sx={{ px: 2.5, py: 1.5 }}>
        <Button variant="text" color="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          color="error"
          disabled={deleting}
          loading={deleting}
          onClick={handleDeleteReports}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </PromptDialog>
  );
}
