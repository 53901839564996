import { useCallback, useState } from "react";
import { ReportGroup } from "../../api/biClient.types";
import ReportGroupForm from "./ReportGroupForm";
import { useGroupsContext } from "../groups/contexts/GroupsContext";
import { defined } from "../../../utilities/typeHelper";
import ReportGroupsConnector from "../reports/ReportGroupsConnector";
import PromptDialog from "../../../components/dialog/PromptDialog";
import { useSharedReportingLocalization } from "../../hooks/useLocalization";

interface Props {
  clientCode: string;
  onAdded: (group: ReportGroup) => void;
  onClose: () => void;
}

const AddGroupDialog = (props: Props) => {
  const { clientCode, onAdded, onClose } = props;

  const { report_groups } = useSharedReportingLocalization();
  const { groups: reportGroups, actions: groupActions } = useGroupsContext();

  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);

  const onSave = useCallback(
    (caption: string) => {
      setSaving(true);
      let maxOrder = 0;
      defined(reportGroups[clientCode]).forEach((rg) => {
        maxOrder = Math.max(maxOrder, rg.order);
      });

      groupActions
        .add(clientCode, { caption, order: maxOrder + 1 } as ReportGroup)
        .then((newGroup) => {
          if (!newGroup) {
            setError(report_groups.add_new_error);
            setSaving(false);
            return;
          }
          onAdded(newGroup);
          onClose();
        })
        .catch(() => {
          setError(report_groups.add_new_error);
          setSaving(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportGroups, onAdded, onClose]
  );

  return (
    <PromptDialog open={true}>
      <ReportGroupsConnector clientCode={clientCode}>
        <ReportGroupForm
          group={{ caption: "" } as ReportGroup}
          title="New Group"
          saving={saving}
          onCancel={onClose}
          error={error}
          onSave={onSave}
        />
      </ReportGroupsConnector>
    </PromptDialog>
  );
};

export default AddGroupDialog;
