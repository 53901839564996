import { SvgIcon, SvgIconProps } from "@mui/material";

export const BarChartIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M2.49935 17.5C2.26324 17.5 2.06546 17.42 1.90602 17.26C1.74602 17.1006 1.66602 16.9028 1.66602 16.6667V8.33333C1.66602 8.09722 1.74602 7.89917 1.90602 7.73917C2.06546 7.57972 2.26324 7.5 2.49935 7.5H5.41602C5.65213 7.5 5.85018 7.57972 6.01018 7.73917C6.16963 7.89917 6.24935 8.09722 6.24935 8.33333V16.6667C6.24935 16.9028 6.16963 17.1006 6.01018 17.26C5.85018 17.42 5.65213 17.5 5.41602 17.5H2.49935ZM8.54102 17.5C8.3049 17.5 8.10713 17.42 7.94768 17.26C7.78768 17.1006 7.70768 16.9028 7.70768 16.6667V3.33333C7.70768 3.09722 7.78768 2.89917 7.94768 2.73917C8.10713 2.57972 8.3049 2.5 8.54102 2.5H11.4577C11.6938 2.5 11.8918 2.57972 12.0518 2.73917C12.2113 2.89917 12.291 3.09722 12.291 3.33333V16.6667C12.291 16.9028 12.2113 17.1006 12.0518 17.26C11.8918 17.42 11.6938 17.5 11.4577 17.5H8.54102ZM14.5827 17.5C14.3466 17.5 14.1488 17.42 13.9893 17.26C13.8293 17.1006 13.7493 16.9028 13.7493 16.6667V10C13.7493 9.76389 13.8293 9.56583 13.9893 9.40583C14.1488 9.24639 14.3466 9.16667 14.5827 9.16667H17.4993C17.7355 9.16667 17.9332 9.24639 18.0927 9.40583C18.2527 9.56583 18.3327 9.76389 18.3327 10V16.6667C18.3327 16.9028 18.2527 17.1006 18.0927 17.26C17.9332 17.42 17.7355 17.5 17.4993 17.5H14.5827Z" />
    </SvgIcon>
  );
};
export default BarChartIcon;
