import { Stack } from "@mui/material";
import FormatSettings from "../../common/fields/format/FormatSettings";
import { GeneralField } from "../../Types";
import { FormatType, PivotFieldConfiguration } from "../../../../../shared/reporting/api/biClient.types";

interface Props {
  dimensions: GeneralField[];
  onUpdateConfig: (dimension: GeneralField, changes: Partial<PivotFieldConfiguration>) => void;
}

export default function DimensionFormattingContainer({ dimensions, onUpdateConfig }: Props) {
  return (
    <Stack gap={1}>
      {dimensions
        .filter((d) => d.meta.defaultFormat?.formatType === FormatType.Numeric)
        .map((value) => (
          <FormatSettings
            key={value.meta.name}
            label={value.meta.caption}
            format={
              value.config.format ||
              (value.meta.defaultFormat?.formatType === "Numeric" ? value.meta.defaultFormat : undefined)
            }
            onUpdateFormatter={(formatter) => onUpdateConfig(value, { format: formatter })}
          />
        ))}
    </Stack>
  );
}
