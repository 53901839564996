import { Badge, Typography } from "@mui/material";

interface Props {
  conditionsCount: number;
}

export const ConditionsCollapsedHeader = (props: Props) => {
  const { conditionsCount } = props;
  return (
    <>
      <Typography variant="h6" sx={(theme) => ({ color: theme.palette.text.primary, transform: "rotate(90deg)" })}>
        Conditions
      </Typography>
      <Badge
        sx={(theme) => ({
          color: theme.palette.secondary.main,
          position: "absolute",
          top: "120px",
          left: "32px",
          ".BaseBadge-badge": { backgroundColor: "rgba(35, 52, 59, 0.08)" },
          transition: "1.5s easy",
        })}
        badgeContent={conditionsCount}
      ></Badge>
    </>
  );
};

export default ConditionsCollapsedHeader;
