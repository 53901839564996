import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { Box, Checkbox, Grid, IconButton } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import AreaFieldItemLoader from "../../fields/areaFields/AreaFieldItemLoader";
import FieldItemCaptions from "../../fields/areaFields/FieldItemCaptions";
import { ChartOfAccountsContextItem, useChartOfAccountsContext } from "../contexts/ChartOfAccountsContext";

interface FilterFieldItemProps<T> {
  field: T;
  formatCaption?: (field: T) => JSX.Element | undefined;
  isLoading?: boolean;
  startIcon: JSX.Element;
  onToggle?: (expanded: boolean) => void;
  disableExpanding?: boolean;
  filterId: string;
  parentNode: ChartOfAccountsContextItem | null;
}

export const FilterFieldItem = <T,>({
  field,
  formatCaption,
  isLoading,
  startIcon,
  onToggle,
  disableExpanding,
  parentNode,
  filterId,
}: FilterFieldItemProps<T>) => {
  const [conditionExpanded, setConditionExpanded] = useState(false);
  const { root, appendChild, getNode, onSelect } = useChartOfAccountsContext();

  const currentNode = useMemo(() => {
    if (!parentNode) return undefined;
    return getNode(filterId, parentNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentNode, filterId, root]);

  useEffect(() => {
    if (!parentNode) return;
    appendChild(filterId, parentNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentNode, filterId]);

  return (
    <>
      {isLoading === true && <AreaFieldItemLoader />}
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          bgcolor: currentNode?.checked ? "rgba(0, 168, 102, 0.08)" : "inherit",
        }}
      >
        <Grid
          container
          sx={{
            alignItems: "center",
            pl: 1,
            pr: 0.625,
            height: "28px",
            width: "auto",
          }}
        >
          {currentNode && (
            <Checkbox
              indeterminate={currentNode.indeterminate === true}
              checked={currentNode.checked === true}
              sx={{ width: "28px", height: "28px" }}
              onChange={(e) => {
                onSelect(currentNode.identifier, e.target.checked);
              }}
            />
          )}
          <IconButton
            color="secondary"
            disabled={disableExpanding}
            onClick={() => {
              setConditionExpanded((prev) => {
                onToggle?.(!prev);
                return !prev;
              });
            }}
            sx={{ width: "28px", height: "28px", "& .MuiSvgIcon-root": disableExpanding ? { color: "#C9CFD6" } : {} }}
          >
            {conditionExpanded ? <ArrowDropDownRoundedIcon /> : <ArrowRightRoundedIcon />}
          </IconButton>
          <Box sx={{ display: "flex", p: 0.5 }}>{startIcon}</Box>
        </Grid>
        <Grid container sx={{ alignItems: "center", pl: 0.625, pr: 1, height: "28px", flex: 1, mt: "auto" }}>
          <FieldItemCaptions field={field} formatCaption={formatCaption} />
        </Grid>
      </Grid>
    </>
  );
};
