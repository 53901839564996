import { SvgIcon, SvgIconProps } from "@mui/material";

export const SortAscIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 21, height: 20 }} viewBox="0 0 21 20" {...props}>
      <path
        d="M10.1463 2.64664L10.1463 2.64658C10.2401 2.55288 10.3672 2.50024 10.4998 2.50024C10.6323 2.50024 10.7595 2.55288 10.8532 2.64658C10.8533 2.6466 10.8533 2.64662 10.8533 2.64664L14.8496 6.64299C14.9391 6.737 14.9886 6.86219 14.9875 6.99209C14.9863 7.12319 14.9338 7.24859 14.8411 7.3413C14.7483 7.434 14.6229 7.48658 14.4918 7.48772C14.3619 7.48885 14.2368 7.43938 14.1427 7.34988L11.8533 5.06048L10.9998 4.20692V5.41403V17C10.9998 17.1326 10.9471 17.2598 10.8533 17.3536C10.7596 17.4474 10.6324 17.5 10.4998 17.5C10.3672 17.5 10.24 17.4474 10.1462 17.3536C10.0525 17.2598 9.99979 17.1326 9.99979 17V5.41403V4.20692L9.14623 5.06048L6.85329 7.35342L9.79279 2.29303L10.1463 2.64664ZM10.1463 2.64664L6.14639 6.64653L10.1463 2.64664ZM6.14629 7.35342C6.05262 7.25967 6 7.13256 6 7.00003C6 6.86747 6.05264 6.74034 6.14634 6.64658L6.85323 7.35348C6.75947 7.44718 6.63234 7.49982 6.49979 7.49982C6.36726 7.49982 6.24015 7.4472 6.14639 7.35353M6.14629 7.35342L5.7928 7.70702L6.14639 7.35353M6.14629 7.35342C6.14632 7.35346 6.14636 7.35349 6.14639 7.35353M6.14629 7.35342C6.14632 7.35346 6.14636 7.35349 6.14639 7.35353"
        fill="#647381"
        stroke="#00A866"
      />
    </SvgIcon>
  );
};
export default SortAscIcon;
