import { Badge, IconButton, Stack, SvgIconProps, Tooltip, Typography } from "@mui/material";
import { createRef } from "react";
import { ReportType } from "../../../../shared/reporting/api/biClient.types";

interface Props {
  reportType: ReportType;
  selectedReportType: ReportType;
  isReportChanged: boolean;
  tooltipText: string;
  onSelected: (newType: ReportType, ref: HTMLButtonElement | null) => void;
  ReactTypeIcon: React.ComponentType<SvgIconProps>;
}

const ReportTypeBadge = ({
  reportType,
  isReportChanged,
  onSelected,
  tooltipText,
  ReactTypeIcon,
  selectedReportType,
}: Props) => {
  const elementRef = createRef<HTMLButtonElement>();
  const selected = reportType === selectedReportType;

  return (
    <CustomBadge show={selected && isReportChanged}>
      <Tooltip title={getToolTipText(tooltipText, selected && isReportChanged)}>
        <IconButton
          ref={elementRef}
          sx={{ borderRadius: "unset", svg: { fill: "none" } }}
          onClick={() => onSelected(reportType, elementRef.current)}
        >
          <ReactTypeIcon
            sx={(theme) => ({ path: { fill: selected ? theme.palette.primary.main : theme.palette.secondary.light } })}
          />
        </IconButton>
      </Tooltip>
    </CustomBadge>
  );
};

const CustomBadge = ({ show, children }: React.PropsWithChildren<{ show: boolean }>) => {
  if (show === false) return <>{children}</>;
  return (
    <Badge
      variant="dot"
      color="primary"
      sx={{
        ".BaseBadge-badge": {
          height: "6px",
          minWidth: "6px",
          transform: "unset",
        },
      }}
    >
      {children}
    </Badge>
  );
};

const getToolTipText = (text: string, selectedAndChanged: boolean) => {
  if (selectedAndChanged) {
    return (
      <Stack>
        <Typography variant="caption">{text}</Typography>
        <Typography variant="caption">* has unsaved changes</Typography>
      </Stack>
    );
  }
  return <Typography variant="caption">{text}</Typography>;
};

export default ReportTypeBadge;
