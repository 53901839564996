import { SvgIcon, SvgIconProps } from "@mui/material";

export const SortDescIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 21, height: 20 }} viewBox="0 0 21 20" {...props}>
      <path
        d="M14.1471 12.6466L11.8541 14.9396L11.0006 15.7931V14.586V3C11.0006 2.86739 10.9479 2.74021 10.8541 2.64645C10.7604 2.55268 10.6332 2.5 10.5006 2.5C10.368 2.5 10.2408 2.55268 10.147 2.64645C10.0532 2.74021 10.0006 2.86739 10.0006 3V14.586V15.7931L9.14702 14.9396L6.85402 12.6466L6.84787 12.6404L6.84792 12.6404C6.8018 12.5926 6.74663 12.5545 6.68563 12.5283C6.62463 12.5021 6.55902 12.4883 6.49263 12.4877C6.42624 12.4872 6.3604 12.4998 6.29895 12.5249C6.2375 12.5501 6.18167 12.5872 6.13473 12.6342C6.08778 12.6811 6.05065 12.7369 6.02551 12.7984C6.00037 12.8598 5.98772 12.9257 5.9883 12.9921C5.98888 13.0584 6.00267 13.1241 6.02887 13.1851C6.05508 13.2461 6.09317 13.3012 6.14093 13.3474L6.14718 13.3534L6.14712 13.3534L10.1471 17.3534C10.1471 17.3534 10.1471 17.3534 10.1471 17.3534C10.2409 17.4471 10.368 17.4998 10.5006 17.4998C10.6331 17.4998 10.7603 17.4471 10.854 17.3534L14.1471 12.6466ZM14.1471 12.6466C14.1471 12.6466 14.1471 12.6466 14.1471 12.6466M14.1471 12.6466L14.1471 12.6466M14.854 12.6465C14.854 12.6465 14.854 12.6466 14.8541 12.6466C14.9477 12.7404 15.0004 12.8675 15.0004 13C15.0004 13.1326 14.9477 13.2597 14.854 13.3534C14.854 13.3535 14.854 13.3535 14.854 13.3535L10.8541 17.3534L14.5006 12.0002C14.7657 12.0002 15.02 12.1055 15.2076 12.293L14.854 12.6465ZM14.854 12.6465C14.7602 12.5528 14.6331 12.5002 14.5006 12.5002C14.368 12.5002 14.2409 12.5529 14.1471 12.6466M14.854 12.6465L14.1471 12.6466"
        fill="#647381"
        stroke="#00A866"
      />
    </SvgIcon>
  );
};
export default SortDescIcon;
