import { ExtendedAxiosError, setUpAxios as setUpAxiosCore } from "./axiosHelper";
import { redirectToEmailVerification, redirectToLoginSignin } from "../auth/Auth";

export const setUpAxios = (errorPipes?: ((error: ExtendedAxiosError) => Promise<never> | undefined)[]) => {
  setUpAxiosCore(redirectToLoginSignin, [isEmailNotVerifiedError, ...(errorPipes ?? [])]);
};

function isEmailNotVerifiedError(axiosError: ExtendedAxiosError): Promise<never> | undefined {
  if (axiosError.response?.status === 400) {
    const apiResponse = axiosError.response.data as { Error: { Type: string } };
    if (apiResponse.Error?.Type === "EmailNotVerified") {
      redirectToEmailVerification();
      return Promise.reject();
    }
  }
  return undefined;
}
