import { Grid, SxProps, Theme, useTheme } from "@mui/material";
import { CustomMeasureReturnType } from "../../customMeasure/hooks/useCustomMeasure";
import VariablePropertyItemWrapper from "./VariablePropertyItemWrapper";
import FunctionPropertyItemWrapper from "./FunctionPropertyItemWrapper";

interface Props {
  state: CustomMeasureReturnType;
  sx?: SxProps<Theme>;
}

export const Properties = ({ state, sx }: Props) => {
  const { palette } = useTheme();
  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        flexWrap: "nowrap",

        gap: 2,
        overflow: "unset",
        "& .MuiSvgIcon-root": {
          color: palette.secondary.light,
        },
        "& .Mui-checked, .MuiCheckbox-indeterminate": {
          "& .MuiSvgIcon-root": {
            color: palette.primary.main,
          },
        },
        ...sx,
      }}
    >
      {state.formulaNodes.map((fn, i) => {
        if (fn.function !== undefined) {
          return (
            <FunctionPropertyItemWrapper key={`${fn.key}-fn-${fn.text}`} formulaNode={fn} state={state} index={i} />
          );
        }
        return <VariablePropertyItemWrapper key={`${fn.key}-${fn.text}`} formulaNode={fn} state={state} index={i} />;
      })}
    </Grid>
  );
};
