import { useState } from "react";
import UnsavedChangesPopover from "../UnsavedChangesPopover";
import { useVisualizationContext } from "./context/VisualizationContext";

interface Props {
  reportKind: "report" | "template";
}

const UnsavedChangesDialog = ({ reportKind }: Props) => {
  const {
    showUnsavedTooltip,
    isConfigurationEmpty,
    unsavedChangesPopoverAnchor,
    onSaveChange,
    onContinueUnsavedChanges,
    onCloseUnsavedChanges,
  } = useVisualizationContext();
  const [isLoading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    await onSaveChange();
    setLoading(false);
  };

  if (showUnsavedTooltip && isConfigurationEmpty === false) {
    return (
      <UnsavedChangesPopover
        open={true}
        anchorEl={unsavedChangesPopoverAnchor}
        onSave={handleSave}
        onContinue={onContinueUnsavedChanges}
        onClose={onCloseUnsavedChanges}
        isLoading={isLoading}
        reportKind={reportKind}
      />
    );
  }

  return null;
};

export default UnsavedChangesDialog;
