import { debounceTime, Subject } from "rxjs";
const subjectDelay = new Subject<string>();

export const DelayObservable = {
  subscribe: (callback: (text: string) => void) => {
    return subjectDelay.pipe(debounceTime(100)).subscribe(callback);
  },
  next: (text: string) => {
    subjectDelay.next(text);
  },
};
