import { DateRangeType } from "../../../../../../shared/reporting/api/biClient.types";
import { ValueField } from "../../../Types";
import { getCaptionByDateRangeType } from "../../preview/Helper";
import FieldCaption from "../FieldCaption";
import FieldCaptionIconsSet from "./FieldCaptionIconsSet";

export function formatMeasureCaption(measure: ValueField | undefined, captionColor?: string) {
  if (measure === undefined) return <></>;
  const customCaption = dateRangeToString(measure.config.dateRange);

  return (
    <FieldCaption
      caption={measure.config.customLabel || measure.meta.caption}
      subCaption={customCaption}
      captionColor={captionColor}
    >
      <FieldCaptionIconsSet config={measure.config} />
    </FieldCaption>
  );
}

export function dateRangeToString(range: DateRangeType) {
  switch (range) {
    case DateRangeType.BeginningBalance: {
      return "Beginning Balance";
    }
    case DateRangeType.EndingBalance: {
      return "Ending Balance";
    }
    case DateRangeType.Mtd: {
      return "MTD";
    }
    case DateRangeType.Qtd: {
      return "QTD";
    }
    case DateRangeType.Ytd: {
      return "YTD";
    }
  }
  return "";
}

export const getMeasureCaption = (measure: ValueField | undefined) => {
  if (measure === undefined) return "";
  return measure?.config.customLabel || getCaptionByDateRangeType(measure.meta.caption, measure.config.dateRange);
};
