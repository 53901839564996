import { Stack } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { PropsWithChildren } from "react";
import TooltipWrapper from "../../../../shared/components/TooltipWrapper";

export function DataGridTooltipWrapper({ children, tooltip }: PropsWithChildren<{ tooltip: string | undefined }>) {
  if (tooltip) {
    return (
      <Stack sx={{ flexDirection: "row", gap: 0.5, alignItems: "center" }}>
        {children}
        <TooltipWrapper title={tooltip}>
          <HelpOutlineOutlinedIcon
            sx={(theme) => ({ fontSize: theme.spacing(2), color: theme.palette.secondary.light, cursor: "pointer" })}
          />
        </TooltipWrapper>
      </Stack>
    );
  }
  return <>{children}</>;
}
