import { GridBasicGroupNode } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { ColumnField } from "../../../Types";
import { MeasureCell } from "../../../../../../shared/reporting/api/biClient.types";

export function getGroupKeys(
  rowNode: GridBasicGroupNode,
  fields: {
    guid: string;
    dimension?: ColumnField;
  }[],
  api: GridApiPremium
) {
  const filters: Record<string, Partial<MeasureCell>> = {};
  if (rowNode.groupingKey !== null) {
    const field = fields.find((f) => f.guid === rowNode.groupingField);
    if (field === undefined) return {};
    filters[field.dimension?.meta?.name || ""] = {
      value: getKey(field.dimension, rowNode.groupingKey.toString()),
    };
  }

  if (rowNode.parent !== null) {
    const parentNode = api.getRowNode(rowNode.parent);
    if (parentNode !== null && parentNode.type === "group") {
      const parentKeys = getGroupKeys(parentNode, fields, api);
      Object.assign(filters, parentKeys);
    }
  }

  return filters;
}

function getKey(dimension: ColumnField | undefined, key: string): string {
  const displayValues = dimension?.config.displayValues || dimension?.meta.displayValues;
  if (displayValues === undefined) {
    return key;
  }

  const fieldKey = displayValues[0];
  if (fieldKey === undefined) {
    return key;
  }

  const item = dimension?.meta.dictionary.find((pair) => pair[fieldKey] === key);
  if (item === undefined || dimension?.meta.keyFieldName === undefined) {
    return key;
  }

  return item[dimension?.meta.keyFieldName] || key;
}
