import { getSettings } from "../variables";

const boolCookieExists = (cookieName: string | undefined) =>
  !!cookieName && document.cookie.includes(`${cookieName}=true`);

export const authCookieExists = (): boolean =>
  (getSettings().AUTH_COOKIE_NAMES ?? "").split(",").some((cookieName) => boolCookieExists(cookieName));

export const refreshCookieExists = (): boolean =>
  (getSettings().REFRESH_COOKIE_NAMES ?? "").split(",").some((cookieName) => boolCookieExists(cookieName));
