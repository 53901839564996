import { Box, SxProps, Theme, Typography } from "@mui/material";
import InlineLoaderSvg from "./InlineLoaderSvg";

interface Props {
  overlay?: boolean;
  sx?: SxProps<Theme>;
  text?: string;
}

const InlineLoader = ({ overlay, sx, text }: Props) => (
  <Box
    position={overlay ? "absolute" : "relative"}
    width={overlay ? "100%" : "inherit"}
    height={overlay ? "100%" : "inherit"}
    display="flex"
    flexGrow={1}
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    zIndex={1}
    sx={{
      pointerEvents: "none",
      ...sx,
    }}
  >
    <Box sx={{ width: "4rem" }}>
      <InlineLoaderSvg />
    </Box>
    {text && <Typography variant="subtitle1">{text}</Typography>}
  </Box>
);

export default InlineLoader;
