import React, { PropsWithChildren, useContext } from "react";
import { defined } from "../../shared/utilities/typeHelper";
import { User } from "../api/identityApi.types";

const UserContext = React.createContext<User | undefined>(undefined);

interface Props {
  value: User;
}

export const UserContextProvider = ({ value, children }: PropsWithChildren<Props>) => (
  <UserContext.Provider value={value}>{children}</UserContext.Provider>
);

export const useUserContext = () => {
  const user = useContext(UserContext);
  return defined(user);
};
