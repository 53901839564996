import { Maybe } from "../types";

export const getInitialsFromUserName = (userName: string) => {
  const matches = userName.match(/([A-Z])/g);
  if (!matches || matches.length === 0) {
    return userName[0];
  }
  return `${matches[0]}${matches.length === 1 ? "" : matches.slice(-1)}`;
};

export const separatedString =
  (separator: string) =>
  (...args: Array<string | undefined>) =>
    args.filter((a) => !!a).join(separator);

export const commaSeparated = (...args: Array<string | undefined>) => separatedString(", ")(...args);

// Process large data array in batches to prevent stack overflow in the browser
const fromCodePointSafe = (array: Uint8Array): string => {
  const CHUNK_SIZE = 0x8000;
  let result = "";

  for (let index = 0; index < array.length; index += CHUNK_SIZE) {
    const batch = array.slice(index, index + CHUNK_SIZE);
    result += String.fromCodePoint(...batch);
  }

  return result;
};

export const byteArrayToBase64 = (array: Uint8Array): string => {
  const binaryString = fromCodePointSafe(array);
  return window.btoa(binaryString);
};

export const stringToBase64 = (str: string): string => {
  const encodedBytes = new TextEncoder().encode(str);
  return byteArrayToBase64(encodedBytes);
};

export const base64ToByteArray = (base64: string): Uint8Array => {
  const binaryString = window.atob(base64);
  return Uint8Array.from(binaryString, (c) => c.codePointAt(0) ?? 0);
};

export const base64ToString = (base64: string): string => {
  const decodedBytes = base64ToByteArray(base64);
  return new TextDecoder().decode(decodedBytes);
};

export const autoFormatCamelCase = (str: string): string => str.replace(/([A-Z])/g, " $1").trim();

export const areSameStrings = (a: Maybe<string>, b: Maybe<string>): boolean => (a ?? "").trim() === (b ?? "").trim();
