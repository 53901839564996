import axios, { AxiosRequestConfig } from "axios";
import { getBaseVariables, getSettings } from "../variables";
import { ApiResponse } from "./types";

export interface SignupTokenData {
  userName: string;
  email: string;
}

export interface SignupTokenResponse {
  data: SignupTokenData;
}

export interface LogoutData {
  redirectToLogoutUrl: string;
}

const requestToken = (code: string, userFlow?: string | undefined): Promise<void> =>
  axios.post(`${getBaseVariables().PORTAL_BACKEND_BASE_URI}/sso/auth/codeExchange`, {
    clientId: getSettings().AD_B2C_CLIENT_ID,
    userFlow: userFlow || getSettings().AD_B2C_USER_FLOW_SIGNIN,
    RedirectUri: getBaseVariables().PORTAL_REDIRECT_URI,
    AuthCode: code,
  });

const requestSignupToken = async (code: string): Promise<SignupTokenResponse> => {
  const { data } = await axios.post(`${getBaseVariables().PORTAL_BACKEND_BASE_URI}/sso/auth/codeExchange`, {
    clientId: getSettings().AD_B2C_CLIENT_ID,
    userFlow: getSettings().AD_B2C_USER_FLOW_SIGNUP,
    RedirectUri: getBaseVariables().PORTAL_REDIRECT_URI,
    AuthCode: code,
  });

  return data;
};

const refreshToken = (): Promise<void> =>
  axios.post(
    `${getBaseVariables().PORTAL_BACKEND_BASE_URI}/sso/auth/refresh`,
    {
      clientId: getSettings().AD_B2C_CLIENT_ID,
      userFlow: getSettings().AD_B2C_USER_FLOW_SIGNIN,
    },
    { skipAuthRefresh: true } as AxiosRequestConfig
  );

const logout = async (): Promise<ApiResponse<LogoutData>> => {
  const { data } = await axios.post<ApiResponse<LogoutData>>(
    `${getBaseVariables().PORTAL_BACKEND_BASE_URI}/sso/auth/logout`,
    {
      userFlow: getSettings().AD_B2C_USER_FLOW_SIGNIN,
    },
    { skipAuthRefresh: true } as AxiosRequestConfig
  );
  return data;
};

export const ssoApi = {
  requestToken,
  requestSignupToken,
  refreshToken,
  logout,
};
