import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PivotGeneralSettings } from "../../../../../shared/reporting/api/biClient.types";
import { createPivotDefaultSettings } from "../../utils/isConfigurationValid";

export type SettingsState = {
  settings: PivotGeneralSettings;
};

const initialState: SettingsState = { settings: createPivotDefaultSettings() };

const fieldsStateSettingsSlice = createSlice({
  name: "fieldsStateSettings",
  initialState,
  reducers: {
    updateSettingsAction: (state, action: PayloadAction<Partial<PivotGeneralSettings>>) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setSettingsAction: (state, action: PayloadAction<PivotGeneralSettings>) => {
      state.settings = action.payload;
    },
  },
});

export const { updateSettingsAction, setSettingsAction } = fieldsStateSettingsSlice.actions;

export default fieldsStateSettingsSlice;
