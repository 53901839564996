import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { IconButton } from "@mui/material";
import React from "react";

interface ExpandButtonProps {
  isExpanded: boolean;
  toggle: () => void;
}
const ChangeSizeButton = React.forwardRef<HTMLButtonElement, ExpandButtonProps>((props, ref) => {
  const { isExpanded, toggle } = props;
  return (
    <IconButton onClick={toggle} ref={ref}>
      {isExpanded ? (
        <CloseFullscreenOutlinedIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
      ) : (
        <OpenInFullIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
      )}
    </IconButton>
  );
});

export default ChangeSizeButton;
