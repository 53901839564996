import PublishedWithChangesRoundedIcon from "@mui/icons-material/PublishedWithChangesRounded";
import { Button } from "@mui/material";
import { Status } from "../../../shared/reporting/api/biClient.types";

interface Props {
  currentStatus: Status;
  disabled: boolean;
  onClick: () => void;
}
export default function SwitchStatusButton({ currentStatus, disabled, onClick }: Props) {
  return (
    <Button
      variant="outlined"
      color="secondary"
      startIcon={<PublishedWithChangesRoundedIcon />}
      onClick={onClick}
      disabled={disabled}
      sx={(theme) => ({
        svg: {
          color: currentStatus === Status.Live ? theme.palette.action.active : theme.palette.success.light,
        },
      })}
    >
      {`Make ${currentStatus === Status.Live ? "Draft" : "Live"}`}
    </Button>
  );
}
