import { MenuItem, SxProps, Theme, Typography, useTheme } from "@mui/material";
import useMergedSx from "../hooks/useMergedSx";

export const ActionMenuItem = (props: {
  icon: JSX.Element;
  title: string;
  disabled?: boolean;
  onClick: () => void;
  sx?: SxProps<Theme>;
}) => {
  const { icon, title, disabled, onClick, sx } = props;
  const theme = useTheme();

  const sxProps = useMergedSx(
    {
      svg: {
        color: theme.palette.secondary.light,
      },
    },
    sx
  );

  return (
    <MenuItem onClick={onClick} sx={sxProps} disabled={disabled}>
      {icon}
      <Typography sx={{ pl: 1 }}>{title}</Typography>
    </MenuItem>
  );
};

export default ActionMenuItem;
