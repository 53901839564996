import {
  GridCellParams,
  GridColumnOrderChangeParams,
  GridFetchRowsParams,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { DrillDownState } from "../../../../hooks/useDrillDownState";
import DrillDownGrid from "./DrillDownGrid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import biClient from "../../../../api/biApi";
import { CellDrillDownInfoBase, DrillDownDataRequest, DrillDownRow } from "../../../../api/biApi.types";
import {
  createColumns,
  PAGE_SIZE,
  rebuildColumnsOrder,
  rebuildColumnsSorting,
  shouldSkipFetch,
} from "./utils/drillDownHelper";
import { useSelector } from "react-redux";
import { selectDimensions } from "../../../../store/metaDataSlice";
import useDebounce from "../../../../../shared/hooks/useDebounce";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { logError } from "../../../../../shared/logging";
import { sortOrderedFields } from "../../../../hooks/sortOrderedFields";
import { useLocalization } from "../../../../hooks/useLocalization";
import { getValidConditionDescriptors } from "../utilities/getValidConditions";
import objectHash from "object-hash";

interface Props {
  state: DrillDownState;
  info: CellDrillDownInfoBase;
  onDrillDown?: (row: DrillDownRow) => void;
}

const getDrillDownData = withErrorHandling((body: DrillDownDataRequest, signal?: AbortSignal) =>
  biClient.getDrillDownData(body, signal)
);

const DrillDownGridWrapper = ({ state, onDrillDown, info }: Props) => {
  const { common: locale } = useLocalization();
  const dimensions = useSelector(selectDimensions);
  const [initialRows, setInitialRows] = useState<DrillDownRow[]>([]);
  const [initializing, setInitializing] = useState(true);
  const initializingRef = useRef(initializing);
  const apiRef = useGridApiRef();
  const fetchAbort = useRef<AbortController | null>(null);
  const stateRef = useRef<DrillDownState>(state);
  stateRef.current = state;
  initializingRef.current = initializing;

  const columns = useMemo(() => createColumns(state.selectedFields), [state.selectedFields]);
  const columnsRef = useRef(columns);
  columnsRef.current = columns;

  const sortModel = useMemo((): GridSortModel => {
    return [...state.sortedFields].sort(sortOrderedFields).map((f) => {
      return { field: f.field.name, sort: f.sortAsc ? "asc" : "desc" };
    });
  }, [state.sortedFields]);

  const conditionsHash = useMemo(() => objectHash(getValidConditionDescriptors(state.conditions)), [state.conditions]);
  const columnsHash = useMemo(() => objectHash(columns.map((c) => c.field)), [columns]);
  const sortingHash = useMemo(() => objectHash(sortModel), [sortModel]);

  const fetchRows = useCallback(async (skip: number, take: number) => {
    fetchAbort.current?.abort();
    fetchAbort.current = new AbortController();

    const body = { ...stateRef.current.drillDownConfig, skip: skip, take: take };

    const [response, error] = await getDrillDownData(body, fetchAbort.current?.signal);
    if (error) {
      const isRequestCanceled = error.isCanceledRequest === true;
      if (!isRequestCanceled) {
        logError(error, "[DrillDownGridWrapper] fetchRows");
      }

      return { data: undefined, error: { isCanceledRequest: isRequestCanceled } };
    }

    return { data: response, error: undefined };
  }, []);

  const handleFetchRows = useCallback(
    async (params: GridFetchRowsParams) => {
      const skip = params.firstRowToRender;
      let take = params.lastRowToRender - params.firstRowToRender;

      if (initializingRef.current || shouldSkipFetch(skip, take, stateRef.current.skip, stateRef.current.take)) {
        return;
      }

      take = Math.max(take, PAGE_SIZE);
      stateRef.current.actions.setLoading(true);
      stateRef.current.actions.setPagination(skip, take);
      stateRef.current.actions.setError(undefined);

      const { data, error } = await fetchRows(skip, take);

      if (error || !data) {
        if (!error?.isCanceledRequest) {
          stateRef.current.actions.setError(locale.calculation_error);
          setInitialRows([]);
        }
        stateRef.current.actions.setLoading(false);

        return;
      }

      apiRef.current.unstable_replaceRows(params.firstRowToRender, data.data);
      stateRef.current.actions.setTotalRows(data.totalCount ?? 0);
      stateRef.current.actions.setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiRef, fetchRows]
  );

  const handleSortingChanged = useCallback(
    (model: GridSortModel) => {
      stateRef.current.actions.changeFieldSorting(rebuildColumnsSorting(model, dimensions));
    },
    [dimensions]
  );

  const handleCellClick = useCallback(
    (params: GridCellParams<Record<string, string>>) => {
      const supportDrillDown = !info?.allocated;
      const { row } = params;
      if (!supportDrillDown) {
        return;
      }

      const entryNo = params.row["EntryNo"];
      if (entryNo !== undefined) {
        onDrillDown?.call(null, row);
      }
    },
    [onDrillDown, info?.allocated]
  );

  const handleColumnOrderChange = useCallback((params: GridColumnOrderChangeParams) => {
    stateRef.current.actions.changeSelectedFields(rebuildColumnsOrder(params, stateRef.current.selectedFields));
  }, []);

  const debouncedHandleFetchRows = useDebounce(handleFetchRows, 300);

  useEffect(() => {
    if (columnsRef.current.length === 0) {
      return;
    }
    const skip = 0;
    const take = PAGE_SIZE;

    setInitializing(true);
    setInitialRows([]);
    stateRef.current.actions.setLoading(true);
    stateRef.current.actions.setTotalRows(0);
    stateRef.current.actions.setPagination(skip, take);
    stateRef.current.actions.setError(undefined);

    const action = async () => {
      const { data, error } = await fetchRows(skip, take);

      if (error || !data) {
        if (!error?.isCanceledRequest) {
          stateRef.current.actions.setError(locale.calculation_error);
          setInitialRows([]);
        }
        stateRef.current.actions.setLoading(false);
        setInitializing(false);

        return;
      }

      setInitialRows(data.data);
      stateRef.current.actions.setTotalRows(data.totalCount ?? 0);
      stateRef.current.actions.setLoading(false);
      setInitializing(false);
    };

    action();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRows, columnsHash, sortingHash, conditionsHash]);

  useEffect(() => {
    return () => {
      fetchAbort.current?.abort();
    };
  }, []);

  return (
    <DrillDownGrid
      columns={columns}
      apiRef={apiRef}
      rowsTotal={state.rowsTotal}
      rows={initialRows}
      onFetchRows={debouncedHandleFetchRows}
      loading={initializing}
      onSortingChanged={handleSortingChanged}
      sortModel={sortModel}
      onCellClicked={handleCellClick}
      selectableRows={!info?.allocated}
      onColumnOrderChange={handleColumnOrderChange}
    />
  );
};

export default DrillDownGridWrapper;
