import { ValueField } from "../../../Types";
import SwitchMenuItem from "./SwitchMenuItem";

interface Props {
  measure: ValueField;
  saveAllowCustomConditions: (useCustomConditions: boolean) => void;
}

export default function CustomConditionsMenuItem(props: Props) {
  const { measure, saveAllowCustomConditions } = props;

  if (!measure.meta.capabilities.customConditionsAllowed) return <></>;

  return (
    <SwitchMenuItem
      caption="Custom Conditions"
      value={!!measure.config.useCustomConditions}
      onClick={() => saveAllowCustomConditions(!measure.config.useCustomConditions)}
    />
  );
}
