import { Badge, IconButton, Stack, SvgIconProps } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ReportingBackIcon from "../../icons/ReportingBackIcon";
import { ComponentType } from "react";

interface Props {
  over: boolean;
  badgeContent?: string;
  ProductIcon: ComponentType<SvgIconProps>;
  onClick?: () => void;
}

export default function BackToButton({ over, badgeContent, ProductIcon, onClick }: Props) {
  return (
    <>
      <Stack width={"35px"} height={"35px"} alignItems="center" justifyContent={"center"}>
        <IconButton
          size="medium"
          sx={{
            p: "3px",
            backgroundColor: "#F4F5F7",
            display: over ? "inherit" : "none",
          }}
          onClick={onClick}
        >
          <ArrowBackRoundedIcon fontSize="medium" sx={(theme) => ({ color: theme.palette.secondary.light })} />
        </IconButton>
        <Badge
          badgeContent={badgeContent}
          sx={{
            "& .MuiBadge-badge": {
              color: "primary.main",
              bgcolor: "#E0F5ED",
            },
            display: over ? "none" : "inherit",
          }}
        >
          <ReportingBackIcon fontSize="large" />
        </Badge>
      </Stack>
      <ProductIcon />
    </>
  );
}
