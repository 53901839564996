export const getCaretIndex = (element: Node) => {
  let position = 0;
  const isSupported = typeof window.getSelection !== "undefined";
  if (isSupported) {
    const selection = window.getSelection();
    if (selection !== null && selection.rangeCount !== 0) {
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      position = preCaretRange.toString().length;
    }
  }
  return position;
};

export const setCaretIndex = (element: Node, index: number) => {
  const isSupported = typeof window.getSelection !== "undefined";
  if (isSupported) {
    const selection = window.getSelection();
    if (selection === null || element.firstChild === null) return;
    const range = document.createRange();
    range.setStart(element.firstChild, index);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};
