import { Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { SettingsArea } from "../../contexts/FieldsStateContext";

interface Props {
  area: SettingsArea;
}

export default function VerticalAlignmentItem({ area }: Props) {
  const alignment = React.useMemo(() => getVerticalAlignment(area), [area]);

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography color={(theme) => theme.palette.text.secondary}>Vertical Alignment</Typography>
      <HorizontalFill />
      <ToggleButtonGroup
        exclusive
        color="primary"
        value={alignment}
        onChange={(_, value) => {
          if (value !== null) area.updateLegend({ verticalAlignment: value });
        }}
      >
        <ToggleButton value="top" sx={{ py: 0.3, fontWeight: 400 }}>
          Top
        </ToggleButton>
        <ToggleButton value="bottom" sx={{ py: 0.3, fontWeight: 400 }}>
          Bottom
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
}

export function getVerticalAlignment(area: SettingsArea) {
  return area.settings?.legend?.verticalAlignment || "top";
}
