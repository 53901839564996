import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useSelector } from "react-redux";
import { MeasureUnitTable } from "../../shared/reporting/api/biClient.types";
import cloneDeep from "../../shared/utilities/cloneDeep";
import { generateGuid } from "../../shared/utilities/generateGuid";
import { CellDrillDownInfoBase, DrillDownDataRequest } from "../api/biApi.types";
import { AreaItemType, ConditionField } from "../components/builder/Types";
import { getValidConditionDescriptors } from "../components/builder/common/utilities/getValidConditions";
import { selectDimensions } from "../store/metaDataSlice";
import { fieldSateConfigurationInitialState, fieldStateActions, fieldStateReducer } from "./fieldsState";
import { FieldWithOrder, FieldWithSorting } from "./FieldWithOrder";
import { getDrillDownColumns, getSorting } from "../components/builder/common/drillDown/utils/drillDownHelper";
import { drillDownActions, drillDownReducer, initialDrillDownState } from "./drillDownState";

export default function useDrillDown(info: CellDrillDownInfoBase) {
  const dimensions = useSelector(selectDimensions);
  const [fieldState, fieldStateDispatch] = useReducer(fieldStateReducer, fieldSateConfigurationInitialState);
  const [state, dispatch] = useReducer(drillDownReducer, initialDrillDownState);

  useEffect(() => {
    const copied = info.conditions
      .map((c) => {
        const meta = dimensions.find((d) => d.name === c.dimensionName);
        if (meta === undefined) return undefined;
        const clone = cloneDeep(c);
        const field: ConditionField = {
          meta,
          areaItemType: AreaItemType.CONDITIONS,
          config: {
            guid: generateGuid(),
            filter: clone,
          },
        };
        return field;
      })
      ?.filter((f): f is ConditionField => !!f);

    if (copied === undefined) return;

    setConditions(copied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.conditions]);

  const drillDownConfig = useMemo(
    (): DrillDownDataRequest => ({
      columns: getDrillDownColumns(state.selectedFields),
      conditions: getValidConditionDescriptors(fieldState.conditions),
      calcTotalCount: true,
      sortingColumns: getSorting(state.sortedFields),
      skip: 0,
      take: 0,
      allocated: info.allocated === true,
      table: info.table || MeasureUnitTable.Gl,
    }),
    [state.selectedFields, state.sortedFields, info.allocated, info.table, fieldState.conditions]
  );

  const setConditions = useCallback((fields: ConditionField[]) => {
    fieldStateDispatch(fieldStateActions.setConditions(fields));
  }, []);
  const addConditions = useCallback((fields: ConditionField[]) => {
    fieldStateDispatch(fieldStateActions.addConditions(fields));
  }, []);
  const addCondition = useCallback((field: ConditionField) => {
    fieldStateDispatch(fieldStateActions.addConditions([field]));
  }, []);
  const updateCondition = useCallback((field: ConditionField, changes: Partial<ConditionField>) => {
    fieldStateDispatch(fieldStateActions.updateCondition({ field, changes }));
  }, []);
  const removeCondition = useCallback((field: ConditionField) => {
    fieldStateDispatch(fieldStateActions.removeCondition(field));
  }, []);

  const setTotalRows = useCallback((num: number) => {
    dispatch(drillDownActions.setRowsTotal(num));
  }, []);

  const changeSelectedFields = useCallback((fields: FieldWithOrder[]) => {
    dispatch(drillDownActions.setSelectedFields([...fields]));
  }, []);

  const changeFieldSorting = useCallback((fields: FieldWithSorting[]) => {
    dispatch(drillDownActions.setSortedFields([...fields]));
  }, []);

  const setLoading = useCallback((value: boolean) => {
    dispatch(drillDownActions.setLoading(value));
  }, []);

  const setPagination = useCallback((skip: number, take: number) => {
    dispatch(drillDownActions.setPagination({ skip, take }));
  }, []);

  const setError = useCallback((error: string | undefined) => {
    dispatch(drillDownActions.setError(error));
  }, []);

  return {
    conditions: fieldState.conditions,
    ...state,
    drillDownConfig,
    actions: {
      setConditions,
      addCondition,
      updateCondition,
      addConditions,
      removeCondition,
      changeSelectedFields,
      changeFieldSorting,
      setTotalRows,
      setLoading,
      setPagination,
      setError,
    },
  };
}

export type DrillDownState = ReturnType<typeof useDrillDown>;
