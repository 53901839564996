import React from "react";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import { getLinearSettings } from "../../contexts/FieldsStateContext.types";
import TitleItem from "../TitleItem";

interface Props {
  area: SettingsArea;
}

export default function Title({ area }: Props) {
  const title = React.useMemo(() => argumentAxisTitle(area), [area]);

  return (
    <TitleItem title={title} placeholder="Set Axis Title..." onUpdate={(title) => area.updateArgumentAxis({ title })} />
  );
}

export function argumentAxisTitle(area: SettingsArea) {
  return getLinearSettings(area.settings)?.argumentAxis?.title || "";
}
