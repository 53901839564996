import { RefreshRounded } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import ReportWarningIcon from "../../icons/ReportWarningIcon";

export default function ImportantDataLoadingFailed({ title, disclaimer }: { title: string; disclaimer: string }) {
  return (
    <Stack sx={{ backgroundColor: grey[50], alignItems: "center", justifyContent: "center", gap: 4, flex: 1 }}>
      <Stack sx={{ gap: 2, alignItems: "center", justifyContent: "center", maxWidth: "460px" }}>
        <ReportWarningIcon />
        <Typography variant="h6">{title}</Typography>
        <Typography sx={(theme) => ({ textAlign: "center", color: theme.palette.secondary.main })}>
          {disclaimer}
        </Typography>
      </Stack>
      <Button variant="contained" startIcon={<RefreshRounded />} onClick={reloadPage}>
        Reload Report
      </Button>
    </Stack>
  );
}

function reloadPage() {
  window.location.reload();
}
