import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./RootState";
import { DevToolsConfiguration } from "./DevToolsConfiguration";

const initialState: DevToolsConfiguration = {
  showPanel: false,
  logQueries: false,
};

const devToolsConfigurationSlice = createSlice({
  name: "devToolsConfiguration",
  initialState,
  reducers: {
    update(state, action: { payload: DevToolsConfiguration | undefined }) {
      if (action.payload === undefined) {
        Object.assign(state, initialState);
      } else {
        Object.assign(state, action.payload);
      }
    },
    updateShowDevPanel(state, action: { payload: boolean }) {
      state.showPanel = action.payload;
      if (state.showPanel === false) {
        state.logQueries = false;
        state.loggingItems = undefined;
      }
    },
  },
});

export const { update, updateShowDevPanel } = devToolsConfigurationSlice.actions;

export const selectShowDevPanel = (state: RootState) => state.devTools.showPanel;
export const selectLogQueries = (state: RootState) => state.devTools.logQueries;
export const selectLoggingItems = (state: RootState) => state.devTools.loggingItems;

export default devToolsConfigurationSlice.reducer;
