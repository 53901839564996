import { Chip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { green } from "@mui/material/colors";

const LiveStatus = () => {
  return (
    <Chip
      icon={<CircleIcon />}
      label="Live"
      sx={{
        alignItems: "center",
        gap: "6px",
        padding: "1px 8px 1px 6px",
        fontWeight: 500,
        bgcolor: green[50],
        color: green[700],
        "& .MuiChip-icon": { color: green[500], fontSize: "0.5rem", mx: 0 },
        "& .MuiChip-label": { px: 0, alignSelf: "center" },
      }}
    />
  );
};

export default LiveStatus;
