import { ConditionConfiguration, ConditionEqualityType } from "../../../../../../shared/reporting/api/biClient.types";
import { getValidConditionDescriptors } from "../../../common/utilities/getValidConditions";
import { ConditionField, ValueField } from "../../../Types";

export type ConditionFilter = {
  guid: string;
  name: string;
  values: string[];
  equality: ConditionEqualityType;
};

export const getMeasureCustomConditions = (measures: ValueField[]) => {
  return measures
    .map((measure) => {
      if (!measure.config.customConditions) return undefined;
      return measure.config.customConditions.filter((cc) => cc.filter.values && cc.filter.values.length > 0);
    })
    .filter((mc): mc is ConditionConfiguration[] => !!mc)
    .flatMap((mc) => mc)
    .map((c) => c.filter);
};

const getMeasureLinkedConditions = (measures: ValueField[], conditions: ConditionField[]) => {
  return getValidConditionDescriptors(
    measures
      .map((measure) => {
        if (!measure.config.linkedConditions) return undefined;
        return measure.config.linkedConditions;
      })
      .filter((mc): mc is string[] => !!mc)
      .flatMap((mc) => mc)
      .map((guid) => {
        const condition = conditions.find((c) => c.config.guid === guid);
        return condition;
      })
      .filter((c): c is ConditionField => !!c),
    true
  );
};

export const getAllFilters = (conditions: ConditionField[], measures: ValueField[]) => {
  let validConditionsWithFilters = getValidConditionDescriptors(conditions, false).map((c) => ({
    filter: c,
    linked: false,
  }));

  const measureCustomConditions = getMeasureCustomConditions(measures).map((c) => ({
    filter: c,
    linked: false,
  }));
  const measureLinkedConditions = getMeasureLinkedConditions(measures, conditions).map((c) => ({
    filter: c,
    linked: true,
  }));

  validConditionsWithFilters = validConditionsWithFilters
    .concat(measureCustomConditions)
    .concat(measureLinkedConditions);

  return validConditionsWithFilters;
};
