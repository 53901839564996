import { PropsWithChildren } from "react";
import InlineLoader from "./inlineLoader/InlineLoader";

interface Props {
  loading: boolean;
  overlay?: boolean;
}

const LoaderBox = (props: PropsWithChildren<Props>) => {
  const { loading } = props;
  if (loading) {
    return <InlineLoader overlay={props.overlay} />;
  }
  return <>{props.children}</>;
};

export default LoaderBox;
