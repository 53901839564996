import { Grid, Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import { PropsWithChildren, ReactNode, forwardRef } from "react";

interface Props {
  component?: React.ElementType;
  sx?: SystemStyleObject<Theme>;
  children: ReactNode;
}

const PropertyItemContainer = forwardRef<HTMLDivElement, Props>(
  ({ component, sx, children }: PropsWithChildren<Props>, ref) => {
    return (
      <Grid
        container
        component={component || "div"}
        ref={ref}
        sx={(theme) => ({
          flexDirection: "column",
          bgcolor: "white",
          borderRadius: "4px",
          border: "1px solid " + theme.palette.divider,
          ...sx,
        })}
      >
        {children}
      </Grid>
    );
  }
);

export default PropertyItemContainer;
