import sortsSlice, {
  addSortingAction,
  moveSortingAction,
  removeSortingAction,
  removeSortingByMetaAction,
  setSortingAction,
  updateSortingAction,
  updateSortingConfigAction,
} from "../../common/hooks/fieldsState.sorts";
import {
  ConditionConfiguration,
  PivotFieldConfiguration,
  ReportType,
  SortConfiguration,
  TabularFieldType,
  AxisSettings,
  ChartStyleSettings,
  LegendSettings,
  Ordering,
  SeriesSettings,
  ValueAxisType,
  UserMeasureConfigurations,
} from "../../../../../shared/reporting/api/biClient.types";
import { DimensionDescriptor } from "../../../../api/biApi.types";
import { AreaItemType, ColumnField, ConditionField, GeneralField, SortField, ValueField } from "../../Types";
import useReducerSafe from "../../common/hooks/useReducerSafe";
import fieldsStateChartSlice, {
  setArgumentsAction,
  addArgumentAction,
  addConditionAction,
  removeArgumentAction,
  moveArgumentAction,
  setConditionsAction,
  updateArgumentAction,
  updateArgumentConfigAction,
  setMeasuresAction,
  addMeasureAction,
  removeMeasureAction,
  moveMeasureAction,
  updateMeasureAction,
  updateMeasureConfigAction,
  setSetingsAction,
  updateSettingsAction,
  updateReportTypeSettingsAction,
  updateLegendSettingsAction,
  updateAxisArgumentSettingsAction,
  updateAxisValueSettingsAction,
  addSeriesAction,
  removeSeriesAction,
  updateSeriesSettingsAction,
  updateSerieValueAxisSettingsAction,
  removeConditionAction,
  updateConditionAction,
  moveConditionAction,
  updateConditionConfigAction,
} from "./fieldsState.charts";

export type TableField = {
  guid: string;
  fieldType: TabularFieldType;
  dimension?: ColumnField;
  measure?: ValueField;
};

export default function useFieldsState() {
  const [state, dispatch] = useReducerSafe(fieldsStateChartSlice);
  const [sortingState, dispatchSorting] = useReducerSafe(sortsSlice);

  //CONDITIONS
  const setConditions = (fields: ConditionField[]) => dispatch(setConditionsAction(fields));

  const addCondition = (field: ConditionField, index: number) => {
    dispatch(addConditionAction({ field, index: index }));
  };

  const removeCondition = (field: ConditionField) => dispatch(removeConditionAction(field));

  const moveCondition = (field: ConditionField, newIndex: number) => dispatch(moveConditionAction({ field, newIndex }));

  const updateCondition = (field: ConditionField, changes: Partial<ConditionField>) =>
    dispatch(updateConditionAction({ field, changes }));

  const updateConditionConfig = (field: ConditionField, changes: Partial<ConditionConfiguration>) =>
    dispatch(updateConditionConfigAction({ field, changes }));

  //ARGUMENTS
  const setArguments = (fields: GeneralField[]) => dispatch(setArgumentsAction(fields));

  const addArgument = (field: GeneralField, index: number) => {
    dispatch(addArgumentAction({ field, index: index }));
  };

  const removeArgument = (field: GeneralField) => {
    removeSortingByMeta(field.meta);
    dispatch(removeArgumentAction(field));
  };

  const moveArgument = (field: GeneralField, newIndex: number) => dispatch(moveArgumentAction({ field, newIndex }));

  const updateArgument = (field: GeneralField, changes: Partial<GeneralField>) =>
    dispatch(updateArgumentAction({ field, changes }));

  const updateArgumentConfig = (field: GeneralField, changes: Partial<PivotFieldConfiguration>) => {
    dispatch(updateArgumentConfigAction({ field, changes }));
    if (field.config.customLabel !== changes.customLabel) {
      const sortingField = sortingState.sorts.find((s) => s.meta.name === field.meta.name);
      if (sortingField) {
        updateSortingConfig(sortingField, { caption: changes.customLabel });
      }
    }
  };

  //MEASURES
  const setMeasures = (fields: ValueField[]) => {
    dispatch(setMeasuresAction(fields));
    fields.forEach((field) => addSerie(field.config.guid));
  };

  const addMeasure = (field: ValueField, index: number) => {
    dispatch(addSeriesAction(field.config.guid));
    dispatch(addMeasureAction({ field, index: index }));
  };

  const removeMeasure = (field: ValueField) => {
    dispatch(removeSeriesAction(field.config.guid));
    dispatch(removeMeasureAction(field));
  };

  const moveMeasure = (field: ValueField, newIndex: number) => dispatch(moveMeasureAction({ field, newIndex }));

  const updateMeasure = (field: ValueField, changes: Partial<ValueField>) =>
    dispatch(updateMeasureAction({ field, changes }));

  const updateMeasureConfig = (field: ValueField, changes: Partial<UserMeasureConfigurations>) =>
    dispatch(updateMeasureConfigAction({ field, changes }));

  //SORTING
  const setSorting = (fields: SortField[]) => dispatchSorting(setSortingAction({ fields, groupMetaNames: [] }));

  const addSorting = (field: GeneralField) => {
    const conditionField: SortField = {
      meta: field.meta,
      config: {
        name: field.meta.name,
        ordering: Ordering.Ascending,
        caption: field.config.customLabel || field.meta.caption,
      },
      areaItemType: AreaItemType.SORTS,
    };
    dispatchSorting(addSortingAction(conditionField));
  };

  const removeSorting = (field: SortField) => dispatchSorting(removeSortingAction(field));

  const removeSortingByMeta = (field: DimensionDescriptor) => dispatchSorting(removeSortingByMetaAction(field));

  const moveSorting = (field: SortField, newIndex: number) => dispatchSorting(moveSortingAction({ field, newIndex }));

  const updateSorting = (field: SortField, changes: Partial<SortField>) =>
    dispatchSorting(updateSortingAction({ field, changes }));

  const updateSortingConfig = (field: SortField, changes: Partial<SortConfiguration>) =>
    dispatchSorting(updateSortingConfigAction({ field, changes }));

  //SETTINGS
  const setSettings = (settings: ChartStyleSettings) => dispatch(setSetingsAction(settings));
  const updateReportTypeSettings = (type: ReportType) => dispatch(updateReportTypeSettingsAction(type));
  const updateSettings = (changes: Partial<ChartStyleSettings>) => dispatch(updateSettingsAction(changes));
  const updateLegendSettings = (changes: Partial<LegendSettings>) => dispatch(updateLegendSettingsAction(changes));
  const updateArgumentAxisSettings = (changes: Partial<AxisSettings>) =>
    dispatch(updateAxisArgumentSettingsAction(changes));
  const updateValueAxisSettings = (name: string, changes: Partial<AxisSettings>) =>
    dispatch(updateAxisValueSettingsAction({ name, changes }));
  const addSerie = (name: string) => dispatch(addSeriesAction(name));
  const removeSerie = (name: string) => {
    dispatch(removeSeriesAction(name));
  };
  const updateSeriesSettings = (name: string, changes: Partial<SeriesSettings>) =>
    dispatch(updateSeriesSettingsAction({ name, changes }));
  const updateSerieValueAxis = (name: string, axis: ValueAxisType) =>
    dispatch(updateSerieValueAxisSettingsAction({ name, axis }));

  return {
    ...state,
    ...sortingState,

    setConditions,
    addCondition,
    updateCondition,
    updateConditionConfig,
    removeCondition,
    moveCondition,

    setArguments,
    addArgument,
    updateArgument,
    updateArgumentConfig,
    removeArgument,
    moveArgument,

    setMeasures,
    addMeasure,
    updateMeasure,
    updateMeasureConfig,
    removeMeasure,
    moveMeasure,

    setSorting,
    addSorting,
    removeSorting,
    removeSortingByMeta,
    moveSorting,
    updateSorting,
    updateSortingConfig,

    setSettings,
    updateSettings,
    updateReportTypeSettings,
    updateLegendSettings,
    updateArgumentAxisSettings,
    updateValueAxisSettings,
    addSerie,
    removeSerie,
    updateSeriesSettings,
    updateSerieValueAxis,
  };
}

export type FieldsStateReturnType = ReturnType<typeof useFieldsState>;
