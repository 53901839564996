import { defined } from "../../../../../../shared/utilities/typeHelper";
import { createTokenizer, Token, TokenType } from "./tokenizer";

export type TokenIterator = {
  all: () => Token[];
  nextToken: () => void;
  token: () => Token;
};

export const tokenIterator = (input: string): TokenIterator => {
  const tokenizer = createTokenizer(input);
  const tokens: Token[] = [];
  let index = 0;
  let currentToken: Token;

  const all = () => tokens;
  const token = () => currentToken;

  const fillAll = () => {
    let ltoken: Token;
    do {
      ltoken = tokenizer.nextToken();
      tokens.push(ltoken);
    } while (ltoken.tokenType !== TokenType.Eof);
  };

  const nextToken = () => {
    while (index < tokens.length) {
      const ltoken = defined(tokens[index]);
      index++;
      if (ltoken.tokenType === TokenType.Space) continue;
      currentToken = ltoken;
      break;
    }
  };

  fillAll();

  nextToken();

  return { all, nextToken, token };
};
