import { Stack, Typography } from "@mui/material";
import { getCalculatedFieldTitle } from "./utils/getCalculatedFieldTitle";
import { CellDrillDownInfoBase } from "../../../../api/biApi.types";
import { MeasureConfigurationType } from "../../../../../shared/reporting/api/biClient.types";
import { DrillDownConfiguration } from "../../../../store/DrillDownConfigurationState";

interface Props {
  configuration: DrillDownConfiguration;
  info: CellDrillDownInfoBase;
}

export default function PageTitle({ configuration, info }: Props) {
  const calculatedField = getCalculatedFieldTitle(info) + getFieldSubCaption(configuration);
  const measureCaption = getMeasureCaption(configuration);

  return (
    <Stack py={1.5} gap={0.3}>
      <Typography variant="h6">{measureCaption}</Typography>
      <Stack direction="row" gap={1}>
        {configuration.cell.formattedValue && (
          <Typography variant="caption" color="text.secondary">
            {configuration.cell.formattedValue}
          </Typography>
        )}
        {calculatedField && (
          <Typography variant="caption" color="text.secondary">
            {calculatedField}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

function getMeasureCaption(configuration: DrillDownConfiguration) {
  const caption = configuration.measure?.config.customLabel || configuration.measure?.meta.caption;
  return caption;
}
function getFieldSubCaption(configuration: DrillDownConfiguration) {
  if (configuration.measure?.config.type === MeasureConfigurationType.Irr) {
    return ` • ${configuration.measure?.config.cashFlowFrequency}`;
  }
  return "";
}
