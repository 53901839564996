import FunctionIcon from "../../../../../../shared/reporting/icons/FunctionIcon";
import { DimensionDescriptor, FunctionDescription } from "../../../../../api/biApi.types";
import { getIcon } from "../../fields/FieldItem.helper";

export interface IFormulaVariable {
  get key(): string;
  get caption(): string;
  get category(): string;
  icon: () => JSX.Element;
}

export class DimensionFormulaVariable implements IFormulaVariable {
  constructor(private dimension: DimensionDescriptor) {}

  get key(): string {
    return this.dimension.name;
  }
  get caption(): string {
    return this.dimension.caption;
  }
  get category(): string {
    return "Dimension";
  }
  get field(): DimensionDescriptor {
    return this.dimension;
  }
  icon() {
    return getIcon(this.dimension);
  }
}

export class FunctionFormulaVariable implements IFormulaVariable {
  constructor(private func: FunctionDescription) {}
  get key(): string {
    return this.func.fullName;
  }
  get caption(): string {
    return this.func.fullName;
  }
  get category(): string {
    return this.func.description;
  }
  get field(): FunctionDescription {
    return this.func;
  }
  icon() {
    return <FunctionIcon />;
  }
}
