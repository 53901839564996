import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Stack, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useLocalization } from "../../../../../hooks/useLocalization";
import LinkIcon from "../../../../../icons/LinkIcon";
import { ConditionField } from "../../../Types";
import AreaFieldItem from "../../../common/fields/areaFields/AreaFieldItem";
import { AreaFieldItemDetailed } from "../../../common/fields/areaFields/AreaFieldItemDetailed";
import { FieldItemProps } from "../../../common/fields/types/areaFiledItem.types";
import ConditionMarker from "./ConditionMarker";
import { ItemDataType } from "../../../../../api/biApi.types";

interface Props extends FieldItemProps<ConditionField> {
  detailedView: boolean;
  marked?: boolean;
  linked?: boolean;
  invalid?: boolean;
}

export const ConditionItem = (props: Props) => {
  const {
    detailedView,
    marked,
    linked,
    invalid,
    field,
    canBeRemoved,
    readonly,
    onRemoveItem,
    formatCaption,
    onShowOptions: showOptions,
  } = props;
  const { report: locale } = useLocalization();
  const [ref, setRef] = useState<HTMLElement | null>(null);

  const isCrossFilterToBeRefreshed = React.useMemo(() => {
    return !field.crossFilter?.loading && field.crossFilter?.toRefresh === true;
  }, [field.crossFilter]);

  const shouldOptionsBeHidden = React.useMemo(() => {
    if (!readonly) {
      return false;
    }
    return field.meta.type === ItemDataType.Date || field.meta.type === ItemDataType.Numeric;
  }, [field.meta.type, readonly]);

  return (
    <>
      {marked && <ConditionMarker />}
      <Stack
        sx={{
          flexDirection: "row",
          flex: 1,
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        {linked && <LinkIcon sx={{ pl: 1 }} />}
        {invalid && (
          <Tooltip title={locale.condition_warning}>
            <ErrorOutlineIcon color="secondary" sx={{ ml: 1 }} />
          </Tooltip>
        )}
        <AreaFieldItem
          field={props.field}
          canBeRemoved={canBeRemoved}
          readonly={readonly}
          isLoading={
            field.crossFilter?.loading === true ||
            field.dictionary?.loading === true ||
            isCrossFilterToBeRefreshed === true
          }
          hideOptions={shouldOptionsBeHidden}
          onRemoveItem={onRemoveItem}
          formatCaption={formatCaption}
          onShowOptions={showOptions}
          optionRef={setRef}
        />
      </Stack>

      {detailedView && (
        <AreaFieldItemDetailed
          field={field}
          onShowOptions={() => showOptions?.call(null, { field, ref, showOnlySelected: true })}
        />
      )}
    </>
  );
};
export default ConditionItem;
