import { LoadingButton } from "@mui/lab";
import { Button, Grid, Popover, Typography } from "@mui/material";
import HorizontalFill from "../../../shared/components/HorizontalFill";
import { AnyRecord } from "../../../shared/types";
import { formatString } from "../../../shared/utilities/stringFormatter";
import { useLocalization } from "../../hooks/useLocalization";

interface Props {
  open: boolean;
  anchorEl: HTMLElement | undefined;
  isLoading: boolean;
  reportKind: "report" | "template";
  onSave: () => void;
  onContinue: () => void;
  onClose: () => void;
}

export default function UnsavedChangesPopover({
  open,
  anchorEl,
  onSave,
  onContinue,
  onClose,
  isLoading,
  reportKind,
}: Props) {
  const {
    validation: { unsaved_changes_tooltip: locale },
  } = useLocalization();
  const handleClose = (_: AnyRecord, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick" && isLoading) {
      return;
    }
    onClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.32))",
          maxWidth: "310px",
          mt: 1,
          ml: -0.4,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            width: 12,
            height: 12,
            bgcolor: "background.paper",
            transform: "translateY(-50%) translateX(100%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      sx={{
        minHeight: "45px",
      }}
    >
      <Grid container sx={{ flexDirection: "column", p: 2, gap: 1 }}>
        <Typography variant="subtitle1">Unsaved Changes</Typography>
        <Typography color="secondary">{formatString(locale.description, reportKind)}</Typography>
        <Grid container sx={{ gap: 1, pt: 1 }}>
          <HorizontalFill />
          <LoadingButton variant="outlined" onClick={onSave} loading={isLoading}>
            {locale.save}
          </LoadingButton>
          <Button variant="contained" onClick={onContinue} disabled={isLoading}>
            {locale.continue}
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
}
