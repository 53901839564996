import { Grid, Typography } from "@mui/material";
import React from "react";
import { SeriesSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { getMeasureCaption } from "../../../pivot/table/measures/helper";
import { ValueField } from "../../../Types";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import BarWidthItem from "./BarWidthItem";
import SerieLineTypeItem from "./SerieLineTypeItem";
import SerieTypeItem from "./SerieTypeItem";
import SerieValueAxis from "./SerieValueAxis";
import ShowLabelConnectorItem from "./ShowLabelConnectorItem";
import ShowLabelItem from "./ShowLabelItem";
import ShowPointsItem from "./ShowPointsItem";

interface Props {
  area: SettingsArea;
  values: ValueField[];
  serie: SeriesSettings;
}

export default function SerieItem({ area, values, serie }: Props) {
  const caption = React.useMemo(() => {
    const measure = values.find((v) => v.config.guid === serie.name);
    return getMeasureCaption(measure);
  }, [serie.name, values]);

  return (
    <Grid container sx={{ flexDirection: "column", gap: 1 }}>
      <Typography variant="subtitle2">{caption}</Typography>
      <SerieTypeItem area={area} serie={serie} />
      <BarWidthItem area={area} serie={serie} />
      <SerieLineTypeItem area={area} serie={serie} />
      <SerieValueAxis area={area} serie={serie} />
      <ShowPointsItem area={area} serie={serie} />
      <ShowLabelItem area={area} serie={serie} />
      <ShowLabelConnectorItem area={area} serie={serie} />
    </Grid>
  );
}
