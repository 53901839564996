import { Box } from "@mui/material";
import { ConditionField, ValueField } from "../../../Types";
import MeasureItem from "./MeasureItem";
import ItemContainer from "../ItemContainer";
import { formatMeasureCaption } from "./helper";
import { UserMeasureConfigurations } from "../../../../../../shared/reporting/api/biClient.types";
import { useSelector } from "react-redux";
import { selectDimensions } from "../../../../../store/metaDataSlice";

interface Props {
  measures: ValueField[];
  globalConditions: ConditionField[];
  updateMeasure: (field: ValueField, change: Partial<UserMeasureConfigurations>) => void;
}

export const MeasureConditions = (props: Props) => {
  const { measures, globalConditions, updateMeasure } = props;

  const dimensions = useSelector(selectDimensions);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
      }}
    >
      {measures.map((measure) => {
        if (!(measure.config.customConditions && measure.config.customConditions.some((cc) => cc.parameter === true))) {
          return undefined;
        }
        return (
          <ItemContainer key={measure.config.guid}>
            <MeasureItem
              field={measure}
              fields={dimensions}
              globalConditions={globalConditions}
              canBeRemoved={false}
              hideOptions
              formatCaption={formatMeasureCaption}
              updateMeasure={updateMeasure}
            />
          </ItemContainer>
        );
      })}
    </Box>
  );
};

export default MeasureConditions;
