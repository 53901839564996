import { createContext } from "react";
import {
  ConditionConfiguration,
  PivotFieldConfiguration,
  PivotGeneralSettings,
  SortConfiguration,
  UserMeasureConfigurations,
} from "../../../../shared/reporting/api/biClient.types";
import { MetaDescriptorBase } from "../../../api/biApi.types";
import { ColumnField, ConditionField, GeneralField, MetaField, RowField, SortField, ValueField } from "../Types";

export type FieldArea<T extends MetaField<MetaDescriptorBase>, TC> = {
  get values(): T[];
  removeItem: (item: T) => void;
  moveItem: (item: T, newIndex: number) => void;
  updateItem: (item: T, changes: Partial<T>) => void;
  updateItemConfig: (item: T, changes: Partial<TC>) => void;
};

export type ConditionFieldsArea = FieldArea<ConditionField, ConditionConfiguration> & {
  addItem: (item: ConditionField, index: number) => void;
};

export type RowFieldsArea = FieldArea<RowField, PivotFieldConfiguration> & {
  addItem: (item: RowField, index: number) => void;
};

export type ColumnFieldsArea = FieldArea<ColumnField, PivotFieldConfiguration> & {
  addItem: (item: ColumnField, index: number) => void;
};

export type ValueFieldsArea = FieldArea<ValueField, UserMeasureConfigurations> & {
  addItem: (item: ValueField, index: number) => void;
};

export type SortingFieldsArea = FieldArea<SortField, SortConfiguration> & {
  addItem: (item: GeneralField, isGroupField: boolean, groupMetaNames: string[]) => void;
  setSorting: (fields: SortField[], groupMetaNames: string[]) => void;
};

export type GeneralSettingsArea = {
  settings: PivotGeneralSettings;
  update: (changes: Partial<PivotGeneralSettings>) => void;
};

export type BuilderContextType = {
  conditionsArea: ConditionFieldsArea;
  rowsArea: RowFieldsArea;
  columnsArea: ColumnFieldsArea;
  valuesArea: ValueFieldsArea;
  sortArea: SortingFieldsArea;
  settingsArea: GeneralSettingsArea;
};

export const BuilderContext = createContext<BuilderContextType>({} as BuilderContextType);
