import { DragLayerMonitor, useDragLayer } from "react-dnd";
import { AreaItemType } from "../../Types";
import GroupFieldItem from "./GroupFieldItem";
import { GroupingField } from "../../../../../shared/reporting/api/biClient.types";

const TableDragLayer: React.FC = () => {
  const { currentOffset, item, itemType } = useDragLayer((monitor: DragLayerMonitor) => {
    return {
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
    };
  });

  if (itemType === AreaItemType.GROUPS && currentOffset) {
    const field = item as GroupingField;

    return (
      <GroupFieldItem
        sx={{
          transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
          position: "fixed",
          top: 0,
          left: 0,
          pointerEvents: "none",
          width: "323px",
        }}
        field={field}
      />
    );
  }
  return null;
};

export default TableDragLayer;
