import { GridAutosizeOptions } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { DependencyList, MutableRefObject, useEffect } from "react";

const autosizeOptions: GridAutosizeOptions = {
  includeOutliers: true,
  includeHeaders: true,
};

const useAutosizeColumns = (apiRef: MutableRefObject<GridApiPremium>, deps: DependencyList) => {
  useEffect(() => {
    setTimeout(() => {
      apiRef.current.autosizeColumns(autosizeOptions);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { autosizeOptions };
};

export default useAutosizeColumns;
