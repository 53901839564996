import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  ChartConfiguration,
  PivotConfiguration,
  ReportType,
  TabularConfiguration,
} from "../../../../../shared/reporting/api/biClient.types";
import {
  selectCurrentReport,
  selectReportConfiguration,
  selectReportEditMode,
} from "../../../../store/currentReportSlice";
import { isEmptyObject } from "../../../../utilities/Utilities";

const useDimensionAndMeasuresSelection = () => {
  const reportConfiguration = useSelector(selectReportConfiguration);
  const isEditMode = useSelector(selectReportEditMode);
  const currentReport = useSelector(selectCurrentReport);

  const dimensionNames = useMemo((): string[] => {
    const configuration = isEditMode ? reportConfiguration : currentReport?.configuration;
    if (configuration === undefined || currentReport?.reportType === undefined) return [];

    if (currentReport?.reportType === ReportType.Pivot) {
      return getPivotDimensionNames(configuration as PivotConfiguration);
    }
    if (currentReport?.reportType === ReportType.Tabular) {
      return getTabularDimensionNames(configuration as TabularConfiguration);
    }
    return getChartDimensionNames(configuration as ChartConfiguration);
  }, [currentReport?.configuration, currentReport?.reportType, isEditMode, reportConfiguration]);

  const measureNames = useMemo((): string[] => {
    const configuration = isEditMode ? reportConfiguration : currentReport?.configuration;
    if (configuration === undefined || currentReport?.reportType === undefined) return [];

    if (currentReport.reportType === ReportType.Pivot) {
      return getPivotMeasureNames(configuration as PivotConfiguration);
    }
    if (currentReport.reportType === ReportType.Tabular) {
      return getTabularMeasureNames(configuration as TabularConfiguration);
    }
    return getChartMeasureNames(configuration as ChartConfiguration);
  }, [currentReport?.configuration, currentReport?.reportType, isEditMode, reportConfiguration]);

  return { dimensionNames, measureNames };
};

export default useDimensionAndMeasuresSelection;

function getPivotDimensionNames(configuration: PivotConfiguration) {
  if (isEmptyObject(configuration)) {
    return [];
  }
  const dimensionNames = configuration.conditions
    .map((c) => c.filter.dimensionName)
    .concat((configuration.rows ?? []).map((r) => r.name))
    .concat((configuration.columns ?? []).map((r) => r.name));

  return dimensionNames;
}

function getTabularDimensionNames(configuration: TabularConfiguration) {
  if (isEmptyObject(configuration)) {
    return [];
  }
  const fields = (configuration.fields ?? [])
    .filter((f) => f.dimension)
    .map((f) => f.dimension?.name)
    .filter((f): f is string => !!f);

  const dimensionNames = configuration.conditions.map((c) => c.filter.dimensionName).concat(fields);
  return dimensionNames;
}

function getChartDimensionNames(configuration: ChartConfiguration) {
  if (isEmptyObject(configuration)) {
    return [];
  }
  const dimensionNames = configuration.conditions
    .map((c) => c.filter.dimensionName)
    .concat((configuration.arguments ?? []).map((r) => r.name));

  return dimensionNames;
}

function getPivotMeasureNames(configuration: PivotConfiguration) {
  if (isEmptyObject(configuration)) {
    return [];
  }
  return (configuration.values ?? []).map((c) => c.name);
}

function getTabularMeasureNames(configuration: TabularConfiguration) {
  if (isEmptyObject(configuration)) {
    return [];
  }
  return (configuration.fields ?? [])
    .filter((f) => f.measure)
    .map((f) => f.measure?.name)
    .filter((f): f is string => !!f);
}

function getChartMeasureNames(configuration: ChartConfiguration) {
  if (isEmptyObject(configuration)) {
    return [];
  }
  return (configuration.values ?? []).map((c) => c.name);
}
