import { GridColDef } from "@mui/x-data-grid-premium";

export const getAggregationModel = (columns: GridColDef[]) => {
  const value: { [x: string]: string } = {};
  columns
    .filter((c) => c.aggregable === true)
    .forEach((c) => {
      value[c.field] = "custom";
    });
  return value;
};
