import { ValueField } from "../../../Types";
import React from "react";
import DateRanges from "../../../pivot/table/measures/DateRanges";
import { DateRangeType, UserMeasureConfigurations } from "../../../../../../shared/reporting/api/biClient.types";

interface Props {
  measure: ValueField;
  onClose: () => void;
  saveChanges: (changes: Partial<UserMeasureConfigurations>, causeClosing: boolean) => void;
}

export default function PeriodMenuItem(props: Props) {
  const { measure, onClose, saveChanges } = props;

  const updateDateRangeType = React.useCallback(
    (newDateRangeType: DateRangeType) => {
      if (measure.config.dateRange === newDateRangeType) {
        onClose();
      } else {
        saveChanges({ dateRange: newDateRangeType }, true);
      }
    },
    [measure, onClose, saveChanges]
  );

  if (!measure.meta.capabilities.dateRangeCustomization) return <></>;

  return <DateRanges dateRange={measure.config.dateRange} onChange={updateDateRangeType} />;
}
