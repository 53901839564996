import { useEffect, useRef } from "react";

export default function useDragLeave(callback: () => void, isOver: boolean, didDrop: boolean) {
  const ref = useRef(isOver);

  useEffect(() => {
    if (ref.current && !isOver && !didDrop) {
      callback();
    }
    ref.current = isOver;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOver, didDrop]);
}
