import { Report, ReportType } from "../../../../../shared/reporting/api/biClient.types";

export const isChartReport = (report?: Report) =>
  report &&
  (report.reportType === ReportType.PieChart ||
    report.reportType === ReportType.BarChart ||
    report.reportType === ReportType.LineChart ||
    report.reportType === ReportType.AreaChart ||
    report.reportType === ReportType.DoughnutChart ||
    report.reportType === ReportType.TreeMapChart);
