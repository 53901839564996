import { CustomMeasureReturnType } from "../../customMeasure/hooks/useCustomMeasure";
import { FormulaNode } from "../../customMeasure/utilities/fillNodes";
import { PropsWithChildren } from "react";
import VariablePropertyItem from "./VariablePropertyItem";
import { useChartOfAccountsContext } from "../contexts/ChartOfAccountsContext";

interface Props {
  formulaNode: FormulaNode;
  state: CustomMeasureReturnType;
  index: number;
}
export default function VariablePropertyItemWrapper({ formulaNode, state, index }: PropsWithChildren<Props>) {
  const { root } = useChartOfAccountsContext();
  return <VariablePropertyItem formulaNode={formulaNode} state={state} parentNode={root} index={index} />;
}
