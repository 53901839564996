import { SvgIcon, SvgIconProps } from "@mui/material";

export const EqualSignIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 9, height: 9 }} viewBox="0 0 9 9" {...props}>
      <path
        d="M6.25879 0.811035V1.83301H0.964844V0.811035H6.25879ZM6.25879 3.44531V4.46729H0.964844V3.44531H6.25879Z"
        fill="#B4BDC6"
      />
    </SvgIcon>
  );
};
export default EqualSignIcon;
