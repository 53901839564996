import { Grid, Switch, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import { ConditionDescriptor, ConditionEqualityType } from "../../../../../../shared/reporting/api/biClient.types";
import { DimensionDescriptor } from "../../../../../api/biApi.types";
import { ConditionField } from "../../../Types";
import TextFilterGrid from "./TextFilter.Grid";
import { getCrossFilterDictionary } from "./conditionsHelper";

interface Props {
  field: ConditionField;
  meta: DimensionDescriptor;
  showOnlySelectedByDefault?: boolean;
  readonly?: boolean;
  onFilterUpdated: (filter: ConditionDescriptor) => void;
}
export const TextFilterCondition = (props: Props) => {
  const { field, meta, showOnlySelectedByDefault, readonly, onFilterUpdated } = props;
  const [textFilter, setTextFilter] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>(field.config.filter?.values || []);
  const [showOnlySelected, setShowOnlySelected] = useState(showOnlySelectedByDefault || false);
  const [excludeValues, setExcludeValues] = useState(
    field.config.filter?.equalityType === ConditionEqualityType.NotEqual || false
  );

  useEffect(() => {
    onFilterUpdated({
      dimensionName: meta.name,
      values: selectedValues,
      equalityType: excludeValues ? ConditionEqualityType.NotEqual : ConditionEqualityType.Equal,
    });
  }, [selectedValues, excludeValues, meta.name, onFilterUpdated]);

  const updateEqualityType = useCallback(
    (exclude: boolean) => {
      const filter: ConditionDescriptor = {
        dimensionName: field.meta.name,
        values: selectedValues,
        equalityType: exclude ? ConditionEqualityType.NotEqual : ConditionEqualityType.Equal,
      };
      onFilterUpdated(filter);
      setExcludeValues(exclude);
    },
    [field, selectedValues, onFilterUpdated]
  );

  const getOnlySelectedValues = useCallback(
    (filteredValues: Record<string, string>[]) => {
      if (showOnlySelected) {
        return filteredValues.filter((val) => {
          const keyFieldValue = val[meta.keyFieldName];
          if (keyFieldValue === undefined) return false;
          return selectedValues.indexOf(keyFieldValue) > -1;
        });
      }
      return filteredValues;
    },
    [showOnlySelected, selectedValues, meta.keyFieldName]
  );

  const values = useMemo(() => {
    const dictionary = getCrossFilterDictionary(field, meta);
    if (!textFilter) {
      return getOnlySelectedValues(dictionary);
    }
    const filter = textFilter.toLowerCase();
    const filteredValues = dictionary.filter((item) => {
      for (const key in item) {
        if (field.meta.availableFields.indexOf(key) > -1) {
          const itemValue = item[key] + "";
          const index = itemValue?.toLowerCase()?.indexOf(filter);
          if (index !== undefined && index > -1) {
            return true;
          }
        }
      }
      return false;
    });
    return getOnlySelectedValues(filteredValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, meta, meta.dictionary, textFilter, getOnlySelectedValues]);

  const handleSelect = useCallback(
    (newSelectedValues: string[]) => {
      setSelectedValues((prev) => [
        ...newSelectedValues,
        ...prev.filter((sv) => !values.some((v) => v[field.meta.keyFieldName] === sv)),
      ]);
    },
    [field.meta.keyFieldName, values]
  );

  return (
    <Grid
      container
      sx={{
        flex: 1,
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Grid item flexDirection={"row"} justifyContent="stretch" display={"flex"}>
        <SearchField onSearch={setTextFilter} debounceTimeMs={500} fullWidth />
      </Grid>
      <Grid container sx={{ pl: 0.5 }}>
        <Typography>Show only selected</Typography>
        <HorizontalFill />
        <Switch checked={showOnlySelected} onChange={(_, value) => setShowOnlySelected(value)} />
      </Grid>
      {!readonly && (
        <Grid container sx={{ pl: 0.5 }}>
          <Typography>Exclude values</Typography>
          <HorizontalFill />
          <Switch checked={excludeValues} onChange={(_, value) => updateEqualityType(value)} />
        </Grid>
      )}
      <Grid container sx={{ mt: -1, overflow: "auto", maxWidth: "100%", flex: 1 }}>
        <TextFilterGrid
          field={field}
          values={values}
          selectedValues={selectedValues}
          readonly={readonly}
          onChanged={handleSelect}
        />
      </Grid>
    </Grid>
  );
};

export default TextFilterCondition;
