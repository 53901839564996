import React from "react";
import { useDispatch } from "react-redux";
import { useLocalization } from "../../../../hooks/useLocalization";
import { GridDataStateActionType, ReducerAction } from "./gridDataState";
import useDebounce from "../../../../../shared/hooks/useDebounce";
import { update } from "../../../../store/devToolsSlice";
import { useRequestConfigViewer } from "./useRequestConfigViewer";
import { currentReportActions } from "../../../../store/currentReportSlice";
import { FailedMeasure } from "../../../../api/biApi.types";
import { useEnhancedBiApiClientProvider } from "../../../../contexts/ApiClientProviderContext";
import { isApiReportResponse } from "../../../../utilities/biClientProviderHelper";
import { apiReportActions } from "../../../../store/apiReportSlice";

export default function useDataLoadingViewer(dispatch: React.Dispatch<ReducerAction>) {
  const dispatchGlobal = useDispatch();
  const { requestConfig } = useRequestConfigViewer();
  const { runReport } = useEnhancedBiApiClientProvider();

  const locale = useLocalization();
  const [dataLoadingCancellationToken, setDataLoadingCancellationToken] = React.useState<CancellationToken | undefined>(
    undefined
  );
  const [failedMeasures, setFailedMeasures] = React.useState<FailedMeasure[]>([]);

  const cancelDataLoading = () => dataLoadingCancellationToken?.cancel();

  const loadData = () => {
    cancelDataLoading();

    dispatch({ type: GridDataStateActionType.UPDATE_STATE, payload: { error: undefined } });
    dispatch({ type: GridDataStateActionType.LOADING_STARTED });
    dispatch({ type: GridDataStateActionType.LOADING_GROUPS_STARTED });
    dispatchGlobal(currentReportActions.setEtag({ etag: undefined, cacheValid: undefined }));

    const calcConfig = { ...requestConfig };

    setDataLoadingCancellationToken(
      runReport(
        calcConfig,
        (resp) => {
          if (resp.data && isApiReportResponse(resp.data)) {
            dispatchGlobal(apiReportActions.setResponse(resp.data.publicResponse));
          }
          dispatch({ type: GridDataStateActionType.LOADING_ENDED });
          dispatch({ type: GridDataStateActionType.LOADING_GROUPS_ENDED });
          if (resp.success) {
            dispatch({
              type: GridDataStateActionType.UPDATE_STATE,
              payload: {
                rows: resp.data.data || [],
                groupTotals: resp.data.groupTotals,
                grandTotals: resp.data.grandTotals,
                totalCount: resp.data.totalCount,
                columns: resp.data.columns,
              },
            });
            dispatchGlobal(update({ loggingItems: resp.data.loggingItems }));
            dispatchGlobal(currentReportActions.setEtag({ etag: resp.data.etag, cacheValid: undefined }));
            setFailedMeasures(resp.data.failedMeasures);
          } else {
            dispatch({
              type: GridDataStateActionType.UPDATE_STATE,
              payload: { error: resp.error?.message || locale.common.calculation_error },
            });
          }
        },
        (error) => {
          dispatch({ type: GridDataStateActionType.LOADING_ENDED });
          dispatch({ type: GridDataStateActionType.LOADING_GROUPS_ENDED });
          if (error === "canceled") {
            dispatch({ type: GridDataStateActionType.UPDATE_STATE, payload: { error: undefined } });
          } else {
            dispatch({
              type: GridDataStateActionType.UPDATE_STATE,
              payload: { error: error || locale.common.calculation_error },
            });
          }
        }
      )
    );
  };

  const doLoadData = useDebounce(loadData, 100);

  const handleInvalidConfiguration = () => {
    dispatchGlobal(apiReportActions.setResponse(undefined));
  };

  return { loadData: doLoadData, cancelDataLoading, failedMeasures, handleInvalidConfiguration };
}

export type ViewerDataLoadingReturnType = ReturnType<typeof useDataLoadingViewer>;

type CancellationToken = {
  cancel: () => void;
};
