import { useCallback, useState } from "react";
import SaveReportForm, { Props } from "./SaveReportForm";
import { formatString } from "../../../utilities/stringFormatter";
import { useSharedReportingLocalization } from "../../hooks/useLocalization";
import { useBiApiClientProvider } from "../../contexts/BiApiClientProviderContext";

interface SaveReportFormWrapperProps extends Props {
  newReport?: boolean;
}
export default function SaveReportFormWrapper(props: SaveReportFormWrapperProps) {
  const { validateName } = useBiApiClientProvider();
  const { report: locale } = useSharedReportingLocalization();
  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);

  const validateReportName = useCallback(
    async (name: string) => {
      if (!props.showName) return true;
      setSaving(true);
      try {
        const reportId = props.newReport ? undefined : props.report.reportId;
        const result = await validateName({ reportId, name, clientCode: props.report.clientCode });
        if (!result.success || result.error) {
          setError(locale.rename_error);
          return false;
        }
        if (result.success && result.data.nameExists === true) {
          setError(formatString(locale.name_exist, name));
          return false;
        }
        return true;
      } catch (e) {
        setError(locale.rename_error);
        return false;
      } finally {
        setSaving(false);
      }
    },
    [locale.name_exist, locale.rename_error, props, validateName]
  );

  const handleSave = useCallback(
    async (name: string, groupId: string) => {
      if (!(await validateReportName(name))) return;
      props.onSave(name, groupId);
    },
    [props, validateReportName]
  );

  return (
    <SaveReportForm
      {...props}
      error={error || props.error}
      saving={saving || props.saving}
      onSave={handleSave}
    ></SaveReportForm>
  );
}
