export enum Pages {
  Oauth = "oauth",
  SignUp = "signup",
  LoggedOut = "loggedout",
  Logout = "logout",
  Reports = "reports",
  Builder = "builder",
  Forbidden = "forbidden",
  Error = "error",
  ApiReports = "api-reports",
  ApiBuilder = "api-builder",
}
