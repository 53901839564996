import { ReportType } from "../../../../../shared/reporting/api/biClient.types";
import { ValueField } from "../../Types";

export default function canDropValueItem(reportType: ReportType | undefined, values: ValueField[]) {
  switch (reportType) {
    case ReportType.PieChart: {
      return values.length === 0;
    }
    case ReportType.BarChart:
    case ReportType.LineChart: {
      return true;
    }
    default:
      return true;
  }
}
