import { Typography } from "@mui/material";
import { CustomMeasureReturnType } from "./hooks/useCustomMeasure";

interface Props {
  state: CustomMeasureReturnType;
}

const FunctionTooltip = ({ state }: Props) => {
  const func = state.formulaNodes.find((node) =>
    node.linkedNodes.some(
      (ln) =>
        ln.token.endIndex === state.functionHovered?.endIndex &&
        ln.token.startIndex === state.functionHovered?.startIndex &&
        ln.token.text === state.functionHovered?.text &&
        ln.token.tokenType === state.functionHovered?.tokenType
    )
  );

  if (!func) {
    return null;
  }

  return (
    <>
      <Typography
        variant="subtitle1"
        sx={(theme) => ({ color: theme.palette.common.white, fontWeight: 500, fontSize: 10 })}
      >
        {func.function?.fullName} - {func.function?.description}
      </Typography>
      {func.function?.args.map((arg) => (
        <Typography sx={{ color: "#B4BDC6", fontWeight: 400, fontSize: 10 }} key={arg.name}>
          {arg.name}: ({arg.description})
        </Typography>
      ))}
    </>
  );
};

export default FunctionTooltip;
