import { createSvgIcon } from "@mui/material";

const ReverseSignIcon = createSvgIcon(
  <svg viewBox="0 0 16 16">
    <path
      d="M2 5.33333C2 4.96514 2.29848 4.66667 2.66667 4.66667H4V3.33333C4 2.96514 4.29848 2.66667 4.66667 2.66667C5.03486 2.66667 5.33333 2.96514 5.33333 3.33333V4.66667H6.66667C7.03486 4.66667 7.33333 4.96514 7.33333 5.33333C7.33333 5.70152 7.03486 6 6.66667 6H5.33333V7.33333C5.33333 7.70152 5.03486 8 4.66667 8C4.29848 8 4 7.70152 4 7.33333V6H2.66667C2.29848 6 2 5.70152 2 5.33333ZM8.66667 10.6667C8.66667 10.2985 8.96514 10 9.33333 10H13.3333C13.7015 10 14 10.2985 14 10.6667C14 11.0349 13.7015 11.3333 13.3333 11.3333H9.33333C8.96514 11.3333 8.66667 11.0349 8.66667 10.6667ZM10.5008 2.33349C10.62 2.12713 10.8401 2 11.0784 2C11.5917 2 11.9126 2.55567 11.656 3.00026L5.49917 13.6665C5.38005 13.8729 5.15988 14 4.9216 14C4.40827 14 4.08741 13.4443 4.34404 12.9997L10.5008 2.33349Z"
      fill="#00A866"
    />
  </svg>,
  "ReverseSignIcon"
);

export default ReverseSignIcon;
