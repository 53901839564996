import { Grid, SvgIconProps } from "@mui/material";
import { useClientContext } from "../../../contexts/ClientContext";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentReport,
  selectIsReportChanged,
  selectReportConfiguration,
  selectReportEditMode,
} from "../../../store/currentReportSlice";
import UnsavedChangesDialog from "./UnsavedChangesDialog";
import BackToButton from "../../common/BackToButton";
import { SaveReportDialogProps } from "../Types";
import { useEnhancedBiApiClientProvider } from "../../../contexts/ApiClientProviderContext";

interface Props {
  showBackButton: boolean;
  SaveReportDialogType: React.ComponentType<SaveReportDialogProps>;
  ProductIcon: React.ComponentType<SvgIconProps>;
}

export default function BackToReports({ showBackButton, SaveReportDialogType, ProductIcon }: Props) {
  const { clientCode } = useClientContext();
  const isReportChanged = useSelector(selectIsReportChanged);
  const report = useSelector(selectCurrentReport);
  const editMode = useSelector(selectReportEditMode);
  const reportConfiguration = useSelector(selectReportConfiguration);
  const [showUnsavedDialog, setShowUnsavedDialog] = React.useState(false);
  const [showSaveDialog, setShowSaveDialog] = React.useState(false);
  const { getReportsUrl } = useEnhancedBiApiClientProvider();

  const navigateToReports = React.useCallback(() => {
    window.location.href = getReportsUrl(clientCode);
  }, [clientCode, getReportsUrl]);

  const onBackButtonClicked = React.useCallback(() => {
    if (editMode === true && isReportChanged === true) {
      setShowUnsavedDialog(true);
    } else {
      navigateToReports();
    }
  }, [isReportChanged, editMode, navigateToReports, setShowUnsavedDialog]);

  const handleOnCancel = React.useCallback(() => setShowUnsavedDialog(false), [setShowUnsavedDialog]);
  const handleOnContinue = React.useCallback(() => navigateToReports(), [navigateToReports]);
  const handleOnSave = React.useCallback(() => {
    setShowSaveDialog(true);
    setShowUnsavedDialog(false);
  }, [setShowSaveDialog, setShowUnsavedDialog]);

  return (
    <>
      <Grid
        container
        sx={{
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
          flexBasis: 1,
          gap: 1.4,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={onBackButtonClicked}
      >
        <BackToButton over={showBackButton} ProductIcon={ProductIcon} />
      </Grid>
      {showUnsavedDialog && (
        <UnsavedChangesDialog
          onCancel={handleOnCancel}
          onContinue={handleOnContinue}
          onSave={handleOnSave}
          name={report?.name}
          type="report"
        />
      )}
      {showSaveDialog && report && (
        <SaveReportDialogType
          report={report}
          open={true}
          title="Save Report"
          saveBtnTitle="Save"
          reportConfiguration={reportConfiguration}
          onClose={() => setShowSaveDialog(false)}
        />
      )}
    </>
  );
}
