import { Box, Grid, SxProps, Typography } from "@mui/material";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import TooltipWrapper from "../../../../../shared/components/TooltipWrapper";
import { AnyObject } from "../../../../../shared/types";
import { MeasureDescriptor } from "../../../../api/biApi.types";
import FormulaInput from "./FormulaInput";
import FormulaInputWrapper from "./FormulaInputWrapper";
import Intellisense from "./Intellisense";
import { CustomMeasureReturnType } from "./hooks/useCustomMeasure";
import { useFormulaInputState } from "./hooks/formulaInputState";
import { styleFormulaNodes } from "./utilities/formulaNodeStyling";
import { toAmountCalculateByCaption, toAmountType, toCalculateBy } from "./utilities/measureConverter";
import { Token, TokenType } from "./utilities/tokenizer";
import FunctionTooltip from "./FunctionTooltip";

const DEFAULT_SHOW_VALIDATION_ERROR_DELAY = 3000;

interface Props {
  measure?: MeasureDescriptor;
  state: CustomMeasureReturnType;
  editable: boolean;
  onValidated?: (valid: boolean) => void;
  showCalculatedBy?: boolean;
  hideCheck?: boolean;
  sx?: SxProps;
  formulaStructureError?: string;
}

const FormulaContainer = ({
  measure,
  state,
  editable,
  onValidated,
  showCalculatedBy,
  hideCheck,
  sx,
  formulaStructureError,
}: Props) => {
  const inputState = useFormulaInputState();
  const [formulaError, setFormulaError] = useState<string>();
  const [formulaFocused, setFormulaFocused] = useState(false);
  const formulaErrorRef = useRef<string>();

  const [displayingError, setDisplayingError] = useState<string>();
  const [inputTrigger, setInputTrigger] = useState<AnyObject>();

  const functionRef = useRef<HTMLElement | null>(null);
  functionRef.current = state.functionHovered?.element || null;

  useEffect(() => {
    formulaErrorRef.current = formulaError;
  }, [formulaError]);

  useEffect(() => {
    styleFormulaNodes(state.formulaNodes);
  }, [state.formulaNodes]);

  useEffect(() => {
    onValidated?.call(null, formulaError === undefined);
  }, [formulaError, onValidated]);

  const onFailed = useCallback(
    (tokens: Token[]) => {
      const invalidToken = tokens.find((token) => !!token.error);
      let error: string | undefined = undefined;
      if (invalidToken !== undefined) {
        error = `${invalidToken.error}${invalidToken.tokenType !== TokenType.Eof ? `: ${invalidToken.value}` : ""}`;
      }
      setFormulaError(error);
    },
    [setFormulaError]
  );

  useEffect(() => {
    const delayMs = formulaFocused ? DEFAULT_SHOW_VALIDATION_ERROR_DELAY : 0;
    let timeoutId: NodeJS.Timeout | undefined = undefined;
    if (!formulaErrorRef.current && !formulaStructureError) {
      setDisplayingError(undefined);
      return;
    }
    timeoutId = setTimeout(() => {
      setDisplayingError(formulaErrorRef.current || formulaStructureError);
    }, delayMs);

    return () => clearTimeout(timeoutId);
  }, [setDisplayingError, inputTrigger, formulaStructureError, formulaFocused]);

  const calculateBy = useMemo(() => {
    if (measure === undefined) return "";
    const calcBy = toCalculateBy(measure.units);
    if (calcBy === undefined) return "";
    const amountType = toAmountType(measure.units);
    return toAmountCalculateByCaption(calcBy, amountType);
  }, [measure]);

  return (
    <Fragment key={"FormulaContainer"}>
      <Grid container flexDirection="column" sx={{ gap: 0.5, ...sx }}>
        <TooltipWrapper
          title={<FunctionTooltip state={state} />}
          placement="top"
          arrow
          open={functionRef.current !== null}
          PopperProps={{
            hidden: functionRef.current === null,
            anchorEl: {
              getBoundingClientRect: () => {
                return new DOMRect(
                  (functionRef.current?.getBoundingClientRect().x || 0) + 15,
                  functionRef.current?.getBoundingClientRect().y,
                  0,
                  0
                );
              },
            },
          }}
        >
          <div />
        </TooltipWrapper>
        <Grid item sx={{ display: "flex", paddingBottom: 1, justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography id="custom_measure_formula_title" variant="subtitle1">
              Formula
            </Typography>
          </Box>
          {showCalculatedBy && (
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography color="secondary">Calculate by</Typography>
              <Typography color="primary">{calculateBy}</Typography>
            </Box>
          )}
        </Grid>
        <FormulaInputWrapper
          state={state}
          focused={formulaFocused}
          formulaError={!!displayingError}
          hideCheck={hideCheck}
        >
          <FormulaInput
            key={measure?.name || "formula_input_key"}
            onSuccess={() => {
              setFormulaError(undefined);
              setDisplayingError(undefined);
            }}
            onFailed={onFailed}
            state={state}
            editable={editable}
            formulaState={inputState}
            actions={inputState.actions}
            defaultFormula={measure?.formula}
            onFocus={() => setFormulaFocused(true)}
            onBlur={() => {
              setFormulaFocused(false);
              setFormulaError(formulaErrorRef.current);
              setDisplayingError(formulaErrorRef.current);
            }}
            onInput={() => setInputTrigger({})}
          />
        </FormulaInputWrapper>
        {displayingError && (
          <Typography variant="caption" sx={(theme) => ({ color: theme.palette.error.dark })}>
            {displayingError}
          </Typography>
        )}
        {!displayingError && editable && (
          <Typography
            variant="caption"
            sx={(theme) => ({ height: editable ? "20px" : "inherit", color: theme.palette.secondary.main })}
          >
            Example DPI: A/(B*-1)
          </Typography>
        )}
        <Intellisense state={state} formulaState={inputState} actions={inputState.actions} />
      </Grid>
    </Fragment>
  );
};

export default FormulaContainer;
