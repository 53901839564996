import PromptDialog from "../../../../shared/components/dialog/PromptDialog";
import SaveReportFormWrapper from "../../../../shared/reporting/components/reports/SaveReportFormWrapper";
import useSaveReportAsDialog from "../common/hooks/useSaveReportAsDialog";
import { SaveAsDialogProps } from "../Types";

const SaveAsDialog = ({ report, reportConfiguration, addReport, onChanged, onClose }: SaveAsDialogProps) => {
  const { error, handleSaveClicked, saving } = useSaveReportAsDialog({
    report,
    reportConfiguration,
    addReport,
    onChanged,
    onClose,
  });

  return (
    <PromptDialog open={true}>
      <SaveReportFormWrapper
        report={report}
        error={error}
        title={"Save As"}
        saveBtnTitle={"Save"}
        saving={saving}
        showName
        newReport
        onClose={onClose}
        onSave={handleSaveClicked}
      />
    </PromptDialog>
  );
};

export default SaveAsDialog;
