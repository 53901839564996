import { SvgIcon, SvgIconProps } from "@mui/material";

export const TabularIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M8.33333 8.35H12.5V17.5H8.33333V8.35ZM14.1667 17.5H16.6667C17.5833 17.5 18.3333 16.75 18.3333 15.8333V8.33333H14.1667V17.5ZM16.6667 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V6.66667H18.3333V4.16667C18.3333 3.25 17.5833 2.5 16.6667 2.5ZM2.5 15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H6.66667V8.33333H2.5V15.8333Z" />
    </SvgIcon>
  );
};
export default TabularIcon;
