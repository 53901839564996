import { createSvgIcon } from "@mui/material";

const AlertReportIcon = createSvgIcon(
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7598_35773)">
      <path
        d="M8 0H72C76.4 0 80 3.6 80 8V72C80 76.4 76.4 80 72 80H8C3.6 80 0 76.4 0 72V8C0 3.6 3.6 0 8 0Z"
        fill="white"
      />
      <path
        d="M65.0004 69.7001H16.9004V10.3H52.8004L65.1004 22.6V69.7001H65.0004ZM21.9004 64.7001H60.0004V24.6L50.7004 15.3H21.9004V64.7001Z"
        fill="#C7DBE5"
      />
      <path d="M62.5 27.8H48V12.8H53V22.8H62.5V27.8Z" fill="#C7DBE5" />
      <path
        d="M55.8008 30.6001V38.1001L43.1008 52.0001L35.4008 47.2001L25.8008 56.4001V49.5001L34.8008 40.9001L42.2008 45.6001L55.8008 30.6001Z"
        fill="#00A866"
      />
      <path
        d="M58.7077 48.2335C59.8754 46.3066 62.6712 46.3066 63.8389 48.2335L77.8022 71.2738C79.0138 73.2731 77.5744 75.8286 75.2365 75.8286H47.3101C44.9723 75.8286 43.5328 73.2731 44.7445 71.2738L58.7077 48.2335Z"
        fill="#FF9800"
      />
      <path
        d="M63.4742 70.9916C64.1294 69.7762 63.6753 68.2599 62.4599 67.6047C61.2446 66.9495 59.7282 67.4036 59.073 68.619C58.4178 69.8343 58.872 71.3507 60.0873 72.0059C61.3027 72.6611 62.8191 72.207 63.4742 70.9916Z"
        fill="white"
      />
      <path d="M64.0518 54.9924L62.9405 65.2189H59.6991L58.4951 54.9924H64.0518Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_7598_35773">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "AlertReportIcon"
);

export default AlertReportIcon;
