import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";

interface FieldItemOptionsButtonProps {
  hideOptions?: boolean;
  showOptions?: () => void;
}
export default function FieldItemOptionsButton({ hideOptions, showOptions }: FieldItemOptionsButtonProps) {
  return (
    <IconButton onClick={showOptions} sx={{ height: "30px", display: hideOptions === true ? "none" : "inherit" }}>
      <ChevronRightIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
    </IconButton>
  );
}
