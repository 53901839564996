import { SvgIcon, SvgIconProps } from "@mui/material";

export const HashIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx={{ width: 20, height: 20, ...props.sx }} viewBox="0 0 20 20">
      <g>
        <path d="M6.04168 16.6668C5.7778 16.6668 5.56252 16.5627 5.39585 16.3543C5.22918 16.146 5.18057 15.9168 5.25002 15.6668L5.83335 13.3335H3.97918C3.70141 13.3335 3.47918 13.226 3.31252 13.011C3.14585 12.7954 3.09724 12.5557 3.16668 12.2918C3.20835 12.0974 3.30557 11.9446 3.45835 11.8335C3.61113 11.7224 3.78474 11.6668 3.97918 11.6668H6.25002L7.08335 8.3335H4.81252C4.53474 8.3335 4.31252 8.226 4.14585 8.011C3.97918 7.79544 3.93057 7.55572 4.00002 7.29183C4.04168 7.09738 4.13891 6.94461 4.29168 6.8335C4.44446 6.72238 4.61807 6.66683 4.81252 6.66683H7.50002L8.18752 3.93766C8.22919 3.75711 8.31946 3.61127 8.45835 3.50016C8.59724 3.38905 8.76391 3.3335 8.95835 3.3335C9.22224 3.3335 9.43752 3.43766 9.60418 3.646C9.77085 3.85433 9.81946 4.0835 9.75002 4.3335L9.16669 6.66683H12.5L13.1875 3.93766C13.2292 3.75711 13.3195 3.61127 13.4584 3.50016C13.5972 3.38905 13.7639 3.3335 13.9584 3.3335C14.2222 3.3335 14.4375 3.43766 14.6042 3.646C14.7709 3.85433 14.8195 4.0835 14.75 4.3335L14.1667 6.66683H16.0209C16.2986 6.66683 16.5209 6.77433 16.6875 6.98933C16.8542 7.20489 16.9028 7.44461 16.8334 7.7085C16.7917 7.90294 16.6945 8.05572 16.5417 8.16683C16.3889 8.27794 16.2153 8.3335 16.0209 8.3335H13.75L12.9167 11.6668H15.1875C15.4653 11.6668 15.6875 11.7743 15.8542 11.9893C16.0209 12.2049 16.0695 12.4446 16 12.7085C15.9584 12.9029 15.8611 13.0557 15.7084 13.1668C15.5556 13.2779 15.382 13.3335 15.1875 13.3335H12.5L11.8125 16.0627C11.7709 16.2432 11.6806 16.3891 11.5417 16.5002C11.4028 16.6113 11.2361 16.6668 11.0417 16.6668C10.7778 16.6668 10.5625 16.5627 10.3959 16.3543C10.2292 16.146 10.1806 15.9168 10.25 15.6668L10.8334 13.3335H7.50002L6.81252 16.0627C6.77085 16.2432 6.68057 16.3891 6.54168 16.5002C6.4028 16.6113 6.23613 16.6668 6.04168 16.6668ZM7.91669 11.6668H11.25L12.0834 8.3335H8.75002L7.91669 11.6668Z" />
      </g>
    </SvgIcon>
  );
};
export default HashIcon;
