import { createSvgIcon } from "@mui/material";

const NoPivotIcon = createSvgIcon(
  <svg viewBox="0 0 16 17" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.72467 14.4462C3.98579 14.7073 4.29967 14.8379 4.66634 14.8379H4.83301H6.41634H7.33301C7.69968 14.8379 8.01356 14.7073 8.27467 14.4462C8.53579 14.1851 8.66634 13.8712 8.66634 13.5046V4.17122C8.66634 3.80456 8.53579 3.49067 8.27467 3.22956C8.01356 2.96845 7.69968 2.83789 7.33301 2.83789H6.41634H4.83301H4.66634C4.29967 2.83789 3.98579 2.96845 3.72467 3.22956C3.46356 3.49067 3.33301 3.80456 3.33301 4.17122V4.33789V5.46289V7.71289V9.96289V12.2129V13.3379V13.5046C3.33301 13.8712 3.46356 14.1851 3.72467 14.4462ZM4.66634 4.2273C4.68111 4.20508 4.7002 4.18599 4.72241 4.17122H7.33301V6.24844H4.66634V4.2273ZM4.72241 13.5046H7.33301V11.1432H4.66634V13.4485C4.68111 13.4707 4.7002 13.4898 4.72241 13.5046ZM4.66634 7.54844V9.84316H7.33301V7.54844H4.66634ZM9.58301 3.48789V2.83789H11.1663C11.9948 2.83789 12.6663 3.50946 12.6663 4.33789V5.46289H12.0163H11.3663V4.33789C11.3663 4.22743 11.2768 4.13789 11.1663 4.13789H9.58301V3.48789ZM12.0163 7.71289H12.6663V9.96289H12.0163H11.3663V7.71289H12.0163ZM12.0163 12.2129H12.6663V13.3379C12.6663 14.1663 11.9948 14.8379 11.1663 14.8379H9.58301V14.1879V13.5379H11.1663C11.2768 13.5379 11.3663 13.4483 11.3663 13.3379V12.2129H12.0163Z"
      fill="#00A866"
    />
  </svg>,
  "NoPivotIcon"
);

export default NoPivotIcon;
