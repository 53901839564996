import { AreaItemType, ConditionField } from "../../Types";
import { generateGuid } from "../../../../../shared/utilities/generateGuid";
import { ConditionEqualityType } from "../../../../../shared/reporting/api/biClient.types";
import { DimensionDescriptor } from "../../../../api/biApi.types";

export function createEmptyConditionItem(meta: DimensionDescriptor): ConditionField {
  return {
    meta: meta,
    areaItemType: AreaItemType.CONDITIONS,
    config: {
      guid: generateGuid(),

      filter: {
        dimensionName: meta.name,
        values: [],
        equalityType: ConditionEqualityType.Equal,
      },
    },
    dictionary: { loaded: false, loading: false },
  };
}
