import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useMemo } from "react";

interface Props {
  pixelSize: number;
  horizontal: boolean;
  dragging: boolean;
}

export const Splitter = ({ dragging, pixelSize, horizontal }: Props) => {
  const bgcolor = useMemo(() => (dragging ? grey[500] : "divider"), [dragging]);

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        height: "100%",
        outline: "none",
        overflow: "hidden",
        userSelect: "none",
        width: "100%",
        cursor: horizontal ? "row-resize" : "col-resize",
      }}
    >
      <Box
        sx={{
          position: "relative",
          bgcolor: bgcolor,
          ":hover": {
            bgcolor: grey[500],
            div: {
              bgcolor: grey[500],
            },
          },
          height: horizontal ? getThinLineSize() : "100%",
          width: horizontal ? "100%" : getThinLineSize(),
          mt: horizontal ? getCenteredMargin(pixelSize) : 0,
          ml: horizontal ? 0 : getCenteredMargin(pixelSize),
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "45%",
            ml: "-2px",
            height: horizontal ? 6 : 32,
            width: horizontal ? 32 : 6,
            bgcolor: bgcolor,
            borderRadius: 1,
            ":hover": {
              bgcolor: grey[500],
            },
          }}
        ></Box>
      </Box>
    </Box>
  );
};

function getThinLineSize() {
  return `2px`;
}

function getCenteredMargin(size: number) {
  return `${Math.max(0, Math.floor(size / 2) - 1)}px`;
}
