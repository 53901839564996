import { useLocalization } from "../../../../hooks/useLocalization";
import { Box, Divider, Grid } from "@mui/material";
import PartContainer from "../../PartContainer";
import PreviewTabs from "../PreviewTabs";
import RequestCode from "../editor/code/RequestCode";
import ResponseCode from "../editor/code/ResponseCode";

interface Props {
  parameters: JSX.Element;
  preview: JSX.Element;
  actionPanel: JSX.Element;
  isLoading: boolean;
}

const ViewContainer = ({ parameters, preview, actionPanel, isLoading }: Props) => {
  const { common: locale } = useLocalization();

  return (
    <Box display={"flex"} overflow={"auto"} height={"100%"}>
      {parameters}
      <Divider orientation="vertical" />
      <PreviewTabs
        GridView={
          <PartContainer
            sx={{ borderRight: "unset", p: "1.3rem", pt: 2, height: "100%" }}
            containerSx={{ position: "relative", gap: "1rem" }}
            caption={locale.report_preview_label}
            captionVariant="subtitle2"
            actions={actionPanel}
          >
            {preview}
          </PartContainer>
        }
        CodeView={
          <Grid
            display={"grid"}
            container
            width={"100%"}
            flexWrap={"nowrap"}
            minWidth={"45rem"}
            gap={3}
            p={"1.3rem"}
            overflow={"hidden"}
            gridTemplateRows={"minmax(20%, auto) minmax(20%, auto)"}
          >
            <RequestCode isLoading={isLoading} />
            <ResponseCode isLoading={isLoading} />
          </Grid>
        }
      />
    </Box>
  );
};

export default ViewContainer;
