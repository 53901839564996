import { TextField, TextFieldProps } from "@mui/material";
import { useState } from "react";
import { ValidationResult, Validator, validResult } from "../../utilities/validators";

interface Props {
  name: string;
  InputProps: TextFieldProps;
  onValueChanged: (value: string) => void;
  doValidate: Validator<string>;
  onValidated: (value: string, validationResult: ValidationResult) => void;
}

export const TextFieldExt = (props: Props) => {
  const [validationResult, setValidationResult] = useState<ValidationResult>(validResult());

  const { name, onValueChanged, doValidate, onValidated } = props;

  const valueChanged = (value: string) => {
    value = value.trim();
    const validationResult = doValidate(value);
    setValidationResult(validationResult);
    onValidated(name, validationResult);
  };

  const valueChanging = (value: string) => {
    const validationResult = doValidate(value);
    onValidated(name, validationResult);
    onValueChanged(value);
  };

  return (
    <TextField
      {...props.InputProps}
      onBlur={(evt) => valueChanged(evt.target.value)}
      onChange={(evt) => valueChanging(evt.target.value)}
      error={!validationResult.isValid}
      helperText={validationResult.error}
    />
  );
};

export default TextFieldExt;
