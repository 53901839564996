import {
  BarSeriesSettings,
  BarSettings,
  BasicLinearSettings,
  BasicLineSeriesSettings,
  ChartStyleSettings,
  DoughnutSettings,
  LinearSeriesSettings,
  LineType,
  ReportType,
  SeriesSettings,
  SerieType,
} from "../../../../../shared/reporting/api/biClient.types";

export function getLinearSettings(settings: ChartStyleSettings): BasicLinearSettings | undefined {
  switch (settings.type) {
    case ReportType.BarChart:
    case ReportType.LineChart:
    case ReportType.AreaChart:
      return settings;
    default:
      return undefined;
  }
}
export function getBarSettings(settings: ChartStyleSettings): BarSettings | undefined {
  switch (settings.type) {
    case ReportType.BarChart:
      return settings;
    default:
      return undefined;
  }
}
export function getLineBasedSettings(settings: ChartStyleSettings): BasicLinearSettings | undefined {
  switch (settings.type) {
    case ReportType.LineChart:
    case ReportType.AreaChart:
      return settings;
    default:
      return undefined;
  }
}

export function getDoughnutSettings(settings: ChartStyleSettings): DoughnutSettings | undefined {
  switch (settings.type) {
    case ReportType.DoughnutChart:
      return settings;
    default:
      return undefined;
  }
}

export function getDefaultSerieType(reportType: ReportType): SerieType | undefined {
  switch (reportType) {
    case ReportType.BarChart:
      return "Bar";
    case ReportType.LineChart:
      return "Line";
    case ReportType.AreaChart:
      return "Area";
    default:
      return undefined;
  }
}
export function getDefaultLineType(): LineType {
  return "Line";
}

export function isLinearSettings(settings: ChartStyleSettings): boolean {
  return !!getLinearSettings(settings);
}

export function isBarSerie(settings: ChartStyleSettings, serie: SeriesSettings): boolean {
  if (!isLinearSettings(settings)) return false;
  const linearSerie = serie as LinearSeriesSettings;
  return linearSerie?.serieType === "Bar";
}

export function toBarSerie(serie: SeriesSettings): BarSeriesSettings | undefined {
  const linearSerie = serie as LinearSeriesSettings;
  if (linearSerie?.serieType === "Bar") return linearSerie as BarSeriesSettings;
  return undefined;
}

export function toBasicLineSerie(serie: SeriesSettings | undefined): BasicLineSeriesSettings | undefined {
  const linearSerie = serie as BasicLineSeriesSettings;
  if (linearSerie?.serieType === "Line" || linearSerie?.serieType === "Area")
    return linearSerie as BasicLineSeriesSettings;
  return undefined;
}

export function isLineBasedSerie(settings: ChartStyleSettings, serie: SeriesSettings): boolean {
  if (!isLinearSettings(settings)) return false;
  const linearSerie = serie as BasicLineSeriesSettings;
  return linearSerie?.serieType === "Line" || linearSerie?.serieType === "Area";
}
