import { Chip, Typography } from "@mui/material";
import { green } from "@mui/material/colors";

const LiveStatus = () => {
  return (
    <Chip
      label={
        <Typography variant="caption" fontWeight={500}>
          Template:
          <Typography variant="caption" component={"span"} sx={{ ml: 0.5, fontWeight: "inherit", color: green[700] }}>
            Live
          </Typography>
        </Typography>
      }
      sx={{ bgcolor: green[50] }}
    />
  );
};

export default LiveStatus;
