import { Typography } from "@mui/material";
import React from "react";
import { MenuItemStyled } from "../../../../../../shared/components/MenuItemStyled";
import { ValueField } from "../../../Types";
import ExplainMeasureDialog from "../../customMeasure/ExplainMeasureDialog";
import { MeasureDescriptor } from "../../../../../api/biApi.types";
import CustomMeasureDialog from "../../customMeasure/CustomMeasureDialog";

interface Props {
  measure: ValueField;
  onClose: () => void;
}

export default function ExplainMenuItem(props: Props) {
  const { measure, onClose } = props;
  const [measureToEdit, setMeasureToEdit] = React.useState<MeasureDescriptor>();

  const [showExplain, setShowExplain] = React.useState(false);

  const doShowExplain = React.useCallback(() => {
    setShowExplain(true);
  }, []);

  return (
    <>
      <MenuItemStyled onClick={doShowExplain} sx={{ px: 2, py: 1.25, gap: 1.25 }}>
        <Typography>Measure details...</Typography>
      </MenuItemStyled>
      {showExplain && (
        <ExplainMeasureDialog
          measure={measure.meta}
          onClose={() => {
            setShowExplain(false);
            onClose();
          }}
          onEdit={(measure) => {
            setShowExplain(false);
            setMeasureToEdit(measure);
          }}
        />
      )}
      {measureToEdit && (
        <CustomMeasureDialog
          measure={measureToEdit}
          defaultGroup={measureToEdit.group}
          onClose={() => setMeasureToEdit(undefined)}
        />
      )}
    </>
  );
}
