import { SvgIcon, SvgIconProps } from "@mui/material";

export const DeleteMeasureIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 80, height: 80 }} viewBox="0 0 80 80" fill="none" {...props}>
      <rect width="80" height="80" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6193 9.72912C42.3763 8.03372 45.3531 7.79492 47.3648 8.1302L49.8308 8.5412L49.0088 13.4732L46.5429 13.0622C45.5546 12.8975 43.8314 13.0086 42.2384 13.9882C40.7397 14.9099 39.1023 16.7552 38.4135 20.5434C37.7154 24.3828 36.7285 29.8686 35.699 35.5965H41.4999H43.9999V40.5965H41.4999H34.8003L34.7453 40.9026C33.6909 46.7684 32.6744 52.4199 31.9597 56.3506C31.0485 61.3624 28.6858 64.5171 25.8346 66.2705C23.0776 67.9659 20.1007 68.2047 18.089 67.8694L15.623 67.4584L16.445 62.5264L18.911 62.9374C19.8993 63.1022 21.6225 62.991 23.2155 62.0114C24.7142 61.0897 26.3516 59.2444 27.0404 55.4562C27.7306 51.66 28.7032 46.2543 29.7202 40.5965H24.4999H21.9999V35.5965H24.4999H30.6188L30.7086 35.097C31.7629 29.2312 32.7795 23.5797 33.4942 19.649C34.4054 14.6372 36.768 11.4825 39.6193 9.72912Z"
        fill="#C7DBE5"
      />
      <path d="M59.5678 47.8951L42.0317 65.4312L45.5672 68.9667L63.1033 51.4306L59.5678 47.8951Z" fill="#F44336" />
      <path d="M45.5672 47.8951L42.0317 51.4306L59.5678 68.9667L63.1033 65.4312L45.5672 47.8951Z" fill="#F44336" />
    </SvgIcon>
  );
};
export default DeleteMeasureIcon;
