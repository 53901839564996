import { SvgIcon, SvgIconProps } from "@mui/material";

export default function AreaChartIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9914 2.70708C12.8113 2.49104 12.5345 2.38037 12.2551 2.41279C11.9758 2.44522 11.7316 2.61635 11.6058 2.86789L8.92858 8.22241L6.22349 5.78782C6.0496 5.63133 5.81794 5.55513 5.58511 5.57784C5.35228 5.60055 5.13971 5.72009 4.99935 5.90724L1.83268 10.1295C1.7245 10.2737 1.66602 10.4491 1.66602 10.6295V17.3146C1.66602 17.7749 2.03911 18.148 2.49935 18.148H18.3327C18.7929 18.148 19.166 17.7749 19.166 17.3146V5.35168C19.166 5.02624 18.9766 4.73057 18.6809 4.59459C18.3852 4.4586 18.0375 4.50717 17.7904 4.71897L15.969 6.28017L12.9914 2.70708ZM9.92989 9.94658L12.5267 4.75291L15.2295 7.99628C15.3723 8.16761 15.5778 8.27456 15.8 8.29321C16.0223 8.31186 16.2427 8.24064 16.412 8.09551L17.4993 7.16353V11.2726L15.5179 12.8125L13.0549 10L10.5919 14.6875L5.66602 12.1875L3.33268 14.4901V10.9072L5.78591 7.63627L8.62706 10.1933C8.82044 10.3673 9.08399 10.4411 9.3396 10.3927C9.59521 10.3443 9.81355 10.1793 9.92989 9.94658Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9715 9.30078C13.2018 9.27354 13.4308 9.36153 13.5836 9.536L15.6093 11.8492L17.9001 10.069C18.1123 9.90408 18.3999 9.87441 18.6413 9.99252C18.8826 10.1106 19.0357 10.3559 19.0357 10.6246V17.4996C19.0357 17.8883 18.7206 18.2033 18.332 18.2033H2.49863C2.10998 18.2033 1.79492 17.8883 1.79492 17.4996V15.3121C1.79492 15.1239 1.87035 14.9435 2.00434 14.8112L5.171 11.6862C5.38648 11.4736 5.71381 11.4226 5.98377 11.5596L10.291 13.7456L12.4312 9.67229C12.5391 9.46699 12.7412 9.32802 12.9715 9.30078ZM13.2002 11.2345L11.2142 15.0144C11.0352 15.355 10.6158 15.4888 10.2727 15.3146L5.79881 13.044L3.20233 15.6063V16.7959H17.6283V12.0627L15.949 13.3678C15.6547 13.5964 15.2332 13.5561 14.9877 13.2757L13.2002 11.2345Z"
      />
    </SvgIcon>
  );
}
