import React, { PropsWithChildren, useCallback, useContext, useState } from "react";
import { defined } from "../utilities/typeHelper";

export interface NotificationContextType {
  notification?: Notification;
  notificationError?: Notification;
  sendNotification: (text: string, autoHideDuration?: number) => void;
  sendNotificationError: (text: string, autoHideDuration?: number) => void;
}

interface Notification {
  text: string;
  autoHideDuration: number;
}

const NotificationContext = React.createContext<NotificationContextType | undefined>(undefined);

export const NotificationContextProvider = (props: PropsWithChildren) => {
  const [notification, setNotification] = useState<Notification>();
  const [notificationError, setNotificationError] = useState<Notification>();

  const sendNotification = useCallback(
    (text: string, autoHideDuration = 6000) => {
      setNotification({ text, autoHideDuration });
    },
    [setNotification]
  );

  const sendNotificationError = useCallback(
    (text: string, autoHideDuration = 10000) => {
      setNotificationError({ text, autoHideDuration });
    },
    [setNotificationError]
  );

  return (
    <NotificationContext.Provider value={{ notification, notificationError, sendNotification, sendNotificationError }}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const client = useContext(NotificationContext);
  return defined(client);
};
