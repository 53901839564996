import { Button } from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { DimensionDescriptor } from "../../../../../api/biApi.types";
import DimensionSelection from "../../../DimensionSelection";
import { ConditionField } from "../../../Types";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

interface Props {
  dimensions: DimensionDescriptor[];
  conditions: ConditionField[];
  anchorEl: HTMLElement | null;
  addCondition: (values: DimensionDescriptor) => void;
}

export const AddCustomConditions = (props: Props) => {
  const { dimensions: fields, conditions, addCondition, anchorEl } = props;

  const [showAddConditions, setShowAddConditions] = useState(false);
  const areaFieldItemWidth = useRef<number>(0);

  const fieldsForConditions = useMemo(() => {
    return fields.filter((f) => !conditions.some((c) => c.meta.name === f.name));
  }, [fields, conditions]);

  return (
    <>
      <Button
        color="secondary"
        startIcon={<AddRoundedIcon />}
        onClick={(e) => {
          setShowAddConditions(true);
          const parentWidth = e.currentTarget.parentElement?.offsetWidth;
          if (parentWidth === undefined) return;
          areaFieldItemWidth.current = parentWidth;
        }}
      >
        Add Condition
      </Button>
      {anchorEl && showAddConditions && (
        <DimensionSelection
          anchorEl={anchorEl}
          dimensions={fieldsForConditions}
          onClose={(value) => {
            if (value !== undefined) {
              addCondition(value);
            }
            setShowAddConditions(false);
          }}
          menuWidth={areaFieldItemWidth.current}
        />
      )}
    </>
  );
};
export default AddCustomConditions;
