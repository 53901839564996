import { ReportInfo, ReportGroup } from "../../../../shared/reporting/api/biClient.types";

export const getFilteredGroupedReports = (
  reports: ReportInfo[] | undefined,
  groups: ReportGroup[] | undefined,
  textFilter: string
) => {
  if (!reports || !groups) {
    return undefined;
  }

  const result: Record<string, ReportInfo[]> = {};
  const filteredReports = !textFilter ? reports : reports.filter((f) => f.name.toLowerCase().indexOf(textFilter) > -1);

  [...groups.sort((a, b) => a.order - b.order)].forEach((g) => {
    const groupReports = filteredReports.filter((r) => r.groupId === g.id).sort((a, b) => a.name.localeCompare(b.name));
    if (groupReports.length > 0) {
      result[g.caption] = groupReports;
    }
  });

  return Object.entries(result);
};

export const replaceReportIdInUrl = (
  currentUrl: string,
  prevReportId: string | undefined,
  newReportId: string
): string => {
  const urlSegments = currentUrl.split("/");
  const prevReportIdIndex = urlSegments.indexOf(prevReportId || "new");

  if (prevReportIdIndex !== -1) {
    urlSegments[prevReportIdIndex] = newReportId;
  }

  return urlSegments.join("/");
};
