import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { NumberFormatterOptions } from "../../../../../../../shared/reporting/api/biClient.types";

enum AbbreviationType {
  None = "",
  Thousand = "K",
  Million = "M",
}

export const Abbreviation = (props: {
  formatOptions: NumberFormatterOptions | undefined;
  applyFormat: (formatOptons: NumberFormatterOptions) => void;
}) => {
  const { formatOptions, applyFormat } = props;

  const changeAbbreviation = (abb: AbbreviationType) => {
    const copiedObject = {
      ...formatOptions,
      percentage: false,
      convertToPercentage: false,
      abbreviation: abb,
    } as NumberFormatterOptions;

    if (abb === AbbreviationType.None) {
      copiedObject.abbreviation = undefined;
    }
    applyFormat(copiedObject);
  };

  const renderAbbreviationItem = (title: string, abbType: AbbreviationType) => {
    return (
      <FormControlLabel
        value={abbType}
        control={<Radio sx={{ p: "4px" }} />}
        label={<Typography>{title}</Typography>}
      />
    );
  };

  return (
    <>
      <Typography variant="subtitle2" color={(theme) => theme.palette.text.primary}>
        Abbreviation
      </Typography>
      <RadioGroup
        value={formatOptions?.abbreviation || ""}
        sx={{
          pl: ".5rem",
        }}
        onChange={(_, value) => changeAbbreviation(value as AbbreviationType)}
      >
        {renderAbbreviationItem("None", AbbreviationType.None)}
        {renderAbbreviationItem("Thousand", AbbreviationType.Thousand)}
        {renderAbbreviationItem("Million", AbbreviationType.Million)}
      </RadioGroup>
    </>
  );
};

export default Abbreviation;
