import { UserMeasureConfigurations } from "../../../../../../shared/reporting/api/biClient.types";
import { ItemDataType } from "../../../../../api/biApi.types";
import { ValueField } from "../../../Types";
import SwitchMenuItem from "./SwitchMenuItem";

interface Props {
  measure: ValueField;
  saveChanges: (changes: Partial<UserMeasureConfigurations>, causeClosing: boolean) => void;
}

export default function BlankZeroMenuItem(props: Props) {
  const { measure, saveChanges } = props;

  if (measure.meta.type !== ItemDataType.Numeric) return <></>;

  return (
    <SwitchMenuItem
      caption="Blank Zero"
      value={!!measure.config.blankZero}
      onClick={() => saveChanges({ blankZero: !measure.config.blankZero }, false)}
    />
  );
}
