import React from "react";
import useDebounce from "../../shared/hooks/useDebounce";

export default function useResizeObserver(ref: React.RefObject<HTMLElement>, maxHeight?: number, maxWidth?: number) {
  const [width, setWidth] = React.useState(300);
  const [height, setHeight] = React.useState(200);

  const doSetSize = useDebounce((entry?: ResizeObserverEntry) => {
    if (entry?.contentRect) {
      setHeight(Math.min(maxHeight || Number.MAX_VALUE, entry.contentRect.height));
      setWidth(Math.min(maxWidth || Number.MAX_VALUE, entry.contentRect.width));
    }
  }, 100);

  const handleResize = React.useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }

      doSetSize(entries[0]);
    },
    [doSetSize]
  );

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const ro = new ResizeObserver(handleResize);
    ro.observe(ref.current);

    return () => {
      ro.disconnect();
    };
  }, [ref, handleResize]);

  return { width, height };
}
