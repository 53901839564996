import { useSelector } from "react-redux";
import { selectDrillDownItems } from "../../../../store/drilldownSlice";
import { Fragment } from "react";
import DrillDownPageContainer from "./DrillDownPageContainer";

export default function DrillDownEntry() {
  const items = useSelector(selectDrillDownItems);

  if (!items || items.length === 0) {
    return <></>;
  }

  return (
    <Fragment key="drilldownpages">
      {items.map((item, index) => (
        <DrillDownPageContainer key={item.id} item={item} index={index} />
      ))}
    </Fragment>
  );
}
