import { Box, Checkbox, Grid, Typography } from "@mui/material";
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { ChartOfAccountsItem } from "../../../../../api/biApi.types";
import { ChartOfAccountsContextItem, useChartOfAccountsContext } from "../contexts/ChartOfAccountsContext";
import { getChartOfAccountUniqueId } from "../helpers/chartOfAccountsContextHelper";
import FieldItemShowOnGridButton from "./FieldItemShowOnGridButton";

interface Props {
  item: ChartOfAccountsItem;
  index: number;
  parentNode: ChartOfAccountsContextItem | null;
}
export default function FilterFieldChartOfAccountItem({ item, index, parentNode }: PropsWithChildren<Props>) {
  const { root, appendChild, getNode, onSelect, scrollToAccountNo } = useChartOfAccountsContext();
  const [fieldIsHovered, setFieldIsHovered] = useState(false);

  const id = getChartOfAccountUniqueId(index, item, parentNode?.identifier);

  const currentNode = useMemo(() => {
    if (!parentNode) return undefined;
    return getNode(id, parentNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, index, parentNode, root]);

  useEffect(() => {
    if (!parentNode) return;
    appendChild(getChartOfAccountUniqueId(index, item, parentNode.identifier), parentNode, item.accountNo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentNode, index, item]);

  const handleNavigateToAccount = useCallback(() => {
    if (!currentNode) return;
    scrollToAccountNo(currentNode);
  }, [currentNode, scrollToAccountNo]);

  return (
    <Grid
      container
      sx={(theme) => ({
        color: theme.palette.text.secondary,
        bgcolor: currentNode?.checked ? "rgba(0, 168, 102, 0.08)" : "inherit",
        pl: 8,
        pr: 1,
        ":hover": {
          cursor: "pointer",
          background: theme.palette.action.hover,
        },
      })}
      onMouseEnter={() => setFieldIsHovered(true)}
      onMouseLeave={() => setFieldIsHovered(false)}
    >
      <Grid>
        {currentNode && (
          <Checkbox
            indeterminate={currentNode.indeterminate === true}
            checked={currentNode.checked === true}
            sx={{ width: "28px", height: "28px" }}
            onChange={(e) => {
              onSelect(currentNode.identifier, e.target.checked);
            }}
          />
        )}
      </Grid>
      <Grid
        container
        sx={{ flexDirection: "column", flex: 1, position: "relative" }}
        onClick={() => currentNode && onSelect(currentNode.identifier, !currentNode.checked)}
      >
        <Box sx={{ position: "absolute", height: "100%", width: "100%", overflow: "hidden" }}>
          <Grid
            container
            sx={(theme) => ({
              height: "28px",
              color: theme.palette.text.primary,
              gap: 1,
              alignItems: "center",
              "& .MuiGrid-item": {
                flex: 0.5,
                overflow: "hidden",
                textOverflow: "ellipsis",
                "& .MuiTypography-root": {
                  whiteSpace: "nowrap",
                  px: 1,
                },
              },
            })}
          >
            <Grid item>
              <Typography variant="caption" color="secondary">
                {item.accountNo}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="secondary">
                {item.accountName}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid>
        <FieldItemShowOnGridButton show={fieldIsHovered} onClick={handleNavigateToAccount} />
      </Grid>
    </Grid>
  );
}
