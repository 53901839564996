import { deepmerge } from "@mui/utils";
import { SxProps, Theme, useTheme } from "@mui/material";

const useMergedSx = (...sxArray: (SxProps<Theme> | undefined)[]) => {
  const theme = useTheme();
  const resolvedSxArray = sxArray.map((sx) => (typeof sx === "function" ? sx(theme) : sx));

  return resolvedSxArray.reduce((acc, sx) => deepmerge(acc, sx || {}, { clone: true }), {});
};

export default useMergedSx;
