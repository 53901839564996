import { Box } from "@mui/material";
import { GridColumnGroup } from "@mui/x-data-grid-premium";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateGuid } from "../../../../../shared/utilities/generateGuid";
import { PivotRowMeasureCell } from "../../../../api/biApi.types";
import useResizeObserver from "../../../../hooks/resizeObserver";
import { selectPreviewMode } from "../../../../store/currentReportSlice";
import { drillDownActions } from "../../../../store/drilldownSlice";
import PreviewComponentAlert from "../../../common/PreviewComponentAlert";
import ValidationState from "../../common/ValidationState";
import BiErrorCodeComponent from "../../common/bi-error/BiErrorCodeComponent";
import { PreviewDefaultSize } from "../../common/constants/const";
import { BuilderContext } from "../types";
import { getDataColumns, toGridColumn, toGridColumnGroup } from "./Helper";
import PivotGrid from "./PivotGrid";
import { PreviewGridStateType } from "./hooks/useGridStateBuilder";

interface Props {
  state: PreviewGridStateType;
}

export default function PreviewComponent({ state }: Props) {
  const dispatch = useDispatch();
  const { rowsArea, columnsArea, valuesArea, settingsArea } = React.useContext(BuilderContext);
  const previewMode = useSelector(selectPreviewMode);

  const divRef = React.createRef<HTMLDivElement>();
  const size = useResizeObserver(divRef, undefined, previewMode === "maximize" ? undefined : PreviewDefaultSize);

  const gridHasConfiguration = React.useMemo(() => {
    return rowsArea.values.length > 0 || columnsArea.values.length > 0;
  }, [rowsArea.values, columnsArea.values]);

  const gridColumns = useMemo(() => {
    return state.columns.flatMap((column) => {
      if (column.id === "lines") {
        return [toGridColumn(column)];
      } else {
        const dataColumns = getDataColumns(column);
        return dataColumns.map(toGridColumn);
      }
    });
  }, [state.columns]);

  const columnGroups = useMemo((): GridColumnGroup[] => {
    return state.columns.map(toGridColumnGroup);
  }, [state.columns]);

  const onCellClicked = useCallback(
    (cell: PivotRowMeasureCell | undefined) => {
      const drillDownInfo = cell?.drillDownId;
      if (!drillDownInfo) {
        return;
      }

      const measure = valuesArea.values.find((f) => f.config.guid === cell.id);
      if (measure === undefined) {
        return;
      }
      dispatch(drillDownActions.add({ id: generateGuid(), measure, cell, onlyLedger: false, chartOfAccounts: true }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [valuesArea.values]
  );

  return (
    <BiErrorCodeComponent errorMessage={state.error}>
      <PreviewComponentAlert error={state.error} failedMeasures={state.failedMeasures} />
      {state.configurationValid.valid && (
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Box
            ref={divRef}
            sx={(theme) => ({
              display: "flex",
              flex: 1,
              alignItems: "center",
              position: "relative",
              fontFamily: theme.typography.h1.fontFamily,
            })}
          >
            {gridHasConfiguration && (
              <PivotGrid
                rows={state.pivot?.rows || []}
                columns={gridColumns}
                columnGroups={columnGroups}
                size={size}
                loading={state.calculating}
                rowFields={rowsArea.values}
                onValueCellClick={onCellClicked}
                grandTotals={state.pivot?.grandTotals}
                showSummaries={settingsArea.settings.rowsGrandTotal}
              />
            )}
          </Box>
        </Box>
      )}
      <ValidationState state={state.configurationValid} measures={valuesArea.values} />
    </BiErrorCodeComponent>
  );
}
