import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FieldWithOrder, FieldWithSorting } from "./FieldWithOrder";

type DrillDownState = {
  selectedFields: FieldWithOrder[];
  sortedFields: FieldWithSorting[];
  error?: string;
  loading: boolean;
  rowsTotal: number;
  skip: number;
  take: number;
};

export const initialDrillDownState: DrillDownState = {
  selectedFields: [],
  sortedFields: [],
  error: undefined,
  loading: false,
  rowsTotal: 0,
  skip: 0,
  take: 0,
};

const drillDownSlice = createSlice({
  name: "drillDown",
  initialState: initialDrillDownState,
  reducers: {
    setSelectedFields(state, action: PayloadAction<FieldWithOrder[]>) {
      state.selectedFields = action.payload;
      const newSorting = state.sortedFields
        .map((f) => {
          const sortedColumn = state.selectedFields.find((sf) => sf.field.name === f.field.name);
          if (sortedColumn) {
            return sortedColumn;
          }
          return undefined;
        })
        .filter((field): field is FieldWithSorting => !!field);
      state.sortedFields = newSorting;
    },
    setSortedFields(state, action: PayloadAction<FieldWithSorting[]>) {
      state.sortedFields = action.payload;
    },
    setError(state, action: PayloadAction<string | undefined>) {
      state.error = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setRowsTotal(state, action: PayloadAction<number>) {
      state.rowsTotal = action.payload;
    },
    setPagination(state, action: PayloadAction<{ skip: number; take: number }>) {
      state.skip = action.payload.skip;
      state.take = action.payload.take;
    },
  },
});

export const drillDownActions = drillDownSlice.actions;
export const drillDownReducer = drillDownSlice.reducer;
