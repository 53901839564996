import { MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import { FunctionDescription } from "../../../../../api/biApi.types";
import { ConditionField } from "../../../Types";
import { INode, ITokenNode, NodeBinary, NodeFunction, NodeNumber, NodeUnary, NodeVariable } from "./formulaParser";
import { Token } from "./tokenizer";

export type FormulaNode = {
  //lower case `value`
  key: string;
  value: string;
  text: string | undefined;
  linkedNodes: ITokenNode[];
  conditions?: ConditionField[];
  args?: FormulaNode[];
  function?: FunctionDescription;
  highlighted?: boolean;
  table?: MeasureUnitTable;
};

export const findNode = (nodes: FormulaNode[], token: Token) => {
  let formulaNode: FormulaNode | undefined = undefined;
  const value = token.value?.toString().toLowerCase();
  if (value === undefined) return undefined;
  nodes.forEach((fn) => {
    if (formulaNode !== undefined) return;
    if (fn.key === value) {
      formulaNode = fn;
      return;
    }
    if (fn.function !== undefined && fn.args !== undefined) {
      fn.args.forEach((arg) => {
        if (arg.key === value) {
          formulaNode = arg;
          return;
        }
      });
    }
  });

  if (formulaNode !== undefined) return formulaNode;
  return nodes.find((fn) => {
    const tokenWithSameStart = fn.linkedNodes.find((n) => n.token.startIndex === token.startIndex);
    return tokenWithSameStart !== undefined;
  });
};

const createFormulaNode = (nodes: FormulaNode[], legacyNodes: FormulaNode[], node: ITokenNode) => {
  if (typeof node.token.value !== "string") return;
  const key = node.token.value.toLocaleLowerCase();
  let formulaNode = findNode(nodes, node.token);
  if (formulaNode === undefined) {
    const legacyNode = findNode(legacyNodes, node.token);
    if (legacyNode === undefined) {
      formulaNode = {
        key,
        value: node.token.value,
        text: node.token.text,
        linkedNodes: [node],
        table: MeasureUnitTable.Gl,
      };
    } else {
      formulaNode = {
        key: node.token.value.toLocaleLowerCase(),
        value: node.token.value,
        text: node.token.text,
        conditions: legacyNode.conditions,
        linkedNodes: [node],
        function: legacyNode.function,
        args: legacyNode.args,
        table: legacyNode.table,
      };
    }
    nodes.push(formulaNode);
  } else {
    formulaNode.linkedNodes.push(node);
  }
  return formulaNode;
};

export const fillNodes = (
  nodes: FormulaNode[],
  legacyNodes: FormulaNode[],
  node: INode,
  functions: FunctionDescription[],
  increaseCounter?: (node: INode) => void
) => {
  if (node instanceof NodeVariable) {
    if (typeof node.token.value === "string") {
      createFormulaNode(nodes, legacyNodes, node);
      increaseCounter?.(node);
    }
  }

  if (node instanceof NodeFunction) {
    const formulaNode = createFormulaNode(nodes, legacyNodes, node);
    if (formulaNode !== undefined) {
      increaseCounter?.(node);
      if (formulaNode.function === undefined) {
        formulaNode.function = functions.find(
          (v) => v.name.toLowerCase() === formulaNode.key && v.args.length === node.args.length
        );
      }
      const args: FormulaNode[] = [];
      node.args.forEach((argNode) => fillNodes(args, formulaNode.args || legacyNodes, argNode, functions));
      formulaNode.args = args;
    }
  }

  if (node instanceof NodeUnary) {
    fillNodes(nodes, legacyNodes, node.node, functions, increaseCounter);
    increaseCounter?.(node.node);
  }

  if (node instanceof NodeBinary) {
    fillNodes(nodes, legacyNodes, node.left, functions, increaseCounter);

    fillNodes(nodes, legacyNodes, node.right, functions, increaseCounter);
  }

  if (node instanceof NodeNumber) {
    increaseCounter?.(node);
  }
};

export const getAllVariableNodes = (nodes: FormulaNode[]): FormulaNode[] => {
  const variableNodes = nodes
    .map((node) => {
      if (node.function && node.args && node.args.length > 0) {
        return getAllVariableNodes(node.args);
      }
      return [node];
    })
    .flatMap((node) => node);

  return variableNodes;
};
