import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import GlobalNotificationErrorSnackbar from "../../shared/components/GlobalNotificationErrorSnackbar";
import GlobalNotificationSnackbar from "../../shared/components/GlobalNotificationSnackbar";
import { NotificationContextProvider } from "../../shared/contexts/NotificationContext";
import sharedBiClient from "../../shared/reporting/api/biClient";
import { BiApiClientProvider } from "../../shared/reporting/contexts/BiApiClientProviderContext";
import biClient from "../api/biApi";
import { BiApiClient } from "../contexts/ApiClientProviderContext";
import { useLocalization } from "../hooks/useLocalization";
import { selectMetaData } from "../store/metaDataSlice";
import { getEditReportUrl, getUiReportsUrl, getViewReportUrl } from "./builder/common/utilities/editReportUrl";
import ImportantDataLoadingFailed from "./common/ImportantDataLoadingFailed";

export default function TemplatesContainer() {
  const { common: locale } = useLocalization();
  const { loadingFailed } = useSelector(selectMetaData);

  if (loadingFailed) {
    return (
      <ImportantDataLoadingFailed
        title={locale.template_data_loading_failed_title}
        disclaimer={locale.template_data_loading_failed_text}
      />
    );
  }

  return (
    <BiApiClientProvider<BiApiClient>
      api={{
        ...biClient.uiReports,
        ...sharedBiClient.uiReports,
        getEditReportUrl,
        getViewReportUrl,
        getReportsUrl: getUiReportsUrl,
      }}
    >
      <NotificationContextProvider>
        <Outlet />
        <GlobalNotificationSnackbar />
        <GlobalNotificationErrorSnackbar />
      </NotificationContextProvider>
    </BiApiClientProvider>
  );
}
