import { Box, Divider, SvgIconProps } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import HorizontalFill from "../../../shared/components/HorizontalFill";
import { Report } from "../../../shared/reporting/api/biClient.types";
import { selectReportEditMode } from "../../store/currentReportSlice";
import BackToReports from "./backToReports/BackToReports";
import ReportNameChanger from "./common/report-name-changer/ReportNameChanger";
import { SaveReportDialogProps } from "./Types";

interface Props {
  report: Report | undefined;
  ReportSelector: React.ReactElement;
  SaveReportDialogType: React.ComponentType<SaveReportDialogProps>;
  ProductIcon: React.ComponentType<SvgIconProps>;
}

export default function ReportActionHeader({
  report,
  ReportSelector,
  children,
  SaveReportDialogType,
  ProductIcon,
}: React.PropsWithChildren<Props>) {
  const isEditMode = useSelector(selectReportEditMode);
  const [isRenaming, setIsRenaming] = useState(false);
  const [headerHovered, setHeaderHovered] = useState(false);

  const isUnsavedReport = report?.reportId === undefined;

  return (
    <Box
      onMouseEnter={() => setHeaderHovered(true)}
      onMouseLeave={() => setHeaderHovered(false)}
      sx={(t) => ({
        display: "flex",
        px: 2.5,
        py: 1.25,
        alignItems: "center",
        gap: 2,
        maxHeight: t.spacing(7.5),
      })}
    >
      <BackToReports
        showBackButton={headerHovered}
        SaveReportDialogType={SaveReportDialogType}
        ProductIcon={ProductIcon}
      />
      <Divider orientation="vertical" flexItem />
      {!isRenaming && ReportSelector}
      {!isUnsavedReport && isEditMode && report && (
        <ReportNameChanger onRenamingChanged={setIsRenaming} report={report} showEditReportName={headerHovered} />
      )}
      <HorizontalFill />
      <Box sx={{ flexGrow: 1 }}></Box>
      <Box onMouseEnter={() => setHeaderHovered(false)} onMouseLeave={() => setHeaderHovered(true)}>
        {children}
      </Box>
    </Box>
  );
}
