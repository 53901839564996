import { Grid } from "@mui/material";
import { useFieldsStateContext } from "../../contexts/FieldsStateContext";
import StyleGroup from "../StyleGroup";
import GridModeItem from "./GridModeItem";
import RotateItem from "./RotateItem";
import ThicknessItem from "./ThicknessItem";
import ToolTipItem from "./ToolTipItem";

export default function ChartStyles() {
  const { settingsArea } = useFieldsStateContext();

  return (
    <StyleGroup caption="General">
      <Grid container sx={{ flexDirection: "column", gap: 1 }}>
        <GridModeItem area={settingsArea} />
        <RotateItem area={settingsArea} />
        <ThicknessItem area={settingsArea} />
        <ToolTipItem area={settingsArea} />
      </Grid>
    </StyleGroup>
  );
}
