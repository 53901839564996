import { SvgIcon, SvgIconProps } from "@mui/material";

export const LineChartIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M10.0208 9.72909C9.92361 9.7152 9.83 9.69076 9.74 9.65576C9.64944 9.62131 9.56944 9.56937 9.5 9.49992L6.35417 6.35409L3.8125 8.20826C3.53472 8.4027 3.24667 8.42353 2.94833 8.27076C2.64944 8.11798 2.5 7.86798 2.5 7.52076C2.5 7.38187 2.52778 7.25687 2.58333 7.14576C2.63889 7.03464 2.72222 6.93742 2.83333 6.85409L5.9375 4.58326C6.02083 4.51381 6.11111 4.46881 6.20833 4.44826C6.30556 4.42714 6.40278 4.42353 6.5 4.43742C6.59722 4.45131 6.69111 4.47548 6.78167 4.50992C6.87167 4.54492 6.95139 4.59714 7.02083 4.66659L10.1667 7.81242L16.1875 3.45826C16.4653 3.26381 16.7533 3.24298 17.0517 3.39576C17.3506 3.54853 17.5 3.79853 17.5 4.14576C17.5 4.28464 17.4689 4.40964 17.4067 4.52076C17.3439 4.63187 17.2569 4.72909 17.1458 4.81242L10.5833 9.58326C10.5 9.6527 10.4097 9.6977 10.3125 9.71826C10.2153 9.73937 10.1181 9.74298 10.0208 9.72909ZM10.0208 17.2291C9.92361 17.2291 9.82639 17.2083 9.72917 17.1666C9.63194 17.1249 9.54861 17.0694 9.47917 16.9999L4.83333 12.4999L3.8125 13.2291C3.53472 13.4235 3.24667 13.4444 2.94833 13.2916C2.64944 13.1388 2.5 12.8888 2.5 12.5416C2.5 12.4027 2.53111 12.2741 2.59333 12.1558C2.65611 12.038 2.74306 11.9444 2.85417 11.8749L4.4375 10.7291C4.52083 10.6596 4.60778 10.6144 4.69833 10.5933C4.78833 10.5727 4.88194 10.5694 4.97917 10.5833C5.07639 10.5971 5.17 10.6213 5.26 10.6558C5.35056 10.6908 5.43056 10.743 5.5 10.8124L10.1042 15.2916L13.3542 12.6874C13.4236 12.618 13.5033 12.5694 13.5933 12.5416C13.6839 12.5138 13.7778 12.4999 13.875 12.4999H16.6667C16.9028 12.4999 17.1006 12.5796 17.26 12.7391C17.42 12.8991 17.5 13.0971 17.5 13.3333C17.5 13.5694 17.42 13.7671 17.26 13.9266C17.1006 14.0866 16.9028 14.1666 16.6667 14.1666H14.1667L10.5625 17.0416C10.4792 17.111 10.3925 17.1596 10.3025 17.1874C10.2119 17.2152 10.1181 17.2291 10.0208 17.2291Z" />
    </SvgIcon>
  );
};
export default LineChartIcon;
