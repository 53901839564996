import { useDispatch, useSelector } from "react-redux";
import { drillDownActions } from "../../../../store/drilldownSlice";
import ExpandableDialog from "../../../ExpandableDialog";
import IrrPage from "./irr/IrrPage";
import Loader from "./Loader";
import PageTitle from "./PageTitle";
import { SystemStyleObject, Theme } from "@mui/system";
import { useCallback } from "react";
import { Box } from "@mui/material";
import { selectDrillDownCacheValid } from "../../../../store/currentReportSlice";
import { CellDrillDownInfoType, CellDrillDownInfoTypes, DrillDownInfo } from "../../../../api/biApi.types";
import MainPage from "./MainPage";
import LedgerPage from "./ledger/LedgerPage";
import LedgerTitle from "./LedgerTitle";
import { DrillDownConfiguration } from "../../../../store/DrillDownConfigurationState";

interface Props {
  item: DrillDownConfiguration;
  index: number;
}
export default function DrillDownPageContainer({ item, index }: Props) {
  const dispatch = useDispatch();
  const isCacheValid = useSelector(selectDrillDownCacheValid);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDialogClose = useCallback(() => dispatch(drillDownActions.remove(item.id)), [item.id]);

  return (
    <ExpandableDialog
      title={item?.info ? <PageTitleWrapper configuration={item} info={item.info.combined} /> : <EmptyHeader />}
      separateTitle={item.onlyLedger}
      dialogSx={{ mx: 5 + index * 4 }}
      backdropSx={getBackgropSx(index)}
      onClose={handleDialogClose}
    >
      {item.info === undefined || !isCacheValid ? (
        <Loader key={item.id} configuration={item} />
      ) : (
        <DrillDownWrapper key={item.id} configuration={item} info={item.info} />
      )}
    </ExpandableDialog>
  );
}

function PageTitleWrapper({
  configuration,
  info,
}: {
  configuration: DrillDownConfiguration;
  info: CellDrillDownInfoTypes;
}) {
  if (configuration.onlyLedger) {
    return <LedgerTitle configuration={configuration} info={info} />;
  }

  return <PageTitle configuration={configuration} info={info} />;
}

interface DrillDownWrapperProps {
  configuration: DrillDownConfiguration;
  info: DrillDownInfo;
}
function DrillDownWrapper({ configuration, info }: DrillDownWrapperProps) {
  const irrInfo = info.combined.drillDownInfoType === CellDrillDownInfoType.Irr;
  if (irrInfo) {
    return <IrrPage configuration={configuration} drillDownInfo={info} />;
  }
  if (configuration.onlyLedger || configuration.measure?.meta.name === "Shares") {
    return <LedgerPage configuration={configuration} info={info.combined} />;
  }
  return <MainPage configuration={configuration} info={info} />;
}

function EmptyHeader() {
  return <Box sx={{ minHeight: 60 }}></Box>;
}

function getBackgropSx(depth: number): SystemStyleObject<Theme> {
  const capacity = ((2 + depth) / 10).toFixed(1);

  return {
    backgroundColor: `rgba(0,0,0,${capacity})`,
  };
}
