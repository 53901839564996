import { ssoApi } from "../api/sso";

export class RefreshTokenService {
  private isRefreshing = false;
  private subscribers: { resolve: (value: boolean) => void }[] = [];

  do(): Promise<boolean> {
    return this.getPromise();
  }

  private async getPromise(): Promise<boolean> {
    const shouldRequest = !this.isRefreshing;
    if (!this.isRefreshing) {
      this.isRefreshing = true;
    }
    const promise = new Promise<boolean>((resolve) => {
      this.subscribers.push({ resolve });
    });

    if (shouldRequest) {
      setTimeout(async () => {
        await this.refreshToken();
      }, 0);
    }

    return promise;
  }

  private async refreshToken(): Promise<void> {
    try {
      await ssoApi.refreshToken();
      this.notifySuscribers(true);
    } catch (error) {
      this.notifySuscribers(false);
    }
  }

  private notifySuscribers(result: boolean): void {
    this.subscribers.forEach((subscriber) => subscriber.resolve(result));
    this.subscribers = [];
    this.isRefreshing = false;
  }
}

const refreshTokenService = new RefreshTokenService();

export default refreshTokenService;
