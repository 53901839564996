import { SvgIcon, SvgIconProps } from "@mui/material";

export const ExportSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path
        d="M8.33398 3.33301C7.87375 3.33301 7.50065 3.70611 7.50065 4.16634V4.99967H3.94108C3.79505 4.74674 3.58512 4.53662 3.33232 4.39037C3.07951 4.24412 2.79271 4.16686 2.50065 4.16634C2.05863 4.16634 1.6347 4.34193 1.32214 4.6545C1.00958 4.96706 0.833984 5.39098 0.833984 5.83301C0.833984 6.27503 1.00958 6.69896 1.32214 7.01152C1.6347 7.32408 2.05863 7.49967 2.50065 7.49967C2.79299 7.49944 3.08013 7.42232 3.33323 7.27606C3.58635 7.12978 3.79654 6.91952 3.94271 6.66634H5.93164C4.84618 7.72737 4.16732 9.20142 4.16732 10.833V11.6663H3.33398C2.87375 11.6663 2.50065 12.0394 2.50065 12.4997V15.833C2.50065 16.2933 2.87375 16.6663 3.33398 16.6663H6.66732C7.12755 16.6663 7.50065 16.2933 7.50065 15.833V12.4997C7.50065 12.0394 7.12755 11.6663 6.66732 11.6663H5.83398V10.833C5.83398 9.46559 6.49043 8.25847 7.50065 7.49967C7.50065 7.95991 7.87375 8.33301 8.33398 8.33301H11.6673C12.1276 8.33301 12.5007 7.95991 12.5007 7.49967C13.5109 8.25847 14.1673 9.46559 14.1673 10.833V11.6663H13.334C12.8737 11.6663 12.5007 12.0394 12.5007 12.4997V15.833C12.5007 16.2933 12.8737 16.6663 13.334 16.6663H16.6673C17.1276 16.6663 17.5006 16.2933 17.5006 15.833V12.4997C17.5006 12.0394 17.1276 11.6663 16.6673 11.6663H15.834V10.833C15.834 9.20142 15.1551 7.72737 14.0696 6.66634H16.0602C16.2062 6.91927 16.4162 7.12939 16.669 7.27565C16.9218 7.4219 17.2086 7.49916 17.5006 7.49967C17.9426 7.49967 18.3666 7.32408 18.6791 7.01152C18.9917 6.69896 19.1673 6.27503 19.1673 5.83301C19.1673 5.39098 18.9917 4.96706 18.6791 4.6545C18.3666 4.34193 17.9426 4.16634 17.5006 4.16634C17.2083 4.16657 16.9212 4.24369 16.6681 4.38996C16.415 4.53623 16.2047 4.7465 16.0586 4.99967H12.5007V4.16634C12.5007 3.70611 12.1276 3.33301 11.6673 3.33301H8.33398ZM9.16732 4.99967H10.834V6.66634H9.16732V4.99967ZM4.16732 13.333H5.83398V14.9997H4.16732V13.333ZM14.1673 13.333H15.834V14.9997H14.1673V13.333Z"
        fill="#8E9BA9"
      />
    </SvgIcon>
  );
};
export default ExportSvgIcon;
