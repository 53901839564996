import { LoadingButton } from "@mui/lab";
import { Alert, Button, DialogActions, Grid, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import DialogHeader from "../../../components/dialog/DialogHeader";
import { useLocalization } from "../../../hooks/useLocalization";
import { formatString } from "../../../utilities/stringFormatter";
import { ReportGroup } from "../../api/biClient.types";

const ReportGroupForm = (props: {
  group: ReportGroup;
  groups?: ReportGroup[];
  title?: string;
  error?: string;
  saving: boolean;
  onCancel: () => void;
  onSave: (caption: string) => void;
}) => {
  const { group, title, groups, error, saving, onCancel, onSave } = props;
  const { common: locale } = useLocalization();
  const [caption, setCaption] = useState(group.caption);
  const [validationAllowed, setValidationAllowed] = useState(false);
  const [validateError, setValidateError] = useState<string>();

  const isNameValid = useMemo(() => {
    let errorText = "";
    const clearedInput = caption.trim();

    try {
      if (clearedInput.length < 2) {
        errorText = locale.validation_min_length;
        return false;
      }
      const existGroup = groups?.find(
        (g) => g.caption.toLowerCase() === clearedInput.toLowerCase() && g.id !== group.id
      );
      if (existGroup !== undefined) {
        errorText = formatString(locale.validation_name_exist, clearedInput);
        return false;
      }
      return true;
    } finally {
      setValidateError(errorText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caption, group.id, groups]);

  const handleSave = () => onSave(caption.trim());

  return (
    <>
      <DialogHeader text={title || ""} disabled={saving} onClose={onCancel}></DialogHeader>
      {error && (
        <Alert sx={{ mb: ".5rem" }} severity="error">
          {error}
        </Alert>
      )}
      <Grid container sx={{ px: 2.5, py: 1, gap: 2, flex: 1, flexDirection: "column", justifyContent: "center" }}>
        <TextField
          value={caption}
          onChange={(evt) => setCaption(evt.target.value)}
          error={validationAllowed && !isNameValid}
          helperText={(validationAllowed && validateError) || " "}
          label="Name"
          disabled={saving}
          onBlur={() => setValidationAllowed(true)}
          onKeyDown={(evt) => {
            if (evt.key === "Enter" && isNameValid) {
              handleSave();
            }
          }}
        />
      </Grid>
      <DialogActions sx={{ px: 2.5, py: 1.5 }}>
        <Button variant="text" color="secondary" disabled={saving} onClick={() => onCancel()}>
          Cancel
        </Button>
        <LoadingButton variant="contained" disabled={saving || !isNameValid} loading={saving} onClick={handleSave}>
          Save
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ReportGroupForm;
