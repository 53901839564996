import { Stack, Typography } from "@mui/material";
import { getCalculatedFieldTitle } from "./utils/getCalculatedFieldTitle";
import { CellDrillDownInfoBase } from "../../../../api/biApi.types";
import { getLedgerTitle } from "./utils/getLedgerTitle";
import { DrillDownConfiguration } from "../../../../store/DrillDownConfigurationState";

interface Props {
  configuration: DrillDownConfiguration;
  info: CellDrillDownInfoBase;
}

export default function LedgerTitle({ configuration, info }: Props) {
  const ledger = getLedgerTitle(info);
  const calculatedField = getCalculatedFieldTitle(info);
  const measureCaption = configuration.measure?.config.customLabel || configuration.measure?.meta.caption;

  return (
    <Stack py={1.5} gap={0.3}>
      <Typography variant="h6">{ledger}</Typography>
      <Stack direction="row" gap={1}>
        {measureCaption && (
          <Typography variant="caption" color="text.secondary">
            {measureCaption} &#xa0;&bull;
          </Typography>
        )}
        {configuration.cell.formattedValue && (
          <Typography variant="caption" color="text.secondary">
            {configuration.cell.formattedValue}
          </Typography>
        )}
        {calculatedField && (
          <Typography variant="caption" color="text.secondary">
            {calculatedField}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
