import { Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import InlineLoader from "../../../shared/components/inlineLoader/InlineLoader";
import { useEnhancedBiApiClientProvider } from "../../contexts/ApiClientProviderContext";
import { useLocalization } from "../../hooks/useLocalization";
import useMetaData from "../../hooks/useMetaData";
import { currentReportActions, selectCurrentReport } from "../../store/currentReportSlice";
import { metaDataActions, selectMetaData } from "../../store/metaDataSlice";
import { getExistingReportWithAuth, manageReportSwitching } from "../../utilities/defineReportDataHelper";
import ApplyingDialog from "../common/dialogs/ApplyingDialog";
import DrillDownEntry from "./common/drillDown/DrillDownEntry";
import ReportNotFoundOrAccessForbidden from "./ReportNotFoundOrAccessForbidden";
import ViewReportPage from "./ViewReportPage";

const ViewRoot = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { metadata: locale, report: reportLocale } = useLocalization();
  const { loadMetaData } = useMetaData({ includeDictionaries: false });
  const metaData = useSelector(selectMetaData);
  const report = useSelector(selectCurrentReport);
  const { getReport, getReportAuthorizationInfo, getReportsUrl } = useEnhancedBiApiClientProvider();
  const cachedReportId = useRef<string>();
  const [showReportNotFound, setShowReportNotFound] = useState(false);
  const [showSwitchingReport, setShowSwitchingReport] = useState(false);

  useEffect(() => {
    if (!metaData.loaded && report !== undefined) {
      loadMetaData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData.loaded, report]);

  useEffect(() => {
    const isSwitchingReport = !!cachedReportId.current && cachedReportId.current !== id;
    cachedReportId.current = id;

    const execute = async () => {
      if (id !== undefined) {
        await getExistingReportWithAuth(
          getReport.bind(null, id, "run"),
          getReportAuthorizationInfo.bind(null, id),
          () => setShowReportNotFound(true),
          (report) => dispatch(currentReportActions.setReport(report)),
          (authorizationInfo) => dispatch(currentReportActions.setAuthorizationInfo(authorizationInfo))
        );
      }
    };
    manageReportSwitching(isSwitchingReport, setShowSwitchingReport, execute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (report?.reportType !== undefined) {
      dispatch(metaDataActions.updateReportType(report.reportType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.reportType]);

  if (id === undefined) {
    return <Navigate to={""} />;
  }

  if (showReportNotFound === true) {
    return <ReportNotFoundOrAccessForbidden getReportsUrl={getReportsUrl} />;
  }

  if (!metaData.loaded || report === undefined) {
    return <InlineLoader text={locale.loading_label} />;
  }

  return (
    <Grid container sx={{ backgroundColor: "white" }}>
      {showSwitchingReport && <ApplyingDialog open text={reportLocale.loading_report} />}
      <ViewReportPage />
      <DrillDownEntry />
    </Grid>
  );
};

export default ViewRoot;
