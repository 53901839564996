import { Alert } from "@mui/material";

interface Props {
  error?: string;
}

export const AlertItem = (props: Props) => {
  const { error } = props;

  return (
    <Alert
      sx={{
        display: error ? "flex" : "none",
        alignItems: "center",
        py: 0,
      }}
      severity="error"
    >
      {error}
    </Alert>
  );
};
