import { useEffect } from "react";
import FullScreenLoader from "../components/fullScreenLoader/FullScreenLoader";
import { logout } from "./identity";
import { refreshCookieExists } from "../utilities/cookies";
import { useLocalization } from "../hooks/useLocalization";

const postLogoutRedirectParam = "post_logout_redirect_uri";

interface Props {
  redirectUri: string;
}

const Logout = (props: Props) => {
  const { redirectUri } = props;
  const locale = useLocalization();
  const page = locale.pages.logout;

  useEffect(() => {
    if (refreshCookieExists()) {
      const navigateToErrorPage = () => (window.location.href = "/error");

      logout()
        .then((response) => {
          if (response.success) {
            try {
              const url = new URL(response.data.redirectToLogoutUrl);
              url.searchParams.append(postLogoutRedirectParam, redirectUri);
              window.location.href = url.toString();
            } catch {
              navigateToErrorPage();
            }
          } else {
            navigateToErrorPage();
          }
        })
        .catch(navigateToErrorPage);
    }
  }, [redirectUri]);

  return <FullScreenLoader title={page.title} subtitle={page.subtitle} />;
};

export default Logout;
