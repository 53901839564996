import React, { PropsWithChildren, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import {
  GroupingField,
  PivotFieldConfiguration,
  TabularGeneralSettings,
  UserMeasureConfigurations,
} from "../../../../../shared/reporting/api/biClient.types";
import { ConditionFieldsArea, SortingFieldsArea } from "../../pivot/types";
import { ColumnField, ValueField } from "../../Types";
import { TableField } from "../hooks/TableField";

export type FieldsArea = {
  get values(): TableField[];
  addItem: (item: TableField, index: number) => void;
  removeItem: (item: TableField) => void;
  moveItem: (item: TableField, newIndex: number) => void;
  updateDimensionField: (field: TableField, changes: Partial<ColumnField>) => void;
  updateDimensionFieldConfig: (field: TableField, changes: Partial<PivotFieldConfiguration>) => void;
  updateMeasureField: (field: TableField, changes: Partial<ValueField>) => void;
  updateMeasureFieldConfig: (field: TableField, changes: Partial<UserMeasureConfigurations>) => void;
  updateFieldsOrder: (guids: string[]) => void;
};

export type GroupingArea = {
  values: GroupingField[];
  setGrouping: (grouping: GroupingField[]) => void;
  addItem: (item: GroupingField) => void;
  removeItem: (group: GroupingField) => void;
  moveItem: (item: GroupingField, newIndex: number) => void;
  updateGroup: (group: GroupingField, changes: Partial<GroupingField>) => void;
};

export type SettingsArea = {
  settings: TabularGeneralSettings;
  update: (changes: Partial<TabularGeneralSettings>) => void;
};

export type FieldsStateContextType = {
  conditionsArea: ConditionFieldsArea;
  fieldsArea: FieldsArea;
  sortingArea: SortingFieldsArea;
  groupingArea: GroupingArea;
  settingsArea: SettingsArea;
};

const FieldsStateContext = React.createContext<FieldsStateContextType | undefined>(undefined);

export const FieldsStateContextProvider = (props: PropsWithChildren<FieldsStateContextType>) => (
  <FieldsStateContext.Provider value={{ ...props }}>{props.children}</FieldsStateContext.Provider>
);

export const useFieldsStateContext = () => {
  const context = useContext(FieldsStateContext);
  return defined(context);
};
