import { Alert } from "@mui/material";
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useGroupsContext } from "../groups/contexts/GroupsContext";
import React from "react";
import LoaderBox from "../../../components/LoaderBox";

interface Props {
  clientCode: string;
  children: JSX.Element;
}

export default function ReportGroupsConnector({ clientCode, children }: PropsWithChildren<Props>) {
  const { groups: reportGroups, actions } = useGroupsContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();

  const currentReportGroups = useMemo(() => {
    return reportGroups[clientCode] || [];
  }, [reportGroups, clientCode]);

  const loadReportGroups = useCallback(() => {
    actions.load(clientCode).catch((err) => setError(err.message));
  }, [actions, clientCode]);

  useEffect(() => {
    const currentReportGroups = reportGroups[clientCode];
    if (currentReportGroups) {
      setLoading(false);
      return;
    } else {
      loadReportGroups();
    }
  }, [clientCode, loadReportGroups, reportGroups]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <LoaderBox overlay loading={loading}>
      {React.cloneElement(children, { groups: currentReportGroups })}
    </LoaderBox>
  );
}
