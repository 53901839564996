import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ApiResponse } from "../../../../../shared/api/types";
import biClient from "../../../../api/biApi";
import { ChartResponse, FailedMeasure } from "../../../../api/biApi.types";
import { useLocalization } from "../../../../hooks/useLocalization";
import { currentReportActions } from "../../../../store/currentReportSlice";
import { update } from "../../../../store/devToolsSlice";
import useDeferredBuild from "../../common/hooks/useDeferredBuild";
import useRequestConfigBuilder from "./useRequestConfigBuilder";

type CancellableRequest = {
  cancel: () => void;
};

export default function useDataLoadingBuilder() {
  const dispatch = useDispatch();
  const [cancellationToken, setCancellationToken] = React.useState<CancellableRequest | undefined>(undefined);
  const { common: locale } = useLocalization();
  const { requestConfig } = useRequestConfigBuilder();

  const [calculateCount, setCalculateCount] = React.useState(0);
  const [data, setData] = React.useState<ChartResponse>();
  const [error, setError] = React.useState<string>();
  const [failedMeasures, setFailedMeasures] = React.useState<FailedMeasure[]>([]);

  const calculating = calculateCount > 0;

  const calculatingRef = React.useRef<number>(calculateCount);
  calculatingRef.current = calculateCount;

  const cancellationTokenRef = React.useRef<CancellableRequest | undefined>(cancellationToken);
  cancellationTokenRef.current = cancellationToken;
  const buildConfigRef = React.useRef(requestConfig);
  React.useEffect(() => {
    buildConfigRef.current = requestConfig;
  }, [requestConfig]);

  React.useEffect(() => {
    return () => {
      if (cancellationTokenRef.current !== undefined) {
        cancellationTokenRef.current.cancel();
      }
    };
  }, []);

  const handleBuildDone = (response: ApiResponse<ChartResponse>) => {
    if (response.success) {
      setError(undefined);
      setData(response.data);
      dispatch(update({ loggingItems: response.data.loggingItems }));
      dispatch(currentReportActions.setEtag({ etag: response.data.etag, cacheValid: undefined }));
      setFailedMeasures(response.data.failedMeasures);
    } else if (response.error?.message) {
      setError(response.error.message);
    }
    setCalculateCount(calculatingRef.current - 1);
  };

  const handleBuildError = (reason: string | undefined) => {
    if (reason === "canceled") {
      setError(undefined);
    } else {
      setError(reason || locale.calculation_error);
    }
    setCalculateCount(calculatingRef.current - 1);
  };

  const calculate = React.useCallback(
    () => {
      setCalculateCount(calculatingRef.current + 1);
      setError(undefined);
      if (cancellationTokenRef.current !== undefined) {
        cancellationTokenRef.current.cancel();
      }
      dispatch(currentReportActions.setEtag({ etag: undefined, cacheValid: undefined }));
      const cancellation = biClient.buildChartReport(buildConfigRef.current, onBuildDone, onBuildError);
      setCancellationToken(cancellation);
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [handleBuildDone]
  );

  const handleInvalidConfiguration = () => {
    cancelCurrentAwaiter();
  };

  const { doRequest, onBuildDone, onBuildError, resetInitialRequestFlag, cancelCurrentAwaiter } = useDeferredBuild({
    request: calculate,
    handleBuildDone,
    handleBuildError,
    cancelRequest: () => cancellationTokenRef.current?.cancel(),
    onProgressChange: (progress) => dispatch(currentReportActions.updateBuildProgress(progress)),
  });

  useEffect(() => {
    resetInitialRequestFlag();
  }, [requestConfig.reportId, resetInitialRequestFlag]);

  return { data, calculating, error, calculate: doRequest, failedMeasures, handleInvalidConfiguration };
}

export type ChartCalculationReturnType = ReturnType<typeof useDataLoadingBuilder>;
