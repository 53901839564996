import { AnyRecord } from "../../shared/types";

export function areConfigurationsEqual(a: AnyRecord, b: AnyRecord): boolean {
  // If both are strictly equal, return true
  if (a === b) {
    return true;
  }

  // Check if both are objects, and not null
  if (a && b && typeof a === "object" && typeof b === "object") {
    if (a.constructor !== b.constructor) {
      return false;
    }

    // Array comparison
    if (Array.isArray(a) && Array.isArray(b)) {
      if (a.length !== b.length) {
        return false;
      }
      for (let i = 0; i < a.length; i++) {
        if (!areConfigurationsEqual(a[i], b[i])) {
          return false;
        }
      }
      return true;
    }

    // Object comparison

    for (const key of Object.keys(a)) {
      // Handle cases where one property is missing or undefined, and false is considered equal
      if (
        ((a[key] === undefined || a[key] === null) && b[key] === false) ||
        ((b[key] === undefined || b[key] === null) && a[key] === false) ||
        (!Object.prototype.hasOwnProperty.call(a, key) && b[key] === false) ||
        (!Object.prototype.hasOwnProperty.call(b, key) && a[key] === false) ||
        (a[key] === undefined && b[key] === undefined)
      ) {
        continue;
      }

      // Ensure `b` has the property and they are equal
      if (!Object.prototype.hasOwnProperty.call(b, key) || !areConfigurationsEqual(a[key], b[key])) {
        return false;
      }
    }

    // Check for additional properties in `b` with non-undefined values (other than false)
    for (const key of Object.keys(b)) {
      if (!Object.prototype.hasOwnProperty.call(a, key) && b[key] !== undefined && b[key] !== false) {
        return false;
      }
    }

    return true;
  }

  // Return true if both are NaN, false otherwise
  return a !== a && b !== b;
}
