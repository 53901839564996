import React from "react";
import { ValueAxis } from "../../../../../../shared/reporting/api/biClient.types";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import { getLinearSettings } from "../../contexts/FieldsStateContext.types";
import TitleItem from "../TitleItem";

interface Props {
  area: SettingsArea;
  axis: ValueAxis;
}

export default function Title({ area, axis }: Props) {
  const title = React.useMemo(() => valueAxisTitle(area, axis.name), [area, axis.name]);

  return (
    <TitleItem
      title={title}
      placeholder="Set Axis Title..."
      onUpdate={(title) => area.updateValueAxis(axis.name, { title })}
    />
  );
}

export function valueAxisTitle(area: SettingsArea, name: string) {
  return getLinearSettings(area.settings)?.valueAxis?.find((va) => va.name === name)?.title || "";
}
