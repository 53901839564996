import { ListItem, Typography } from "@mui/material";
import { memo } from "react";
import { DEFAULT_AREA_FIELD_ITEM_HEIGHT } from "../fields/areaFields/AreaFieldsHelper";

const AreaFieldItemDraggable = memo((props: { caption: string }) => {
  const { caption } = props;

  return (
    <ListItem
      sx={{
        display: "flex",
        border: "1px solid #E5E6E9",
        borderRadius: "2px",
        p: "5px",
        width: "322px",
        height: `${DEFAULT_AREA_FIELD_ITEM_HEIGHT}px`,
        backgroundColor: "#fff",
        boxShadow: "0px 3px 2px -1px rgba(0, 0, 0, 0.1)",
        "&:hover": {
          cursor: "grab",
        },
      }}
    >
      <Typography variant="caption" sx={{ flexGrow: 1 }} color={(theme) => theme.palette.text.primary}>
        {caption}
      </Typography>
    </ListItem>
  );
});

export default AreaFieldItemDraggable;
