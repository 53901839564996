import DxTreeMap from "devextreme-react/tree-map";
import React from "react";
import { ChartProps } from "./ChartProps";
import ChartCore from "./ChartCore";

export class TreeMap extends React.Component<ChartProps> {
  override render(): React.ReactNode {
    return (
      <ChartCore {...this.props}>
        <DxTreeMap
          ref={(ref) => this.props.exportFunc(ref?.instance.exportTo.bind(ref.instance))}
          dataSource={this.props.dataSource}
          valueField={this.props.values[0]?.config.guid || ""}
          labelField={this.props.args[0]?.meta.name || ""}
        >
          {/* <Tooltip enabled={true} customizeTooltip={customizeTooltip} /> */}
        </DxTreeMap>
      </ChartCore>
    );
  }
}

export default TreeMap;
