import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

interface Props {
  value: boolean;
}

const DimensionBooleanValue = ({ value }: Props) => {
  return value ? <CheckRoundedIcon sx={{ color: "action.active" }} /> : null;
};

export default DimensionBooleanValue;
