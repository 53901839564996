import { MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import { CellDrillDownInfoBase } from "../../../../../api/biApi.types";

export function getLedgerTitle(drillDownInfo: CellDrillDownInfoBase | undefined): string {
  if (drillDownInfo === undefined) return "";
  if (drillDownInfo.table === MeasureUnitTable.Memo) {
    return drillDownInfo.allocated ? "Allocated Memo Ledger" : "Memo Ledger";
  }
  return drillDownInfo.allocated ? "Allocated General Ledger" : "General Ledger";
}
