import { maxBy } from "../../../../shared/utilities/arrayHelper";

export type HoveredFieldItemIndex = number | "nonSortableItemHovered" | "draggingIsOff";

export function getInsertToIndex(listElements: Element[]) {
  let insertTo = 0;
  const nonShiftedChildren = listElements.filter((c) => c.getAttribute("data-is-shifted") === "false");
  //While dragging over the first item, all items are shifted down, so the insert index should be 0.
  if (nonShiftedChildren.length === 0) {
    return insertTo;
  }

  const containsDraggingItem = Boolean(nonShiftedChildren.find((c) => c.getAttribute("data-is-dragging") === "true"));
  insertTo = maxBy(
    nonShiftedChildren.map((child) => child.getAttribute("data-index")),
    (v) => +(v || 0)
  );

  //Since the dragging item has display none in a list, while hovering list items it doesn't include the index of dragging item.
  return insertTo + (containsDraggingItem ? 0 : 1);
}

export function hoveredIndexIsDefined(index: HoveredFieldItemIndex): index is number {
  return typeof index === "number";
}
