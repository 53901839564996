import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import { PropsWithChildren } from "react";
import EqualSignIcon from "../../../../icons/EqualSignIcon";
import { CustomMeasureReturnType } from "./hooks/useCustomMeasure";

interface FormulaInputWrapperProps {
  state: CustomMeasureReturnType;
  focused: boolean;
  formulaError: boolean;
  hideCheck?: boolean;
}
export default function FormulaInputWrapper({
  state,
  focused,
  formulaError,
  children,
  hideCheck,
}: PropsWithChildren<FormulaInputWrapperProps>) {
  return (
    <Grid
      container
      sx={(theme) => ({
        flexWrap: "nowrap",
        backgroundColor: grey[50],
        fontSize: "13px",
        height: theme.spacing(6.25),
        lineHeight: theme.spacing(6),
        borderRadius: "4px",
        border: `1px solid ${focused ? theme.palette.primary.main : theme.palette.divider}`,
        verticalAlign: "middle",
        alignItems: "center",
        contenteditable: {
          outline: "0px solid transparent",
        },
        overflowX: "auto",
        overflowY: "hidden",
        pb: 1.75,
      })}
    >
      <Grid
        item
        sx={{
          alignSelf: "center",
          paddingLeft: 1.75,
          display: "flex",
          marginTop: 2.5,
        }}
      >
        <EqualSignIcon />
      </Grid>
      {children}
      <Grid
        item
        sx={{
          marginTop: 1.5,
          paddingRight: 1.75,
          display: "flex",
          alignItems: "center",
        }}
      >
        {!hideCheck && !formulaError && state.formula && (
          <CheckRoundedIcon sx={(theme) => ({ color: theme.palette.success.main })} />
        )}
      </Grid>
    </Grid>
  );
}
