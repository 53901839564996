import { Report } from "../../../../shared/reporting/api/biClient.types";
import PivotContainer from "./PivotContainer";
import PivotViewer from "./PivotViewer";

interface Props {
  report: Report;
}

export default function PivotViewerContainer({ report }: Props) {
  return (
    <PivotContainer report={report}>
      <PivotViewer></PivotViewer>
    </PivotContainer>
  );
}
