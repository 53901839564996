import {
  GroupingField,
  Ordering,
  TabularConfiguration,
  TabularFieldConfiguration,
  TabularGeneralSettings,
} from "../../../../../shared/reporting/api/biClient.types";
import { ColumnSorting } from "../../../../api/biApi.types";
import { ConditionField } from "../../Types";
import { TableField } from "../hooks/TableField";

export const createTabularDataRequest = (
  allConditions: ConditionField[],
  fields: TableField[],
  sorting: ColumnSorting[],
  grouping: GroupingField[],
  settings: TabularGeneralSettings
) => {
  const result: TabularConfiguration = {
    conditions: allConditions.map((c) => ({ ...c.config, invalid: c.invalid })),
    fields: fields.map(
      (field): TabularFieldConfiguration => ({
        fieldType: field.fieldType,
        guid: field.guid,
        dimension: field.dimension
          ? {
              name: field.dimension?.meta.name,
              format: field.dimension?.config.format,
              grouping: field.dimension?.config.grouping,
              customLabel: field.dimension?.config.customLabel,
              suppressEmptyValues: field.dimension?.config.suppressEmptyValues,
              aggregation: field.dimension?.config.aggregation,
            }
          : undefined,
        measure: field.measure?.config,
        showLogoIcon: field.dimension?.config.showLogoIcon,
      })
    ),
    sort: sorting.map((s) => ({
      name: s.dimensionName,
      ordering: s.sortAsc ? Ordering.Ascending : Ordering.Descending,
      caption: s.caption,
    })),
    grouping: grouping.map((g) => ({ name: g.name, hideSummaries: g.hideSummaries ?? false })),
    settings,
  };

  return result;
};
