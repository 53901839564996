import { SvgIcon, SvgIconProps } from "@mui/material";

export const FunctionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M10.35 4.87937C9.43335 4.80298 8.62502 5.42176 8.54169 6.26973L8.33335 8.47749H9.91942C10.3413 8.47749 10.6834 8.81951 10.6834 9.24142C10.6834 9.66333 10.3413 10.0054 9.91942 10.0054H8.18335L7.81669 13.8785C7.65002 15.5591 6.03335 16.8043 4.20002 16.6516C3.37415 16.5849 2.61114 16.2398 2.05635 15.6923C1.80634 15.4456 1.85814 15.0475 2.11705 14.8101C2.49433 14.4643 3.15514 14.6329 3.59436 14.8957C3.99793 15.1371 4.51092 15.2168 5.00002 15.0702C5.65002 14.8869 6.10835 14.3598 6.16669 13.741L6.51669 10.0054H4.78062C4.35871 10.0054 4.01669 9.66333 4.01669 9.24142C4.01669 8.81951 4.35871 8.47749 4.78062 8.47749H6.66669L6.89169 6.13222C7.05002 4.45157 8.65835 3.20636 10.5 3.34387C11.3331 3.41054 12.1032 3.76093 12.6605 4.31491C12.911 4.56384 12.858 4.96371 12.5977 5.20232C12.2195 5.54906 11.561 5.38192 11.1196 5.12017C10.894 4.98636 10.6314 4.90167 10.35 4.87937ZM17.698 11.8524C18.04 11.5367 18.039 10.996 17.6959 10.6815C17.3918 10.4027 16.9249 10.4027 16.6208 10.6815L14.8 12.3506L12.9782 10.6806C12.6741 10.4018 12.2078 10.4001 11.9017 10.6768C11.5521 10.9928 11.5502 11.541 11.8976 11.8594L13.625 13.443L11.9038 14.9988C11.5539 15.315 11.5539 15.8644 11.9038 16.1806C12.2087 16.4562 12.6732 16.4546 12.9762 16.1769L14.8 14.5049L16.6228 16.1759C16.9258 16.4537 17.3909 16.4537 17.6939 16.1759C18.0373 15.8611 18.0363 15.3193 17.6918 15.0057L15.975 13.443L17.698 11.8524Z" />
    </SvgIcon>
  );
};

export default FunctionIcon;
