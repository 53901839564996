import { SvgIcon, SvgIconProps } from "@mui/material";

export const NavigationParentIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 21 }} viewBox="0 0 20 21" {...props}>
      <mask id="mask0_3289_135094" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="21">
        <rect y="0.0351562" width="20" height="20" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_3289_135094)">
        <path d="M15.8333 14.2018C15.8333 14.6621 15.4602 15.0352 15 15.0352L12.5 15.0352C11.3472 15.0352 10.3644 14.6288 9.55163 13.816C8.7394 13.0038 8.33329 12.0213 8.33329 10.8685L8.33329 5.70182L5.91663 8.11849C5.59446 8.44066 5.07213 8.44066 4.74996 8.11849C4.42779 7.79632 4.42779 7.27399 4.74996 6.95182L9.16663 2.53516L13.5833 6.95182C13.9055 7.27399 13.9055 7.79632 13.5833 8.11849C13.2611 8.44066 12.7388 8.44066 12.4166 8.11849L9.99996 5.70182L9.99996 10.8685C9.99996 11.5629 10.243 12.1532 10.7291 12.6393C11.2152 13.1254 11.8055 13.3685 12.5 13.3685L15 13.3685C15.4602 13.3685 15.8333 13.7416 15.8333 14.2018Z" />
      </g>
    </SvgIcon>
  );
};
export default NavigationParentIcon;
