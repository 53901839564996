import { Box, SxProps } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { groupByToMap } from "../../../utilities/arrayHelper";
import TemplatesMenuItem from "./TemplatesMenuItem";
import { FundType, ReportTemplateDto } from "../../api/biClient.types";
import { formatFundType } from "./helpers/reportTemplateHelper";
import { MenuItemRecord } from "./MenuItemRecord";

interface Props {
  displayingTemplates: ReportTemplateDto[];
  allTemplatesList: ReportTemplateDto[];
  onSelect: (item?: MenuItemRecord) => void;
  sx?: SxProps;
  showNewLabel?: boolean;
}

const allTemplatesLabel = "All Templates";

export default function TemplatesMenu({ displayingTemplates, onSelect, sx, showNewLabel, allTemplatesList }: Props) {
  const [selectedMenuItemLabel, setSelectedMenuItemLabel] = useState<MenuItemRecord>(() => ({
    label: allTemplatesLabel,
    value: allTemplatesLabel,
  }));

  const filters = useMemo(() => {
    return {
      all: [
        { label: allTemplatesLabel, value: allTemplatesLabel, count: displayingTemplates.length } as MenuItemRecord,
      ],
      fundType: getTemplateMenuGroupItemData(
        createFundTypesMapFromTemplates(displayingTemplates, allTemplatesList),
        "Fund Types",
        (value) => formatFundType(value as FundType)
      ),
      groups: getTemplateMenuGroupItemData(
        createGroupsMapFromTemplates(displayingTemplates, allTemplatesList),
        "Groups",
        (value) => value
      ),
    };
  }, [displayingTemplates, allTemplatesList]);

  const onMenuItemSelect = useCallback(
    (item?: MenuItemRecord) => {
      setSelectedMenuItemLabel(item || { label: allTemplatesLabel, value: allTemplatesLabel });
      onSelect(item);
    },
    [onSelect, setSelectedMenuItemLabel]
  );

  return (
    <Box sx={{ display: "flex", gap: 2.5, flexDirection: "column", width: "100%", ...sx }}>
      <TemplatesMenuItem items={filters.all} selectedItem={selectedMenuItemLabel} onClick={onMenuItemSelect} />
      <TemplatesMenuItem
        items={filters.fundType}
        selectedItem={selectedMenuItemLabel}
        subHeader={"Fund Types"}
        onClick={onMenuItemSelect}
        showNewLabel={showNewLabel}
      />
      <TemplatesMenuItem
        items={filters.groups}
        subHeader={"Groups"}
        selectedItem={selectedMenuItemLabel}
        onClick={onMenuItemSelect}
      />
    </Box>
  );
}

function getTemplateMenuGroupItemData(
  displayingTemplates: Map<string, ReportTemplateDto[]>,
  fieldName: string,
  labelFormatter?: (value: string) => string
): MenuItemRecord[] {
  return Array.from(displayingTemplates.entries()).map(([key, value]) => ({
    fieldName: fieldName,
    count: value.length,
    isNew: value.some((t) => t.isNew),
    value: key,
    label: labelFormatter ? labelFormatter(key) : key,
  }));
}

function createFundTypesMapFromTemplates(displayingTemplates: ReportTemplateDto[], allTemplates: ReportTemplateDto[]) {
  const uniqueFundTypes = Array.from(new Set(allTemplates.flatMap((t) => t.fundTypes)));
  return uniqueFundTypes.reduce((result, ft) => {
    result.set(
      ft,
      displayingTemplates.filter((t) => t.fundTypes.includes(ft))
    );
    return result;
  }, new Map<string, ReportTemplateDto[]>());
}

function createGroupsMapFromTemplates(displayingTemplates: ReportTemplateDto[], allTemplates: ReportTemplateDto[]) {
  const uniqueGroups = Array.from(groupByToMap(allTemplates, (item) => item.groupName).keys());
  return uniqueGroups.reduce((result, g) => {
    result.set(
      g,
      displayingTemplates.filter((t) => t.groupName === g)
    );
    return result;
  }, new Map<string, ReportTemplateDto[]>());
}
