import { useCallback, useEffect, useRef, useState } from "react";
import { useNotificationContext } from "../../shared/contexts/NotificationContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectTemplates } from "../store/reportTemplatesSlice";
import sharedBiClient from "../../shared/reporting/api/biClient";
import { currentReportActions } from "../store/currentReportSlice";
import { useLocalization } from "./useLocalization";
import { getStatusCodeFromError } from "../../shared/api/axiosHelper";

const templateIdQueryParamKey = "templateId";

const useApplyTemplate = () => {
  const { sendNotification } = useNotificationContext();
  const { report_templates: templatesLocale } = useLocalization();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const templates = useSelector(selectTemplates);

  const [showTemplateNotFound, setShowTemplateNotFound] = useState(false);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [showTemplateFailedToApply, setShowTemplateFailedToApply] = useState(false);

  const cachedTemplateId = useRef<string | null>(null);
  const [searchParams] = useSearchParams();

  const templateId = searchParams.get(templateIdQueryParamKey);
  const applyingTemplateName = templates.find((t) => t.reportId === cachedTemplateId.current)?.name;

  const handleCloseFailedToApplyDialog = useCallback(() => {
    cachedTemplateId.current = null;
    setShowTemplateFailedToApply(false);
  }, []);

  const handleTryAgain = useCallback(() => {
    setShowTemplateFailedToApply(false);
    searchParams.set(templateIdQueryParamKey, cachedTemplateId.current || "");
    navigate("?" + searchParams.toString());
  }, [navigate, searchParams]);

  useEffect(() => {
    const getTemplate = async () => {
      if (templateId !== null) {
        cachedTemplateId.current = templateId;
        searchParams.delete(templateIdQueryParamKey);
        navigate("?" + searchParams.toString());
        setLoadingTemplate(true);
        try {
          const getTemplatePromise = sharedBiClient.getReportTemplateForCompany({ templateId: templateId });
          const delayPromise = new Promise<void>((resolve) => {
            setTimeout(() => {
              resolve();
            }, 3000);
          });
          const [templateResponse] = await Promise.all([getTemplatePromise, delayPromise]);
          if (templateResponse.success && templateResponse.data !== undefined) {
            dispatch(currentReportActions.applyTemplateOnReport(templateResponse.data));
            sendNotification(templatesLocale.template_applied);
          } else {
            setShowTemplateNotFound(true);
          }
        } catch (e) {
          if (getStatusCodeFromError(e) === 404) {
            setShowTemplateNotFound(true);
          } else {
            setShowTemplateFailedToApply(true);
          }
        } finally {
          setLoadingTemplate(false);
        }
      }
    };
    getTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  return {
    loadingTemplate,
    showTemplateNotFound,
    showTemplateFailedToApply,
    applyingTemplateName,
    handleCloseFailedToApplyDialog,
    handleTryAgain,
  };
};

export default useApplyTemplate;
