import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar, SnackbarCloseReason } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useNotificationContext } from "../contexts/NotificationContext";

export const GlobalNotificationErrorSnackbar = () => {
  const { notificationError } = useNotificationContext();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (notificationError !== undefined) {
      setShow(true);
    }
  }, [notificationError]);

  const handleClose = useCallback(
    (_: Event | SyntheticEvent<unknown, Event>, reason: SnackbarCloseReason) => {
      if (reason !== "clickaway") {
        setShow(false);
      }
    },
    [setShow]
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={show}
      autoHideDuration={notificationError?.autoHideDuration}
      onClose={handleClose}
      message={notificationError?.text}
      sx={(theme) => ({
        ".MuiPaper-root": {
          backgroundColor: theme.palette.error.main,
          flexWrap: "nowrap",
        },
      })}
      action={
        <IconButton color="inherit" onClick={() => setShow(false)}>
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

export default GlobalNotificationErrorSnackbar;
