import { useCallback, useState } from "react";
import { useSharedReportingLocalization } from "../../../../../shared/reporting/hooks/useLocalization";
import { Report, ReportConfiguration } from "../../../../../shared/reporting/api/biClient.types";
import { FromReport } from "../../../../../shared/reporting/components/reports/FromReport";

interface Props {
  report: FromReport;
  reportConfiguration: ReportConfiguration;
  addReport: (report: Report) => Promise<Report | undefined>;
  onChanged: (reportId: string) => void;
  onClose: () => void;
}

const useSaveReportAsDialog = ({ addReport, onChanged, report, reportConfiguration, onClose }: Props) => {
  const { report: locale } = useSharedReportingLocalization();

  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);

  const handleSaveClicked = useCallback(
    (name: string, groupId: string) => {
      setSaving(true);
      const rep = { ...report, name, groupId, configuration: reportConfiguration } as Report;

      addReport(rep)
        .then((response) => {
          setSaving(false);
          onChanged(response?.reportId || "");
          onClose();
        })
        .catch(() => {
          setError(locale.duplicating_error);
          setSaving(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportConfiguration, onChanged, report, locale.duplicating_error]
  );

  return {
    error,
    saving,
    handleSaveClicked,
  };
};

export default useSaveReportAsDialog;
