import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";

interface Props {
  reportName: string | undefined;
  loading: boolean;
  validationError: string | undefined;
  onApply: (newReportName: string) => void;
  onClose: () => void;
  onReportNameChanged: (newReportName: string) => void;
}

const ChangeReportNameField = ({
  reportName,
  onApply,
  validationError,
  onClose,
  loading,
  onReportNameChanged,
}: Props) => {
  const [newReportName, setNewReportName] = useState(reportName || "");

  const handleApply = () => {
    onApply(newReportName);
  };

  return (
    <Box sx={(t) => ({ display: "flex", gap: 1, width: "100%", maxWidth: t.spacing(71.25) })}>
      <Box sx={(t) => ({ position: "relative", width: "inherit", maxWidth: t.spacing(53) })}>
        <TextField
          autoFocus
          placeholder="Enter Report Name"
          focused
          sx={{
            position: "absolute",
            top: 5,
            "& .MuiOutlinedInput-root": { maxHeight: "30px" },
            "& .MuiFormHelperText-root": { mt: 0.25 },
            width: "inherit",
          }}
          value={newReportName}
          onChange={(e) => {
            setNewReportName(e.target.value);
            onReportNameChanged(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleApply();
              return;
            }
            if (e.key === "Escape") {
              onClose();
              return;
            }
          }}
          error={!!validationError}
          helperText={validationError}
        />
      </Box>
      <Box display="flex" gap={1} alignItems="center" py={0.5}>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" loading={loading} onClick={handleApply} disabled={!!validationError}>
          Apply
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ChangeReportNameField;
