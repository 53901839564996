import { Grid, SxProps, Theme } from "@mui/material";
import React from "react";

export interface TabPanelProps<T> {
  children?: React.ReactNode;
  selectedTab: T;
  value: T;
  sx?: SxProps<Theme>;
}

export function PersistedTabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, selectedTab, sx, ...other } = props;
  const [rendered, setRendered] = React.useState(false);

  React.useEffect(() => {
    if (value === selectedTab) {
      setRendered(true);
    }
  }, [value, selectedTab]);

  if (!rendered) return null;

  return (
    <Grid container display={value === selectedTab ? "flex" : "none"} id={`dd-tabpanel-${value}`} sx={sx} {...other}>
      {children}
    </Grid>
  );
}
