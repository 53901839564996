import { Typography } from "@mui/material";
import { useCallback } from "react";
import { MenuItemStyled } from "../../../../../../shared/components/MenuItemStyled";
import { Grouping, PivotFieldConfiguration } from "../../../../../../shared/reporting/api/biClient.types";
import { ItemDataType } from "../../../../..//api/biApi.types";
import CustomLabel from "../../../../common/customLabel/CustomLabel";
import FieldOptionDeleteButton from "../../../../common/FieldOptionDeleteButton";
import FieldOptionsDivider from "../../../../common/FieldOptionsDivider";
import FieldPopover from "../../../common/fields/FieldPopover";
import { SuppressNoValueDimensionComponent } from "../../../common/fields/SuppressNoValueComponent";
import { GeneralField } from "../../../Types";
import { getDateGroupingLabel } from "../../../utils/captionsHelper";

interface Props {
  anchorEl: HTMLElement;
  field: GeneralField;
  supportedDateGroupings?: (Grouping | undefined)[];
  saveChanges: (changes: Partial<PivotFieldConfiguration>, causeClosing: boolean) => void;
  cancel: () => void;
  onRemove: () => void;
  onUpdateCustomLabel: (name: string | undefined) => void;
}

export default function GeneralFieldOptionPopup(props: Props) {
  const { anchorEl, field, supportedDateGroupings, cancel, saveChanges, onRemove, onUpdateCustomLabel } = props;

  const isDateField = field.meta.type === ItemDataType.Date;

  const updateGrouping = useCallback(
    (newGrouping: Grouping | undefined) => {
      if (field.config.grouping !== newGrouping) {
        saveChanges({ grouping: newGrouping }, true);
      }
    },
    [field.config.grouping, saveChanges]
  );

  const updateSuppression = useCallback(
    (value: boolean) => {
      if (field.config.suppressEmptyValues !== value) {
        saveChanges({ suppressEmptyValues: value }, false);
      }
    },
    [field.config.suppressEmptyValues, saveChanges]
  );

  const handleOnRemove = useCallback(() => {
    onRemove();
    cancel();
  }, [onRemove, cancel]);

  return (
    <FieldPopover open={!!anchorEl} anchorEl={anchorEl} onClose={cancel} sx={{ width: 400, py: 0 }}>
      <CustomLabel
        customLabelValue={field.config.customLabel}
        originalName={field.meta.caption}
        updateCustomLabel={onUpdateCustomLabel}
      />
      <FieldOptionsDivider />
      {isDateField && (
        <>
          {(supportedDateGroupings || defaultGrouping).map((grouping, index) => (
            <MenuItemStyled
              key={`${grouping}${index}`}
              selected={field.config.grouping === grouping}
              onClick={() => updateGrouping(grouping)}
            >
              <Typography variant="caption">{getDateGroupingLabel(grouping)}</Typography>
            </MenuItemStyled>
          ))}
          <FieldOptionsDivider />
        </>
      )}
      {!isDateField && (
        <>
          <SuppressNoValueDimensionComponent field={field} onChange={updateSuppression} />
          <FieldOptionsDivider />
        </>
      )}
      <FieldOptionDeleteButton onRemove={handleOnRemove} />
    </FieldPopover>
  );
}

const defaultGrouping = [Grouping.ByYear, Grouping.ByQuarter, Grouping.ByMonth];
