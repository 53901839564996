import { SvgIcon, SvgIconProps } from "@mui/material";

export const CurrencyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 10, height: 16 }} viewBox="0 0 10 16" {...props}>
      <path d="M0.833008 10.5H2.49967C2.49967 11.4 3.64134 12.1667 4.99967 12.1667C6.35801 12.1667 7.49967 11.4 7.49967 10.5C7.49967 9.58333 6.63301 9.25 4.79967 8.80833C3.03301 8.36667 0.833008 7.81667 0.833008 5.5C0.833008 4.00833 2.05801 2.74167 3.74967 2.31667V0.5H6.24967V2.31667C7.94134 2.74167 9.16634 4.00833 9.16634 5.5H7.49967C7.49967 4.6 6.35801 3.83333 4.99967 3.83333C3.64134 3.83333 2.49967 4.6 2.49967 5.5C2.49967 6.41667 3.36634 6.75 5.19967 7.19167C6.96634 7.63333 9.16634 8.18333 9.16634 10.5C9.16634 11.9917 7.94134 13.2583 6.24967 13.6833V15.5H3.74967V13.6833C2.05801 13.2583 0.833008 11.9917 0.833008 10.5Z" />
    </SvgIcon>
  );
};
export default CurrencyIcon;
