import { createSvgIcon } from "@mui/material";

const UnsavedChangesIcon = createSvgIcon(
  <svg viewBox="0 0 50 60" fill="currentColor">
    <path
      d="M49.0004 59.6998H0.900391V0.299805H36.8004L49.1004 12.5998V59.6998H49.0004ZM5.90039 54.6998H44.0004V14.5998L34.7004 5.2998H5.90039V54.6998Z"
      fill="#C7DBE5"
    />
    <path d="M46.5 17.7998H32V2.7998H37V12.7998H46.5V17.7998Z" fill="#C7DBE5" />
    <path
      d="M39.8008 20.5996V28.0996L27.1008 41.9996L19.4008 37.1996L9.80078 46.3996V39.4996L18.8008 30.8996L26.2008 35.5996L39.8008 20.5996Z"
      fill="#00A866"
    />
  </svg>,
  "UnsavedChangesIcon"
);

export default UnsavedChangesIcon;
