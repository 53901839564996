import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TabularGeneralSettings } from "../../../../../shared/reporting/api/biClient.types";
import { createTabularDefaultSettings } from "../../utils/isConfigurationValid";

export type SettingsState = {
  settings: TabularGeneralSettings;
};

const initialState: SettingsState = { settings: createTabularDefaultSettings() };

const settingsSlice = createSlice({
  name: "settingsFieldsState",
  initialState,
  reducers: {
    setSettingsAction: (state, action: PayloadAction<TabularGeneralSettings>) => {
      state.settings = action.payload;
    },
    updateSettingsAction: (state, action: PayloadAction<Partial<TabularGeneralSettings>>) => {
      state.settings = { ...state.settings, ...action.payload };
    },
  },
});

export const { updateSettingsAction, setSettingsAction } = settingsSlice.actions;

export default settingsSlice;
