import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ApiResponse } from "../../../../../shared/api/types";
import useDebounce from "../../../../../shared/hooks/useDebounce";
import { ApiReportResponse, FailedMeasure, TabularDataResponse } from "../../../../api/biApi.types";
import { useEnhancedBiApiClientProvider } from "../../../../contexts/ApiClientProviderContext";
import { useLocalization } from "../../../../hooks/useLocalization";
import { apiReportActions } from "../../../../store/apiReportSlice";
import { currentReportActions } from "../../../../store/currentReportSlice";
import { update } from "../../../../store/devToolsSlice";
import { isApiReportResponse } from "../../../../utilities/biClientProviderHelper";
import useDeferredBuild from "../../common/hooks/useDeferredBuild";
import { GridDataStateActionType, ReducerAction } from "./gridDataState";
import useRequestConfigBuilder from "./useRequestConfigBuilder";

export default function useDataLoadingBuilder<R extends TabularDataResponse | ApiReportResponse>(
  dispatch: React.Dispatch<ReducerAction>
) {
  const dispatchGlobal = useDispatch();
  const { requestConfig } = useRequestConfigBuilder();
  const { buildReport, calculateTabularGroupTotalsData } = useEnhancedBiApiClientProvider();

  const locale = useLocalization();
  const [dataLoadingCancellationToken, setDataLoadingCancellationToken] = React.useState<CancellationToken | undefined>(
    undefined
  );
  const [groupTotalsCancellationToken, setGroupTotalsCancellationToken] = React.useState<CancellationToken | undefined>(
    undefined
  );
  const [failedMeasures, setFailedMeasures] = React.useState<FailedMeasure[]>([]);
  const [failedGroupTotalMeasures, setFailedGroupTotalMeasures] = React.useState<FailedMeasure[] | undefined>();

  const cancelDataLoading = () => dataLoadingCancellationToken?.cancel();

  const cancelGroupTotalsLoading = () => groupTotalsCancellationToken?.cancel();

  const loadData = () => {
    dispatch({ type: GridDataStateActionType.UPDATE_STATE, payload: { error: undefined } });
    dispatch({ type: GridDataStateActionType.LOADING_STARTED });
    dispatch({ type: GridDataStateActionType.LOADING_GROUPS_STARTED });
    dispatchGlobal(currentReportActions.setEtag({ etag: undefined, cacheValid: undefined }));

    const calcConfig = { ...requestConfig };
    calcConfig.calcGroupTotals = true;

    setDataLoadingCancellationToken(buildReport(calcConfig, onBuildDone, onBuildError));
  };

  const handleBuildDone = (resp: ApiResponse<R>) => {
    if (resp.data && isApiReportResponse(resp.data)) {
      dispatchGlobal(apiReportActions.setResponse(resp.data.publicResponse));
    }
    dispatch({ type: GridDataStateActionType.LOADING_ENDED });
    dispatch({ type: GridDataStateActionType.LOADING_GROUPS_ENDED });
    if (resp.success) {
      dispatch({
        type: GridDataStateActionType.UPDATE_STATE,
        payload: {
          rows: resp.data.data || [],
          groupTotals: resp.data.groupTotals,
          grandTotals: resp.data.grandTotals,
          totalCount: resp.data.totalCount,
          columns: resp.data.columns,
        },
      });
      dispatchGlobal(update({ loggingItems: resp.data.loggingItems }));
      dispatchGlobal(currentReportActions.setEtag({ etag: resp.data.etag, cacheValid: undefined }));

      setFailedGroupTotalMeasures(undefined);
      setFailedMeasures(resp.data.failedMeasures);
    } else if (resp.error?.message) {
      dispatch({
        type: GridDataStateActionType.UPDATE_STATE,
        payload: { error: resp.error?.message || locale.common.calculation_error },
      });
    }
  };

  const handleBuildError = (error: string | undefined) => {
    dispatch({ type: GridDataStateActionType.LOADING_ENDED });
    dispatch({ type: GridDataStateActionType.LOADING_GROUPS_ENDED });
    if (error === "canceled") {
      dispatch({ type: GridDataStateActionType.UPDATE_STATE, payload: { error: undefined } });
    } else {
      dispatch({
        type: GridDataStateActionType.UPDATE_STATE,
        payload: { error: error || locale.common.calculation_error },
      });
    }
  };

  const loadGroupTotals = () => {
    cancelGroupTotalsLoading();

    dispatch({ type: GridDataStateActionType.UPDATE_STATE, payload: { error: undefined } });
    dispatch({ type: GridDataStateActionType.LOADING_GROUPS_STARTED });

    const body = { ...requestConfig };

    setGroupTotalsCancellationToken(
      calculateTabularGroupTotalsData(
        body,
        (resp) => {
          if (resp.success) {
            dispatch({ type: GridDataStateActionType.LOADING_GROUPS_ENDED });
            dispatch({ type: GridDataStateActionType.UPDATE_STATE, payload: { groupTotals: resp.data.groupTotals } });
            setFailedGroupTotalMeasures(resp.data.failedMeasures);
          }
        },
        (error) => {
          dispatch({ type: GridDataStateActionType.LOADING_GROUPS_ENDED });
          if (error === "canceled") {
            dispatch({ type: GridDataStateActionType.UPDATE_STATE, payload: { error: undefined } });
          } else {
            dispatch({
              type: GridDataStateActionType.UPDATE_STATE,
              payload: { error: error || locale.common.calculation_error },
            });
          }
        }
      )
    );
  };

  const handleInvalidConfiguration = () => {
    cancelCurrentAwaiter();
    dispatchGlobal(apiReportActions.setResponse(undefined));
  };

  const {
    doRequest: load,
    onBuildDone,
    onBuildError,
    resetInitialRequestFlag,
    cancelCurrentAwaiter,
  } = useDeferredBuild({
    request: loadData,
    handleBuildDone,
    handleBuildError,
    cancelRequest: () => {
      cancelDataLoading();
      cancelGroupTotalsLoading();
    },
    onProgressChange: (progress) => dispatchGlobal(currentReportActions.updateBuildProgress(progress)),
  });

  const doLoadGroupTotals = useDebounce(loadGroupTotals, 100);

  useEffect(() => {
    resetInitialRequestFlag();
  }, [requestConfig.reportId, resetInitialRequestFlag]);

  return {
    loadData: load,
    loadGroupTotals: doLoadGroupTotals,
    cancelDataLoading,
    cancelGroupTotalsLoading,
    failedMeasures,
    failedGroupTotalMeasures,
    handleInvalidConfiguration,
  };
}

export type DataLoadingReturnType = ReturnType<typeof useDataLoadingBuilder>;

type CancellationToken = {
  cancel: () => void;
};
