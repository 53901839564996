import { Box, List, SxProps, Theme } from "@mui/material";
import AddField from "./areaFields/AddField";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { ShowFieldOptionsSettings } from "../../Types";
import { AreaFieldItemContainer } from "./areaFields/AreaFieldItemContainer";
import { useCallback, useEffect, useRef, useState } from "react";
import { AnyObject } from "../../../../../shared/types";
import useDragLeave from "../hooks/useDragLeave";
import { FieldItemProps } from "./types/areaFiledItem.types";
import { insertItemAt } from "../../../../utilities/Utilities";
import { extractAreaItemType, extractMeta, isFieldWrapper, wrapItem } from "../utilities/dropFieldContainerHelper";
import DropFieldPlaceholder from "./DropFieldPlaceholder";
import { HoveredFieldItemIndex, getInsertToIndex, hoveredIndexIsDefined } from "../../utils/dragDropHelper";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "./types/dropField.types.ts";

interface Props<T extends DraggableFieldType, TMeta extends DraggableMetaType> {
  containerSx?: SxProps<Theme>;
  areaFieldType: string;
  fields: T[];
  acceptedDropTypes: string[];
  readonly?: boolean;
  renderListItem?: (props: FieldItemProps<T>) => JSX.Element;
  canDropItem?: (item: FieldWrapper<T> | TMeta) => boolean;
  getKeyValue: (field: FieldWrapper<T> | TMeta) => string;
  formatCaption?: (field: T) => JSX.Element | undefined;
  onAddItem: (item: T, index: number) => void;
  onMoveItem: (item: T, newIndex: number) => void;
  onRemoveItem: (item: T) => void;
  showOptions?: (settings: ShowFieldOptionsSettings<T>) => void;
  createListItem: (meta: TMeta) => T;
}
export const DropFieldContainer = <T extends DraggableFieldType, TMeta extends DraggableMetaType>(
  props: Props<T, TMeta>
) => {
  const {
    areaFieldType,
    fields,
    acceptedDropTypes,
    readonly,
    containerSx,
    renderListItem,
    formatCaption,
    canDropItem,
    getKeyValue,
    onAddItem,
    onMoveItem,
    onRemoveItem,
    showOptions,
    createListItem,
  } = props;

  const [areaFields, setAreaFields] = useState<FieldWrapper<T>[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<HoveredFieldItemIndex>("draggingIsOff");
  const listRef = useRef<HTMLUListElement | null>(null);
  const fieldsRef = useRef<FieldWrapper<T>[]>([]);
  const areaFieldTypeRef = useRef(areaFieldType);
  const hoveredIndexRef = useRef<HoveredFieldItemIndex>(hoveredIndex);
  fieldsRef.current = areaFields;
  areaFieldTypeRef.current = areaFieldType;
  hoveredIndexRef.current = hoveredIndex;

  useEffect(() => {
    const values = fields.map((field, index) => wrapItem(field, index, areaFieldType));
    setAreaFields(values);
  }, [fields, areaFieldType]);

  const onEndReordering = useCallback(
    (draggingItem: FieldWrapper<T>) => {
      const toIndex = getInsertToIndex(Array.from(listRef.current?.children || []));

      const values = [...fieldsRef.current];
      values.splice(draggingItem.index, 1);
      values.splice(toIndex, 0, draggingItem);
      setAreaFields(values);
      setHoveredIndex("draggingIsOff");
      setDraggingItemMovedFromInitialPosition(false);
      setFieldItemIndexesForShifting(undefined);

      setTimeout(() => {
        onMoveItem(draggingItem.field, toIndex);
      }, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onMoveItem]
  );

  const isNewFieldToAdd = useCallback(
    (item: FieldWrapper<T> | TMeta) => {
      const itemAreaFieldType = extractAreaItemType(item);
      if (itemAreaFieldType !== areaFieldTypeRef.current) {
        return true;
      }
      if (isFieldWrapper(item)) {
        const isSelf = fieldsRef.current.find((v) => getKeyValue(v) == getKeyValue(item) && v.type === item.type);
        return !isSelf;
      }
      return false;
    },
    [getKeyValue]
  );

  const collect = useCallback((monitor: DropTargetMonitor) => {
    return {
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
      draggingItem: monitor.getItem(),
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [{ canDrop, isOver, didDrop, draggingItem }, drop] = useDrop<any, any, any>(() => {
    return {
      accept: [...acceptedDropTypes, areaFieldType],
      collect,
      drop: (item: FieldWrapper<T> | TMeta) => {
        const meta = extractMeta(item);
        if (isNewFieldToAdd(item) && meta) {
          const idx = getInsertToIndex(Array.from(listRef.current?.children || []));
          const newFieldItem = createListItem(meta as TMeta);
          if (!newFieldItem) return;
          const values = [...fieldsRef.current];
          const newValues = insertItemAt(values, wrapItem(newFieldItem, idx, areaFieldTypeRef.current), idx);
          setAreaFields(newValues);
          setHoveredIndex("draggingIsOff");
          setDraggingItemMovedFromInitialPosition(false);
          setFieldItemIndexesForShifting(undefined);

          setTimeout(() => {
            onAddItem(newFieldItem, idx);
          }, 0);
        }
        return { dropContainerType: areaFieldType };
      },
      canDrop: (item: FieldWrapper<T> | TMeta, monitor: DropTargetMonitor) => {
        if (!monitor.isOver()) {
          return false;
        }
        if (isNewFieldToAdd(item)) {
          if (canDropItem) {
            return canDropItem(item);
          }
        }
        return true;
      },
    };
  }, []);
  const canDropRef = useRef(!!canDrop);
  canDropRef.current = !!canDrop;

  useDragLeave(() => setHoveredIndex("draggingIsOff"), isOver, didDrop);

  const [fieldItemIndexesForShifting, setFieldItemIndexesForShifting] = useState<number[]>();
  const [draggingItemMovedFromInitialPosition, setDraggingItemMovedFromInitialPosition] = useState(false);

  const handleItemHovered = useCallback((hoveredItem: FieldWrapper<T>) => {
    if (!canDropRef.current) return;
    const prevIndex = hoveredIndexRef.current;
    const newIndex = hoveredItem.index;
    if (prevIndex !== "draggingIsOff" && prevIndex !== newIndex) {
      setDraggingItemMovedFromInitialPosition(true);
    }
    setHoveredIndex(newIndex);
  }, []);

  useEffect(() => {
    const arr = fieldsRef.current
      .filter((v) => hoveredIndexIsDefined(hoveredIndex) && v.index >= hoveredIndex)
      .map((v) => v.index);
    setFieldItemIndexesForShifting(arr);
  }, [hoveredIndex]);

  useEffect(() => {
    //Dragging is over
    if (!draggingItem) {
      setFieldItemIndexesForShifting(undefined);
      setDraggingItemMovedFromInitialPosition(false);
    }
  }, [draggingItem]);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FAFAFA",
        minHeight: readonly ? "inherit" : "90px",
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
        p: ".5rem",
        borderColor: canDrop && isOver ? theme.palette.primary.main : "#E5E6E9",
        ...(containerSx as AnyObject),
      })}
      ref={drop}
      role={"Dustbin"}
    >
      <List ref={listRef} sx={{ p: 0, display: "flex", flexDirection: "column", gap: "5px" }}>
        {areaFields.map((item, index) => {
          const addShifting =
            fieldItemIndexesForShifting !== undefined && fieldItemIndexesForShifting.includes(item.index);
          const hideDraggingItem =
            draggingItem &&
            item.type === draggingItem.type &&
            item.index === draggingItem.index &&
            fieldItemIndexesForShifting !== undefined;
          return (
            <AreaFieldItemContainer
              key={`${index}-field-item${getKeyValue(item)}`}
              canBeRemoved={!readonly}
              areaField={item}
              createItem={renderListItem}
              formatCaption={formatCaption}
              onRemoveItem={onRemoveItem}
              onEndReordering={onEndReordering}
              showOptions={showOptions}
              acceptedDropTypes={acceptedDropTypes}
              canDrop={!!canDrop}
              onItemHovered={handleItemHovered}
              draggingItemMovedFromInitialPosition={draggingItemMovedFromInitialPosition}
              addShifting={addShifting}
              hideDraggingItem={hideDraggingItem}
            />
          );
        })}
        {isOver && !!canDrop && (
          <DropFieldPlaceholder
            hide={fieldItemIndexesForShifting === undefined && draggingItem && draggingItem.type === areaFieldType}
          />
        )}
        {!readonly && <AddField onDragEnter={() => setHoveredIndex("nonSortableItemHovered")} />}
      </List>
    </Box>
  );
};

export default DropFieldContainer;
