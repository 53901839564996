import { Box, Button, Chip, DialogActions, DialogContent, Divider, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import LoaderBox from "../../../../components/LoaderBox";
import PromptDialog from "../../../../components/dialog/PromptDialog";
import PromptDialogHeader from "../../../../components/dialog/PromptDialogHeader";
import useFetch from "../../../../hooks/useFetch";
import { convertISODate } from "../../../../utilities/dateUtils";
import biClient from "../../../api/biClient";
import { ReportTemplate, ReportType } from "../../../api/biClient.types";
import AreaChartIcon from "../../../icons/reportTypes/AreaChartIcon";
import BarChartIcon from "../../../icons/reportTypes/BarChartIcon";
import DoughnutChartIcon from "../../../icons/reportTypes/DoughnutChartIcon";
import LineChartIcon from "../../../icons/reportTypes/LineChartIcon";
import PieChartIcon from "../../../icons/reportTypes/PieChartIcon";
import PivotIcon from "../../../icons/reportTypes/PivotIcon";
import TabularIcon from "../../../icons/reportTypes/TabularIcon";
import NewLabel from "../NewLabel";
import TemplateInfoFields from "../TemplateInfoFields";
import { formatFundType } from "../helpers/reportTemplateHelper";

interface Props {
  template: ReportTemplate;
  onClose: () => void;
  onUseTemplate: (template: ReportTemplate) => void;
}

export default function TemplateInfoDialog({ template, onClose, onUseTemplate }: Props) {
  const getTemplateFieldsData = useCallback(() => {
    return biClient.getTemplateFieldsData({ templateId: template.reportId });
  }, [template.reportId]);

  const [data, , { isFetching }] = useFetch(getTemplateFieldsData);
  const fields = data?.fields || {};
  const isConfigurationsEmpty = Object.values(fields).every((f) => f.length === 0);

  return (
    <PromptDialog open sx={{ minWidth: 740, height: "100%", maxHeight: 780, p: 0 }}>
      <PromptDialogHeader text="Template" onClose={onClose} />
      <Divider orientation="horizontal" flexItem />
      <DialogContent sx={{ height: "100%" }}>
        <LoaderBox loading={isFetching}>
          <Stack gap={2}>
            <Stack gap={2.5} id="template-info">
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography variant="h5">{template.name}</Typography>
                  {template.isNew && <NewLabel />}
                </Box>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {template.fundTypes.map((ft) => (
                    <Chip
                      key={ft}
                      label={formatFundType(ft)}
                      variant="outlined"
                      sx={{ py: 0.375, px: 0.5, fontSize: 13 }}
                    />
                  ))}
                </Box>
              </Box>
              {template.description.length > 0 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography variant="subtitle2">Description</Typography>
                  <Typography>{template.description}</Typography>
                </Box>
              )}
              <Box sx={{ display: "flex", gap: 3.5 }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography variant="subtitle2">Report Type</Typography>
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Box sx={(theme) => ({ display: "inline-flex", color: theme.palette.action.active })}>
                      {getReportTypeIcon(template.reportType)}
                    </Box>
                    <Typography>{template.reportType}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "space-between" }}>
                  <Typography variant="subtitle2">Added At</Typography>
                  <Typography>{convertISODate(template.createdAt)}</Typography>
                </Box>
              </Box>
            </Stack>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                borderStyle: "dashed",
              }}
            />
            {!isConfigurationsEmpty && <TemplateInfoFields fields={fields} />}
          </Stack>
        </LoaderBox>
      </DialogContent>
      <Divider orientation="horizontal" flexItem />
      <DialogActions sx={{ px: "1.5rem", py: 2 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled={isFetching} onClick={() => onUseTemplate(template)}>
          Use Template
        </Button>
      </DialogActions>
    </PromptDialog>
  );
}

const getReportTypeIcon = (reportType: ReportType | undefined) => {
  switch (reportType) {
    case ReportType.Tabular:
      return <TabularIcon />;
    case ReportType.Pivot:
      return <PivotIcon />;
    case ReportType.BarChart:
      return <BarChartIcon />;
    case ReportType.LineChart:
      return <LineChartIcon />;
    case ReportType.AreaChart:
      return <AreaChartIcon />;
    case ReportType.PieChart:
      return <PieChartIcon />;
    case ReportType.DoughnutChart:
      return <DoughnutChartIcon />;
    default:
      return undefined;
  }
};
