import { PivotFieldConfiguration } from "../../../../../../shared/reporting/api/biClient.types";
import { ColumnField } from "../../../Types";
import GeneralFieldOptionPopup from "./GeneralFieldOptionPopup";

interface Props {
  anchorEl: HTMLElement;
  field: ColumnField;
  saveChanges: (changes: Partial<PivotFieldConfiguration>, causeClosing: boolean) => void;
  cancel: () => void;
  onRemove: () => void;
  onUpdateCustomLabel: (name: string | undefined) => void;
}

const ColumnFieldOptionPopup = (props: Props) => {
  return <GeneralFieldOptionPopup {...props} />;
};

export default ColumnFieldOptionPopup;
