import React from "react";
import { BuilderContext } from "./types";
import PreviewComponent from "./preview/PreviewComponent";
import ViewContainer from "../common/ViewContainer";
import ViewerParameters from "../common/ViewerParameters";
import PreviewActions from "../common/PreviewActions";
import { isValueFieldHasParameters } from "../common/utilities/valueFieldUtils";
import useGridStateViewer from "./preview/hooks/useGridStateViewer";
import withExport from "./preview/hocs/withExport";

export default function PivotViewer() {
  const { conditionsArea, valuesArea } = React.useContext(BuilderContext);
  const state = useGridStateViewer();

  const fields = React.useMemo(
    () => conditionsArea.values.filter((v) => v.config.parameter === true),
    [conditionsArea.values]
  );

  const measuresToShow = React.useMemo(() => {
    return valuesArea.values.filter(isValueFieldHasParameters);
  }, [valuesArea.values]);

  const showParameters = React.useMemo(() => {
    return fields.length > 0 || measuresToShow.length > 0;
  }, [fields, measuresToShow]);

  return (
    <ViewContainer
      parameters={
        showParameters ? (
          <ViewerParameters
            conditionsArea={conditionsArea}
            measuresWithConditions={measuresToShow}
            updateMeasure={valuesArea.updateItemConfig}
          />
        ) : (
          <></>
        )
      }
      preview={<PreviewComponentWithExport state={state} />}
      actionPanel={<PreviewActions />}
    ></ViewContainer>
  );
}

const PreviewComponentWithExport = withExport(PreviewComponent);
