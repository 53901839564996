import { defined } from "../../../../../../shared/utilities/typeHelper";

export type Token = {
  tokenType: TokenType;
  value?: string | number;
  text?: string;
  startIndex: number;
  endIndex: number;
  error?: string;
  element?: HTMLElement;
};

export enum TokenType {
  Eof = 1,
  Add,
  Subtract,
  Multiply,
  Divide,
  Comma,
  OpenParens,
  CloseParens,
  Identifier,
  Number,
  Space,
  Unknown,
}

export type Tokenizer = {
  nextToken: () => Token;
};

export const createTokenizer = (input: string): Tokenizer => {
  const iterator = createIterator(input);
  let char = "";
  let startIndex = -1;

  const nextChar = () => {
    const result = iterator.nextChar();
    char = result.char;
    startIndex = result.index;
  };

  const nextToken = (): Token => {
    switch (char) {
      case "\0": {
        const token = { tokenType: TokenType.Eof, startIndex, endIndex: startIndex + 1 };
        nextChar();
        return token;
      }
      case "+": {
        const token = { tokenType: TokenType.Add, startIndex, endIndex: startIndex + 1, value: char };
        nextChar();
        return token;
      }
      case "-": {
        const token = { tokenType: TokenType.Subtract, startIndex, endIndex: startIndex + 1, value: char };
        nextChar();
        return token;
      }
      case "*": {
        const token = { tokenType: TokenType.Multiply, startIndex, endIndex: startIndex + 1, value: char };
        nextChar();
        return token;
      }
      case "/": {
        const token = { tokenType: TokenType.Divide, startIndex, endIndex: startIndex + 1, value: char };
        nextChar();
        return token;
      }
      case "(": {
        const token = { tokenType: TokenType.OpenParens, startIndex, endIndex: startIndex + 1, value: char };
        nextChar();
        return token;
      }
      case ")": {
        const token = { tokenType: TokenType.CloseParens, startIndex, endIndex: startIndex + 1, value: char };
        nextChar();
        return token;
      }
      case ",": {
        const token = { tokenType: TokenType.Comma, startIndex, endIndex: startIndex + 1, value: char };
        nextChar();
        return token;
      }
    }

    if (hasSpaceCharacter(char)) {
      const token = { tokenType: TokenType.Space, startIndex, endIndex: startIndex + 1, value: char };
      nextChar();
      return token;
    }

    if (isNumeric(char)) {
      let valueStr = "";
      const index = startIndex;
      while (isNumeric(char)) {
        valueStr += char;
        nextChar();
      }
      const value = parseInt(valueStr);
      return { tokenType: TokenType.Number, startIndex: index, endIndex: index + valueStr.length, value };
    }

    if (isLetter(char)) {
      let valueStr = "";
      const index = startIndex;
      while (isLetter(char) || isNumeric(char)) {
        valueStr += char;
        nextChar();
      }
      return {
        tokenType: TokenType.Identifier,
        startIndex: index,
        endIndex: index + valueStr.length,
        value: valueStr,
        text: normalizeVariableName(valueStr),
      };
    }

    if (isDoubleQuote(char)) {
      let valueStr = char;
      const index = startIndex;
      nextChar();
      while ((char as string) !== "\0") {
        valueStr += char;
        if (isDoubleQuote(char)) {
          nextChar();
          break;
        }
        nextChar();
      }
      return {
        tokenType: TokenType.Identifier,
        startIndex: index,
        endIndex: index + valueStr.length,
        value: valueStr,
        text: normalizeVariableName(valueStr),
      };
    }

    const token = { tokenType: TokenType.Unknown, startIndex, endIndex: startIndex + 1, value: char };
    nextChar();
    return token;
  };

  nextChar();

  return { nextToken };
};

const createIterator = (input: string) => {
  const length = input.length;
  let index = -1;

  const nextChar = () => {
    if (index < length - 1) {
      index++;
      return { char: defined(input[index]), index };
    }
    return { char: "\0", index };
  };

  return { nextChar };
};

const isNumeric = (char: string) => {
  return /^-?\d+$/.test(char);
};

const isLetter = (char: string) => {
  return char.match(/[a-zA-Z]/i);
};

const isDoubleQuote = (char: string) => {
  return char === '"';
};

export const isOperationToken = (tokenType: TokenType) => {
  const acceptedTypes = [TokenType.Add, TokenType.Subtract, TokenType.Multiply, TokenType.Divide];
  return acceptedTypes.includes(tokenType);
};

export const hasSpaceCharacter = (input: string) => {
  return /\s/.test(input);
};

export const normalizeVariableName = (input: string) => {
  let result = input;
  if (input.startsWith('"')) result = result.substring(1);
  if (input.endsWith('"')) result = result.substring(0, result.length - 1);
  return result;
};
