import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { SettingsArea } from "../../contexts/FieldsStateContext";

interface Props {
  area: SettingsArea;
}

export default function ToolTipItem({ area }: Props) {
  const showTooltip = React.useMemo(() => isShowToolTip(area), [area]);

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography color={(theme) => theme.palette.text.secondary}>Show Tooltip</Typography>
      <HorizontalFill />
      <Switch checked={showTooltip} onChange={(_, value) => area.update({ showTooltip: value })} />
    </Stack>
  );
}

export function isShowToolTip(area: SettingsArea) {
  return area.settings?.showTooltip === true;
}
