import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import { getLinearSettings } from "../../contexts/FieldsStateContext.types";

interface Props {
  area: SettingsArea;
}

export default function ShowLabelItem({ area }: Props) {
  const showLabel = React.useMemo(() => isShowArgumentLabel(area), [area]);

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography color={(theme) => theme.palette.text.secondary}>Show Label</Typography>
      <HorizontalFill />
      <Switch checked={showLabel} onChange={(_, value) => area.updateArgumentAxis({ showLabel: value })} />
    </Stack>
  );
}

export function isShowArgumentLabel(area: SettingsArea) {
  return getLinearSettings(area.settings)?.argumentAxis?.showLabel !== false;
}
