import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Button, Dialog, Divider, Grid, Typography } from "@mui/material";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import { MeasureDescriptor } from "../../../../api/biApi.types";
import FormulaContainer from "./FormulaContainer";
import { useCustomMeasure } from "./hooks/useCustomMeasure";
import Properties from "./properties/Properties";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import AuthorizedBox from "../../../../../shared/components/AuthorizedBox";

interface Props {
  measure: MeasureDescriptor;
  onClose: () => void;
  onEdit?: (measure: MeasureDescriptor) => void;
}

const ExplainMeasureDialog = ({ measure, onClose, onEdit }: Props) => {
  const state = useCustomMeasure({ measure });

  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          width: "100%",
          height: "100%",
          borderRadius: "4px",
          maxWidth: "1000px",
          maxHeight: "700px",
        },
      }}
      onClose={onClose}
    >
      <Grid container sx={{ alignItems: "center", px: 3, py: 2, justifyContent: "space-between" }}>
        <Typography variant="h6">
          {state.caption}{" "}
          {!state.isConfigurable && (
            <Typography component={"span"} color="text.secondary">
              (System measure)
            </Typography>
          )}
        </Typography>
        <CloseIconButton onClick={onClose} />
      </Grid>
      <Divider />
      <FormulaContainer
        state={state}
        measure={measure}
        editable={false}
        showCalculatedBy
        sx={{ pt: 2, pb: 2, px: 3 }}
      />
      <Divider />
      <ScrollableFlexContainer>
        <Properties editable={false} state={state} />
      </ScrollableFlexContainer>
      <Divider />
      <Grid container sx={{ justifyContent: "space-between", alignItems: "center", py: 2, px: 3, gap: 1 }}>
        <AuthorizedBox permissions={["ManageCustomMeasures"]}>
          {measure.custom && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onEdit?.(measure)}
              startIcon={<EditOutlinedIcon />}
            >
              Edit Measure
            </Button>
          )}
        </AuthorizedBox>
        <Button variant="contained" onClick={onClose} sx={{ ml: "auto" }}>
          Close
        </Button>
      </Grid>
    </Dialog>
  );
};

export default ExplainMeasureDialog;
