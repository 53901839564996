import { TableField } from "../hooks/TableField";

export const updateTableField = <T>(
  key: "measure" | "dimension",
  item: TableField,
  changedValues: Partial<T>,
  fields: TableField[]
) => {
  const field = fields.find((field) => field.guid === item.guid);
  if (field !== undefined) {
    const copy = [...fields];
    const index = copy.indexOf(field);
    const subField = field[key] as unknown as T;
    const updatedField: TableField = { ...field, [key]: { ...subField, ...changedValues } };
    copy[index] = updatedField;
    return copy;
  }
  return fields;
};

export const updateTableFieldsOrder = (fields: TableField[], guids: string[]) => {
  return guids
    .map((guid) => {
      return fields.find((field) => field.guid === guid);
    })
    .filter((field): field is TableField => !!field);
};
