import { Grid, Stack, Switch, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import { CellDrillDownInfoBase } from "../../../../api/biApi.types";
import { DrillDownState } from "../../../../hooks/useDrillDownState";
import Conditions from "../../pivot/table/conditions/Conditions";
import { DrillDownConfiguration } from "../../../../store/DrillDownConfigurationState";

interface Props {
  state: DrillDownState;
  configuration: DrillDownConfiguration;
  info: CellDrillDownInfoBase;
}

export default function ReadonlyConditions({ state, configuration, info }: Props) {
  const [detailedView, setDetailedView] = React.useState(true);

  if (!configuration || !info?.conditions || info.conditions.length === 0) {
    return <></>;
  }

  return (
    <Grid sx={{ flexDirection: "column" }}>
      <Stack direction={"row"} alignItems="center" gap={0.5}>
        <Typography variant="subtitle2">Conditions</Typography>
        <HorizontalFill />
        <Typography>Detailed View</Typography>
        <Switch checked={detailedView} onChange={(_, value) => setDetailedView(value)}></Switch>
      </Stack>
      <Grid container sx={{ pt: 2 }}>
        <Conditions
          fields={state.conditions}
          detailed={detailedView}
          readonly
          canBeRemoved={false}
          onRemoveFields={state.actions.removeCondition}
          onFieldChanged={state.actions.updateCondition}
        />
      </Grid>
    </Grid>
  );
}
