import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow disableInteractive classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.text.primary,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.text.primary,
    pointerEvents: "none",
  },
}));

export default TooltipWrapper;
