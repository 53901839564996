import { Switch, Typography } from "@mui/material";
import { MenuItemStyled } from "../../../../../../shared/components/MenuItemStyled";

interface Props {
  caption: string;
  value: boolean;
  onClick: () => void;
}

export default function SwitchMenuItem({ caption, value, onClick }: Props) {
  return (
    <MenuItemStyled sx={{ display: "flex", flex: 1, flexDirection: "row" }} onClick={onClick}>
      <Typography sx={{ display: "flex", flex: 1 }}>{caption}</Typography>
      <Switch checked={value} />
    </MenuItemStyled>
  );
}
