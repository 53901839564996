import CopyAllIcon from "@mui/icons-material/CopyAll";
import { Box, IconButton, SxProps, Tooltip } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

interface Props {
  visible: boolean;
  values: string[];
  sx?: SxProps;
}

export const CopyFilter = ({ visible, values, sx }: Props) => {
  const [tooltip, setTooltip] = useState("Copy");
  const stringToCopy = useMemo(() => {
    return values.join(", ");
  }, [values]);

  const copyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(stringToCopy);
    setTooltip("Copied!");
    setTimeout(() => {
      setTooltip("Copy");
    }, 2000);
  }, [stringToCopy, setTooltip]);

  return (
    <Box
      sx={{
        position: "absolute",
        right: 0,
        height: "100%",
        display: "flex",
        alignItems: "center",
        visibility: visible ? "visible" : "hidden",
        opacity: visible ? 1 : 0,
        transition: "all 0.3s ease",
        ...sx,
      }}
    >
      <Tooltip title={tooltip} placement="right-end">
        <IconButton onClick={copyToClipboard}>
          <CopyAllIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default CopyFilter;
