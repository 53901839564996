import { createContext, PropsWithChildren, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { ExportStateReturnType } from "../hooks/exportState";

export const ExportStateContext = createContext<ExportStateReturnType | undefined>(undefined);

export const ExportStateContextProvider = (props: PropsWithChildren<ExportStateReturnType>) => (
  <ExportStateContext.Provider value={{ ...props }}>{props.children}</ExportStateContext.Provider>
);

export const useExportStateContext = () => {
  const context = useContext(ExportStateContext);
  return defined(context);
};
