import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Report, ReportTemplate, ReportType } from "../../../../../shared/reporting/api/biClient.types";
import { currentReportActions, selectReportConfiguration } from "../../../../store/currentReportSlice";
import { createDefaultConfiguration } from "../../utils/isConfigurationValid";

interface Props {
  report: Report | ReportTemplate;
}
const useReportTypeChange = ({ report }: Props) => {
  const dispatch = useDispatch();
  const currentConfiguration = useSelector(selectReportConfiguration);

  const onReportTypeChanged = useCallback(
    (reportType: ReportType) => {
      const configuration = createDefaultConfiguration(reportType);
      configuration.conditions = currentConfiguration?.conditions ?? report?.configuration.conditions ?? [];

      dispatch(currentReportActions.updateReport({ reportType }));
      dispatch(currentReportActions.updateReportConfiguration(configuration));
      dispatch(currentReportActions.refreshSession());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentConfiguration?.conditions, report?.configuration.conditions]
  );

  return { onReportTypeChanged };
};

export default useReportTypeChange;
