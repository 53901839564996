import { useCallback, useMemo, useState } from "react";
import { ReportTemplate, ReportTemplateDto } from "../../../../shared/reporting/api/biClient.types";
import CompanyTemplatesDialog from "../../../../shared/reporting/components/reportTemplates/dialogs/CompanyTemplatesDialog";
import { getCreateReportFromTemplateUrl } from "./utilities/editReportUrl";
import ReportTemplatesButton from "../../../../shared/reporting/components/reportTemplates/ReportTemplatesButton";
import { useNavigate, useParams } from "react-router-dom";
interface Props {
  templates: ReportTemplateDto[];
  clientCode: string;
}
export default function ReportTemplates({ templates, clientCode }: Props) {
  const [openTemplates, setOpenTemplate] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleQuickUse = useCallback(
    (template: ReportTemplate) => {
      setOpenTemplate(false);
      navigate(getCreateReportFromTemplateUrl(clientCode, template.reportId, id || ""));
    },
    [id, navigate, clientCode]
  );

  const showBadge = useMemo(() => {
    return templates.some((t) => t.isNew);
  }, [templates]);

  const doOpenTemplates = useCallback(() => {
    setOpenTemplate(true);
  }, []);

  const doHideTemplates = useCallback(() => {
    setOpenTemplate(false);
  }, []);

  return (
    <>
      <ReportTemplatesButton onClick={doOpenTemplates} showBadge={showBadge} />
      {openTemplates && (
        <CompanyTemplatesDialog
          templates={templates}
          onClose={doHideTemplates}
          hideCreateBlankReport={true}
          onQuickUse={handleQuickUse}
        />
      )}
    </>
  );
}
