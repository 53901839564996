import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import useDebounce from "../../../../../shared/hooks/useDebounce";

interface Props {
  title?: string;
  placeholder?: string;
  onUpdate: (value: string | undefined) => void;
}

export default function TitleItem({ title, placeholder, onUpdate }: Props) {
  const [dirtyTitle, setDirtyTitle] = React.useState(title);

  const handleTitleChanged = useDebounce((value: string | undefined) => onUpdate(value), 500);

  React.useEffect(() => {
    handleTitleChanged(dirtyTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyTitle]);

  return (
    <Stack flexDirection="row" alignItems="center" gap={4}>
      <Typography color={(theme) => theme.palette.text.secondary}>Title</Typography>
      <TextField
        sx={{
          flex: 1,
          input: {
            py: 0.7,
            fontSize: 13,
          },
        }}
        value={dirtyTitle}
        placeholder={placeholder}
        onChange={(event) => setDirtyTitle(event.target.value)}
      />
    </Stack>
  );
}
