import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGridPremium, GridColDef, GridRowClassNameParams, useGridApiRef } from "@mui/x-data-grid-premium";
import { useCallback, useEffect, useMemo, useState } from "react";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";
import { MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import { AccountType, CellDrillDownInfoTypes, ChartOfAccountsDataItem } from "../../../../../api/biApi.types";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";
import { useChartOfAccountsContext } from "../../chartOfAccounts/contexts/ChartOfAccountsContext";
import ToolBar from "./ToolBar";

interface LedgerAccountsListProps {
  info: CellDrillDownInfoTypes | undefined;
  configuration: DrillDownConfiguration;
  items: ChartOfAccountsDataItem[];
  ledger: MeasureUnitTable;
  onDrillDown: (info: CellDrillDownInfoTypes | undefined, row: ChartOfAccountsDataItem) => void;
}

export default function LedgerAccountsList({
  info,
  configuration,
  items,
  ledger,
  onDrillDown,
}: LedgerAccountsListProps) {
  const classes = useStyles();

  const rowHeight = 32;
  const { selectedAccounts, scrollTargetAccount } = useChartOfAccountsContext();
  const [activeAccountScroll, setActiveAccountScroll] = useState<{
    accountNo: string;
  }>();

  const [showOnlyIncluded, setShowOnlyIncluded] = useState(true);

  const filteredAccounts = useMemo(() => {
    if (showOnlyIncluded) {
      return items.filter((item) => item.included);
    }
    return items;
  }, [items, showOnlyIncluded]);

  const apiRef = useGridApiRef();

  useEffect(() => {
    setActiveAccountScroll(scrollTargetAccount);
  }, [scrollTargetAccount]);

  const columns = useMemo(() => {
    const result: GridColDef<ChartOfAccountsDataItem>[] = [];
    if (items) {
      result.push({ field: "no", headerName: "No.", width: 100, valueGetter: (_, row) => row.accountNo });
      result.push({
        field: "name",
        headerName: "Name",
        minWidth: 70,
        flex: 1,
        valueGetter: (_, row) => row.accountName,
        renderCell(params) {
          return (
            <Typography pl={params.row.level} variant={getNameCellVariant(params.row.accountType)}>
              {params.value}
            </Typography>
          );
        },
      });
      if (ledger === MeasureUnitTable.Gl) {
        result.push({
          field: "ib",
          headerName: "Income/Balance",
          minWidth: 70,
          flex: 0.8,
          valueGetter: (_, row) => row.incomeBalance,
        });
      }
      result.push({
        field: "acn",
        headerName: "Account Category Name",
        minWidth: 70,
        flex: 0.8,
        valueGetter: (_, row) => row.accountCategory,
      });
      result.push({
        field: "as",
        headerName: "Account Subcategories",
        minWidth: 70,
        flex: 0.8,
        valueGetter: (_, row) => row.accountSubcategory,
      });
      result.push({
        field: "at",
        headerName: "Account Type",
        width: 110,
        valueGetter: (_, row) => row.accountType,
      });
      result.push({
        field: "amountFormatted",
        headerName: "Amount",
        headerAlign: "right",
        align: "right",
        width: 150,
        renderCell: (params) => {
          if (params.row.amountFormatted === undefined) return null;
          return (
            <Typography
              sx={{ ":hover": { textDecoration: "underline", cursor: "pointer" } }}
              onClick={() => onDrillDown(info, params.row)}
            >
              {params.row.amountFormatted}
            </Typography>
          );
        },
      });
    }
    return result.map((col) => ({ ...col, sortable: false }));
  }, [info, items, ledger, onDrillDown]);

  useEffect(() => {
    const current = apiRef.current;
    if (current && scrollTargetAccount) {
      current.scrollToIndexes({
        rowIndex: current.getRowIndexRelativeToVisibleRows(scrollTargetAccount.accountNo),
      });

      setTimeout(() => {
        setActiveAccountScroll((prev) => (prev?.accountNo === scrollTargetAccount.accountNo ? undefined : prev));
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTargetAccount]);

  const getRowClassName = useCallback(
    (params: GridRowClassNameParams<ChartOfAccountsDataItem>) => {
      if (params.id === activeAccountScroll?.accountNo) {
        return classes.animatedItem;
      }
      return "";
    },
    [activeAccountScroll, classes.animatedItem]
  );

  return (
    <Grid container sx={{ flex: 1, width: "unset", flexDirection: "column", pl: "1.3rem", py: 2, pr: 3, gap: 2 }}>
      <ToolBar
        configuration={configuration}
        table={ledger}
        showOnlyIncluded={showOnlyIncluded}
        onShowOnlyIncludedChange={setShowOnlyIncluded}
      />
      <ScrollableFlexContainer scrollContainerSx={{ mb: 1, minHeight: 1 }}>
        <DataGridPremium<ChartOfAccountsDataItem>
          apiRef={apiRef}
          rowHeight={rowHeight}
          columnHeaderHeight={32}
          columns={columns}
          rowSelectionModel={selectedAccounts}
          rows={filteredAccounts}
          getRowId={getRowId}
          getRowClassName={getRowClassName}
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnMenu
          hideFooter
        ></DataGridPremium>
      </ScrollableFlexContainer>
    </Grid>
  );
}

const getNameCellVariant = (type: AccountType) => {
  switch (type) {
    case AccountType.Heading:
    case AccountType.BeginTotal:
    case AccountType.Total:
    case AccountType.EndTotal:
      return "subtitle2";
    default:
      return "body1";
  }
};

const useStyles = makeStyles(() => {
  return {
    animatedItem: {
      animation: "$blinking .15s steps(4) 4",
    },
    "@keyframes blinking": { "50%": { boxShadow: "0 0 5px #00A866" } },
  };
});

function getRowId(row: ChartOfAccountsDataItem) {
  return row.accountNo;
}
