import { SvgIcon, SvgIconProps } from "@mui/material";

const DataRefreshIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 81, height: 81 }} viewBox="0 0 81 81" {...props}>
      <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M42.7192 27.1641H37.8999V40.4653H42.7192V27.1641Z" fill="#C7DBE5" />
          <path
            d="M62.5747 36.418H17.9482V54.2493H37.9964V58.1047V63.9842H42.8157V58.1047V54.2493H62.6711V36.418H62.5747ZM57.7555 49.43H22.7675V41.2372H57.7555V49.43Z"
            fill="#C7DBE5"
          />
          <path
            d="M53.8359 46.5078C54.4421 45.3832 54.0219 43.9802 52.8974 43.374C51.7729 42.7678 50.3699 43.188 49.7637 44.3125C49.1575 45.437 49.5776 46.8401 50.7021 47.4463C51.8267 48.0525 53.2297 47.6323 53.8359 46.5078Z"
            fill="#C7DBE5"
          />
          <path
            d="M62.5747 31.9837H17.9482V14.1523H62.5747V31.9837ZM22.7675 27.1644H57.7555V18.9716H22.7675V27.1644Z"
            fill="#C7DBE5"
          />
          <path
            d="M53.8471 24.2373C54.4534 23.1127 54.0332 21.7097 52.9087 21.1035C51.7841 20.4973 50.3811 20.9175 49.7749 22.042C49.1687 23.1665 49.5889 24.5696 50.7134 25.1758C51.8379 25.782 53.2409 25.3618 53.8471 24.2373Z"
            fill="#C7DBE5"
          />
          <path d="M68.6469 61.0918H11.9722V65.9111H68.6469V61.0918Z" fill="#C7DBE5" />
          <mask id="mask0_6121_245112" maskUnits="userSpaceOnUse" x="40" y="41" width="41" height="41">
            <rect x="40.5" y="41.5" width="40" height="40" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_6121_245112)">
            <path
              d="M60.5832 74.8337C56.8609 74.8337 53.6943 73.542 51.0832 70.9587C48.4721 68.3753 47.1665 65.2225 47.1665 61.5003V61.2087L44.4998 63.8753L42.1665 61.542L48.8332 54.8753L55.4998 61.542L53.1665 63.8753L50.4998 61.2087V61.5003C50.4998 64.2781 51.479 66.6392 53.4373 68.5837C55.3957 70.5281 57.7776 71.5003 60.5832 71.5003C61.3054 71.5003 62.0137 71.417 62.7082 71.2503C63.4026 71.0837 64.0832 70.8337 64.7498 70.5003L67.2498 73.0003C66.1943 73.6114 65.111 74.0698 63.9998 74.3753C62.8887 74.6809 61.7498 74.8337 60.5832 74.8337ZM72.1665 68.1253L65.4998 61.4587L67.8332 59.1253L70.4998 61.792V61.5003C70.4998 58.7225 69.5207 56.3614 67.5623 54.417C65.604 52.4725 63.2221 51.5003 60.4165 51.5003C59.6943 51.5003 58.9859 51.5837 58.2915 51.7503C57.5971 51.917 56.9165 52.167 56.2498 52.5003L53.7498 50.0003C54.8054 49.3892 55.8887 48.9309 56.9998 48.6253C58.111 48.3198 59.2498 48.167 60.4165 48.167C64.1387 48.167 67.3054 49.4587 69.9165 52.042C72.5276 54.6253 73.8332 57.7781 73.8332 61.5003V61.792L76.4998 59.1253L78.8332 61.4587L72.1665 68.1253Z"
              fill="#00A866"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_6121_245112">
            <rect width="80" height="80" fill="white" transform="translate(0.5 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
export default DataRefreshIcon;
