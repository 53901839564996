import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { Collapse, Grid, IconButton, Typography } from "@mui/material";
import { Fragment, memo, useState } from "react";
import { ChartOfAccountsItem } from "../../../../../api/biApi.types";

interface Props {
  text?: string;
  name?: string;
  conditionName: string;
  chartOfAccountItems?: ChartOfAccountsItem[];
}
const FilterItemInner = ({ text, name, conditionName, chartOfAccountItems }: Props) => {
  const [conditionExpanded, setConditionExpanded] = useState(false);

  const subItems =
    conditionName === "AccountCategory"
      ? (chartOfAccountItems || []).filter((item) => item.accountCategory === text)
      : [];

  return (
    <Fragment key={`FilterItem${name}`}>
      <Grid
        container
        sx={(theme) => ({
          justifyContent: "space-between",
          "& .MuiSvgIcon-root": {
            color: theme.palette.secondary.light,
          },
        })}
      >
        <Grid container sx={{ alignItems: "center", height: "28px", width: "auto" }}>
          <IconButton
            sx={{ visibility: subItems.length > 0 ? "visible" : "hidden" }}
            color="secondary"
            onClick={() => setConditionExpanded((prev) => !prev)}
          >
            {conditionExpanded ? <ArrowDropDownRoundedIcon /> : <ArrowRightRoundedIcon />}
          </IconButton>
        </Grid>
        <Grid
          container
          sx={(theme) => ({
            alignItems: "center",
            height: "28px",
            flex: 1,
            color: theme.palette.text.primary,
            "& .MuiGrid-item": { flex: 0.5 },
          })}
        >
          <Grid item>
            <Typography variant="caption">{text?.toString()}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">{name}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Collapse
        orientation="vertical"
        in={conditionExpanded}
        sx={{
          "& .MuiCollapse-wrapperInner": {
            display: "flex",
            flexDirection: "column",
          },
          width: "100%",
        }}
      >
        {subItems.map((item, i) => (
          <Grid
            key={`subitem-${i}`}
            container
            sx={(theme) => ({
              alignItems: "center",
              color: theme.palette.text.secondary,
              paddingLeft: 4.75,
              pr: 1,
              flexWrap: "nowrap",
              "& .MuiGrid-item": { flex: 1, wordBreak: "break-word", py: 0.5 },
            })}
          >
            <Grid item>
              <Typography variant="caption">{item.accountNo}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{item.accountName}</Typography>
            </Grid>
          </Grid>
        ))}
      </Collapse>
    </Fragment>
  );
};

const FilterItem = memo(FilterItemInner, (prev, next) => prev.name === next.name && prev.text === next.text);

export default FilterItem;
