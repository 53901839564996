import { ClientInfoBase } from "../../../../api/clientTypes";
import { AccessPermission } from "../../../../api/types";
import { ClientInfo, FundType } from "../../../api/biClient.types";

export function getAccessibleCompanies(
  clients: ClientInfoBase[],
  permissions: { clientCode: string; permissions: AccessPermission[] }[],
  clientCode: string
) {
  return clients.filter((c) => {
    const companyPermissions = permissions.find((p) => p.clientCode === c.clientCode);
    return (
      c.type === "FundManager" &&
      c.managedByClients.includes(clientCode) &&
      companyPermissions?.permissions.includes("ManageReports")
    );
  }) as ClientInfo[];
}

export const formatFundType = (fundType: FundType): string => {
  switch (fundType) {
    case FundType.FundOfFunds:
      return "Fund of Funds";
    case FundType.PrivateEquity:
      return "Private Equity";
    case FundType.VentureCapital:
      return "Venture Capital";
    default:
      return "";
  }
};
