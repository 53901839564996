import { useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { ReportAccessType, UserBasedAuthorization, UserForSharing } from "../../api/biClient.types";
import UserAvatar from "../../../components/UserAvatar";
import AccessModeMenuLabel from "./AccessModeMenuLabel";
import HorizontalFill from "../../../components/HorizontalFill";
import { CurrentUser } from "./ShareReport.types";
import { getAllowedAccessByPermisions } from "./utils";

interface AuthorizedUsersProps {
  currentUser: CurrentUser;
  currentUserAccess: ReportAccessType;
  allUsers: UserForSharing[];
  authorizedUsers: UserBasedAuthorization[];
  disabled: boolean;
  onAccessChanged: (userId: string, access: ReportAccessType) => void;
  onRemoveUser: (userId: string) => void;
}
export default function AuthorizedUsers({
  currentUser,
  currentUserAccess,
  authorizedUsers,
  allUsers,
  disabled,
  onAccessChanged,
  onRemoveUser,
}: AuthorizedUsersProps) {
  const getUserInfo = useCallback(
    (userId: string) => {
      return allUsers.find((u) => u.userId === userId);
    },
    [allUsers]
  );

  const items = useMemo(() => {
    const result: UserItem[] = [];

    authorizedUsers.forEach((authUser) => {
      const user = getUserInfo(authUser.userId);
      if (!user) {
        return;
      }

      if (authUser.access === ReportAccessType.Owner) {
        result.unshift({
          userId: user.userId,
          name: user.name,
          email: user.email,
          access: authUser.access,
          allowedAccesses: getAllowedAccessByPermisions(user.permissions, currentUserAccess),
          isCurrentUser: user.userId === currentUser.id,
        });
      } else {
        result.push({
          userId: user.userId,
          name: user.name,
          email: user.email,
          access: authUser.access,
          allowedAccesses: getAllowedAccessByPermisions(user.permissions, currentUserAccess),
          isCurrentUser: user.userId === currentUser.id,
        });
      }
    });

    return result;
  }, [authorizedUsers, currentUser.id, currentUserAccess, getUserInfo]);

  return (
    <AuthorizedUsersList
      items={items}
      disabled={disabled}
      onAccessChanged={onAccessChanged}
      onRemoveUser={onRemoveUser}
    />
  );
}

type UserItem = {
  userId: string;
  name: string;
  email: string;
  access: ReportAccessType;
  isCurrentUser: boolean;
  allowedAccesses: ReportAccessType[];
};

interface AuthorizedUsersListProps {
  items: UserItem[];
  disabled: boolean;
  onAccessChanged: (userId: string, access: ReportAccessType) => void;
  onRemoveUser: (userId: string) => void;
}
function AuthorizedUsersList({ items, disabled, onAccessChanged, onRemoveUser }: AuthorizedUsersListProps) {
  return (
    <Stack spacing={2} overflow={"auto"}>
      {items.map((user) => {
        return (
          <Stack key={user.userId} direction={"row"} spacing={1.5} alignItems={"center"}>
            <UserAvatar userName={user.name} size={"medium"} />
            <Stack>
              <Stack direction={"row"} spacing={0.5}>
                <Typography variant="body2">{user.name}</Typography>
                {user.isCurrentUser ? (
                  <Typography variant="body2" color={"secondary"}>
                    (You)
                  </Typography>
                ) : (
                  ""
                )}
              </Stack>
              <Typography variant="caption" color="textSecondary">
                {user.email}
              </Typography>
            </Stack>
            <HorizontalFill />
            <AccessModeMenuLabel
              access={user.access}
              allowedAccesses={user.allowedAccesses}
              hasRemove
              disabled={disabled}
              onSelect={(access) => onAccessChanged(user.userId, access)}
              onRemove={() => onRemoveUser(user.userId)}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}
