import React from "react";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import { getLinearSettings, isLinearSettings } from "../../contexts/FieldsStateContext.types";
import SwitchField from "../../../../common/SwitchField";

interface Props {
  area: SettingsArea;
}

export default function RotateItem({ area }: Props) {
  const { chart: locale } = useLocalization();
  const supported = React.useMemo(() => isLinearSettings(area.settings), [area]);
  const rotated = React.useMemo(() => isRotated(area), [area]);

  if (!supported) {
    return <></>;
  }

  return (
    <SwitchField
      label="Rotated"
      checked={rotated}
      onChange={(newValue) => area.update({ rotated: newValue })}
      tooltipText={locale.rotated_property_explanation}
      typographyProps={{ color: "text.secondary" }}
    />
  );
}

export function isRotated(area: SettingsArea) {
  return getLinearSettings(area.settings)?.rotated === true;
}
