import { ReportTemplate } from "../../../shared/reporting/api/biClient.types";
import { EditorProps } from "../builder/Types";
import TemplateTypeSelector from "./TemplateTypeSelector";

interface Props {
  Editor: React.ComponentType<EditorProps>;
  template: ReportTemplate;
}

const TemplateEditor = ({ Editor, template }: Props) => (
  <Editor report={template} ReportTypeSelector={<TemplateTypeSelector template={template} />} exportAllowed={false} />
);

export default TemplateEditor;
