import { Series } from "devextreme-react/chart";
import PieChart from "devextreme-react/pie-chart";
import { ChartProps } from "./ChartProps";
import ChartCore, { getBasicLegend, getBasicSerieLabel } from "./ChartCore";
import { useFieldsStateContext } from "./contexts/FieldsStateContext";
import { getThickness } from "./style/chart/ThicknessItem";
import formatArgumentLabel from "./utilities/argumentFormat";
import { useCallback } from "react";

export default function Doughnut(props: ChartProps) {
  const { valuesArea, settingsArea } = useFieldsStateContext();
  const updateRef = useCallback(
    (ref: PieChart) => {
      props.exportFunc(ref?.instance.exportTo.bind(ref.instance));
    },
    [props]
  );
  const renderSeries = () => {
    const argument = props.args[0];
    if (argument === undefined) return <></>;
    return props.values.map((value) => {
      return (
        <Series
          key={value.config.guid}
          argumentField={argument.meta.name}
          valueField={value.config.guid}
          name={value.config.guid}
          label={getBasicSerieLabel(value.config.guid, settingsArea, valuesArea, props.data)}
        ></Series>
      );
    });
  };

  return (
    <ChartCore {...props}>
      <PieChart
        ref={updateRef}
        dataSource={props.dataSource}
        type="doughnut"
        legend={{
          ...getBasicLegend(settingsArea),
          customizeText: ({ pointName }) => formatArgumentLabel(props.args, pointName, props.data),
        }}
        innerRadius={getThickness(settingsArea)}
      >
        {renderSeries()}
      </PieChart>
    </ChartCore>
  );
}
