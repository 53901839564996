import { Box } from "@mui/material";

interface Props {
  hide: boolean;
}

const ColumnsPlaceHolder = ({ hide }: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: hide ? "none" : "flex",
        boxSizing: "border-box",
        height: `37px`,
        pointerEvents: "none",
      }}
    />
  );
};

export default ColumnsPlaceHolder;
