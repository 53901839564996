import EventNoteIcon from "@mui/icons-material/EventNote";
import { Box, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { MetaIcons, TemplateFieldsItem } from "../../api/biClient.types";
import FunctionIcon from "../../icons/FunctionIcon";
import HashIcon from "../../icons/HashIcon";
import NavigationParentIcon from "../../icons/NavigationParentIcon";
import SigmaIcon from "../../icons/SigmaIcon";

interface Props {
  fields: { [key: string]: TemplateFieldsItem[] };
}
export default function TemplateInfoFields({ fields }: Props) {
  return (
    <Stack gap={2}>
      <Typography variant="subtitle1">Fields</Typography>
      <Stack gap={2}>
        {Object.entries(fields).map(([key, items]) => (
          <Container key={key} title={key} items={Object.values(items).flat()} />
        ))}
      </Stack>
    </Stack>
  );
}
interface ContainerProps {
  title: string;
  items: TemplateFieldsItem[];
}
function Container({ title, items }: ContainerProps) {
  if (items.length === 0) return null;
  return (
    <Stack gap={0.5}>
      <Typography variant="subtitle1" textTransform={"capitalize"}>
        {title}
      </Typography>
      {items.map((item, index) => (
        <RowContainer key={index} icon={getIcon(item)} name={item.name} subItems={item.subItems} />
      ))}
    </Stack>
  );
}

type FieldItemProps = {
  name: string;
  icon: JSX.Element;
  subItems?: TemplateFieldsItem[];
};
function RowContainer({ name, icon, subItems }: FieldItemProps) {
  return (
    <Stack
      sx={(theme) => ({
        width: 245,
        px: 0.75,
        border: `1px solid ${theme.palette.action.disabledBackground}`,
        borderRadius: 1,
      })}
    >
      {RowItem({ name, icon, subItems })}
      {subItems &&
        subItems.map((i, index) => (
          <Fragment key={index}>{RowItem({ name: i.name, icon: <NavigationParentIcon /> })}</Fragment>
        ))}
    </Stack>
  );
}

function RowItem({ name, icon, subItems }: FieldItemProps) {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        gap: 1,
        py: 0.5,
        borderBottom:
          subItems && subItems.length > 0 ? `1px dashed ${theme.palette.action.disabledBackground}` : "none",
      })}
    >
      <Box
        sx={(theme) => ({
          width: 20,
          height: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          svg: {
            color: theme.palette.action.active,
          },
        })}
      >
        {icon}
      </Box>
      <Typography>{name}</Typography>
    </Box>
  );
}

const getIcon = (field: TemplateFieldsItem) => {
  switch (field.icon) {
    case MetaIcons.Hash:
      return <HashIcon />;
    case MetaIcons.Calendar:
      return <EventNoteIcon />;
    case MetaIcons.Sigma:
      return <SigmaIcon />;
    case MetaIcons.Function:
      return <FunctionIcon />;
    default:
      return <SigmaIcon />;
  }
};
