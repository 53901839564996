import React, { PropsWithChildren, useContext } from "react";
import { ClientInfoBase } from "../../shared/api/clientTypes";
import { ClientInfo } from "../../shared/reporting/api/biClient.types";
import { defined } from "../../shared/utilities/typeHelper";

export interface Client {
  clientCode: string;
  clientInfo: ClientInfo;
  clients: ClientInfoBase[];
  setClientCode: (clientCode: string) => void;
}

const ClientContext = React.createContext<Client | undefined>(undefined);

interface Props {
  client: Client;
}

export const ClientContextProvider = ({ client, children }: PropsWithChildren<Props>) => (
  <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
);

export const useClientContext = () => {
  const client = useContext(ClientContext);
  return defined(client);
};
