import { Button, Typography } from "@mui/material";
import InfoPage from "../components/infoPage/InfoPage";
import { useLocalization } from "../hooks/useLocalization";
import LoggedOutIcon from "../icons/LoggedOutIcon";

interface Props {
  href: string;
  hideLoginRedirectButton?: boolean | undefined;
}

const LoggedOut = ({ href, hideLoginRedirectButton }: Props) => {
  const locale = useLocalization();
  const page = locale.pages.loggedout;

  return (
    <InfoPage
      Icon={<LoggedOutIcon />}
      title={page.title}
      subtitle={<Typography color="text.secondary">{page.subtitle}</Typography>}
    >
      {!hideLoginRedirectButton && (
        <Button variant="outlined" color="primary" href={href} sx={{ width: "10rem" }}>
          {page.button}
        </Button>
      )}
    </InfoPage>
  );
};

export default LoggedOut;
