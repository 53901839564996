import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { ReportType } from "../../../../shared/reporting/api/biClient.types";
import { useEnhancedBiApiClientProvider } from "../../../contexts/ApiClientProviderContext";
import { useLocalization } from "../../../hooks/useLocalization";
import useMetaData from "../../../hooks/useMetaData";
import { currentReportActions, selectCurrentReport } from "../../../store/currentReportSlice";
import { metaDataActions, selectMetaData } from "../../../store/metaDataSlice";
import { getExistingReportWithAuth } from "../../../utilities/defineReportDataHelper";
import DrillDownEntry from "../common/drillDown/DrillDownEntry";
import ReportNotFoundOrAccessForbidden from "../ReportNotFoundOrAccessForbidden";
import ViewReportPage from "./ViewReportPage";

const ViewRoot = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { metadata: locale } = useLocalization();
  const { loadMetaData } = useMetaData({ includeDictionaries: false });
  const metaData = useSelector(selectMetaData);
  const report = useSelector(selectCurrentReport);
  const { getReport, getReportAuthorizationInfo, getReportsUrl } = useEnhancedBiApiClientProvider();

  const [showReportNotFound, setShowReportNotFound] = useState(false);

  useEffect(() => {
    if (!metaData.loaded && report !== undefined) {
      loadMetaData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData.loaded, report]);

  useEffect(() => {
    if (id !== undefined) {
      getExistingReportWithAuth(
        getReport.bind(null, id, "run"),
        getReportAuthorizationInfo.bind(null, id),
        () => setShowReportNotFound(true),
        (report) => dispatch(currentReportActions.setReport(report)),
        (authorizationInfo) => dispatch(currentReportActions.setAuthorizationInfo(authorizationInfo))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (report?.reportType !== undefined) {
      dispatch(metaDataActions.updateReportType(report.reportType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.reportType]);

  if (id === undefined) {
    return <Navigate to={""} />;
  }

  if (showReportNotFound === true || (report && report.reportType !== ReportType.Tabular)) {
    return <ReportNotFoundOrAccessForbidden getReportsUrl={getReportsUrl} />;
  }

  if (!metaData.loaded || report === undefined) {
    return <InlineLoader text={locale.loading_label} />;
  }

  return (
    <Stack sx={{ bgcolor: "white", height: "100%", width: "100%" }}>
      <ViewReportPage report={report} />
      <DrillDownEntry />
    </Stack>
  );
};

export default ViewRoot;
