import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useCallback } from "react";
import { CalculateByField } from "../../../../../shared/reporting/api/biClient.types";
import TooltipWrapper from "../../../../../shared/components/TooltipWrapper";

interface Props {
  calculateBy: CalculateByField | undefined;
  disabled?: boolean;
  onChange: (calculateBy: CalculateByField) => void;
}

export default function CalculateByFieldControl({ calculateBy, disabled, onChange }: Props) {
  const handleCalculateByChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newCalculateBy: CalculateByField | null) => {
      if (newCalculateBy !== null) onChange(newCalculateBy);
    },
    [onChange]
  );

  return (
    <Grid container sx={{ alignItems: "center", justifyContent: "space-between", flexWrap: "nowrap" }}>
      <Box sx={{ display: "flex", flexGrow: 1, flexBasis: "30%" }}>
        <Typography noWrap color="secondary">
          Calculate Amount By
        </Typography>
      </Box>
      <ToggleButtonGroup
        exclusive
        color="primary"
        value={calculateBy}
        disabled={disabled}
        fullWidth
        sx={{ width: 208 }}
        onChange={handleCalculateByChange}
      >
        {availableCalculateByFields.map((item) => (
          <TooltipWrapper key={item.field} title={item.title}>
            <ToggleButton value={item.field} sx={{ py: 0.3, fontWeight: 400, textWrap: "nowrap" }}>
              {toCalculateByCaption(item.field)}
            </ToggleButton>
          </TooltipWrapper>
        ))}
      </ToggleButtonGroup>
    </Grid>
  );
}

const availableCalculateByFields = [
  { field: CalculateByField.Pcy, title: "Position Currency" },
  { field: CalculateByField.Lcy, title: "Local Currency" },
  { field: CalculateByField.AdditionalLcy, title: "Additional Reporting Currency" },
];

const toCalculateByCaption = (calcBy: CalculateByField): string => {
  switch (calcBy) {
    case CalculateByField.Lcy:
      return "LCY";
    case CalculateByField.Pcy:
      return "PCY";
    case CalculateByField.AdditionalLcy:
      return "ACY";
    case CalculateByField.Shares:
      return "Shares";
    default:
      return "";
  }
};
