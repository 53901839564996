import { useMemo, useState } from "react";
import ReportSelectorMenu from "./ReportSelectorMenu";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import UnsavedChangesDialog from "../../builder/backToReports/UnsavedChangesDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  currentReportActions,
  selectCurrentReport,
  selectIsReportChanged,
  selectReportConfiguration,
  selectReportEditMode,
} from "../../../store/currentReportSlice";
import { useClientContext } from "../../../contexts/ClientContext";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import { logError } from "../../../../shared/logging";
import { useLocalization } from "../../../hooks/useLocalization";
import { selectReports, setReports } from "../../../store/reportsSlice";
import ReportSelectorButton from "./ReportSelectorButton";
import { useNavigate } from "react-router-dom";
import { replaceReportIdInUrl } from "./reportSelectorHelpers";
import { ReportAccessType, ReportGroup } from "../../../../shared/reporting/api/biClient.types";
import { checkAccess } from "../../../../shared/reporting/components/sharing/utils";
import { useEnhancedBiApiClientProvider } from "../../../contexts/ApiClientProviderContext";

interface Props {
  requiredAccess: ReportAccessType;
  groups: Record<string, ReportGroup[]> | undefined;
  isGrouped: boolean;
}
const ReportSelector = ({ requiredAccess, groups, isGrouped }: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const { clientCode, clientInfo } = useClientContext();
  const { getReports, updateReport } = useEnhancedBiApiClientProvider();

  const { report: locale } = useLocalization();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const report = useSelector(selectCurrentReport);
  const reportConfiguration = useSelector(selectReportConfiguration);
  const isReportChanged = useSelector(selectIsReportChanged);
  const editMode = useSelector(selectReportEditMode);
  const reports = useSelector(selectReports);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
  const [isSavingReport, setSavingReport] = useState(false);
  const [navigateToReportId, setNavigateToReportId] = useState<string>();
  const isMenuOpened = Boolean(anchorEl);

  const eligibleReports = useMemo(
    () => reports.filter((r) => checkAccess(r.authorization.access, requiredAccess)),
    [reports, requiredAccess]
  );

  const navigateToNewReport = (reportId: string) => {
    if (!report) return;

    const currentUrl = window.location.pathname;
    const prevReportId = report.reportId;
    const newUrl = replaceReportIdInUrl(currentUrl, prevReportId, reportId);
    // We keep the conditions configuration when changing the report. In this particular case, we want to reset the configuration.
    dispatch(currentReportActions.resetReportConfiguration());
    navigate(newUrl);
  };

  const handleOpenReportSelectorMenu = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);

    if (eligibleReports.length === 0) {
      setIsLoading(true);

      const [response, error] = await withErrorHandling(getReports)();

      setIsLoading(false);

      if (error) {
        logError(error, "[ReportSelector] handleOpenReportSelectorMenu");
        sendNotificationError("Failed to get reports");

        return;
      }
      dispatch(setReports(response));
    }
  };

  const resetNavigateToReportId = () => {
    setNavigateToReportId(undefined);
  };

  const handleCloseReportSelectorMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectNewReport = (reportId: string) => {
    handleCloseReportSelectorMenu();
    if (editMode && isReportChanged) {
      setNavigateToReportId(reportId);
      setShowUnsavedChangesDialog(true);
    } else {
      navigateToNewReport(reportId);
    }
  };

  const handleDiscardAndContinue = () => {
    setShowUnsavedChangesDialog(false);
    if (navigateToReportId) {
      navigateToNewReport(navigateToReportId);
      resetNavigateToReportId();
    }
  };

  const handleCloseSaveDialog = () => {
    setShowUnsavedChangesDialog(false);
    resetNavigateToReportId();
  };

  const handleSaveReport = async () => {
    if (!report || !reportConfiguration) return;

    setSavingReport(true);

    const [, error] = await withErrorHandling(updateReport)({ ...report, configuration: reportConfiguration });

    setSavingReport(false);

    if (!error && navigateToReportId) {
      setShowUnsavedChangesDialog(false);
      navigateToNewReport(navigateToReportId);
      resetNavigateToReportId();

      return;
    }

    logError(error, "[ReportSelector] handleSaveReport");
    sendNotificationError(locale.saving_error);
  };

  return (
    <>
      <ReportSelectorButton
        companyName={clientInfo.title}
        reportName={report?.name}
        onOpenReportSelectorMenu={handleOpenReportSelectorMenu}
        isMenuOpened={isMenuOpened}
      />
      {isMenuOpened && (
        <ReportSelectorMenu
          anchorEl={anchorEl}
          clientCode={clientCode}
          currentReportName={report?.name}
          onClose={handleCloseReportSelectorMenu}
          onSelectNewReport={handleSelectNewReport}
          groups={groups}
          reports={eligibleReports}
          isLoading={isLoading}
          isGrouped={isGrouped}
        />
      )}
      {showUnsavedChangesDialog && (
        <UnsavedChangesDialog
          onCancel={handleCloseSaveDialog}
          onContinue={handleDiscardAndContinue}
          onSave={handleSaveReport}
          name={report?.name}
          type="report"
          isLoading={isSavingReport}
        />
      )}
    </>
  );
};

export default ReportSelector;
