let currentClientCode = "";

export const updateClientCode = (clientCode: string) => {
  currentClientCode = clientCode;
};

export const getClientCode = () => {
  return currentClientCode;
};

export const clientCodeHeader = () => ({ SelectedClientCode: currentClientCode });
