/* eslint-disable no-console */
import { deepClone } from "@mui/x-data-grid/utils/utils";
import {
  ConditionConfiguration,
  DateRangeType,
  PivotConfiguration,
  PivotFieldConfiguration,
  PivotGeneralSettings,
  SortConfiguration,
  UserMeasureConfigurations,
} from "../../../../../shared/reporting/api/biClient.types";
import { generateGuid } from "../../../../../shared/utilities/generateGuid";
import { DimensionDescriptor, MeasureDescriptor } from "../../../../api/biApi.types";
import { valueToConfiguration } from "../../common/utilities/valueToConfiguration";
import { AreaItemType, ColumnField, ConditionField, RowField, SortField, ValueField } from "../../Types";

export const createReportConfiguration = (
  conditions: ConditionField[],
  rows: RowField[],
  columns: ColumnField[],
  values: ValueField[],
  sorts: SortField[],
  settings: PivotGeneralSettings
) => {
  const reportConfiguration: PivotConfiguration = {
    conditions: conditions.map((c) => c.config),
    rows: rows.map((r) => r.config),
    columns: columns.map((c) => c.config),
    values: values.map(valueToConfiguration),
    sort: sorts.map((s) => s.config),
    settings: { ...settings },
  };
  return reportConfiguration;
};

export const configurationToConditions = (
  configConditions: ConditionConfiguration[],
  dimensions: DimensionDescriptor[]
): ConditionField[] => {
  try {
    const conditions = configConditions
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.filter.dimensionName);
        if (dimension) {
          const copied = { config, meta: dimension, areaItemType: AreaItemType.CONDITIONS } as ConditionField;
          // backward compatibility with saved old reports
          if (copied.config.guid === undefined) {
            copied.config.guid = generateGuid();
          }
          return copied;
        } else {
          console.log(`Field ${config.filter.dimensionName} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c): c is ConditionField => c !== undefined);
    return conditions;
  } catch {
    return [];
  }
};

export const configurationToRows = (configRows: PivotFieldConfiguration[], dimensions: DimensionDescriptor[]) => {
  try {
    const rows = configRows
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.name);
        if (dimension) {
          const copied: RowField = { config, meta: dimension, areaItemType: AreaItemType.ROWS };
          return copied;
        } else {
          console.log(`Field ${config.name} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c): c is RowField => !!c);
    return rows;
  } catch {
    return [];
  }
};

export const configurationToColumns = (configColumns: PivotFieldConfiguration[], dimensions: DimensionDescriptor[]) => {
  try {
    const columns = configColumns
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.name);
        if (dimension) {
          const copied: ColumnField = { config, meta: dimension, areaItemType: AreaItemType.COLUMNS };
          return copied;
        } else {
          console.log(`Field ${config.name} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c): c is ColumnField => !!c);
    return columns;
  } catch {
    return [];
  }
};

export const configurationToValues = (configValues: UserMeasureConfigurations[], measures: MeasureDescriptor[]) => {
  try {
    const values = configValues
      .map((config) => {
        const measure = measures.find((d) => d.name === config.name);
        if (measure) {
          const copied: ValueField = {
            config: {
              ...config,
              format:
                config.format ||
                (measure?.defaultFormat?.formatType === "Numeric" ? measure?.defaultFormat : undefined),
              dateRange: config.dateRange || DateRangeType.EndingBalance,
            },
            meta: measure,
            areaItemType: AreaItemType.VALUES,
          };
          return copied;
        } else {
          console.log(`Field ${config.name} is not found in the measures' list`);
        }
        return undefined;
      })
      .filter((c) => c !== undefined) as ValueField[];
    return values;
  } catch {
    return [];
  }
};

export const configurationToSorts = (configSorts: SortConfiguration[], dimensions: DimensionDescriptor[]) => {
  try {
    const sorts = configSorts
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.name);
        if (dimension) {
          const copied: SortField = {
            config,
            meta: dimension,
            areaItemType: AreaItemType.SORTS,
          };
          return copied;
        } else {
          console.log(`Field ${config.name} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c) => c !== undefined) as SortField[];
    return sorts;
  } catch {
    return [];
  }
};

export const configurationToSettings = (settings: PivotGeneralSettings) => {
  try {
    const copiedSettings = deepClone(settings);
    return copiedSettings;
  } catch {
    return {};
  }
};
