import { Box, Grid, Stack, Typography } from "@mui/material";
import StyledPage from "../infoPage/StyledPage";
import AuthLoaderSvg from "./AuthLoaderSvg";

interface Props {
  title: string;
  subtitle: string;
}

const FullScreenLoader = ({ title, subtitle }: Props) => (
  <StyledPage containerProps={{ alignItems: "center", justifyContent: "center", direction: "column", spacing: 3 }}>
    <Grid item>
      <Box mt={-20} width={240}>
        <AuthLoaderSvg />
      </Box>
    </Grid>
    <Grid item>
      <Stack spacing={1} alignItems="center">
        <Typography variant="h3">{title}</Typography>
        <Typography>{subtitle}</Typography>
      </Stack>
    </Grid>
  </StyledPage>
);

export default FullScreenLoader;
