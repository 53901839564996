import axios from "axios";
import { createApiResponse, createErrorApiResponse } from "../../shared/api/axiosHelper";
import { ApiResponse } from "../../shared/api/types";
import { getBackendBaseUrl } from "../../shared/variables";
import { Identity, User, UserPermissions } from "./identityApi.types";

const baseUri = getBackendBaseUrl("reporting");

const getIdentity = async () => {
  const { data } = await axios.get<ApiResponse<Identity>>(`${baseUri}/Identity`, {
    params: { loadLargeAvatarImage: false },
  });
  return data;
};

const getPermissions = async () => {
  const { data } = await axios.get<ApiResponse<UserPermissions>>(`${baseUri}/Users/current/permissions`);
  return data;
};

const loadUser = async (): Promise<ApiResponse<User>> => {
  const [identityResponse, permissionsResponse] = await Promise.all([getIdentity(), getPermissions()]);
  if (!identityResponse.success) {
    return createErrorApiResponse(identityResponse.error);
  }
  if (!permissionsResponse.success) {
    return createErrorApiResponse(permissionsResponse.error);
  }
  return createApiResponse({ ...identityResponse.data, ...permissionsResponse.data });
};

export { loadUser };
