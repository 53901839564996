import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ClientInfo, ReportTemplateDto } from "../../shared/reporting/api/biClient.types";
import { RootState } from "./RootState";
import { ReportTemplatesState } from "./ReportTemplatesState";

const initialState: ReportTemplatesState = {
  templates: [],
  companies: [],
};

export const reportTemplatesSlice = createSlice({
  name: "reportTemplates",
  initialState,
  reducers: {
    setTemplates: (state, action: PayloadAction<ReportTemplateDto[]>) => {
      state.templates = action.payload;
    },
    setCompanies: (state, action: PayloadAction<ClientInfo[]>) => {
      state.companies = action.payload;
    },
  },
});

export const { setTemplates, setCompanies } = reportTemplatesSlice.actions;

export const selectTemplates = (state: RootState) => state.reportTemplates.templates;
export const selectCompanies = (state: RootState) => state.reportTemplates.companies;

export default reportTemplatesSlice.reducer;
