import { FormulaNode } from "./fillNodes";

export const styleFormulaNodes = (formulaNodes: FormulaNode[]) => {
  formulaNodes.forEach((fn) => {
    if (fn.function !== undefined) {
      fn.linkedNodes.forEach((ln) => {
        if (ln.token.element !== undefined) {
          ln.token.element.classList.add(FunctionFormulaClass);
        }
      });
    }
  });
};

export const FunctionFormulaClass = "function";
