import { useState, useEffect, useCallback } from "react";
import { ReportGroup } from "../../../../../shared/reporting/api/biClient.types";
import biClient from "../../../../api/biApi";
import { useClientContext } from "../../../../contexts/ClientContext";

export default function useReportGroups() {
  const { clientCode } = useClientContext();
  const [reportGroups, setReportGroups] = useState<ReportGroup[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);

  const loadReportGroups = useCallback(async (clientCode: string) => {
    try {
      const groupsRes = await biClient.getReportGroups(clientCode);
      if (groupsRes.success === false) {
        setError(groupsRes.error?.message);
        return;
      }
      setReportGroups(groupsRes.data);
    } catch {
      setError("Report groups loading failed");
    } finally {
      setLoaded(true);
    }
  }, []);
  useEffect(() => {
    loadReportGroups(clientCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadReportGroups = useCallback(async () => {
    const resp = await biClient.getReportGroups(clientCode);
    if (resp.success) {
      setReportGroups(resp.data);
    }
  }, [clientCode]);

  const addReportGroup = useCallback(
    async (clientCode: string, group: ReportGroup) => {
      const resp = await biClient.addReportGroup({ clientCode, group });
      if (resp.success) {
        await reloadReportGroups();
        return resp.data;
      }
      throw new Error(resp.error?.message);
    },
    [reloadReportGroups]
  );

  const updateReportGroup = useCallback(
    async (clientCode: string, group: ReportGroup) => {
      const resp = await biClient.updateReportGroup({ clientCode, group });
      if (resp.success) {
        await reloadReportGroups();
        return;
      }
      throw new Error(resp.error?.message);
    },
    [reloadReportGroups]
  );

  const deleteReportGroup = useCallback(
    async (clientCode: string, groupId: string) => {
      const resp = await biClient.deleteReportGroup(clientCode, groupId);
      if (resp.success) {
        await reloadReportGroups();
        return;
      }
      throw new Error(resp.error?.message);
    },
    [reloadReportGroups]
  );

  const replaceReportGroup = useCallback(async (clientCode: string, fromGroup: string, toGroup: string) => {
    const resp = await biClient.replaceReportGroup({ clientCode, fromGroup, toGroup });
    if (resp.success) {
      return resp.data;
    }
    throw new Error(resp.error?.message);
  }, []);

  const reorderReportGroups = useCallback(async (clientCode: string, groups: ReportGroup[]) => {
    const resp = await biClient.reorderReportGroups({ clientCode, groups });
    if (resp.success) {
      setReportGroups(resp.data);
      return;
    }
    throw new Error(resp.error?.message);
  }, []);

  return {
    reportGroups,
    ui: { error, loaded },
    actions: {
      add: addReportGroup,
      update: updateReportGroup,
      delete: deleteReportGroup,
      replace: replaceReportGroup,
      reorder: reorderReportGroups,
      load: reloadReportGroups,
    },
  };
}
