import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReportsState } from "./ReportsState";
import { ReportInfo } from "../../shared/reporting/api/biClient.types";
import { RootState } from "./RootState";

const initialState: ReportsState = {
  reports: [],
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReports: (state, action: PayloadAction<ReportInfo[]>) => {
      state.reports = action.payload;
    },
  },
});

export const { setReports } = reportsSlice.actions;

export const selectReports = (state: RootState) => state.reports.reports;

export default reportsSlice.reducer;
