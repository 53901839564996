import { Box, SxProps, Theme } from "@mui/material";
import { PropsWithChildren, useCallback, useState } from "react";
import EditCustomLabel from "./EditCustomLabel";
import ViewCustomLabel from "./ViewCustomLabel";
import { CustomLabelData } from "./CustomLabelData";
import useMergedSx from "../../../../shared/hooks/useMergedSx";

interface CustomLabelProps extends CustomLabelData {
  readonly?: boolean;
  updateCustomLabel?: (customLabel: string | undefined) => void;
  onEditMode?: (isEditing: boolean) => void;
  sx?: SxProps<Theme>;
}
export default function CustomLabel({
  customLabelValue,
  originalName,
  readonly,
  updateCustomLabel,
  onEditMode,
  sx,
  children,
}: PropsWithChildren<CustomLabelProps>) {
  const [isEditingCustomLabel, setIsEditingCustomLabel] = useState(false);
  const onEditingCustomLabel = useCallback(
    (editing: boolean) => {
      setIsEditingCustomLabel(editing);
      onEditMode?.(editing);
    },
    [onEditMode]
  );

  const mergedContainerSx = useMergedSx(sx, (theme) => ({
    display: "flex",
    alignItems: "center",
    px: 2,
    py: 1.5,
    height: customLabelValue ? theme.spacing(8) : theme.spacing(6.5),
  }));

  return (
    <Box sx={mergedContainerSx}>
      <Box sx={{ display: "flex", flex: 1, gap: 1, alignItems: "center" }}>
        {isEditingCustomLabel && updateCustomLabel && (
          <EditCustomLabel
            customLabelValue={customLabelValue}
            originalName={originalName}
            onApply={(label) => {
              updateCustomLabel(label);
              onEditingCustomLabel(false);
            }}
            onCancel={() => onEditingCustomLabel(false)}
          />
        )}
        {!isEditingCustomLabel && (
          <ViewCustomLabel
            customLabelValue={customLabelValue}
            originalName={originalName}
            onRenamClick={() => onEditingCustomLabel(true)}
            readonly={!updateCustomLabel || readonly}
          />
        )}
      </Box>
      {children}
    </Box>
  );
}
