import { Grid, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import MonitorWithBusinessAppIcon from "../../../../../shared/icons/MonitorWithBusinessAppIcon";
import { useLocalization } from "../../../../hooks/useLocalization";
import { BiErrorCodes } from "./BiErrorCodes";

interface Props {
  errorMessage: string | undefined;
}
export default function BiErrorCodeComponent({ errorMessage, children }: PropsWithChildren<Props>) {
  const { biErrors } = useLocalization();

  if (errorMessage?.includes(BiErrorCodes.REPORT_SIZE_EXCEEDED)) {
    return (
      <Grid container sx={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "column", gap: 1 }}>
        <MonitorWithBusinessAppIcon />
        <Typography key={"REPORT_SIZE_EXCEEDED"} variant="subtitle1">
          {biErrors.REPORT_SIZE_EXCEEDED.title}
        </Typography>
        <Typography color="secondary">{biErrors.REPORT_SIZE_EXCEEDED.message}</Typography>
      </Grid>
    );
  }
  return <>{children}</>;
}
