import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGridPremium, GridColDef, GridRowsProp, useGridApiRef } from "@mui/x-data-grid-premium";
import { useEffect, useMemo, useState } from "react";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { MeasureUnitTable } from "../../../../../shared/reporting/api/biClient.types";
import { AccountType, ChartOfAccounts, ChartOfAccountsItem } from "../../../../api/biApi.types";
import { useChartOfAccountsContext } from "./contexts/ChartOfAccountsContext";

interface LedgerAccountsListProps {
  chartOfAccounts: ChartOfAccounts | undefined;
  ledger: MeasureUnitTable;
}
export default function LedgerAccountsList({ chartOfAccounts, ledger }: LedgerAccountsListProps) {
  const classes = useStyles();

  const rowHeight = 32;
  const { selectedAccounts, scrollTargetAccount } = useChartOfAccountsContext();
  const [activeAccountScroll, setActiveAccountScroll] = useState<{ accountNo: string }>();

  const apiRef = useGridApiRef();

  useEffect(() => {
    setActiveAccountScroll(scrollTargetAccount);
  }, [scrollTargetAccount]);

  const columns = useMemo(() => {
    const result: GridColDef<ChartOfAccountsItem>[] = [];
    if (chartOfAccounts) {
      result.push({ field: "no", headerName: "No.", width: 100, valueGetter: (_, row) => row.accountNo });
      result.push({
        field: "name",
        headerName: "Name",
        minWidth: 70,
        flex: 1,
        valueGetter: (_, row) => row.accountName,
        renderCell(params) {
          return (
            <Typography pl={params.row.level} variant={getNameCellVariant(params.row.accountType)}>
              {params.value}
            </Typography>
          );
        },
      });
      if (ledger === MeasureUnitTable.Gl) {
        result.push({
          field: "ib",
          headerName: "Income/Balance",
          minWidth: 70,
          flex: 0.8,
          valueGetter: (_, row) => row.incomeBalance,
        });
      }
      result.push({
        field: "acn",
        headerName: "Account Category Name",
        minWidth: 70,
        flex: 0.8,
        valueGetter: (_, row) => row.accountCategory,
      });
      result.push({
        field: "as",
        headerName: "Account Subcategories",
        minWidth: 70,
        flex: 0.8,
        valueGetter: (_, row) => row.accountSubcategory,
      });
      result.push({
        field: "at",
        headerName: "Account Type",
        width: 110,
        valueGetter: (_, row) => row.accountType,
      });
    }
    return result.map((col) => ({ ...col, sortable: false }));
  }, [chartOfAccounts, ledger]);

  const rows = useMemo<GridRowsProp<ChartOfAccountsItem>>(() => {
    if (chartOfAccounts && ledger) {
      return ledger === MeasureUnitTable.Gl ? chartOfAccounts.gl : chartOfAccounts.memo;
    }
    return [];
  }, [chartOfAccounts, ledger]);

  useEffect(() => {
    const current = apiRef.current;
    if (current && scrollTargetAccount) {
      const isGl = chartOfAccounts?.gl.some((account) => scrollTargetAccount.accountNo === account.accountNo);
      if ((isGl && ledger === MeasureUnitTable.Gl) || (!isGl && ledger === MeasureUnitTable.Memo)) {
        setTimeout(() => {
          current.scrollToIndexes({
            rowIndex: current.getRowIndexRelativeToVisibleRows(scrollTargetAccount.accountNo),
          });
        }, 10);

        setTimeout(() => {
          setActiveAccountScroll((prev) => (prev?.accountNo === scrollTargetAccount.accountNo ? undefined : prev));
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTargetAccount]);

  return (
    <Grid container sx={{ py: 2.5, pr: 3, pl: 1.5, gap: 2, flexDirection: "column" }}>
      <ScrollableFlexContainer>
        <DataGridPremium
          apiRef={apiRef}
          rowHeight={rowHeight}
          columnHeaderHeight={32}
          columns={columns}
          rowSelectionModel={selectedAccounts}
          rows={rows}
          getRowId={(row) => row.accountNo}
          getRowClassName={(params) => {
            if (params.id === activeAccountScroll?.accountNo) {
              return classes.animatedItem;
            }
            return "";
          }}
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnMenu
          hideFooter
        ></DataGridPremium>
      </ScrollableFlexContainer>
    </Grid>
  );
}

const getNameCellVariant = (type: AccountType) => {
  switch (type) {
    case AccountType.Heading:
    case AccountType.BeginTotal:
    case AccountType.Total:
    case AccountType.EndTotal:
      return "subtitle2";
    default:
      return "body1";
  }
};

const useStyles = makeStyles(() => {
  return {
    animatedItem: {
      animation: "$blinking .15s steps(4) 4",
    },
    "@keyframes blinking": { "50%": { boxShadow: "0 0 5px #00A866" } },
  };
});
