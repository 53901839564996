import { LoadingButton } from "@mui/lab";
import { Alert, Button, DialogActions, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { ApiResponse } from "../../../../api/types";
import DialogHeader from "../../../../components/dialog/DialogHeader";
import PromptDialog from "../../../../components/dialog/PromptDialog";
import useRequest from "../../../../hooks/useRequest";
import DeleteDocumentIcon from "../../../../icons/DeleteDocumentIcon";
import { ReportTemplate } from "../../../api/biClient.types";
import { useSharedReportingLocalization } from "../../../hooks/useLocalization";

interface Props {
  templates: ReportTemplate[];
  doDelete: (templates: ReportTemplate[]) => Promise<ApiResponse<boolean>>;
  onDeleted: () => void;
  onClose: () => void;
}

export default function DeleteTemplateDialog({ templates, doDelete, onClose, onDeleted }: Props) {
  const { report_templates: locale } = useSharedReportingLocalization();

  const deleteTemplates = useCallback(() => doDelete(templates), [doDelete, templates]);

  const { request, error, isLoading: deleting } = useRequest(deleteTemplates);

  const handleDeleteTemplates = async () => {
    const [, error] = await request();
    if (!error) {
      onDeleted();
      onClose();
    }
  };
  const isSingleTemplate = templates.length === 1;

  return (
    <PromptDialog open={true} sx={{ width: 514, minHeight: 305 }}>
      <DialogHeader text="Delete Template" disabled={deleting} onClose={onClose} />
      {error && (
        <Alert sx={{ mb: ".5rem", mx: 2.5 }} severity="error">
          {locale.delete_error}
        </Alert>
      )}
      <Stack
        sx={{
          flexDirection: "column",
          flex: 1,
          gap: 3,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DeleteDocumentIcon />
        <Stack gap={1} alignItems="center">
          <Typography variant="h6">
            {isSingleTemplate ? `Delete ${templates.at(0)?.name} template` : `Delete ${templates.length} templates`}
          </Typography>
          <Typography color="text.secondary" sx={{ whiteSpace: "pre-line", textAlign: "center" }}>
            {isSingleTemplate ? locale.deleted_question_singular : locale.deleted_question_plural}
          </Typography>
        </Stack>
      </Stack>

      <DialogActions sx={{ px: 2.5, py: 1.5 }}>
        <Button variant="text" color="secondary" disabled={deleting} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          color="error"
          disabled={deleting}
          loading={deleting}
          onClick={handleDeleteTemplates}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </PromptDialog>
  );
}
