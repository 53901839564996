import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { IconButton } from "@mui/material";
import React from "react";
import { ClientInfo, ReportConfiguration, ReportTemplate } from "../../../shared/reporting/api/biClient.types";
import TemplateActions from "./TemplateActions";

interface Props {
  template: ReportTemplate;
  templateConfiguration: ReportConfiguration | undefined;
  companies: ClientInfo[];
  onEdited: (template: ReportTemplate, dataSourceChanged?: boolean) => void;
  onDataSourceChanged: (ds: string) => void;
}

export default function MoreAction(props: Props) {
  const { template, onEdited, templateConfiguration, onDataSourceChanged, companies } = props;
  const [openActionsMenu, setOpenActionsMenu] = React.useState(false);
  const [actionMenuRef, setActionMenuRef] = React.useState<HTMLElement | null>(null);

  return (
    <>
      <IconButton key="actionButton" ref={setActionMenuRef} onClick={() => setOpenActionsMenu(true)}>
        <MoreHorizOutlinedIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
      </IconButton>
      {openActionsMenu && (
        <TemplateActions
          key={"actions"}
          anchorEl={actionMenuRef}
          template={template}
          templateConfiguration={templateConfiguration}
          onClose={() => setOpenActionsMenu(false)}
          onEdited={onEdited}
          onDataSourceChanged={onDataSourceChanged}
          companies={companies}
        />
      )}
    </>
  );
}
