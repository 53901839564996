export const ErrorIcon = () => {
  return (
    <svg width="270" height="204" viewBox="0 0 270 204" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M62.8504 33.2875V99.9875C62.8504 103.088 60.3504 105.587 57.2504 105.587H6.45039C3.35039 105.587 0.850388 103.088 0.850388 99.9875V33.2875C0.850388 30.1875 3.35039 27.6875 6.45039 27.6875H57.3504C60.3504 27.6875 62.8504 30.1875 62.8504 33.2875Z"
        fill="white"
        stroke="#D6D8D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M36.8504 104.987C36.8504 107.787 34.6504 109.987 31.8504 109.987C29.1504 109.987 26.8504 107.787 26.8504 104.987C26.8504 102.187 29.0504 99.9873 31.8504 99.9873C34.6504 99.9873 36.8504 102.187 36.8504 104.987Z"
        fill="white"
        stroke="#D6D8D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path d="M52.2504 39.8877H11.4504V45.3877H52.2504V39.8877Z" fill="#F6F6F6" />
      <path d="M52.2504 55.1875H11.4504V60.6875H52.2504V55.1875Z" fill="#F6F6F6" />
      <path d="M52.2504 69.2876H11.4504V74.7876H52.2504V69.2876Z" fill="#F6F6F6" />
      <path
        d="M11.4504 95.2873C13.6043 95.2873 15.3504 93.5412 15.3504 91.3873C15.3504 89.2334 13.6043 87.4873 11.4504 87.4873C9.29647 87.4873 7.55038 89.2334 7.55038 91.3873C7.55038 93.5412 9.29647 95.2873 11.4504 95.2873Z"
        fill="#F6F6F6"
      />
      <path
        opacity="0.3"
        d="M141.55 107.888V39.8876C141.55 38.0876 142.55 36.4876 144.05 35.5876L202.95 1.5876C204.45 0.687598 206.45 0.687598 207.95 1.5876L266.85 35.5876C268.35 36.4876 269.35 38.0876 269.35 39.8876V107.888C269.35 109.688 268.35 111.288 266.85 112.188L207.95 146.188C206.45 147.088 204.45 147.088 202.95 146.188L144.05 112.188C142.45 111.288 141.55 109.688 141.55 107.888Z"
        fill="#E8E8E8"
      />
      <path
        opacity="0.4"
        d="M154.65 100.288V47.4878C154.65 45.6878 155.65 44.0878 157.15 43.1878L202.95 16.7878C204.45 15.8878 206.45 15.8878 207.95 16.7878L253.75 43.1878C255.25 44.0878 256.25 45.6878 256.25 47.4878V100.288C256.25 102.088 255.25 103.688 253.75 104.588L207.95 130.988C206.45 131.888 204.45 131.888 202.95 130.988L157.15 104.588C155.65 103.688 154.65 102.088 154.65 100.288Z"
        fill="#E8E8E8"
      />
      <path
        opacity="0.43"
        d="M239.85 51.0876L207.85 32.5876C206.35 31.6876 204.35 31.6876 202.85 32.5876L170.85 51.0876C169.35 51.9876 168.35 53.5876 168.35 55.3876V92.2876C168.35 94.0876 169.35 95.6876 170.85 96.5876L202.85 115.088C204.35 115.988 206.35 115.988 207.85 115.088L239.85 96.5876C241.35 95.6876 242.35 94.0876 242.35 92.2876V55.3876C242.35 53.5876 241.45 51.9876 239.85 51.0876Z"
        fill="#E3E3E3"
      />
      <path
        d="M227.45 51.2876H31.8504C29.0504 51.2876 26.8504 53.4876 26.8504 56.2876V189.788H232.35V56.2876C232.45 53.4876 230.15 51.2876 227.45 51.2876Z"
        fill="white"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M223.887 181.228L223.887 59.8281L129.387 59.8281L129.387 181.228H223.887Z" fill="#F6F6F6" />
      <path
        d="M258.65 198.188H0.650391V194.388C0.650391 191.788 2.75039 189.788 5.25039 189.788H254.05C256.65 189.788 258.65 191.888 258.65 194.388V198.188Z"
        fill="white"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M258.65 198.188H0.650391L3.65039 201.188C4.85039 202.387 6.35039 203.087 7.85039 203.087H252.15C253.95 203.087 255.75 202.087 257.05 200.387L258.65 198.188Z"
        fill="white"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.65 193.988H110.65C109.25 193.988 108.05 192.987 107.35 191.387L106.55 189.688H152.75L151.95 191.387C151.25 192.987 150.05 193.988 148.65 193.988Z"
        fill="#BDBDBD"
      />
      <path
        d="M150.652 105.388C149.852 100.388 145.552 96.5877 140.352 96.5877C138.352 96.5877 136.552 97.0877 134.852 98.0877C132.352 93.8877 127.952 91.3877 122.952 91.3877C115.352 91.3877 109.052 97.5877 109.052 105.288V105.388C104.152 106.188 100.252 110.488 100.252 115.688C100.252 121.388 105.052 126.088 110.752 126.088H148.852C154.552 126.088 159.352 121.388 159.352 115.688C159.552 110.488 155.552 106.188 150.652 105.388Z"
        fill="#BDBDBD"
      />
      <path d="M129.452 125.088V153.688" stroke="#BDBDBD" strokeMiterlimit="10" />
      <path d="M115.752 125.088V131.588L105.552 136.788V146.088" stroke="#BDBDBD" strokeMiterlimit="10" />
      <path
        d="M129.352 158.088C131.285 158.088 132.852 156.521 132.852 154.588C132.852 152.655 131.285 151.088 129.352 151.088C127.419 151.088 125.852 152.655 125.852 154.588C125.852 156.521 127.419 158.088 129.352 158.088Z"
        fill="#BDBDBD"
      />
      <path
        d="M105.552 151.688C107.485 151.688 109.052 150.12 109.052 148.188C109.052 146.255 107.485 144.688 105.552 144.688C103.619 144.688 102.052 146.255 102.052 148.188C102.052 150.12 103.619 151.688 105.552 151.688Z"
        fill="#BDBDBD"
      />
      <path d="M144.152 124.388V130.888L154.352 136.088V145.388" stroke="#BDBDBD" strokeMiterlimit="10" />
      <path opacity="0.31" d="M129.466 181.249L129.466 59.8491L35.4661 59.8491L35.4661 181.249H129.466Z" fill="white" />
      <path
        d="M223.904 181.212L223.904 59.8115L35.4036 59.8115L35.4036 181.212L223.904 181.212Z"
        stroke="#D6D8D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M199.75 59.7876V76.7876C199.75 79.5876 201.95 81.7876 204.75 81.7876H223.95"
        stroke="#D6D8D9"
        strokeMiterlimit="10"
      />
      <path
        d="M129.45 72.4878H165.15C167.95 72.4878 170.15 74.6878 170.15 77.4878V94.1878C170.15 96.9878 172.35 99.1878 175.15 99.1878H200.45C203.25 99.1878 205.45 101.388 205.45 104.188V145.088H192.25C189.45 145.088 187.25 147.288 187.25 150.088V163.388C187.25 166.188 185.05 168.388 182.25 168.388H129.45"
        stroke="#D6D8D9"
        strokeMiterlimit="10"
      />
      <path
        d="M213.75 83.7876C214.855 83.7876 215.75 82.8922 215.75 81.7876C215.75 80.683 214.855 79.7876 213.75 79.7876C212.646 79.7876 211.75 80.683 211.75 81.7876C211.75 82.8922 212.646 83.7876 213.75 83.7876Z"
        fill="#D6D8D9"
      />
      <path
        d="M151.35 74.4878C152.455 74.4878 153.35 73.5924 153.35 72.4878C153.35 71.3832 152.455 70.4878 151.35 70.4878C150.246 70.4878 149.35 71.3832 149.35 72.4878C149.35 73.5924 150.246 74.4878 151.35 74.4878Z"
        fill="#D6D8D9"
      />
      <path
        d="M205.45 146.988C206.555 146.988 207.45 146.092 207.45 144.988C207.45 143.883 206.555 142.988 205.45 142.988C204.346 142.988 203.45 143.883 203.45 144.988C203.45 146.092 204.346 146.988 205.45 146.988Z"
        fill="#D6D8D9"
      />
      <path
        d="M157.55 170.288C158.655 170.288 159.55 169.392 159.55 168.288C159.55 167.183 158.655 166.288 157.55 166.288C156.446 166.288 155.55 167.183 155.55 168.288C155.55 169.392 156.446 170.288 157.55 170.288Z"
        fill="#D6D8D9"
      />
      <path
        d="M205.45 144.988V153.688C205.45 156.488 207.65 158.688 210.45 158.688H223.95"
        stroke="#D6D8D9"
        strokeMiterlimit="10"
      />
      <path d="M157.55 168.288V181.188" stroke="#D6D8D9" strokeMiterlimit="10" />
      <path d="M150.816 141.671L157.887 148.742" stroke="#BDBDBD" strokeWidth="1.5" />
      <path d="M150.816 148.742L157.887 141.671" stroke="#BDBDBD" strokeWidth="1.5" />
    </svg>
  );
};

export default ErrorIcon;
