import { Box, Grid, Typography } from "@mui/material";
import { NumberFormatterOptions } from "../../../../../../shared/reporting/api/biClient.types";
import FormatSelect from "../../FormatSelect";

interface Props {
  label: string;
  format: NumberFormatterOptions | undefined | null;
  onUpdateFormatter: (formatter: NumberFormatterOptions | undefined) => void;
}

const FormatSettings = ({ label, format, onUpdateFormatter }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        borderBottom: "1px dashed #E0E0E0",
        gap: ".5rem",
        pb: "1rem",
      }}
    >
      <Typography variant="subtitle2" color={(theme) => theme.palette.text.primary}>
        {label}
      </Typography>
      <Grid container sx={{ alignItems: "center" }}>
        <Typography sx={(theme) => ({ color: theme.palette.text.secondary, width: "5rem" })}>Format</Typography>
        <FormatSelect format={format} onUpdateFormatter={onUpdateFormatter} />
      </Grid>
    </Box>
  );
};

export default FormatSettings;
