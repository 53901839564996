import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChartStyleSettings } from "../../../../../shared/reporting/api/biClient.types";
import { BuildChartReportRequest } from "../../../../api/biApi.types";
import {
  currentReportActions,
  selectCacheSessionId,
  selectCurrentReport,
  selectSessionId,
} from "../../../../store/currentReportSlice";
import { selectLogQueries } from "../../../../store/devToolsSlice";
import { ConditionField, GeneralField, SortField, ValueField } from "../../Types";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";

export default function useRequestConfigBuilder() {
  const dispatch = useDispatch();
  const { conditionsArea, argumentsArea, valuesArea, sortingArea } = useFieldsStateContext();
  const report = useSelector(selectCurrentReport);

  const isQueryLoggingActive = useSelector(selectLogQueries);
  const sessionId = useSelector(selectSessionId);
  const cacheSessionId = useSelector(selectCacheSessionId);

  const requestConfig = React.useMemo(() => {
    const request: BuildChartReportRequest = {
      ...createChartRequest(conditionsArea.values, argumentsArea.values, valuesArea.values, sortingArea.values),
      reportId: report?.reportId ?? "",
      sessionId,
      cacheSessionId,
      withDrilldown: true,
      useQueryLogging: isQueryLoggingActive === true,
    };
    return request;
  }, [
    conditionsArea.values,
    argumentsArea.values,
    valuesArea.values,
    sortingArea.values,
    report?.reportId,
    sessionId,
    cacheSessionId,
    isQueryLoggingActive,
  ]);

  React.useEffect(() => {
    dispatch(currentReportActions.updateReportRequest(requestConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestConfig]);

  return { requestConfig };
}

function createChartRequest(
  conditions: ConditionField[],
  args: GeneralField[],
  values: ValueField[],
  sort: SortField[]
) {
  const result = {
    conditions: conditions.map((c) => c.config),
    arguments: args.map((v) => v.config),
    values: values.map((v) => v.config),
    sort: sort.map((s) => s.config),
    settings: {} as ChartStyleSettings,
  };
  return result;
}
