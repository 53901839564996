import { Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useNotificationContext } from "../contexts/NotificationContext";

export const GlobalNotificationSnackbar = () => {
  const { notification } = useNotificationContext();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (notification !== undefined) {
      setShow(true);
    }
  }, [notification]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={show}
      autoHideDuration={notification?.autoHideDuration}
      onClose={() => setShow(false)}
      message={notification?.text}
    ></Snackbar>
  );
};

export default GlobalNotificationSnackbar;
