import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import qs from "qs";
import { redirectToLoginSignin } from "../auth/Auth";
import { userAuthenticated } from "../auth/identity";
import RefreshTokenService from "../services/RefreshTokenService";
import { updateCurrentAppVersion } from "../services/appVersion";
import { FuncArgs } from "../types";
import { refreshCookieExists } from "../utilities/cookies";
import { ApiError, ApiResponse } from "./types";

export interface ExtendedAxiosError<T = unknown, D = unknown> extends AxiosError<T, D> {
  config: ExtendedAxiosConfig<D>;
}

export interface ExtendedAxiosConfig<D = unknown> extends InternalAxiosRequestConfig<D> {
  skipAuthRefresh?: boolean;
}

export const setUpAxios = (
  nonAuthUserAction: () => void,
  errorPipes?: ((error: ExtendedAxiosError) => Promise<never> | undefined)[]
) => {
  axios.defaults.withCredentials = true;

  axios.defaults.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: "repeat" });

  axios.interceptors.response.use(
    (res) => {
      updateCurrentAppVersion(res.headers["x-entrilia-web-app-version"]);
      return res;
    },
    async (error) => {
      const axiosError = error as ExtendedAxiosError;
      if (!axiosError.isAxiosError) {
        return Promise.reject(error);
      }

      if (errorPipes !== undefined) {
        for (let index = 0; index < errorPipes.length; index++) {
          const pipe = errorPipes[index];
          const result = pipe && pipe(error);
          if (result !== undefined) return result;
        }
      }

      if (axiosError.response?.status !== 401 || axiosError.config?.skipAuthRefresh) {
        return Promise.reject(error);
      }

      if (refreshCookieExists() && (await RefreshTokenService.do())) {
        return axios.request({ ...error.config, skipAuthRefresh: true });
      }

      nonAuthUserAction();

      return Promise.resolve();
    }
  );

  const nonAuthRequestUrlPatterns = [
    /sso\/auth/i,
    /anonymous\/admin/i,
    /emailverification\/emailverifications\/.+\/complete/i,
  ];

  axios.interceptors.request.use((request) => {
    if (nonAuthRequestUrlPatterns.some((regex) => regex.test(request.url ?? ""))) {
      return request;
    }

    return userAuthenticated().then((authenticated) => {
      if (authenticated) {
        return request;
      }

      redirectToLoginSignin();
      throw new axios.Cancel("Request canceled: unauthenticated user");
    });
  });
};

export const getErrorMessage = (error: unknown) => {
  if (!error) {
    return "";
  }

  if (typeof error === "string") {
    return error;
  }

  if (axios.isAxiosError(error) && error.message) {
    const responseData = error.response && (error.response.data as ApiResponse<null>);
    return responseData?.error?.message || error.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === "object" && "message" in error) {
    return error["message"] + "";
  }

  return error + "";
};

const getApiError = (error: unknown): ApiError => {
  if (!error) {
    return { message: "Unknown error occurred during API call" };
  }

  if (axios.isCancel(error)) {
    return { isCanceledRequest: true, message: error.message || "Request canceled" };
  }

  const errorMesage = getErrorMessage(error);
  if (!axios.isAxiosError(error)) {
    return { message: `Error occurred during API call: ${errorMesage}` };
  }

  const resp = error.response?.data;
  if (!resp) {
    return { message: `Request failed: ${errorMesage}` };
  }

  const apiResponse = resp as ApiResponse<undefined>;
  return apiResponse.error ?? { message: `Request failed: ${errorMesage}` };
};

export const getStatusCodeFromError = (error: unknown): number | undefined => {
  if (!error || !axios.isAxiosError(error)) {
    return undefined;
  }

  return error.response?.status;
};

export type HandledApiResponse<TData> = [TData, undefined] | [undefined, ApiError];

export const withErrorHandling =
  <TData, Args extends FuncArgs>(apiCall: (...args: Args) => Promise<ApiResponse<TData>>) =>
  async (...args: Args): Promise<HandledApiResponse<TData>> => {
    try {
      const resp = await apiCall(...args);
      if (resp.success) {
        return [resp.data, undefined];
      } else {
        const apiError = resp.error ?? { message: "Unknown API error" };
        return [undefined, apiError];
      }
    } catch (error) {
      const apiError = getApiError(error);
      return [undefined, apiError];
    }
  };

export const createApiResponse = <TData>(data: TData): ApiResponse<TData> => ({
  success: true,
  data,
});

export const createErrorApiResponse = <TData>(error: ApiError | undefined): ApiResponse<TData> => ({
  success: false,
  error,
});
