import { Tooltip, Typography } from "@mui/material";
import { useLocalization } from "../../hooks/useLocalization";
import useEntityLevelAccess from "../builder/tabular/hooks/useEntityLevelAccess";

export default function EntityLevelAccess() {
  const isEntityLevelAccess = useEntityLevelAccess();
  const { entity_level_access } = useLocalization();

  if (isEntityLevelAccess) {
    return (
      <Tooltip title={entity_level_access}>
        <Typography color="primary" sx={{ textDecoration: "underline dotted", cursor: "help" }}>
          Entity Level Access
        </Typography>
      </Tooltip>
    );
  }
  return null;
}
