import { Chip, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

const DraftStatus = () => {
  return (
    <Chip
      label={
        <Typography variant="caption" fontWeight={500}>
          Template:
          <Typography variant="caption" component={"span"} color={"secondary"} sx={{ ml: 0.5, fontWeight: "inherit" }}>
            Draft
          </Typography>
        </Typography>
      }
      sx={{ bgcolor: blueGrey[50] }}
    />
  );
};

export default DraftStatus;
