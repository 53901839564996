import { ReactNode } from "react";
import {
  ConditionConfiguration,
  PivotFieldConfiguration,
  Report,
  ReportConfiguration,
  SortConfiguration,
  UserMeasureConfigurations,
} from "../../../shared/reporting/api/biClient.types";
import { FromReport } from "../../../shared/reporting/components/reports/FromReport";

import { DimensionDescriptor, MeasureDescriptor, MetaDescriptorBase } from "../../api/biApi.types";

export const EMPTY_CROSSFILTERED_CONDITION_VALUES = null;

export type MetaField<T extends MetaDescriptorBase> = {
  meta: T;
  areaItemType: AreaItemType;
};

export type MultiCaptionField = { customLabel?: string; systemLabel?: string };

export type ConditionField = MetaField<DimensionDescriptor> & {
  config: ConditionConfiguration;
  linked?: boolean;
  hasLinks?: boolean;
  invalid?: boolean;
  crossFilter?: {
    loading?: boolean;
    toRefresh?: boolean;
    values?: unknown[] | typeof EMPTY_CROSSFILTERED_CONDITION_VALUES;
  };
  dictionary?: { loading?: boolean; loaded?: boolean; failedToLoad?: boolean };
};

export type GeneralField = MetaField<DimensionDescriptor> & {
  config: PivotFieldConfiguration;
};

export type RowField = GeneralField;

export type ColumnField = GeneralField;

export type ValueField = MetaField<MeasureDescriptor> & {
  config: UserMeasureConfigurations;
};

export type SortField = MetaField<DimensionDescriptor> & {
  config: SortConfiguration;
};

export enum AreaItemType {
  "CONDITIONS" = "CONDITIONS",
  "DIMENSION" = "DIMENSION",
  "MEASURE" = "MEASURE",
  "ROWS" = "ROWS",
  "COLUMNS" = "COLUMNS",
  "VALUES" = "VALUES",
  "FIELDS" = "FIELDS",
  "SORTS" = "SORTS",
  "GROUPS" = "GROUPS",
  "ARGUMENTS" = "ARGUMENTS",
}

export type ShowFieldOptionsSettings<T> = {
  field: T;
  ref: HTMLElement | null;
  showOnlySelected: boolean;
};

export interface SaveReportDialogProps {
  report: Report;
  open: boolean;
  title?: string;
  saveBtnTitle?: string;
  reportConfiguration?: ReportConfiguration;
  onClose: () => void;
}

export interface SaveAsDialogProps {
  report: FromReport;
  reportConfiguration: ReportConfiguration;
  addReport: (report: Report) => Promise<Report | undefined>;
  onChanged: (reportId: string) => void;
  onClose: () => void;
}

export interface EditorProps {
  report: Report;
  exportAllowed?: boolean;
  ReportTypeSelector: ReactNode;
}

export interface TableComponentProps {
  ReportTypeSelector: ReactNode;
}