import objectHash from "object-hash";
import { Ordering, TabularFieldType } from "../../../../../shared/reporting/api/biClient.types";
import { TabularColumn } from "../../../../api/biApi.types";
import { ConditionField, SortField, ValueField } from "../../Types";
import { TableField } from "../hooks/TableField";
import { getAllFilters } from "../../pivot/preview/utilities/getAllConditions";

export function getColumns(fields: TableField[], columns: TabularColumn[]) {
  return fields.map((v) => {
    const column = columns.find((c) => c.guid === v.guid);
    let title = column?.title;
    if (title === undefined) {
      if (v.fieldType === TabularFieldType.Dimension && v.dimension !== undefined) {
        title = v.dimension.config.customLabel || v.dimension.meta.caption;
      } else if (v.fieldType === TabularFieldType.Measure && v.measure !== undefined) {
        title = v.measure.config.customLabel || v.measure.meta.caption;
      }
    }
    return {
      name: v.guid,
      title,
      failedToLoad: column?.failedToLoad || false,
    } as Column;
  });
}

export function getSorting(sorting: SortField[], fields: TableField[]) {
  return sorting
    .map((v) => {
      const field = fields.find((value) => value.dimension?.meta.name === v.meta.name);
      if (field === undefined) return undefined;
      return {
        columnName: field.guid,
        direction: v.config.ordering === Ordering.Ascending ? "asc" : "desc",
      } as Sorting;
    })
    .filter((r): r is Sorting => !!r);
}

export function getConditionsHash(conditions: ConditionField[], fields: TableField[]) {
  const measures = fields.map((v) => v.measure).filter((m): m is ValueField => !!m);
  return objectHash(getAllFilters(conditions, measures));
}

type Sorting = {
  columnName: string;
  direction: "asc" | "desc";
};

export type Column = {
  name: string;
  title: string;
  failedToLoad: boolean;
};
