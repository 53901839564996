import { ListItem } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  setRef?: (ref: HTMLElement | null) => void;
}

export const ItemContainer = (props: PropsWithChildren<Props>) => {
  const { setRef } = props;

  return (
    <ListItem
      role="Handle"
      ref={setRef}
      sx={{
        dispaly: "flex",
        flexDirection: "column",
        border: "1px solid #E5E6E9",
        borderRadius: "2px",
        p: 0,
        backgroundColor: "#fff",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      {props.children}
    </ListItem>
  );
};

export default ItemContainer;
