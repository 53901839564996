import { ConditionConfiguration, ConditionEqualityType } from "../../../../../shared/reporting/api/biClient.types";
import {
  DimensionDescriptor,
  ExportParameters,
  BuildTabularReportRequest,
  BuildExportRequest,
  RunTabularReportRequest,
  RunExportRequest,
  BuildPivotReportRequest,
  RunPivotReportRequest,
  ExportConfig,
} from "../../../../api/biApi.types";

const buildParameters = (
  conditions: ConditionConfiguration[],
  dimensions: DimensionDescriptor[]
): ExportParameters[] => {
  return conditions
    .filter((c) => c.parameter === true && c.invalid !== true)
    .map((c) => ({
      name:
        c.customLabel ||
        c.systemLabel ||
        dimensions.find((d) => d.name === c.filter.dimensionName)?.caption ||
        c.filter.dimensionName,
      equality: c.filter.equalityType ?? ConditionEqualityType.Equal,
      values: c.filter.values,
    }));
};

const buildExportConfig = (
  conditions: ConditionConfiguration[],
  dimensions: DimensionDescriptor[],
  withParameters: boolean
): ExportConfig => {
  return { withParameters: withParameters, parameters: buildParameters(conditions, dimensions) };
};

export const createBuildTabularExportRequest = (
  buildConfig: BuildTabularReportRequest,
  dimensions: DimensionDescriptor[],
  withParameters: boolean
): BuildExportRequest => {
  return {
    buildConfig: buildConfig,
    exportConfig: buildExportConfig(buildConfig.conditions, dimensions, withParameters),
  };
};

export const createRunTabularExportRequest = (
  viewConfig: RunTabularReportRequest,
  dimensions: DimensionDescriptor[],
  withParameters: boolean
): RunExportRequest => {
  return {
    viewConfig: viewConfig,
    exportConfig: buildExportConfig(viewConfig.conditions, dimensions, withParameters),
  };
};

export const createBuildPivotExportRequest = (
  buildConfig: BuildPivotReportRequest,
  dimensions: DimensionDescriptor[],
  withParameters: boolean
): BuildExportRequest => {
  return {
    buildConfig: buildConfig,
    exportConfig: buildExportConfig(buildConfig.conditions, dimensions, withParameters),
  };
};

export const createRunPivotExportRequest = (
  viewConfig: RunPivotReportRequest,
  dimensions: DimensionDescriptor[],
  withParameters: boolean
): RunExportRequest => {
  return {
    viewConfig: viewConfig,
    exportConfig: buildExportConfig(viewConfig.conditions, dimensions, withParameters),
  };
};
