import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Entry from "./bi/Entry";
import { defined } from "./shared/utilities/typeHelper";

createRoot(defined(document.getElementById("root"))).render(
  <React.StrictMode>
    <BrowserRouter>
      <Entry />
    </BrowserRouter>
  </React.StrictMode>
);
