import { Grid } from "@mui/material";
import React from "react";
import { useFieldsStateContext } from "../../contexts/FieldsStateContext";
import { isLinearSettings } from "../../contexts/FieldsStateContext.types";
import StyleGroup from "../StyleGroup";
import ShowLabelItem from "./ShowLabelItem";
import Title from "./Title";

export default function ArgumentAxisStyles() {
  const { settingsArea } = useFieldsStateContext();

  const supported = React.useMemo(() => isLinearSettings(settingsArea.settings), [settingsArea.settings]);

  if (!supported) {
    return <></>;
  }

  return (
    <StyleGroup caption="Argument Axis">
      <Grid container sx={{ flexDirection: "column", gap: 1 }}>
        <ShowLabelItem area={settingsArea} />
        <Title area={settingsArea} />
      </Grid>
    </StyleGroup>
  );
}
