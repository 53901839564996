import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentReportActions, selectAutoShowItemOptionsId } from "../../../../../store/currentReportSlice";
import { FieldItemProps } from "../types/areaFiledItem.types";
import AreaFieldItemLoader from "./AreaFieldItemLoader";
import FieldItemCaptions from "./FieldItemCaptions";
import FieldItemOptionsButton from "./FieldItemOptionsButton";

const AreaFieldItem = <T,>(props: FieldItemProps<T>) => {
  const { field, hideOptions, isLoading, readonly, formatCaption, onShowOptions, optionRef, disableEqualitySelection } =
    props;
  const autoShowItemOptionsId = useSelector(selectAutoShowItemOptionsId);
  const dispatch = useDispatch();

  const [optionElementRef, setOptionElementRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    optionRef?.call(null, optionElementRef);
  }, [optionRef, optionElementRef]);

  const showOptions = useCallback(() => {
    if (!onShowOptions) return false;
    const ref = optionElementRef;
    if (!ref) return false;

    onShowOptions({ field: field, ref, showOnlySelected: false });
    return true;
  }, [optionElementRef, field, onShowOptions]);

  useEffect(() => {
    if (!autoShowItemOptionsId) return;
    const item = field as { config: { guid: string; filter: { guid: string } }; guid: string };
    if (
      autoShowItemOptionsId === item?.guid ||
      item.config?.guid === autoShowItemOptionsId ||
      item.config?.filter?.guid === autoShowItemOptionsId
    ) {
      if (showOptions()) {
        dispatch(currentReportActions.setAutoShowItemOptionsId(undefined));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoShowItemOptionsId, field, showOptions]);

  return (
    <>
      {isLoading === true && <AreaFieldItemLoader />}
      <Grid
        container
        sx={(theme) => ({
          justifyContent: "space-between",
          minHeight: "30px",
          backgroundColor: readonly ? theme.palette.action.disabledBackground : "none",
        })}
      >
        <Grid container sx={{ alignItems: "center", px: "5px", justifyContent: "center", flex: 1 }}>
          <FieldItemCaptions
            field={field}
            formatCaption={formatCaption}
            disableEqualitySelection={disableEqualitySelection}
          />
        </Grid>
        <Grid ref={setOptionElementRef}>
          <FieldItemOptionsButton hideOptions={hideOptions} showOptions={showOptions} />
        </Grid>
      </Grid>
    </>
  );
};
export default AreaFieldItem;
