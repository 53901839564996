import React from "react";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import TitleItem from "../TitleItem";

interface Props {
  area: SettingsArea;
}

export default function Title({ area }: Props) {
  const title = React.useMemo(() => area.settings.legend?.title || "", [area.settings]);

  return (
    <TitleItem title={title} placeholder="Set Legend Title..." onUpdate={(title) => area.updateLegend({ title })} />
  );
}
