import React from "react";
import { useNotificationContext } from "../../shared/contexts/NotificationContext";
import { useExportStateContext } from "../components/builder/common/contexts/ExportStateContext";
import { useLocalization } from "./useLocalization";
import { downloadFileFromUrl } from "../../shared/services/downloadFile";
import { ExportResponse } from "../api/biApi.types";
import { ApiResponse } from "../../shared/api/types";
import biClient from "../api/biApi";

interface Props {
  makeRequest: () => Promise<ApiResponse<ExportResponse>>;
}
export default function useExport({ makeRequest: doRequest }: Props) {
  const exportState = useExportStateContext();
  const { sendNotificationError } = useNotificationContext();
  const { common: locale } = useLocalization();

  const downloadExport = React.useCallback((token: string) => {
    const url = biClient.generateExportDownloadLink(token);
    downloadFileFromUrl(url);
  }, []);

  const waitExportReady = React.useCallback(
    (token: string, attempt: number) => {
      biClient
        .checkExportReady(token)
        .then((response) => {
          if (response.success && !response.data.error) {
            if (response.data.fileProcessed) {
              downloadExport(token);
              exportState.actions.update({ exporting: false });
            } else {
              if (attempt < 20) {
                setTimeout(() => waitExportReady(token, attempt + 1), 1500);
              } else {
                sendNotificationError(locale.export_file_not_generated);
                exportState.actions.update({ exporting: false });
              }
            }
          } else {
            sendNotificationError(locale.export_file_error);
            exportState.actions.update({ exporting: false });
          }
        })
        .catch(() => {
          sendNotificationError(locale.export_file_error);
          exportState.actions.update({ exporting: false });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendNotificationError, downloadExport]
  );

  const requestExport = React.useCallback(
    () => {
      exportState.actions.update({ exporting: true });

      const request = doRequest();

      request
        .then((response) => {
          if (response.success) {
            waitExportReady(response.data.token, 0);
          } else {
            sendNotificationError(locale.export_file_error);
            exportState.actions.update({ exporting: false });
          }
        })
        .catch(() => {
          sendNotificationError(locale.export_file_error);
          exportState.actions.update({ exporting: false });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendNotificationError, waitExportReady, doRequest]
  );

  return { requestExport };
}
