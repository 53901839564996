import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { IconButton, ListItem, Menu, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { MenuItemStyled } from "../../../../../shared/components/MenuItemStyled";
import { GroupingArea } from "../../tabular/contexts/FieldsStateContext";
import { TableField } from "../../tabular/hooks/TableField";

interface Props {
  area: GroupingArea;
  availableFields: TableField[];
}

export const SelectGroupFieldInput = ({ area, availableFields }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const onBeforeClose = useCallback(() => {
    setOpen(false);
  }, []);

  const addFieldToGroup = useCallback(
    (field: TableField) => {
      area.addItem({ name: field.guid });
      onBeforeClose();
    },
    [area, onBeforeClose]
  );

  return (
    <>
      <ListItem
        role="Handle"
        ref={(ref) => setAnchorEl(ref)}
        disabled={availableFields.length === 0}
        sx={() => ({
          dispaly: "flex",
          border: "1px solid rgba(33, 33, 33, 0.35)",
          borderRadius: "2px",
          p: "5px",
          backgroundColor: "#fff",
          height: "32px",
          "&:hover": {
            cursor: availableFields.length > 0 ? "pointer" : "unset",
          },
        })}
        onClick={() => {
          if (availableFields.length > 0) {
            setOpen(true);
          }
        }}
      >
        <Typography variant="caption" sx={{ flexGrow: 1 }} color={(theme) => theme.palette.text.primary}>
          Select Field
        </Typography>
        <IconButton>
          <ArrowDropDownRoundedIcon fontSize="medium" />
        </IconButton>
      </ListItem>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={() => onBeforeClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
            width: "322px",
            maxWidth: "400px",
            maxHeight: "350px",
          },
        }}
      >
        {availableFields.map((field) => {
          return (
            <MenuItemStyled key={field?.dimension?.meta.name} onClick={() => addFieldToGroup(field)}>
              <Typography variant="caption">
                {field?.dimension?.config?.customLabel || field?.dimension?.meta?.caption}
              </Typography>
            </MenuItemStyled>
          );
        })}
      </Menu>
    </>
  );
};

export default SelectGroupFieldInput;
