import React from "react";
import { useSelector } from "react-redux";
import { selectRefreshToken } from "../../../../store/currentReportSlice";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import { gridDataStateReducer, initialGridDataState } from "./gridDataState";
import { isTabularConfigurationValid } from "../../utils/isConfigurationValid";
import useDataLoadingViewer from "./useDataLoadingViewer";
import { selectDimensions } from "../../../../store/metaDataSlice";
import { getColumns, getConditionsHash, getSorting } from "../utilities/gridStateHelper";

export default function useGridStateViewer() {
  const { conditionsArea, sortingArea, fieldsArea } = useFieldsStateContext();
  const dimensions = useSelector(selectDimensions);

  const [state, dispatch] = React.useReducer(gridDataStateReducer, initialGridDataState);
  const { loadData, cancelDataLoading, failedMeasures, handleInvalidConfiguration } = useDataLoadingViewer(dispatch);
  const refreshToken = useSelector(selectRefreshToken);

  const loading = state.loading > 0;
  const groupTotalsLoading = state.groupTotalsLoading > 0;

  const columns = React.useMemo(() => getColumns(fieldsArea.values, state.columns), [fieldsArea.values, state.columns]);
  const sorting = React.useMemo(
    () => getSorting(sortingArea.values, fieldsArea.values),
    [sortingArea.values, fieldsArea.values]
  );

  const conditionsHash = React.useMemo(
    () => getConditionsHash(conditionsArea.values, fieldsArea.values),
    [conditionsArea.values, fieldsArea.values]
  );

  const configurationValidity = React.useMemo(() => {
    return isTabularConfigurationValid(conditionsArea.values, fieldsArea.values, dimensions);
  }, [conditionsArea.values, fieldsArea.values, dimensions]);

  React.useEffect(() => {
    if (configurationValidity.valid) {
      loadData();
    } else {
      handleInvalidConfiguration();
      cancelDataLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionsHash, refreshToken, configurationValidity.valid]);

  return {
    rows: state.rows,
    columns,
    sorting,
    groupTotals: state.groupTotals,
    grandTotals: state.grandTotals,
    totalCount: state.totalCount,
    loading,
    groupTotalsLoading,
    error: state.error,
    configurationValidity,
    failedMeasures,
    failedGroupTotalMeasures: undefined,
  };
}
