import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useRef } from "react";
import {
  ConditionConfiguration,
  GroupingField,
  Ordering,
  PivotFieldConfiguration,
  SortConfiguration,
  TabularFieldType,
  TabularGeneralSettings,
  UserMeasureConfigurations,
} from "../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { DimensionDescriptor } from "../../../../api/biApi.types";
import { insertItemAt } from "../../../../utilities/Utilities";
import sortsSlice, {
  addGroupSortingAction,
  addSortingAction,
  moveSortingAction,
  removeSortingAction,
  removeSortingByMetaAction,
  setSortingAction,
  updateSortingAction,
  updateSortingConfigAction,
} from "../../common/hooks/fieldsState.sorts";
import useReducerSafe from "../../common/hooks/useReducerSafe";
import {
  calculateAndAssignSystemLabel,
  getGroupMetaNames,
  moveConditionItem,
  moveGroupItem,
  moveItem,
  removeConditionFromLinked,
  removeConditionItem,
  removeGroupItem,
  removeItem,
  updateConditionConfig,
  updateConditionItem,
  updateLinkAndValidate,
} from "../../common/utilities/fieldsState";
import { AreaItemType, ColumnField, ConditionField, GeneralField, SortField, ValueField } from "../../Types";
import { createTabularDefaultSettings } from "../../utils/isConfigurationValid";
import { updateTableField, updateTableFieldsOrder } from "../utilities/updateTableField";
import settingsSlice, { setSettingsAction, updateSettingsAction } from "./fieldsState.settings";
import { FieldsState, TableField } from "./TableField";
export type FieldsStateReturnType = ReturnType<typeof useFieldsState>;

const initialState: FieldsState = {
  conditions: [],
  fields: [],
  grouping: [],
  settings: createTabularDefaultSettings(),
};

const fieldsStateSlice = createSlice({
  name: "tabularFieldsStates",
  initialState,
  reducers: {
    setConditionsAction: (state, action: PayloadAction<ConditionField[]>) => {
      state.conditions = action.payload;
      state.conditions = updateLinkAndValidate(state.conditions, getMeasuresFromFields(state.fields));
    },
    addConditionsAction: (state, action: PayloadAction<{ field: ConditionField; index: number }>) => {
      const condition: ConditionField = { ...action.payload.field };
      calculateAndAssignSystemLabel(condition, state.conditions);
      const conditions = insertItemAt(state.conditions, condition, action.payload.index);
      state.conditions = updateLinkAndValidate(conditions, getMeasuresFromFields(state.fields));
    },
    removeConditionAction: (state, action: PayloadAction<ConditionField>) => {
      const conditions = removeConditionItem(action.payload, state.conditions);
      removeConditionFromLinked(action.payload.config.guid, getMeasuresFromFields(state.fields));
      state.conditions = updateLinkAndValidate(conditions, []);
    },
    moveConditionAction: (state, action: PayloadAction<{ field: ConditionField; newIndex: number }>) => {
      state.conditions = moveConditionItem(action.payload.field, action.payload.newIndex, state.conditions);
      state.conditions = updateLinkAndValidate(state.conditions, getMeasuresFromFields(state.fields));
    },
    updateConditionAction: (
      state,
      action: PayloadAction<{ field: ConditionField; changes: Partial<ConditionField> }>
    ) => {
      state.conditions = updateConditionItem(action.payload.field, action.payload.changes, state.conditions);
    },
    updateConditionConfigAction: (
      state,
      action: PayloadAction<{ field: ConditionField; changes: Partial<ConditionConfiguration> }>
    ) => {
      const condition = state.conditions.find((c) => c.config.guid === action.payload.field.config.guid);
      if (condition) {
        condition.config = updateConditionConfig(condition.config, action.payload.changes);
      }
    },
    setFieldsAction: (state, action: PayloadAction<TableField[]>) => {
      state.fields = action.payload;
      state.conditions = updateLinkAndValidate(state.conditions, getMeasuresFromFields(action.payload));
    },
    addFieldAction: (state, action: PayloadAction<{ field: TableField; index: number }>) => {
      state.fields = insertItemAt(state.fields, action.payload.field, action.payload.index);
    },
    removeFieldAction: (state, action: PayloadAction<TableField>) => {
      const fields = removeItem("guid", action.payload, state.fields);
      state.fields = fields;
      if (action.payload.measure !== undefined) {
        state.conditions = updateLinkAndValidate(state.conditions, getMeasuresFromFields(fields));
      }
      if (action.payload.fieldType === TabularFieldType.Dimension && action.payload.dimension !== undefined) {
        state.grouping = removeGroupItem({ name: action.payload.guid }, state.grouping);
      }
    },
    moveFieldAction: (state, action: PayloadAction<{ field: TableField; newIndex: number }>) => {
      state.fields = moveItem("guid", action.payload.field, action.payload.newIndex, state.fields);
    },
    updateDimensionFieldAction: (
      state,
      action: PayloadAction<{ field: TableField; changes: Partial<ColumnField> }>
    ) => {
      state.fields = updateTableField("dimension", action.payload.field, action.payload.changes, state.fields);
    },
    updateMeasureFieldAction: (state, action: PayloadAction<{ field: TableField; changes: Partial<ValueField> }>) => {
      const fields = updateTableField("measure", action.payload.field, action.payload.changes, state.fields);
      state.fields = fields;
      state.conditions = updateLinkAndValidate(state.conditions, getMeasuresFromFields(fields));
    },
    updateMeasureFieldConfigAction: (
      state,
      action: PayloadAction<{ field: TableField; changes: Partial<UserMeasureConfigurations> }>
    ) => {
      const field = state.fields.find((f) => f.guid === action.payload.field.guid);
      if (field !== undefined && field.measure !== undefined) {
        const index = state.fields.indexOf(field);
        const measure = cloneDeep(field.measure);
        measure.config = <UserMeasureConfigurations>{ ...measure.config, ...action.payload.changes };
        const fields = [...state.fields];
        fields[index] = { ...field, measure };
        state.fields = fields;
        state.conditions = updateLinkAndValidate(state.conditions, getMeasuresFromFields(fields));
      }
    },
    updateDimensionFieldConfigAction: (
      state,
      action: PayloadAction<{ field: TableField; changes: Partial<PivotFieldConfiguration> }>
    ) => {
      const field = state.fields.find((f) => f.guid === action.payload.field.guid);
      if (field !== undefined && field.dimension !== undefined) {
        const index = state.fields.indexOf(field);
        const dimension = cloneDeep(field.dimension);
        dimension.config = <PivotFieldConfiguration>{ ...dimension.config, ...action.payload.changes };
        const fields = [...state.fields];
        fields[index] = { ...field, dimension };
        state.fields = fields;
      }
    },
    updateFieldsOrderAction: (state, action: PayloadAction<string[]>) => {
      state.fields = updateTableFieldsOrder(state.fields, action.payload);
    },
    setGroupingAction: (state, action: PayloadAction<GroupingField[]>) => {
      const validatedGroups = action.payload.filter((group) => {
        const field = state.fields.find((f) => f.guid === group.name);
        return field !== undefined;
      });
      state.grouping = validatedGroups;
    },
    updateGroupAction: (state, action: PayloadAction<{ group: GroupingField; changes: Partial<GroupingField> }>) => {
      const group = state.grouping.find((g) => g.name === action.payload.group.name);
      if (group !== undefined) {
        const index = state.grouping.indexOf(group);
        const newGroup = { ...group, ...action.payload.changes };
        state.grouping[index] = newGroup;
      }
    },
    addGroupAction: (state, action: PayloadAction<GroupingField>) => {
      const group = action.payload;
      state.grouping = [...state.grouping, group];
    },
    removeGroupAction: (state, action: PayloadAction<GroupingField>) => {
      const grouping = removeGroupItem(action.payload, state.grouping);
      state.grouping = grouping;
    },
    moveGroupAction: (
      state,
      action: PayloadAction<{
        field: GroupingField;
        newIndex: number;
        onGroupMoved?: (groups: GroupingField[]) => void;
      }>
    ) => {
      const groups = moveGroupItem(action.payload.field, action.payload.newIndex, state.grouping);
      action.payload.onGroupMoved?.(cloneDeep(groups));
      state.grouping = groups;
    },
  },
});

const {
  setConditionsAction,
  addConditionsAction,
  removeConditionAction,
  moveConditionAction,
  updateConditionAction,
  setFieldsAction,
  addFieldAction,
  removeFieldAction,
  moveFieldAction,
  updateDimensionFieldAction,
  updateDimensionFieldConfigAction,
  updateMeasureFieldAction,
  updateMeasureFieldConfigAction,
  updateFieldsOrderAction,
  setGroupingAction,
  updateGroupAction,
  addGroupAction,
  removeGroupAction,
  moveGroupAction,
  updateConditionConfigAction,
} = fieldsStateSlice.actions;

export default function useFieldsState() {
  const [state, dispatch] = useReducerSafe(fieldsStateSlice);
  const [sortingState, dispatchSorting] = useReducerSafe(sortsSlice);
  const [settingsState, dispatchSettings] = useReducerSafe(settingsSlice);

  const stateRef = useRef(state);
  stateRef.current = state;
  const sortingStateRef = useRef(sortingState.sorts);
  sortingStateRef.current = sortingState.sorts;

  //CONDITIONS
  const setConditions = (fields: ConditionField[]) => dispatch(setConditionsAction(fields));

  const addCondition = (field: ConditionField, index: number) => {
    dispatch(addConditionsAction({ field, index: index }));
  };

  const removeCondition = (field: ConditionField) => dispatch(removeConditionAction(field));

  const moveCondition = (field: ConditionField, newIndex: number) => dispatch(moveConditionAction({ field, newIndex }));

  const updateCondition = (field: ConditionField, changes: Partial<ConditionField>) =>
    dispatch(updateConditionAction({ field, changes }));

  const updateConditionConfig = (field: ConditionField, changes: Partial<ConditionConfiguration>) =>
    dispatch(updateConditionConfigAction({ field, changes }));

  //FIELDS
  const setFields = (fields: TableField[]) => dispatch(setFieldsAction(fields));

  const addField = (field: TableField, idx: number) => {
    dispatch(addFieldAction({ field, index: idx }));
  };

  const removeField = (field: TableField) => dispatch(removeFieldAction(field));

  const moveField = (field: TableField, newIndex: number) => dispatch(moveFieldAction({ field, newIndex }));

  const updateDimensionField = (field: TableField, changes: Partial<ColumnField>) =>
    dispatch(updateDimensionFieldAction({ field, changes }));

  const updateDimensionFieldConfig = (field: TableField, changes: Partial<PivotFieldConfiguration>) => {
    dispatch(updateDimensionFieldConfigAction({ field, changes }));
    if (field.dimension?.config?.customLabel !== changes.customLabel) {
      const sortingField = sortingState.sorts.find((s) => s.meta.name === field.dimension?.meta.name);
      if (sortingField) {
        updateSortingConfig(sortingField, { caption: changes.customLabel });
      }
    }
  };

  const updateMeasureField = (field: TableField, changes: Partial<ValueField>) =>
    dispatch(updateMeasureFieldAction({ field, changes }));

  const updateMeasureFieldConfig = (field: TableField, changes: Partial<UserMeasureConfigurations>) =>
    dispatch(updateMeasureFieldConfigAction({ field, changes }));

  const updateFieldsOrder = (guids: string[]) => dispatch(updateFieldsOrderAction(guids));

  //SORTING
  const setSorting = (fields: SortField[], groupMetaNames: string[]) => {
    dispatchSorting(setSortingAction({ fields, groupMetaNames }));
  };

  const addSorting = (field: GeneralField) => {
    const conditionField: SortField = {
      meta: field.meta,
      config: {
        name: field.meta.name,
        ordering: Ordering.Ascending,
        caption: field.config.customLabel || field.meta.caption,
        isGroupField: false,
      },
      areaItemType: AreaItemType.SORTS,
    };
    dispatchSorting(addSortingAction(conditionField));
  };

  const addGroupSorting = (field: GeneralField, groupMetaNames: string[]) => {
    const conditionField: SortField = {
      meta: field.meta,
      config: {
        name: field.meta.name,
        ordering: Ordering.Ascending,
        caption: field.config.customLabel || field.meta.caption,
        isGroupField: true,
      },
      areaItemType: AreaItemType.SORTS,
    };
    dispatchSorting(addGroupSortingAction({ field: conditionField, groupMetaNames }));
  };

  const removeSorting = (field: SortField) => dispatchSorting(removeSortingAction(field));

  const removeSortingByMeta = (field: DimensionDescriptor) => dispatchSorting(removeSortingByMetaAction(field));

  const moveSorting = (field: SortField, newIndex: number) => dispatchSorting(moveSortingAction({ field, newIndex }));

  const updateSorting = (field: SortField, changes: Partial<SortField>) => {
    dispatchSorting(updateSortingAction({ field, changes }));
  };

  const updateSortingConfig = (field: SortField, changes: Partial<SortConfiguration>) => {
    dispatchSorting(updateSortingConfigAction({ field, changes }));
  };

  const setGrouping = (grouping: GroupingField[]) => dispatch(setGroupingAction(grouping));

  const updateGroup = (group: GroupingField, changes: Partial<GroupingField>) =>
    dispatch(updateGroupAction({ group, changes }));

  const addGroup = (group: GroupingField) => {
    const field = stateRef.current.fields.find((f) => f.guid === group.name);
    if (field?.dimension?.meta) {
      const optimisticGroups = [...stateRef.current.grouping, group];

      removeSortingByMeta(field.dimension.meta);
      addGroupSorting(field.dimension, getGroupMetaNames(optimisticGroups, stateRef.current.fields));
    }
    dispatch(addGroupAction(group));
  };

  const removeGroup = (group: GroupingField) => {
    const field = stateRef.current.fields.find((f) => f.guid === group.name);
    if (field?.dimension?.meta) {
      removeSortingByMeta(field.dimension.meta);
    }
    dispatch(removeGroupAction(group));
  };

  const moveGroup = (field: GroupingField, newIndex: number) => {
    dispatch(
      moveGroupAction({
        field,
        newIndex,
        onGroupMoved: (groups) =>
          setSorting(sortingStateRef.current, getGroupMetaNames(groups, stateRef.current.fields)),
      })
    );
  };

  const updateSettings = (changes: Partial<TabularGeneralSettings>) => dispatchSettings(updateSettingsAction(changes));
  const setSettings = (settings: TabularGeneralSettings) => dispatchSettings(setSettingsAction(settings));

  return {
    ...state,
    ...sortingState,
    ...settingsState,

    setConditions,
    addCondition,
    updateCondition,
    updateConditionConfig,
    removeCondition,
    moveCondition,

    setFields,
    addField,
    removeField,
    moveField,
    updateDimensionField,
    updateDimensionFieldConfig,
    updateMeasureField,
    updateMeasureFieldConfig,
    updateFieldsOrder,

    setSorting,
    addSorting,
    removeSorting,
    removeSortingByMeta,
    moveSorting,
    updateSorting,
    updateSortingConfig,

    setGrouping,
    updateGroup,
    addGroup,
    removeGroup,
    moveGroup,

    updateSettings,
    setSettings,
  };
}

const getMeasuresFromFields = (fields: TableField[]) => {
  return fields
    .filter((f) => f.fieldType === TabularFieldType.Measure)
    .map((f) => f.measure)
    .filter((m): m is ValueField => !!m);
};
