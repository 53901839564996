import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkAuthentication } from "./identity";

export const useAuth = (onAuthenticated: () => void, onNotAuthenticated: () => void) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      let authenticated = false;
      try {
        authenticated = await checkAuthentication(onNotAuthenticated);
      } catch (error) {
        return navigate("/error");
      }
      setAuthenticated(authenticated);
      if (authenticated && typeof onAuthenticated === "function") {
        onAuthenticated();
      }
    };

    checkAuth();
    // eslint-disable-next-line
  }, []);

  return isAuthenticated;
};
