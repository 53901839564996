import { Box, Stack, Typography } from "@mui/material";
import PromptDialog from "../../../../shared/components/dialog/PromptDialog";
import InlineLoaderSvg from "../../../../shared/components/inlineLoader/InlineLoaderSvg";

interface Props {
  open: boolean;
  text?: string;
}
export default function ApplyingDialog({ open, text }: Props) {
  return (
    <PromptDialog open={open} sx={{ width: 514, height: 300, p: 0 }}>
      <Stack sx={{ p: 3, flex: 1, alignItems: "center", justifyContent: "center", gap: 1 }}>
        <Box sx={{ width: 80 }}>
          <InlineLoaderSvg />
        </Box>
        <Typography variant="h6">{text}</Typography>
      </Stack>
    </PromptDialog>
  );
}
