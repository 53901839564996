import {
  Grouping,
  PivotFieldConfiguration,
  TabularFieldType,
} from "../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { generateGuid } from "../../../../../shared/utilities/generateGuid";
import { DimensionDescriptor, ItemDataType, MeasureDescriptor, MetaItemType } from "../../../../api/biApi.types";
import { formatDate } from "../../../../utilities/Utilities";
import { TableField } from "../../tabular/hooks/TableField";
import { referenceDateFields } from "../../tabular/utilities/referenceDateFields";
import { AreaItemType, ColumnField, ConditionField, GeneralField, RowField } from "../../Types";
import { MeasureUtils } from "../../utils/MeasureUtils";
import { toAmountType, toCalculateBy } from "../customMeasure/utilities/measureConverter";

const DEFAULT_DATE_GROUPING = Grouping.ByYear;

export function createConditionField(meta: DimensionDescriptor) {
  const metaClone = cloneDeep(meta);
  const values = metaClone.type === ItemDataType.Date ? [formatDate(new Date())] : [];
  const field: ConditionField = {
    meta: metaClone,
    config: { guid: generateGuid(), filter: { dimensionName: metaClone.name, values }, parameter: true },
    areaItemType: AreaItemType.CONDITIONS,
  };
  return field;
}

export function createTabularField(meta: DimensionDescriptor | MeasureDescriptor) {
  const metaClone = cloneDeep(meta);
  const newField: TableField = {
    fieldType: metaClone.itemType === MetaItemType.DIMENSION ? TabularFieldType.Dimension : TabularFieldType.Measure,
    guid: generateGuid(),
  };

  if (newField.fieldType === TabularFieldType.Dimension) {
    newField.dimension = {
      areaItemType: AreaItemType.FIELDS,
      meta: metaClone as DimensionDescriptor,
      config: {
        name: metaClone.name,
        grouping:
          metaClone.type === ItemDataType.Date && referenceDateFields.includes(meta.name)
            ? DEFAULT_DATE_GROUPING
            : undefined,
        customLabel: undefined,
        aggregation: undefined,
      },
    };
  } else {
    newField.measure = MeasureUtils.createValueField(metaClone as MeasureDescriptor, AreaItemType.FIELDS);
  }

  if (newField.fieldType === TabularFieldType.Measure && newField.measure !== undefined) {
    newField.measure.config.calculateByField = toCalculateBy(newField.measure.meta.units);
    newField.measure.config.amountType = toAmountType(newField.measure.meta.units);
    if (!newField.measure.config.format && newField.measure.meta.defaultFormat?.formatType === "Numeric") {
      newField.measure.config.format = newField.measure.meta.defaultFormat;
    }
  }
  return newField;
}

export function createMeasureField(meta: MeasureDescriptor) {
  const metaClone = cloneDeep(meta);
  const valueField = MeasureUtils.createValueField(metaClone, AreaItemType.VALUES);
  if (!valueField.config.format && valueField.meta.defaultFormat?.formatType === "Numeric") {
    valueField.config.format = valueField.meta.defaultFormat;
  }
  return valueField;
}

export function createRowField(meta: DimensionDescriptor) {
  const metaClone = cloneDeep(meta);
  const cloneField: RowField = {
    meta: metaClone,
    areaItemType: AreaItemType.ROWS,
    config: {
      name: metaClone.name,
      showEmptyItems: false,
      displayValues: metaClone.displayValues,
      grouping: metaClone.type === ItemDataType.Date ? DEFAULT_DATE_GROUPING : undefined,
      customLabel: undefined,
      aggregation: undefined,
    } as PivotFieldConfiguration,
  };
  return cloneField;
}

export function createColumnField(meta: DimensionDescriptor) {
  const metaClone = cloneDeep(meta);
  const columnField: ColumnField = {
    meta: metaClone,
    areaItemType: AreaItemType.COLUMNS,
    config: {
      name: metaClone.name,
      displayValues: metaClone.displayValues,
      showEmptyItems: false,
      grouping: metaClone.type === ItemDataType.Date ? DEFAULT_DATE_GROUPING : undefined,
      customLabel: undefined,
      aggregation: undefined,
    } as PivotFieldConfiguration,
  };
  return columnField;
}

export function createArgument(meta: DimensionDescriptor) {
  const metaClone = cloneDeep(meta);
  const argumentField: GeneralField = {
    meta: metaClone,
    areaItemType: AreaItemType.ARGUMENTS,
    config: {
      name: metaClone.name,
      grouping: metaClone.type === ItemDataType.Date ? DEFAULT_DATE_GROUPING : undefined,
      customLabel: undefined,
    } as PivotFieldConfiguration,
  };
  return argumentField;
}
