import { getSettings } from "../../../../../shared/variables";

export const getEditReportUrl = (reportId: string) => {
  return `../builder/${reportId}`;
};
export const getViewReportUrl = (reportId: string) => {
  return `../reports/${reportId}`;
};
export const getUiReportsUrl = (clientCode: string) => {
  return `${getSettings().ADMIN_PORTAL_URI}/${clientCode}/smart-views/company-reports`;
};
export const getOrganizationReportTemplatesUrl = (organizationCode: string) => {
  return `${getSettings().ADMIN_PORTAL_URI}/${organizationCode}/smart-views/report-templates`;
};
export const getEditTemplateUrl = (dataSource: string, templateId: string, organization: string) => {
  return `/${dataSource}/${organization}/template/${templateId}`;
};
export const getCreateReportFromTemplateUrl = (clientCode: string, templateId: string, reportId: string) => {
  return `/${clientCode}/builder/${reportId}?templateId=${templateId}`;
};
export const getRunPublicApiReportUrl = () => {
  return `${getSettings().PORTAL_BACKEND_BASE_URI}/reports/Public/run`;
};
export const getEditApiReportUrl = (reportId: string) => {
  return `../api-builder/${reportId}`;
};
export const getViewApiReportUrl = (reportId: string) => {
  return `../api-reports/${reportId}`;
};
export const getApiReportsUrl = (clientCode: string) => {
  return `${getSettings().ADMIN_PORTAL_URI}/${clientCode}/smart-views/api-reports`;
};
