import { Box } from "@mui/material";
import React from "react";
import { PivotFieldConfiguration } from "../../../../../shared/reporting/api/biClient.types";
import { MetaDescriptorBase, ItemDataType } from "../../../../api/biApi.types";
import { MetaField, GeneralField } from "../../Types";
import DisplayFieldsComponent from "../../common/fields/format/DisplayFieldsComponent";
import TextField from "../../common/fields/format/TextField";

const getTextFields = <T extends MetaField<MetaDescriptorBase>>(fields: T[]): T[] => {
  return fields.filter((v) => v.meta.type === ItemDataType.General);
};

interface Props {
  fields: GeneralField[];
  onUpdateConfig: (field: GeneralField, changes: Partial<PivotFieldConfiguration>) => void;
}

export default function TextFieldContainer({ fields, onUpdateConfig }: Props) {
  const textFields = React.useMemo(() => getTextFields(fields), [fields]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      {textFields.map((field) => (
        <TextField key={field.meta.name} field={field} onUpdateConfig={onUpdateConfig}>
          <DisplayFieldsComponent field={field} onUpdateConfig={onUpdateConfig} />
        </TextField>
      ))}
    </Box>
  );
}
