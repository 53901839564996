import { useEffect } from "react";
import { useLocalization } from "../../../../hooks/useLocalization";
import { useExportStateContext } from "../../common/contexts/ExportStateContext";
import { PreviewChartStateType } from "./useChartStateBuilder";
import { ExportState } from "../../common/hooks/exportState";

export default function useUpdateExportState(state: PreviewChartStateType) {
  const { chart: locale } = useLocalization();
  const exportState = useExportStateContext();

  useEffect(() => {
    const s: Partial<ExportState> = { exportAllowed: state.fieldsConfigured && !state.calculating };
    if (state.calculating) {
      s.warning = locale.chart_is_built_warning;
    } else if (!state.fieldsConfigured) {
      s.warning = locale.chart_is_not_configured_warning;
    } else {
      s.warning = undefined;
    }
    exportState.actions.update(s);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.fieldsConfigured, state.calculating]);
}
