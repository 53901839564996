import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { IconButton } from "@mui/material";
import React from "react";
import { Report } from "../../../../../shared/reporting/api/biClient.types";
import { useClientContext } from "../../../../contexts/ClientContext";
import ReportActions from "./ReportActions";
import { useEnhancedBiApiClientProvider } from "../../../../contexts/ApiClientProviderContext";

interface Props {
  report: Report;
  hideChangeGroup?: boolean;
}

export default function MoreAction({ report, hideChangeGroup }: Props) {
  const { clientCode } = useClientContext();
  const [openActionsMenu, setOpenActionsMenu] = React.useState(false);
  const [actionMenuRef, setActionMenuRef] = React.useState<HTMLElement | null>(null);
  const { getReportsUrl } = useEnhancedBiApiClientProvider();

  return (
    <>
      <IconButton key="action-button" ref={setActionMenuRef} onClick={() => setOpenActionsMenu(true)}>
        <MoreHorizOutlinedIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
      </IconButton>
      {openActionsMenu && (
        <ReportActions
          key={"actions"}
          anchorEl={actionMenuRef}
          report={report}
          onDeleted={() => (window.location.href = getReportsUrl(clientCode))}
          onClose={() => setOpenActionsMenu(false)}
          hideChangeGroup={hideChangeGroup}
        />
      )}
    </>
  );
}
