import { Divider, Grid, Popover } from "@mui/material";
import SearchField from "../../../../shared/components/inputs/SearchField";
import { useState } from "react";
import ScrollableFlexContainer from "../../../../shared/components/ScrollableFlexContainer";
import { ReportGroup, ReportInfo } from "../../../../shared/reporting/api/biClient.types";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import ReportSelectorGroupedList from "./ReportSelectorGroupedList";
import ReportSelectorNonGroupedList from "./ReportSelectorNonGroupedList";

interface Props {
  anchorEl: HTMLElement | null;
  clientCode: string;
  currentReportName: string | undefined;
  groups: Record<string, ReportGroup[]> | undefined;
  reports: ReportInfo[];
  isLoading: boolean;
  isGrouped: boolean;
  onClose: () => void;
  onSelectNewReport: (reportId: string) => void;
}

const ReportSelectorMenu = ({
  anchorEl,
  onClose,
  reports,
  clientCode,
  currentReportName,
  onSelectNewReport,
  groups,
  isLoading,
  isGrouped,
}: Props) => {
  const [textFilter, setTextFilter] = useState("");

  return (
    <Popover
      disableRestoreFocus // Without this prop, the TextField inside the SearchField will lose its autofocus. https://github.com/mui/material-ui/issues/33004#issuecomment-1455260156
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      slotProps={{
        paper: {
          sx: {
            boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
            maxHeight: "500px",
            width: "100%",
            maxWidth: "440px",
            height: "100%",
          },
        },
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
          width: "100%",
        }}
      >
        <Grid item flexDirection="row" justifyContent="stretch" display="flex" sx={{ px: 2, pt: 2 }}>
          <SearchField onSearch={(t) => setTextFilter(t.toLowerCase())} autoFocus debounceTimeMs={300} fullWidth />
        </Grid>
        <Divider />
        <ScrollableFlexContainer>
          {isLoading && <InlineLoader overlay />}
          {!isLoading &&
            (isGrouped ? (
              <ReportSelectorGroupedList
                clientCode={clientCode}
                reports={reports}
                groups={groups}
                textFilter={textFilter}
                currentReportName={currentReportName}
                onSelectNewReport={onSelectNewReport}
              />
            ) : (
              <ReportSelectorNonGroupedList
                reports={reports}
                textFilter={textFilter}
                currentReportName={currentReportName}
                onSelectNewReport={onSelectNewReport}
              />
            ))}
        </ScrollableFlexContainer>
      </Grid>
    </Popover>
  );
};

export default ReportSelectorMenu;
