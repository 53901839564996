import { getRunPublicApiReportUrl } from "../../../common/utilities/editReportUrl";
import { useRequestConfigViewer } from "../../../tabular/hooks/useRequestConfigViewer";
import { createCurlCommand, createJson, createRequestConfig } from "./helpers/codeHelper";
import CodeContainer from "./CodeContainer";
import { Stack, Typography } from "@mui/material";
import CopyButton from "./CopyButton";

interface Props {
  isLoading: boolean;
}
const RequestCode = ({ isLoading }: Props) => {
  const { requestConfig } = useRequestConfigViewer();
  const payload = createRequestConfig(requestConfig);
  const curlCommand = createCurlCommand(payload, getRunPublicApiReportUrl());

  return (
    <CodeContainer
      Header={
        <>
          <Typography variant="h6">Request</Typography>
          <Stack direction="row" spacing={1}>
            <CopyButton title="Copy Payload" code={createJson(payload)} />
            <CopyButton title="Copy All" code={curlCommand} />
          </Stack>
        </>
      }
      title="Request"
      code={curlCommand}
      isLoading={isLoading}
    />
  );
};

export default RequestCode;
