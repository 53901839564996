import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, IconButton, Typography } from "@mui/material";
import TooltipWrapper from "../../../../shared/components/TooltipWrapper";
import { CustomLabelData } from "./CustomLabelData";

interface ViewCustomLabelProps extends CustomLabelData {
  onRenamClick: () => void;
  readonly?: boolean;
}
export default function ViewCustomLabel({
  customLabelValue,
  originalName,
  onRenamClick,
  readonly,
}: ViewCustomLabelProps) {
  return (
    <>
      <Box flex={1}>
        <Typography variant="subtitle1">{customLabelValue ? customLabelValue : originalName}</Typography>
        {customLabelValue && <Typography variant="caption">Default name - {originalName}</Typography>}
      </Box>
      {readonly !== true && (
        <TooltipWrapper title="Rename" placement="bottom">
          <IconButton onClick={onRenamClick}>
            <DriveFileRenameOutlineIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
          </IconButton>
        </TooltipWrapper>
      )}
    </>
  );
}
