import { useSelector } from "react-redux";
import { selectReportEditMode } from "../../../../store/currentReportSlice";
import React from "react";
import useExport from "../../../../hooks/useExport";
import useRequestConfigBuilder from "./useRequestConfigBuilder";
import { useRequestConfigViewer } from "./useRequestConfigViewer";
import biClient from "../../../../api/biApi";
import { selectDimensions } from "../../../../store/metaDataSlice";
import { createBuildTabularExportRequest, createRunTabularExportRequest } from "../utilities/exportHelper";

export const useTabularExport = () => {
  const { requestConfig: buildConfig } = useRequestConfigBuilder();
  const { requestConfig: viewConfig } = useRequestConfigViewer();
  const editMode = useSelector(selectReportEditMode);
  const dimensions = useSelector(selectDimensions);

  const buildExportRef = React.useRef(buildConfig);
  const viewExportRef = React.useRef(viewConfig);

  const tabularExportRequest = React.useCallback(
    () =>
      editMode === true
        ? biClient.requestBuildTabularExport(createBuildTabularExportRequest(buildExportRef.current, dimensions, false))
        : biClient.requestRunTabularExport(createRunTabularExportRequest(viewExportRef.current, dimensions, false)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMode, dimensions]
  );

  const tabularExportWithParametersRequest = React.useCallback(
    () =>
      editMode === true
        ? biClient.requestBuildTabularExport(createBuildTabularExportRequest(buildExportRef.current, dimensions, true))
        : biClient.requestRunTabularExport(createRunTabularExportRequest(viewExportRef.current, dimensions, true)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMode, dimensions]
  );

  const { requestExport } = useExport({ makeRequest: tabularExportRequest });
  const { requestExport: requestExportWithParameters } = useExport({ makeRequest: tabularExportWithParametersRequest });

  React.useEffect(() => {
    buildExportRef.current = buildConfig;
  }, [buildConfig]);

  React.useEffect(() => {
    viewExportRef.current = viewConfig;
  }, [viewConfig]);

  return { requestExport, requestExportWithParameters };
};
