import { SvgIcon, SvgIconProps } from "@mui/material";

export const PivotIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M8.33333 6.66667V2.5H15.8333C16.2917 2.5 16.6842 2.66306 17.0108 2.98917C17.3369 3.31583 17.5 3.70833 17.5 4.16667V6.66667H8.33333ZM4.16667 17.5C3.70833 17.5 3.31583 17.3369 2.98917 17.0108C2.66306 16.6842 2.5 16.2917 2.5 15.8333V8.33333H6.66667V17.5H4.16667ZM2.5 6.66667V4.16667C2.5 3.70833 2.66306 3.31583 2.98917 2.98917C3.31583 2.66306 3.70833 2.5 4.16667 2.5H6.66667V6.66667H2.5ZM10.25 17.75L8.08333 15.5833C8 15.5 7.94111 15.4097 7.90667 15.3125C7.87167 15.2153 7.85417 15.1111 7.85417 15C7.85417 14.8889 7.87167 14.7847 7.90667 14.6875C7.94111 14.5903 8 14.5 8.08333 14.4167L10.2708 12.2292C10.4236 12.0764 10.6111 12 10.8333 12C11.0556 12 11.25 12.0833 11.4167 12.25C11.5694 12.4028 11.6458 12.5936 11.6458 12.8225C11.6458 13.0519 11.5694 13.25 11.4167 13.4167L10.7083 14.1667H12.5C12.9583 14.1667 13.3508 14.0036 13.6775 13.6775C14.0036 13.3508 14.1667 12.9583 14.1667 12.5V10.6667L13.3958 11.4375C13.2431 11.5903 13.0556 11.6667 12.8333 11.6667C12.6111 11.6667 12.4167 11.5833 12.25 11.4167C12.0972 11.2639 12.0208 11.0694 12.0208 10.8333C12.0208 10.5972 12.0972 10.4028 12.25 10.25L14.4167 8.08333C14.5 8 14.5903 7.94083 14.6875 7.90583C14.7847 7.87139 14.8889 7.85417 15 7.85417C15.1111 7.85417 15.2153 7.87139 15.3125 7.90583C15.4097 7.94083 15.5 8 15.5833 8.08333L17.7708 10.2708C17.9236 10.4236 18 10.6111 18 10.8333C18 11.0556 17.9167 11.25 17.75 11.4167C17.5972 11.5694 17.4028 11.6458 17.1667 11.6458C16.9306 11.6458 16.7361 11.5694 16.5833 11.4167L15.8333 10.6667V12.5C15.8333 13.4167 15.5069 14.2014 14.8542 14.8542C14.2014 15.5069 13.4167 15.8333 12.5 15.8333H10.7083L11.4375 16.5833C11.5903 16.75 11.6667 16.9444 11.6667 17.1667C11.6667 17.3889 11.5833 17.5833 11.4167 17.75C11.2639 17.9028 11.0694 17.9792 10.8333 17.9792C10.5972 17.9792 10.4028 17.9028 10.25 17.75Z" />
    </SvgIcon>
  );
};
export default PivotIcon;
