import { Box } from "@mui/material";
import { useLocalization } from "../../../hooks/useLocalization";
import BuildProgressWrapper from "../BuildProgressWrapper";
import PreviewActions from "../common/PreviewActions";
import PartContainer from "../PartContainer";
import { EditorProps } from "../Types";
import PivotContainer from "./PivotContainer";
import withExport from "./preview/hocs/withExport";
import useGridStateBuilder from "./preview/hooks/useGridStateBuilder";
import PreviewComponent from "./preview/PreviewComponent";
import TableComponent from "./table/TableComponent";

export default function PivotEditor({ report, exportAllowed = true, ReportTypeSelector }: EditorProps) {
  const { common: locale } = useLocalization();

  return (
    <PivotContainer report={report}>
      <PartContainer sx={{ maxWidth: "24rem", minWidth: "24rem" }} caption={<Box pl="1.3rem">Builder</Box>}>
        <TableComponent ReportTypeSelector={ReportTypeSelector} />
      </PartContainer>
      <BuildProgressWrapper>
        <PartContainer
          sx={{ borderRight: "unset", p: "1.3rem", pt: 2 }}
          containerSx={{ position: "relative", gap: "1rem" }}
          caption={locale.report_preview_label}
          actions={<PreviewActions />}
        >
          <PivotEditorContainer exportAllowed={exportAllowed} />
        </PartContainer>
      </BuildProgressWrapper>
    </PivotContainer>
  );
}

const PreviewComponentWithExport = withExport(PreviewComponent);

function PivotEditorContainer({ exportAllowed = true }: { exportAllowed?: boolean }) {
  const state = useGridStateBuilder();
  return exportAllowed ? <PreviewComponentWithExport state={state} /> : <PreviewComponent state={state} />;
}
