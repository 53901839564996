import { SvgIcon, SvgIconProps } from "@mui/material";

export const ExportPdfIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path
        d="M16.666 1.66699H6.66602C5.74935 1.66699 4.99935 2.41699 4.99935 3.33366V13.3337C4.99935 14.2503 5.74935 15.0003 6.66602 15.0003H16.666C17.5827 15.0003 18.3327 14.2503 18.3327 13.3337V3.33366C18.3327 2.41699 17.5827 1.66699 16.666 1.66699ZM9.58268 7.91699C9.58268 8.60866 9.02435 9.16699 8.33268 9.16699H7.49935V10.8337H6.24935V5.83366H8.33268C9.02435 5.83366 9.58268 6.39199 9.58268 7.08366V7.91699ZM13.7493 9.58366C13.7493 10.2753 13.191 10.8337 12.4993 10.8337H10.416V5.83366H12.4993C13.191 5.83366 13.7493 6.39199 13.7493 7.08366V9.58366ZM17.0827 7.08366H15.8327V7.91699H17.0827V9.16699H15.8327V10.8337H14.5827V5.83366H17.0827V7.08366ZM7.49935 7.91699H8.33268V7.08366H7.49935V7.91699ZM3.33268 5.00033H1.66602V16.667C1.66602 17.5837 2.41602 18.3337 3.33268 18.3337H14.9993V16.667H3.33268V5.00033ZM11.666 9.58366H12.4993V7.08366H11.666V9.58366Z"
        fill="#8E9BA9"
      />
    </SvgIcon>
  );
};
export default ExportPdfIcon;
