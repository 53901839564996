import SaveReportFormWrapper from "../../../../../shared/reporting/components/reports/SaveReportFormWrapper";
import PromptDialog from "../../../../../shared/components/dialog/PromptDialog";
import ReportGroupsConnector from "../../../../../shared/reporting/components/reports/ReportGroupsConnector";
import { useClientContext } from "../../../../contexts/ClientContext";
import useSaveReportDialog from "../hooks/useSaveReportDialog";
import { SaveReportDialogProps } from "../../Types";

export default function SaveReportDialog({
  report,
  open,
  title,
  saveBtnTitle,
  reportConfiguration,
  onClose,
}: SaveReportDialogProps) {
  const { clientCode } = useClientContext();
  const { error, onSaveReport, saving } = useSaveReportDialog({
    report,
    reportConfiguration,
    onClose,
  });

  return (
    <PromptDialog open={open}>
      <ReportGroupsConnector clientCode={clientCode}>
        <SaveReportFormWrapper
          report={report}
          error={error}
          title={title}
          saveBtnTitle={saveBtnTitle}
          showName
          showLevel
          saving={saving}
          onClose={onClose}
          onSave={onSaveReport}
        />
      </ReportGroupsConnector>
    </PromptDialog>
  );
}
