import { Button, Typography } from "@mui/material";
import { useLocalization } from "../hooks/useLocalization";
import ErrorIcon from "../icons/ErrorIcon";
import InfoPage from "./infoPage/InfoPage";

interface Props {
  emphasize?: string;
  title?: string;
  subtitle?: string;
}

const Error = ({ emphasize: emphasise, title, subtitle }: Props) => {
  const { pages } = useLocalization();

  return (
    <InfoPage
      Icon={<ErrorIcon />}
      emphasize={emphasise || pages.error.emphasize}
      title={title || pages.error.title}
      subtitle={<Typography color="text.secondary">{subtitle || pages.error.subtitle}</Typography>}
    >
      <Button sx={{ width: "10rem" }} href="/" color="primary" variant="outlined">
        {pages.error.button}
      </Button>
    </InfoPage>
  );
};

export default Error;
