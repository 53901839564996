import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SortConfiguration } from "../../../../../shared/reporting/api/biClient.types";
import { DimensionDescriptor } from "../../../../api/biApi.types";
import { SortField } from "../../Types";
import { moveSortingItem, removeSortingItem, updateSortingItem } from "../utilities/fieldsState";
import { sortSortingFields } from "../utilities/sortFieldStateHelper";

export type SortingState = {
  sorts: SortField[];
};

const initialSortingState: SortingState = { sorts: [] };

const sortsSlice = createSlice({
  name: "sortsFieldStates",
  initialState: initialSortingState,
  reducers: {
    setSortingAction: (state, action: PayloadAction<{ fields: SortField[]; groupMetaNames: string[] }>) => {
      state.sorts = sortSortingFields([...action.payload.fields], action.payload.groupMetaNames);
    },
    addSortingAction: (state, action: PayloadAction<SortField>) => {
      state.sorts = sortSortingFields([...state.sorts, action.payload], []);
    },
    addGroupSortingAction: (state, action: PayloadAction<{ field: SortField; groupMetaNames: string[] }>) => {
      state.sorts = sortSortingFields([...state.sorts, action.payload.field], action.payload.groupMetaNames);
    },
    removeSortingAction: (state, action: PayloadAction<SortField>) => {
      const sorts = removeSortingItem(action.payload, state.sorts);
      state.sorts = sorts;
    },
    removeSortingByMetaAction: (state, action: PayloadAction<DimensionDescriptor>) => {
      const item = state.sorts.find((s) => s.meta.name === action.payload.name);
      if (item === undefined) return;
      const sorts = removeSortingItem(item, state.sorts);
      state.sorts = sorts;
    },
    moveSortingAction: (state, action: PayloadAction<{ field: SortField; newIndex: number }>) => {
      state.sorts = moveSortingItem(action.payload.field, action.payload.newIndex, state.sorts);
    },
    updateSortingAction: (state, action: PayloadAction<{ field: SortField; changes: Partial<SortField> }>) => {
      state.sorts = updateSortingItem(action.payload.field, action.payload.changes, state.sorts);
    },
    updateSortingConfigAction: (
      state,
      action: PayloadAction<{ field: SortField; changes: Partial<SortConfiguration> }>
    ) => {
      const item = state.sorts.find((s) => s.meta.name === action.payload.field.meta.name);
      if (item !== undefined) {
        item.config = { ...item.config, ...action.payload.changes };
      }
    },
  },
});

export const {
  setSortingAction,
  addSortingAction,
  addGroupSortingAction,
  removeSortingAction,
  removeSortingByMetaAction,
  moveSortingAction,
  updateSortingAction,
  updateSortingConfigAction,
} = sortsSlice.actions;

export default sortsSlice;
