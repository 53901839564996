import { Grid } from "@mui/material";
import GroupSettings from "./GroupSettings";
import MeasureSettings from "./MeasureSettings";
import GeneralSettings from "./GeneralSettings";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";

export default function SettingsContainer() {
  return (
    <ScrollableFlexContainer>
      <Grid container sx={{ gap: 2, flexDirection: "column", px: "1.3rem" }}>
        <GeneralSettings />
        <GroupSettings />
        <MeasureSettings />
      </Grid>
    </ScrollableFlexContainer>
  );
}
