import { Stack } from "@mui/material";
import LinkIcon from "../../../../../icons/LinkIcon";
import NavigationParentIcon from "../../../../../../shared/reporting/icons/NavigationParentIcon";
import { ConditionField, ShowFieldOptionsSettings } from "../../../Types";
import { LinkedAreaFieldItem } from "../../../common/fields/areaFields/LinkedAreaFieldItem";
import ConditionMarker from "../conditions/ConditionMarker";
import { formatCaption } from "../conditions/conditionsHelper";
import AreaFieldItem from "../../../common/fields/areaFields/AreaFieldItem";

interface Props {
  conditions: ConditionField[];
  canBeRemoved: boolean;
  disableEqualitySelection?: boolean;
  onRemoveItem: (item: ConditionField) => void;
  onShowOptions?: (settings: ShowFieldOptionsSettings<ConditionField>) => void;
}

export const MeasureItemConditions = (props: Props) => {
  const { conditions, canBeRemoved, onRemoveItem, onShowOptions, disableEqualitySelection } = props;

  return (
    <>
      {conditions.map((field) => {
        const isLinked = field.linked === true;
        const FieldComponent = isLinked ? LinkedAreaFieldItem : AreaFieldItem;
        return (
          <Stack key={field.config.guid} sx={{ flexDirection: "row", alignItems: "center", pl: 1 }}>
            {field.config.parameter && canBeRemoved && <ConditionMarker />}
            <Stack>{isLinked ? <LinkIcon /> : <NavigationParentIcon color="success" />}</Stack>
            <FieldComponent
              field={field}
              canBeRemoved={canBeRemoved}
              onRemoveItem={onRemoveItem}
              onShowOptions={onShowOptions}
              formatCaption={formatCaption}
              disableEqualitySelection={disableEqualitySelection}
            />
          </Stack>
        );
      })}
    </>
  );
};
export default MeasureItemConditions;
