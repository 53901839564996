import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { Grid, IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentReportActions, selectAutoShowItemOptionsId } from "../../../../store/currentReportSlice";
import AreaFieldItemLoader from "../fields/areaFields/AreaFieldItemLoader";
import { FieldItemProps } from "../fields/types/areaFiledItem.types";
import FieldItemCaptions from "../fields/areaFields/FieldItemCaptions";
import FieldItemOptionsButton from "../fields/areaFields/FieldItemOptionsButton";

interface FilterFieldItemProps<T> extends FieldItemProps<T> {
  startIcon?: JSX.Element;
  expandable?: boolean;
  onToggle?: (expanded: boolean) => void;
  disableExpanding?: boolean;
  copyButton?: JSX.Element | null;
}

export const FilterFieldItem = <T,>({
  field,
  hideOptions,
  formatCaption,
  onShowOptions,
  optionRef,
  isLoading,
  startIcon,
  expandable,
  onToggle,
  disableExpanding,
  copyButton,
}: FilterFieldItemProps<T>) => {
  const autoShowItemOptionsId = useSelector(selectAutoShowItemOptionsId);
  const dispatch = useDispatch();

  const [optionElementRef, setOptionElementRef] = useState<HTMLElement | null>(null);
  const [conditionExpanded, setConditionExpanded] = useState(false);

  useEffect(() => {
    optionRef?.call(null, optionElementRef);
  }, [optionRef, optionElementRef]);

  const showOptions = useCallback(() => {
    if (!onShowOptions) return false;
    const ref = optionElementRef;
    if (!ref) return false;

    onShowOptions({ field: field, ref, showOnlySelected: false });
    return true;
  }, [optionElementRef, field, onShowOptions]);

  useEffect(() => {
    if (!autoShowItemOptionsId) return;
    const item = field as { config: { guid: string; filter: { guid: string } }; guid: string };
    if (
      autoShowItemOptionsId === item?.guid ||
      item.config?.guid === autoShowItemOptionsId ||
      item.config?.filter?.guid === autoShowItemOptionsId
    ) {
      if (showOptions()) {
        dispatch(currentReportActions.setAutoShowItemOptionsId(undefined));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoShowItemOptionsId, field, showOptions]);

  return (
    <>
      {isLoading === true && <AreaFieldItemLoader />}
      <Grid
        container
        sx={{
          justifyContent: "space-between",
        }}
      >
        {expandable && (
          <Grid
            container
            sx={(theme) => ({
              alignItems: "center",
              px: "5px",
              height: "30px",
              width: "auto",
              "& .MuiSvgIcon-root": {
                color: startIcon ? theme.palette.secondary.light : "inherit",
              },
            })}
          >
            <IconButton
              color="secondary"
              disabled={disableExpanding}
              onClick={() => {
                setConditionExpanded((prev) => {
                  onToggle?.(!prev);
                  return !prev;
                });
              }}
              sx={{ "& .MuiSvgIcon-root": disableExpanding ? { color: "#C9CFD6" } : {} }}
            >
              {conditionExpanded ? <ArrowDropDownRoundedIcon /> : <ArrowRightRoundedIcon />}
            </IconButton>
            {startIcon && startIcon}
          </Grid>
        )}
        <Grid container sx={{ alignItems: "center", px: "5px", height: "28px", flex: 1, mt: "auto" }}>
          <FieldItemCaptions field={field} formatCaption={formatCaption} />
        </Grid>
        {copyButton && copyButton}
        <Grid ref={setOptionElementRef}>
          <FieldItemOptionsButton hideOptions={hideOptions} showOptions={showOptions} />
        </Grid>
      </Grid>
    </>
  );
};
