import { useTheme } from "@mui/material";
import { Cancelable, NativeEventInfo } from "devextreme/events";
import dxChart, { dxChartValueAxis } from "devextreme/viz/chart";
import { PointInteractionInfo } from "devextreme/viz/chart_components/base_chart";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChartStyleSettings, SeriesSettings } from "../../../../shared/reporting/api/biClient.types";
import { AnyObject } from "../../../../shared/types";
import { generateGuid } from "../../../../shared/utilities/generateGuid";
import { PivotRowMeasureCell } from "../../../api/biApi.types";
import { formatMeasure } from "../../../formatters/NumberFormatter";
import { drillDownActions } from "../../../store/drilldownSlice";
import { selectDimensions, selectMeasures } from "../../../store/metaDataSlice";
import { ValueFieldsArea } from "../pivot/types";
import { ValueField } from "../Types";
import "./ChartCore.css";
import { ChartProps } from "./ChartProps";
import { SettingsArea, useFieldsStateContext } from "./contexts/FieldsStateContext";
import { getLinearSettings, toBasicLineSerie } from "./contexts/FieldsStateContext.types";
import { isShowArgumentLabel } from "./style/argumentAxis/ShowLabelItem";
import { argumentAxisTitle } from "./style/argumentAxis/Title";
import { isRotated } from "./style/chart/RotateItem";
import { getHorizontalAlignment } from "./style/legend/HorizontalAlignmentItem";
import { getVerticalAlignment } from "./style/legend/VerticalAlignmentItem";
import { getSerieType } from "./style/serie/SerieTypeItem";
import { isShowSerieLabelConnector } from "./style/serie/ShowLabelConnectorItem";
import { isShowSerieLabel } from "./style/serie/ShowLabelItem";
import formatArgumentLabel from "./utilities/argumentFormat";
import { getArgumentAxisGrid, getValueAxisGrid } from "./utilities/axisGrid";
import updateValueAxis from "./utilities/updateValueAxis";

interface Props extends ChartProps {
  children: JSX.Element;
}

export default function ChartCore({ children, args, values, size, data }: Props) {
  const dispatch = useDispatch();
  const dimensions = useSelector(selectDimensions);
  const measures = useSelector(selectMeasures);
  const { conditionsArea, valuesArea, settingsArea } = useFieldsStateContext();
  const { palette } = useTheme();

  const handlePointDrillDown = React.useCallback(
    (serieName: string, value: unknown) => {
      const cell = data.find((row) => row[serieName]?.value === value);
      if (cell !== undefined) {
        const measureCell = cell[serieName] as PivotRowMeasureCell;
        if (measureCell?.drillDownId !== undefined) {
          const measure = values.find((f) => f.config.guid === serieName);
          if (measure === undefined) return;
          dispatch(
            drillDownActions.add({
              id: generateGuid(),
              measure,
              cell: measureCell,
              onlyLedger: false,
              chartOfAccounts: true,
            })
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conditionsArea.values, dimensions, measures, values, args]
  );

  return React.cloneElement(children, {
    palette: [
      "#00A866",
      "#3395A8",
      "#2FCE90",
      "#6AD4FD",
      "#34B8E2",
      "#147DDB",
      "#FFCB66",
      "#FF6D38",
      "#DD391B",
      "#6829A7",
    ],
    size,
    className: "entr-chart-core",
    rotated: isRotated(settingsArea),
    resolveLabelOverlapping: "hide",
    tooltip: buildToolTip(valuesArea.values, settingsArea.settings),
    argumentAxis: {
      color: palette.divider,
      tick: { color: palette.divider },
      grid: { ...getArgumentAxisGrid(settingsArea), color: palette.divider },
      label: {
        visible: isShowArgumentLabel(settingsArea),
        customizeText: (info: { value: string }) => formatArgumentLabel(args, info.value, data),
        font: { family: "Roboto" },
      },
      title: { text: argumentAxisTitle(settingsArea), font: { family: "Roboto" } },
      tickInterval: 1,
    },
    valueAxis: defaultValueAxis(settingsArea).map((axis) => ({
      ...axis,
      color: palette.divider,
      tick: { color: palette.divider },
      grid: { ...axis.grid, color: palette.divider },
    })),
    onPointClick: (e: NativeEventInfo<dxChart, MouseEvent | PointerEvent> & PointInteractionInfo) => {
      const cancellable = e.event as Cancelable;
      cancellable.cancel = true;
      const value = e.target.originalValue;
      const serieName: string = e.target.series.name;
      handlePointDrillDown(serieName, value);
    },
  });
}

function buildToolTip(values: ValueField[], settings: ChartStyleSettings) {
  const contentTemplate = (info: { seriesName: string; value: number }) => {
    return formatMeasure(
      values.find((v) => v.config.guid === info.seriesName),
      info.value
    );
  };

  return {
    enabled: settings.showTooltip,
    contentTemplate,
  };
}

export function getBasicLegend(settingsArea: SettingsArea) {
  return {
    horizontalAlignment: getHorizontalAlignment(settingsArea),
    verticalAlignment: getVerticalAlignment(settingsArea),
    title: settingsArea.settings.legend?.title,
    font: { family: "Roboto" },
  };
}

export function getBasicSerieLabel(
  name: string | undefined,
  settingsArea: SettingsArea,
  values: ValueFieldsArea,
  data: AnyObject[]
) {
  return {
    visible: isShowSerieLabel(name, settingsArea),
    customizeText: (info: { seriesName: string; value: number | undefined }) => {
      const row = data.find((r) => {
        const cell = r[info.seriesName] as PivotRowMeasureCell;
        return cell?.value === info.value;
      });
      if (row !== undefined) {
        const cell = row[info.seriesName] as PivotRowMeasureCell;
        return cell?.formattedValue;
      }
      return info.value;
    },
    connector: {
      visible: isShowSerieLabelConnector(name, settingsArea),
    },
    font: { family: "Roboto" },
  };
}

export function getDxSerieType(settingsArea: SettingsArea, serie: SeriesSettings | undefined) {
  const serieType = getSerieType(settingsArea.settings, serie);
  switch (serieType) {
    case "Bar":
      return "bar";
    case "Line":
    case "Area":
      return getDxLineType(serie);
    default:
      return "bar";
  }
}
export function getDxLineType(serie: SeriesSettings | undefined) {
  const lineSerie = toBasicLineSerie(serie);

  switch (lineSerie?.lineType) {
    case "Line":
      return lineSerie?.serieType === "Line" ? "line" : "area";
    case "Spline":
      return lineSerie?.serieType === "Line" ? "spline" : "splinearea";
    case "Stepline":
      return lineSerie?.serieType === "Line" ? "stepline" : "steparea";
    default: {
      return lineSerie?.serieType === "Line" ? "line" : "area";
    }
  }
}

function defaultValueAxis(settingsArea: SettingsArea): Array<dxChartValueAxis> {
  const linearSettings = getLinearSettings(settingsArea.settings);
  if (linearSettings === undefined) {
    return [];
  }
  const rotated = linearSettings.rotated === true;
  const valueAxes = updateValueAxis(linearSettings)?.map(
    (va): dxChartValueAxis => ({
      name: va.name,
      visible: true,
      title: { text: va.title, font: { family: "Roboto" } },
      position: va.name === "Secondary" ? (rotated ? "top" : "right") : rotated ? "bottom" : "left",
      label: { visible: va.showLabel !== false, font: { family: "Roboto" } },
      grid: getValueAxisGrid(settingsArea),
    })
  );

  return valueAxes || [];
}
