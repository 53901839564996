import { ClientInfoBase } from "../../api/clientTypes";
import { AccessPermission } from "../../api/types";

export type GenerateApiReportRequest = DuplicateReportRequest;

export type DuplicateReportRequest = Pick<Report, "clientCode" | "reportId" | "name" | "groupId">;

export type NumberFormatterOptions = {
  formatType: FormatType;
  type: string;
  abbreviation: string | undefined;
  decimals: number | undefined;
  thousandSeparator: string | undefined;
  decimalSeparator: string | undefined;
  percentage: boolean | undefined;
  convertToPercentage: boolean | undefined;
  currency: string;
  prefix: string | undefined;
  suffix: string | undefined;
};

export enum FormatType {
  None = "None",
  Numeric = "Numeric",
  Date = "Date",
  DateTime = "DateTime",
  BlankIfZero = "BlankIfZero",
  BlankIfFalse = "BlankIfFalse",
}

export enum CalculateByField {
  Lcy = "Lcy",
  Pcy = "Pcy",
  Shares = "Shares",
  AdditionalLcy = "AdditionalLcy",
}

export enum AmountType {
  Net = "Net",
  Credit = "Credit",
  Debit = "Debit",
}

export enum ConditionEqualityType {
  Equal = "Equal",
  NotEqual = "NotEqual",
  LessThan = "LessThan",
  LessThanOrEqual = "LessThanOrEqual",
  Between = "Between",
}

export enum DateRangeType {
  "NetChange" = "NetChange",
  "BeginningBalance" = "BeginningBalance",
  "EndingBalance" = "EndingBalance",
  "Mtd" = "Mtd",
  "Qtd" = "Qtd",
  "Ytd" = "Ytd",
}

export enum Grouping {
  "ByYear" = "ByYear",
  "ByMonth" = "ByMonth",
  "ByQuarter" = "ByQuarter",
}

export enum Ordering {
  "None" = 0,
  "Ascending" = 1,
  "Descending" = 2,
}
export enum MeasureUnitTable {
  NotSet = 0,
  Gl = 1 << 1, //2
  Memo = 1 << 2, //4
}

export type ConditionDescriptor = {
  equalityType?: ConditionEqualityType;
  dimensionName: string;
  values: string[];
};

export type PivotValuesRequest = {
  guid: string;
  measureName: string;
  dateRangeType: DateRangeType;
  standalone?: boolean | undefined | null;
  conditions: ConditionDescriptor[];
  customLabel?: string | undefined | null;
  calculateByField: CalculateByField;
  amountType?: AmountType | undefined | null;
};

export type ReportResponse = Report;

export type Report = {
  reportId: string;
  reportType?: ReportType;
  clientCode: string;
  name: string;
  groupId?: string;
  createdAt: string;
  configuration: ReportConfiguration;
  authorization: ReportAuthorizationInfo;
  reportUsage: ReportUsage;
};

export type ReportUsage = "Ui" | "Api";

export type ReportTemplate = Report & {
  fundTypes: FundType[];
  status: Status;
  dataSource: string;
  organization: string;
  description: string;
  isNew?: boolean;
};

export enum FundType {
  FundOfFunds = "FundOfFunds",
  PrivateEquity = "PrivateEquity",
  VentureCapital = "VentureCapital",
}

export enum Status {
  Live = "Live",
  Draft = "Draft",
}

export enum ReportType {
  Pivot = "Pivot",
  Tabular = "Tabular",
  PieChart = "PieChart",
  BarChart = "BarChart",
  LineChart = "LineChart",
  AreaChart = "AreaChart",
  DoughnutChart = "DoughnutChart",
  TreeMapChart = "TreeMapChart",
}

export enum CellPropertyEnum {
  "None" = "None",
  "StartIcon" = "StartIcon",
}
export type CellProperties = {
  [key in keyof typeof CellPropertyEnum]: string;
};

export type ReportGroupsResponse = Array<ReportGroup>;

export type ReportGroup = {
  id: string;
  caption: string;
  order: number;
};

export type FieldCustomLabel = {
  customLabel?: string;
  systemLabel?: string;
};

export type ConditionConfiguration = FieldCustomLabel & {
  guid: string;
  filter: ConditionDescriptor;
  parameter?: boolean | undefined;
  mandatory?: boolean | undefined;
  invalid?: boolean | undefined;
};

export type MeasureConfiguration = {
  type: MeasureConfigurationType;
  standalone?: boolean | undefined | null;
  hideAggregation?: boolean | undefined | null;
  blankZero?: boolean | undefined | null;
  useCustomConditions?: boolean | undefined | null;
  dateRange: DateRangeType;
  reverseSign?: boolean | undefined | null;
  suppressEmptyValues?: boolean | undefined | null;
};

export type DefaultMeasureConfiguration = MeasureConfiguration & {
  type: MeasureConfigurationType.Default;
};

export type IrrMeasureConfiguration = MeasureConfiguration & {
  type: MeasureConfigurationType.Irr;
  cashFlowFrequency: IrrCashFlowFrequency;
};

export type MeasureConfigurations = DefaultMeasureConfiguration | IrrMeasureConfiguration;

export enum MeasureConfigurationType {
  Default = "Default",
  Irr = "Irr",
}

export enum IrrCashFlowFrequency {
  Daily = "Daily",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
}

export type UserMeasureConfiguration = MeasureConfiguration & {
  name: string;
  guid: string;
  customLabel: string | undefined | null;
  format: NumberFormatterOptions | undefined | null;
  customConditions: ConditionConfiguration[] | undefined | null;
  linkedConditions: string[] | undefined | null;
  calculateByField?: CalculateByField | undefined | null;
  amountType?: AmountType | undefined | null;
};

export type DefaultUserMeasureConfiguration = UserMeasureConfiguration & {
  type: MeasureConfigurationType.Default;
};

export type IrrUserMeasureConfiguration = UserMeasureConfiguration & {
  type: MeasureConfigurationType.Irr;
  cashFlowFrequency: IrrCashFlowFrequency;
};

export type UserMeasureConfigurations = DefaultUserMeasureConfiguration | IrrUserMeasureConfiguration;

export type PivotFieldConfiguration = {
  name: string;
  grouping?: Grouping | undefined | null;
  style?: PivotFieldStyle | undefined | null;
  displayValues?: string[] | undefined | null;
  suppressEmptyValues?: boolean | undefined | null;
  showLogoIcon?: boolean | undefined | null;
  format?: NumberFormatterOptions | undefined | null;
  customLabel: string | undefined | null;
  aggregation: DimensionAggregation | undefined | null;
};

type PivotFieldStyle = {
  fontStyle?: string[] | undefined | null;
  showTotals?: boolean | undefined | null;
};

export type PivotGeneralSettings = {
  hideBlankRows: boolean;
  hideEmptyColumns: boolean;
  rowsGrandTotal: boolean;
};

export type BaseConfiguration = {
  conditions: ConditionConfiguration[];
};

export type ReportConfiguration = PivotConfiguration | TabularConfiguration | ChartConfiguration;

export type TabularFieldConfiguration = {
  guid: string;
  fieldType: TabularFieldType;
  dimension?: UserDimensionConfiguration | undefined | null;
  measure?: UserMeasureConfigurations | undefined | null;
  showLogoIcon?: boolean | undefined | null;
};

export type UserDimensionConfiguration = {
  name: string;
  format?: NumberFormatterOptions | undefined | null;
  grouping?: Grouping | undefined | null;
  customLabel?: string | undefined | null;
  suppressEmptyValues?: boolean | undefined | null;
  aggregation?: DimensionAggregation | undefined | null;
};

export type DimensionAggregation = {
  aggregationType: BiAggregation;
};

export enum BiAggregation {
  Count = "Count",
  Sum = "Sum",
  Min = "Min",
  Max = "Max",
  Average = "Average",
  StringAgg = "StringAgg",
}

export type TemplateFieldsItem = {
  name: string;
  icon: MetaIcons;
  subItems: TemplateFieldsItem[];
};

export enum MetaIcons {
  Hash = "Hash",
  Calendar = "Calendar",
  Sigma = "Sigma",
  Function = "Function",
}
export type TemplateInfoFieldsResponse = {
  fields: { [key: string]: TemplateFieldsItem[] };
};

export type PivotConfiguration = BaseConfiguration & {
  rows: PivotFieldConfiguration[];
  columns: PivotFieldConfiguration[];
  values: UserMeasureConfigurations[];
  sort: SortConfiguration[];
  settings: PivotGeneralSettings;
};

export type SortConfiguration = {
  name: string;
  ordering: Ordering;
  caption: string | undefined | null;
  isGroupField?: boolean | undefined | null;
};

export type ReportTemplateDto = {
  groupName: string;
  groupOrder: number;
} & ReportTemplate;

export type SummaryPlace = "Footer" | "Inline";

export type TabularGeneralSettings = {
  hideBlankRows: boolean;
  showGrandTotal: boolean;
  groupSummaryPlace: SummaryPlace;
};

export type GroupingField = {
  name: string;
  hideSummaries?: boolean;
};

export type TabularConfiguration = BaseConfiguration & {
  conditions: ConditionConfiguration[];
  fields: TabularFieldConfiguration[];
  sort: SortConfiguration[];
  grouping: GroupingField[];
  settings: TabularGeneralSettings;
};

export type LegendSettings = {
  horizontalAlignment?: "left" | "center" | "right";
  verticalAlignment?: "top" | "bottom";
  title?: string;
};

export type AxisSettings = {
  showLabel?: boolean;
  title?: string;
};

export type BasicSeriesSettings = {
  name: string;
  showLabel?: boolean;
  showConnector?: boolean;
};

export type SerieType = "Bar" | "Line" | "Area";
export type BarWidth = "Thin" | "Small" | "Medium" | "Large";
export type LineType = "Line" | "Spline" | "Stepline";
export type ValueAxisType = "Main" | "Secondary";

export type LinearSeriesSettings = BasicSeriesSettings & {
  serieType: SerieType;
  valueAxis?: ValueAxisType;
};

export type BarSeriesSettings = LinearSeriesSettings & {
  barWidth?: BarWidth;
};

export type BasicLineSeriesSettings = LinearSeriesSettings & {
  lineType?: LineType;
  showPoints?: boolean;
};

export type SeriesSettings = LinearSeriesSettings | BasicLineSeriesSettings | BasicSeriesSettings;

export type BasicSettings = {
  type: ReportType;
  showTooltip?: boolean;
  legend?: LegendSettings;
  series?: SeriesSettings[];
};

export type ValueAxis = AxisSettings & {
  name: ValueAxisType;
};

export type BasicLinearSettings = BasicSettings & {
  gridMode?: "none" | "horizontal" | "all";
  rotated?: boolean;
  argumentAxis?: AxisSettings;
  valueAxis?: ValueAxis[];
};

export type BarSettings = BasicLinearSettings;

export type LineSettings = BasicLinearSettings;

export type AreaSettings = BasicLinearSettings;

export type PieSettings = BasicSettings;

export type DoughnutSettings = BasicSettings & {
  thickness?: number;
};

export type ChartStyleSettings = BarSettings | LineSettings | AreaSettings | PieSettings | DoughnutSettings;

export type ChartConfiguration = BaseConfiguration & {
  arguments: PivotFieldConfiguration[];
  values: UserMeasureConfigurations[];
  sort: SortConfiguration[];
  settings: ChartStyleSettings;
};

export enum TabularFieldType {
  Dimension = "Dimension",
  Measure = "Measure",
}

export interface TabularDataField {
  guid: string;
  fieldType: TabularFieldType;
  dimension?: DrillDownColumn;
  measure?: PivotValuesRequest;
}

export type MeasureCell = {
  value: unknown;
  formattedValue: string;
  drillDownId?: string;
};

export type TabularDataCell = MeasureCell & {
  toolTip?: string;
  properties?: CellProperties;
};

export type ClientInfo = ClientInfoBase & {
  organization: string;
};
export interface ClientsResponse {
  clients: ClientInfo[];
}

export type DrillDownColumn = { dimensionName: string };

export type NameValidationResponse = { nameExists?: boolean };

export type DeleteReportItem = Pick<Report, "clientCode" | "reportId">;
export type DeleteReportsRequest = DeleteReportItem[];

export type ValidateReportNameRequest = Pick<Report, "clientCode" | "name"> & { reportId?: string };
export type RenameReportRequest = Pick<Report, "clientCode" | "name" | "reportId">;
export type ChangeReportGroupRequest = Pick<Report, "clientCode" | "groupId" | "reportId">;

export type AddReportGroupRequest = { clientCode: string; group: ReportGroup };
export type UpdateReportGroupRequest = { clientCode: string; group: ReportGroup };
export type ReplaceReportGroupRequest = { clientCode: string; fromGroup: string; toGroup: string };
export type ReorderReportGroupsRequest = { clientCode: string; groups: ReportGroup[] };

export type AddReportTemplateRequest = { template: ReportTemplate };
export type AddReportTemplateResponse = ReportTemplate;
export type GetReportTemplatesResponse = Array<ReportTemplateDto>;
export type UpdateReportTemplateStatusRequest = { templateId: string; status: Status };
export type UpdateReportTemplateStatusResponse = ReportTemplate;
export type DeleteReportTemplatesRequest = { templates: { templateId: string; organization: string }[] };
export type ValidateTemplateNameRequest = Pick<ReportTemplate, "organization" | "name"> & { templateId?: string };
export type TemplateNameValidationResponse = { nameExists?: boolean };
export type GetReportTemplateResponse = ReportTemplate;
export type UpdateReportTemplateRequest = { template: ReportTemplate };
export type UpdateReportTemplateResponse = ReportTemplate;
export type UpdateReportTemplateDataSourceRequest = { dataSource: string; templateId: string };
export type UpdateReportTemplateDataSourceResponse = { dataSource: string };
export type GetReportTemplateForOrganizationRequest = { templateId: string; organization: string };
export type GetReportTemplateForCompanyRequest = { templateId: string };
export type GetTemplateFieldsDataRequest = { templateId: string };
export type DuplicateTemplateRequest = { organization: string; templateId: string; name: string; groupId: string };

export interface ReportInfo {
  clientCode: string;
  reportId: string;
  name: string;
  reportType: ReportType;
  groupId: string;
  updateAt: string;
  createdBy: string;
  modifiedBy: string;
  authorization: ReportAuthorizationInfo;
  reportUsage: ReportUsage;
}

export interface ReportAuthorizationInfo {
  private: boolean;
  own: boolean;
  access: ReportAccessType;
}

export enum ReportAccessType {
  NoAccess = 0, //0
  Read = 1 << 1, //2
  Write = (1 << 2) | Read, //6
  FullAccess = (1 << 3) | Read | Write, //14
  Owner = (1 << 4) | FullAccess, //30
  Supervisor = (1 << 5) | Owner, //62
}

export interface UserForSharing {
  userId: string;
  name: string;
  email: string;
  permissions: AccessPermission[];
}

export interface ReportAuthorization {
  users: UserBasedAuthorization[];
  companyShared: CompanySharedAuthorization;
}

export interface UserBasedAuthorization {
  userId: string;
  access: ReportAccessType;
}

export interface CompanySharedAuthorization {
  access: ReportAccessType;
}

export interface GetReportAuthorizationResponse {
  authorization: ReportAuthorization;
  users: UserForSharing[];
}

export interface UpdateReportAuthorizationRequest {
  clientCode: string;
  authorization: ReportAuthorization;
}
