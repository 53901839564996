import { IDependencyInitializerDetails } from "@microsoft/applicationinsights-dependencies-js";
import { ICustomProperties } from "@microsoft/applicationinsights-web";
import { clientCodeHeader } from "../../shared/api/clientApiContext";

const buildReportRoutes = ["ApiTabular/build", "UiTabular/build", "Pivot/build", "Chart/build", "DrillDown"];

const ignoredRequestPredicates = new Map<string, (details: IDependencyInitializerDetails) => boolean>();

ignoredRequestPredicates.set("Aborted report build", (details: IDependencyInitializerDetails): boolean => {
  return (details.aborted && buildReportRoutes.some((r) => details.item.target?.endsWith(r))) || false;
});

/**
 * This function checks if the incoming request details match any of the predicates in the ignoredRequestPredicates map.
 * If a match is found, it prevents the event from being reported by returning false.
 *
 * @param {IDependencyInitializerDetails} details - The details to be checked against the predicates.
 * @returns {boolean | void} - Returns false if a match is found, otherwise returns undefined.
 */
export const dependencyInitializerHandler = (details: IDependencyInitializerDetails): boolean | void => {
  if (Array.from(ignoredRequestPredicates.values()).some((predicate) => predicate(details))) {
    return false;
  }
  return undefined;
};

/**
 * This function sets the client code to the properties of the request.
 *
 * @returns {ICustomProperties} - Returns an object containing the client code header.
 */
export const setClientCode = (): ICustomProperties => {
  return {
    ...clientCodeHeader(),
  };
};
