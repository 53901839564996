import { Grid, Stack } from "@mui/material";
import React from "react";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import ArgumentAxisStyles from "./argumentAxis/ArgumentAxisStyles";
import ChartStyles from "./chart/ChartStyles";
import LegendStyles from "./legend/LegendStyles";
import SeriesStyles from "./serie/SeriesStyles";
import ValueAxisStyles from "./valueAxis/ValueAxisStyles";

export default function StyleContainer() {
  const { argumentsArea, valuesArea } = useFieldsStateContext();

  const configurationValid = React.useMemo(() => {
    return argumentsArea.values.length > 0 && valuesArea.values.length > 0;
  }, [argumentsArea.values, valuesArea.values]);

  if (!configurationValid) {
    return <></>;
  }

  return (
    <Grid container sx={{ mt: -2, flexDirection: "column", flex: 1, position: "relative" }}>
      <Stack sx={{ position: "absolute", height: "100%", width: "100%", overflow: "auto" }}>
        <ChartStyles />
        <SeriesStyles />
        <ArgumentAxisStyles />
        <ValueAxisStyles />
        <LegendStyles />
      </Stack>
    </Grid>
  );
}
