import CloseIcon from "@mui/icons-material/CloseRounded";
import { IconButton, IconButtonProps, SvgIconProps } from "@mui/material";

interface Props extends IconButtonProps {
  iconProps?: SvgIconProps;
}

const CloseIconButton = ({ iconProps, ...props }: Props) => (
  <IconButton aria-label="close" {...props}>
    <CloseIcon {...iconProps} />
  </IconButton>
);

export default CloseIconButton;
