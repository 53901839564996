import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { SeriesSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { SettingsArea } from "../../contexts/FieldsStateContext";

interface Props {
  area: SettingsArea;
  serie: SeriesSettings;
}

export default function ShowLabelItem({ area, serie }: Props) {
  const showLabel = React.useMemo(() => isShowSerieLabel(serie.name, area), [area, serie]);

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography color={(theme) => theme.palette.text.secondary}>Show Labels</Typography>
      <HorizontalFill />
      <Switch checked={showLabel} onChange={(_, value) => area.updateSerie(serie.name, { showLabel: value })} />
    </Stack>
  );
}

export function isShowSerieLabel(name: string | undefined, area: SettingsArea) {
  return area.settings?.series?.find((s) => s.name === name)?.showLabel !== false;
}
