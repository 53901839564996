import { useReducer } from "react";
import { generateGuid } from "../../../../../../shared/utilities/generateGuid";

export enum ExportActionType {
  PREVIOUS = "PREVIOUS",
  NEXT = "NEXT",
  SELECT = "SELECT",
  INSERTED = "INSERTED",
  CLOSE = "CLOSE",
}

export type PreviousAction = {
  type: ExportActionType.PREVIOUS;
};
export type NextAction = {
  type: ExportActionType.NEXT;
};
export type SelectAction = {
  type: ExportActionType.SELECT;
};
export type InsertedAction = {
  type: ExportActionType.INSERTED;
};
export type CloseAction = {
  type: ExportActionType.CLOSE;
};

type Actions = PreviousAction | NextAction | SelectAction | InsertedAction | CloseAction;

export type FormulaInputState = {
  previous: string;
  next: string;
  select: string;
  inserted: string;
  close: string;
};

const initialState: FormulaInputState = {
  previous: "",
  next: "",
  select: "",
  inserted: "",
  close: "",
};

const reducer = (state: FormulaInputState, action: Actions): FormulaInputState => {
  switch (action.type) {
    case ExportActionType.PREVIOUS: {
      return { ...state, previous: generateGuid() };
    }
    case ExportActionType.NEXT: {
      return { ...state, next: generateGuid() };
    }
    case ExportActionType.SELECT: {
      return { ...state, select: generateGuid() };
    }
    case ExportActionType.INSERTED: {
      return { ...state, inserted: generateGuid() };
    }
    case ExportActionType.CLOSE: {
      return { ...state, close: generateGuid() };
    }
    default:
      return state;
  }
};

export type FormulaInputActions = {
  previous: () => void;
  next: () => void;
  select: () => void;
  inserted: () => void;
  close: () => void;
};

export const useFormulaInputState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    ...state,
    actions: {
      previous: () => dispatch({ type: ExportActionType.PREVIOUS }),
      next: () => dispatch({ type: ExportActionType.NEXT }),
      select: () => dispatch({ type: ExportActionType.SELECT }),
      inserted: () => dispatch({ type: ExportActionType.INSERTED }),
      close: () => dispatch({ type: ExportActionType.CLOSE }),
    } as FormulaInputActions,
  };
};
