import { importEnvVariable } from "./env";
import { AnyObject } from "./types";
import { defined } from "./utilities/typeHelper";

export const authState: AnyObject = {};

export enum PortalVariablesTarget {
  AdminPortal = "AdminPortal",
  InvestorPortal = "InvestorPortal",
  ReportingPortal = "ReportingPortal",
  AquariumPortal = "AquariumPortal",
  InvestorPortalImpersonation = "InvestorPortalImpersonation",
}

type EnvVar =
  | "AD_B2C_AUTH_ENDPOINT"
  | "AD_B2C_USER_FLOW_SIGNIN"
  | "AD_B2C_USER_FLOW_SIGNUP"
  | "AD_B2C_USER_FLOW_PASSWORD_RESET"
  | "AD_B2C_CLIENT_ID"
  | "PORTAL_ACCESS_SCOPE"
  | "AUTH_COOKIE_NAMES"
  | "REFRESH_COOKIE_NAMES"
  | "PORTAL_BACKEND_BASE_URI"
  | "PORTAL_REDIRECT_URI"
  | "APP_INSIGHTS_CONN_STRING"
  | "REPORTING_PORTAL_URI"
  | "ADMIN_PORTAL_URI"
  | "DEVELOPER_TOOLS"
  | "LOGIN_APP_URI"
  | "APP_VERSION";

const getBaseVariables = () => ({
  PORTAL_BACKEND_BASE_URI: defined(importEnvVariable("REACT_APP_PORTAL_BACKEND_BASE_URI")),
  PORTAL_REDIRECT_URI: defined(importEnvVariable("REACT_APP_PORTAL_REDIRECT_URI")),
  APP_INSIGHTS_CONN_STRING: importEnvVariable("REACT_APP_APP_INSIGHTS_CONN_STRING"),
  APP_VERSION: importEnvVariable("REACT_APP_VERSION"),
});

let activePortalVariables: Record<EnvVar, string | undefined> | undefined;
let currentTarget: PortalVariablesTarget | undefined;

const portalVariables = {
  [PortalVariablesTarget.AdminPortal]: () => {
    return {
      ...getBaseVariables(),
      AD_B2C_AUTH_ENDPOINT: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_AUTH_ENDPOINT")),
      AD_B2C_USER_FLOW_SIGNIN: "",
      AD_B2C_USER_FLOW_SIGNUP: "",
      AD_B2C_USER_FLOW_PASSWORD_RESET: "",
      AD_B2C_CLIENT_ID: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_CLIENT_ID")),
      PORTAL_ACCESS_SCOPE: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_ACCESS_SCOPE")),
      AUTH_COOKIE_NAMES: "x-entrilia-ap-authorized",
      REFRESH_COOKIE_NAMES: "x-entrilia-ap-refresh-available",
      REPORTING_PORTAL_URI: defined(importEnvVariable("REACT_APP_REPORTING_PORTAL_URI")),
      LOGIN_APP_URI: defined(importEnvVariable("REACT_APP_LOGIN_APP_URI")),
      ADMIN_PORTAL_URI: "",
      DEVELOPER_TOOLS: "",
    };
  },
  [PortalVariablesTarget.InvestorPortal]: () => {
    return {
      ...getBaseVariables(),
      AD_B2C_AUTH_ENDPOINT: defined(importEnvVariable("REACT_APP_INVESTOR_PORTAL_AUTH_ENDPOINT")),
      AD_B2C_USER_FLOW_SIGNIN: importEnvVariable("REACT_APP_INVESTOR_PORTAL_USER_FLOW_SIGNIN"),
      AD_B2C_USER_FLOW_SIGNUP: importEnvVariable("REACT_APP_INVESTOR_PORTAL_USER_FLOW_SIGNUP"),
      AD_B2C_USER_FLOW_PASSWORD_RESET: importEnvVariable("REACT_APP_INVESTOR_PORTAL_USER_FLOW_PASSWORD_RESET"),
      AD_B2C_CLIENT_ID: defined(importEnvVariable("REACT_APP_INVESTOR_PORTAL_CLIENT_ID")),
      PORTAL_ACCESS_SCOPE: defined(importEnvVariable("REACT_APP_INVESTOR_PORTAL_ACCESS_SCOPE")),
      AUTH_COOKIE_NAMES: "x-entrilia-ir-authorized",
      REFRESH_COOKIE_NAMES: "x-entrilia-ir-refresh-available",
      REPORTING_PORTAL_URI: "",
      ADMIN_PORTAL_URI: "",
      LOGIN_APP_URI: "",
      DEVELOPER_TOOLS: "",
    };
  },
  [PortalVariablesTarget.ReportingPortal]: () => {
    return {
      ...getBaseVariables(),
      AD_B2C_AUTH_ENDPOINT: defined(importEnvVariable("REACT_APP_AD_B2C_AUTH_ENDPOINT")),
      AD_B2C_USER_FLOW_SIGNIN: "",
      AD_B2C_USER_FLOW_SIGNUP: "",
      AD_B2C_USER_FLOW_PASSWORD_RESET: "",
      AD_B2C_CLIENT_ID: defined(importEnvVariable("REACT_APP_AD_B2C_CLIENT_ID")),
      PORTAL_ACCESS_SCOPE: defined(importEnvVariable("REACT_APP_PORTAL_ACCESS_SCOPE")),
      AUTH_COOKIE_NAMES: "x-entrilia-ap-authorized",
      REFRESH_COOKIE_NAMES: "x-entrilia-ap-refresh-available",
      REPORTING_PORTAL_URI: "",
      ADMIN_PORTAL_URI: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_URI")),
      LOGIN_APP_URI: defined(importEnvVariable("REACT_APP_LOGIN_APP_URI")),
      DEVELOPER_TOOLS: importEnvVariable("REACT_APP_DEVELOPER_TOOLS"),
    };
  },
  [PortalVariablesTarget.InvestorPortalImpersonation]: () => {
    return {
      ...getBaseVariables(),
      AD_B2C_AUTH_ENDPOINT: "",
      AD_B2C_USER_FLOW_SIGNIN: "",
      AD_B2C_USER_FLOW_SIGNUP: "",
      AD_B2C_USER_FLOW_PASSWORD_RESET: "",
      AD_B2C_CLIENT_ID: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_CLIENT_ID")),
      PORTAL_ACCESS_SCOPE: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_ACCESS_SCOPE")),
      AUTH_COOKIE_NAMES: "x-entrilia-ap-authorized",
      REFRESH_COOKIE_NAMES: "x-entrilia-ap-refresh-available",
      REPORTING_PORTAL_URI: "",
      ADMIN_PORTAL_URI: "",
      LOGIN_APP_URI: defined(importEnvVariable("REACT_APP_LOGIN_APP_URI")),
      DEVELOPER_TOOLS: "",
    };
  },
  [PortalVariablesTarget.AquariumPortal]: () => {
    return {
      ...getBaseVariables(),
      AD_B2C_AUTH_ENDPOINT: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_AUTH_ENDPOINT")),
      AD_B2C_USER_FLOW_SIGNIN: "",
      AD_B2C_USER_FLOW_SIGNUP: "",
      AD_B2C_USER_FLOW_PASSWORD_RESET: "",
      AD_B2C_CLIENT_ID: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_CLIENT_ID")),
      PORTAL_ACCESS_SCOPE: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_ACCESS_SCOPE")),
      AUTH_COOKIE_NAMES: "x-entrilia-ap-authorized",
      REFRESH_COOKIE_NAMES: "x-entrilia-ap-refresh-available",
      REPORTING_PORTAL_URI: "",
      LOGIN_APP_URI: defined(importEnvVariable("REACT_APP_LOGIN_APP_URI")),
      ADMIN_PORTAL_URI: "",
      DEVELOPER_TOOLS: "",
    };
  },
};

const setPortalTarget = (portalTargetName: PortalVariablesTarget) => {
  currentTarget = portalTargetName;
  activePortalVariables = portalVariables[portalTargetName]();
};

const getSettings = (): Record<EnvVar, string | undefined> =>
  defined(activePortalVariables, "Portal target is not set in shared variables");

const isImpersonation = () => currentTarget === PortalVariablesTarget.InvestorPortalImpersonation;

const getBackendBaseUrl = (segment: string): string => {
  const localServerUrl =
    importEnvVariable(`REACT_APP_LOCAL_SERVER_${segment.toUpperCase()}`) || importEnvVariable("REACT_APP_LOCAL_SERVER");
  if (localServerUrl) {
    return localServerUrl;
  }
  return `${getBaseVariables().PORTAL_BACKEND_BASE_URI}/${segment}`;
};

export { getBackendBaseUrl, getBaseVariables, getSettings, isImpersonation, setPortalTarget };
