import AddIcon from "@mui/icons-material/Add";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { MeasureDescriptor } from "../../../../../api/biApi.types";
import { selectMeasureGroups, selectMeasures, selectMeasuresStructure } from "../../../../../store/metaDataSlice";
import ChartOfAccounts from "../../chartOfAccounts/ChartOfAccounts";
import CustomMeasureDialog from "../../customMeasure/CustomMeasureDialog";
import DeleteMeasureDialog from "../../customMeasure/DeleteMeasureDialog";
import ExplainMeasureDialog from "../../customMeasure/ExplainMeasureDialog";
import MeasureMenu from "./MeasureMenu";
import MeasuresTree from "./MeasuresTree";
import AuthorizedBox from "../../../../../../shared/components/AuthorizedBox";

interface Props {
  searchText: string;
}

export const Measures = ({ searchText }: Props) => {
  const measures = useSelector(selectMeasures);
  const measuresStructure = useSelector(selectMeasuresStructure);
  const measureGroups = useSelector(selectMeasureGroups);

  const [measuresExpanded, setMeasuresExpanded] = useState(true);
  const [showAddCustomMeasure, setShowAddCustomMeasure] = useState(false);
  const [measureToEdit, setMeasureToEdit] = useState<{ measure: MeasureDescriptor; group?: string }>();
  const [measureToDelete, setMeasureToDelete] = useState<MeasureDescriptor>();
  const [measureToExplain, setMeasureToExplain] = useState<MeasureDescriptor>();
  const [measureForChartOfAccounts, setMeasureForChartOfAccounts] = useState<MeasureDescriptor>();
  const [measureOptions, setMeasureOptions] = useState<{
    x: number;
    y: number;
    measure: MeasureDescriptor;
    group: string;
  }>();
  const defaultGroup = measureToEdit?.group || measureGroups[0];

  const filteredMeasures = useMemo(() => {
    if (searchText === "") {
      return measures;
    }
    return measures.filter((d) => d.caption.toLowerCase().indexOf(searchText) > -1);
  }, [searchText, measures]);

  const explainMeasure = useCallback(
    (measure: MeasureDescriptor) => {
      setMeasureOptions(undefined);
      setMeasureToExplain(measure);
    },
    [setMeasureOptions]
  );
  const editMeasure = useCallback(
    (measure: MeasureDescriptor) => {
      setMeasureOptions(undefined);
      setMeasureToEdit({ measure, group: measureOptions?.group });
    },
    [measureOptions]
  );
  const deleteMeasure = useCallback((measure: MeasureDescriptor) => {
    setMeasureOptions(undefined);
    setMeasureToDelete(measure);
  }, []);

  const openChartOfAccounts = useCallback((measure: MeasureDescriptor) => {
    setMeasureOptions(undefined);
    setMeasureForChartOfAccounts(measure);
  }, []);

  const onShowCustomMeasureDialog = useCallback(() => setShowAddCustomMeasure(true), []);
  const offShowCustomMeasureDialog = useCallback(() => setShowAddCustomMeasure(false), []);
  const revertExpansion = useCallback(() => setMeasuresExpanded((ov) => !ov), []);
  const onShowOptions = useCallback((x: number, y: number, measure: MeasureDescriptor, group: string) => {
    setMeasureOptions({ x, y, measure, group });
  }, []);

  const clearMeasureToEdit = useCallback(() => setMeasureToEdit(undefined), []);
  const clearMeasureToDelete = useCallback(() => setMeasureToDelete(undefined), []);
  const clearMeasureToExplain = useCallback(() => setMeasureToExplain(undefined), []);
  const clearMeasureChartOfAccounts = useCallback(() => setMeasureForChartOfAccounts(undefined), []);
  const clearMeasureOptions = useCallback(() => setMeasureOptions(undefined), []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography sx={{ flexGrow: 1 }} variant="subtitle2" color={({ palette }) => palette.text.primary}>
          Measures
        </Typography>
        <AuthorizedBox permissions={["ManageCustomMeasures"]}>
          <IconButton color="primary" onClick={onShowCustomMeasureDialog}>
            <AddIcon />
          </IconButton>
        </AuthorizedBox>
        <IconButton color="secondary" onClick={revertExpansion}>
          {measuresExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      <Collapse orientation="vertical" in={measuresExpanded}>
        <MeasuresTree measures={filteredMeasures} structure={measuresStructure} onShowOptions={onShowOptions} />
      </Collapse>
      {showAddCustomMeasure && <CustomMeasureDialog onClose={offShowCustomMeasureDialog} defaultGroup={defaultGroup} />}
      {measureToEdit && (
        <CustomMeasureDialog measure={measureToEdit.measure} defaultGroup={defaultGroup} onClose={clearMeasureToEdit} />
      )}
      {measureToDelete && <DeleteMeasureDialog measure={measureToDelete} onClose={clearMeasureToDelete} />}
      {measureToExplain && (
        <ExplainMeasureDialog measure={measureToExplain} onClose={clearMeasureToExplain} onEdit={editMeasure} />
      )}
      {measureForChartOfAccounts !== undefined && (
        <ChartOfAccounts initialMeasure={measureForChartOfAccounts} onClose={clearMeasureChartOfAccounts} />
      )}
      {measureOptions !== undefined && (
        <MeasureMenu
          {...measureOptions}
          onExplain={explainMeasure}
          onEdit={editMeasure}
          onDelete={deleteMeasure}
          onClose={clearMeasureOptions}
          onChartOfAccounts={openChartOfAccounts}
        />
      )}
    </Box>
  );
};

export default Measures;
