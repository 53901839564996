import SaveReportFormWrapper from "../../../../../shared/reporting/components/reports/SaveReportFormWrapper";
import PromptDialog from "../../../../../shared/components/dialog/PromptDialog";
import ReportGroupsConnector from "../../../../../shared/reporting/components/reports/ReportGroupsConnector";
import useSaveReportAsDialog from "../hooks/useSaveReportAsDialog";
import { SaveAsDialogProps } from "../../Types";

export default function SaveAsDialog({
  report,
  reportConfiguration,
  addReport,
  onChanged,
  onClose,
}: SaveAsDialogProps) {
  const { error, handleSaveClicked, saving } = useSaveReportAsDialog({
    report,
    reportConfiguration,
    addReport,
    onChanged,
    onClose,
  });

  return (
    <PromptDialog open={true}>
      <ReportGroupsConnector clientCode={report.clientCode}>
        <SaveReportFormWrapper
          report={report}
          error={error}
          title={"Save As"}
          saveBtnTitle={"Save"}
          saving={saving}
          showName
          showLevel
          newReport
          onClose={onClose}
          onSave={handleSaveClicked}
        />
      </ReportGroupsConnector>
    </PromptDialog>
  );
}
