import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { PivotFieldConfiguration } from "../../../../../../shared/reporting/api/biClient.types";
import { GeneralField } from "../../../Types";

interface Props {
  field: GeneralField;
  onUpdateConfig: (field: GeneralField, changes: Partial<PivotFieldConfiguration>) => void;
}

export default function DisplayFieldsComponent({ field, onUpdateConfig }: Props) {
  const selectedValues = useMemo(() => {
    if (field.config.displayValues && field.config.displayValues.length > 0) return field.config.displayValues;
    return field.meta.displayValues;
  }, [field.config.displayValues, field.meta.displayValues]);

  const checked = useCallback(
    (fieldName: string) => {
      return selectedValues.indexOf(fieldName) > -1;
    },
    [selectedValues]
  );

  const updateCheckedState = useCallback(
    (fieldName: string, value: boolean) => {
      const copy = [...selectedValues];
      if (value) {
        copy.push(fieldName);
      } else {
        if (copy.length === 1) {
          return;
        }
        const index = copy.indexOf(fieldName);
        if (index > -1) {
          copy.splice(index, 1);
        }
      }

      const newDisplayValues = field.meta.availableFields.filter((value) => copy.indexOf(value) > -1);
      onUpdateConfig(field, { displayValues: newDisplayValues });
    },
    [selectedValues, field, onUpdateConfig]
  );

  return (
    <>
      {field.meta.availableFields?.map((caption) => (
        <FormControlLabel
          key={caption}
          control={
            <Checkbox
              sx={{ p: "2px .4rem 2px 0" }}
              disableRipple
              disableFocusRipple
              disableTouchRipple
              checked={checked(caption)}
              onChange={(_, value) => updateCheckedState(caption, value)}
            />
          }
          label={<Typography>{caption}</Typography>}
        />
      ))}
    </>
  );
}
