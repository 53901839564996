import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./RootState";
import { DrillDownInfo } from "../api/biApi.types";
import { DrillDownConfiguration, DrillDownConfigurationState } from "./DrillDownConfigurationState";

const initialState: DrillDownConfigurationState = {
  pages: [],
};

const drillDownConfigurationSlice = createSlice({
  name: "drillDownConfiguration",
  initialState,
  reducers: {
    add(state, action: { payload: DrillDownConfiguration }) {
      state.pages.push(action.payload);
    },

    remove(state, action: { payload: string }) {
      state.pages = state.pages.filter((page) => page.id !== action.payload);
    },
    removeAll(state) {
      state.pages = [];
    },
    setInfo(state, action: { payload: { id: string; info: DrillDownInfo } }) {
      const { id, info } = action.payload;
      const page = state.pages.find((page) => page.id === id);
      if (page) {
        page.info = info;
      }
    },
  },
});

export const drillDownActions = drillDownConfigurationSlice.actions;

export const selectDrillDownItems = (state: RootState) => state.drillDownConfiguration.pages;

export default drillDownConfigurationSlice.reducer;
