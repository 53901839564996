import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ApiResponse } from "../../../../../../shared/api/types";
import biClient from "../../../../../api/biApi";
import { FailedMeasure, PivotDataResponse } from "../../../../../api/biApi.types";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { currentReportActions } from "../../../../../store/currentReportSlice";
import { update } from "../../../../../store/devToolsSlice";
import useDeferredBuild from "../../../common/hooks/useDeferredBuild";
import useRequestConfigBuilder from "./useRequestConfigBuilder";

type CancellablePivotRequest = {
  cancel: () => void;
};

export default function useDataLoadingBuilder() {
  const dispatch = useDispatch();
  const [cancellationToken, setCancellationToken] = React.useState<CancellablePivotRequest | undefined>(undefined);
  const { common: locale } = useLocalization();
  const { requestConfig } = useRequestConfigBuilder();

  const [calculateCount, setCalculateCount] = React.useState(0);
  const [pivot, setPivot] = React.useState<PivotDataResponse>();
  const [error, setError] = React.useState<string>();
  const [failedMeasures, setFailedMeasures] = React.useState<FailedMeasure[]>([]);

  const calculating = calculateCount > 0;

  const calculatingRef = React.useRef<number>(calculateCount);
  calculatingRef.current = calculateCount;

  const cancellationTokenRef = React.useRef<CancellablePivotRequest | undefined>(cancellationToken);
  cancellationTokenRef.current = cancellationToken;

  const buildConfigRef = React.useRef(requestConfig);
  React.useEffect(() => {
    buildConfigRef.current = requestConfig;
  }, [requestConfig]);

  React.useEffect(() => {
    return () => {
      if (cancellationTokenRef.current !== undefined) {
        cancellationTokenRef.current.cancel();
      }
    };
  }, []);

  const handleBuildDone = (response: ApiResponse<PivotDataResponse>) => {
    if (response.success) {
      setError(undefined);
      setPivot(response.data);
      dispatch(update({ loggingItems: response.data.loggingItems }));
      dispatch(currentReportActions.setEtag({ etag: response.data.etag, cacheValid: undefined }));
      setFailedMeasures(response.data.failedMeasures);
    } else if (response.error?.message) {
      setError(response.error.message);
    }
    setCalculateCount(calculatingRef.current - 1);
  };

  const handleBuildError = (reason: string | undefined) => {
    if (reason === "canceled") {
      setError(undefined);
    } else {
      setError(reason || locale.calculation_error);
    }
    setCalculateCount(calculatingRef.current - 1);
  };

  const calculate = () => {
    setCalculateCount(calculatingRef.current + 1);
    setError(undefined);
    dispatch(currentReportActions.setEtag({ etag: undefined, cacheValid: undefined }));

    setCancellationToken(biClient.buildPivotReport(buildConfigRef.current, onBuildDone, onBuildError));
  };

  const { doRequest, onBuildDone, onBuildError, resetInitialRequestFlag, cancelCurrentAwaiter } = useDeferredBuild({
    request: calculate,
    handleBuildDone,
    handleBuildError,
    cancelRequest: () => cancellationTokenRef.current?.cancel(),
    onProgressChange: (progress) => dispatch(currentReportActions.updateBuildProgress(progress)),
  });

  const handleInvalidConfiguration = () => {
    cancelCurrentAwaiter();
  };

  useEffect(() => {
    resetInitialRequestFlag();
  }, [requestConfig.reportId, resetInitialRequestFlag]);

  return { pivot, calculating, error, calculate: doRequest, failedMeasures, handleInvalidConfiguration };
}

export type PivotCalculationReturnType = ReturnType<typeof useDataLoadingBuilder>;
