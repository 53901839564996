import { configureStore } from "@reduxjs/toolkit";
import currentReportReducer from "./currentReportSlice";
import metaDataReducer from "./metaDataSlice";
import measureDatasetsReducer from "./measureDataSetsSlice";
import drillDownConfigurationReducer from "./drilldownSlice";
import devPanelConfigurationReducer from "./devToolsSlice";
import reportTemplatesReducer from "./reportTemplatesSlice";
import reportsReducer from "./reportsSlice";
import apiReportReducer from "./apiReportSlice";

export const store = configureStore({
  reducer: {
    currentReport: currentReportReducer,
    metaData: metaDataReducer,
    measureDatasets: measureDatasetsReducer,
    drillDownConfiguration: drillDownConfigurationReducer,
    devTools: devPanelConfigurationReducer,
    reportTemplates: reportTemplatesReducer,
    reports: reportsReducer,
    apiReport: apiReportReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
