import { ReportTemplate } from "../../../../shared/reporting/api/biClient.types";
import SaveTemplateDialog from "../../../../shared/reporting/components/reportTemplates/dialogs/SaveTemplateDialog";
import { useVisualizationContext } from "./context/VisualizationContext";

const SaveTemplateChangesDialog = () => {
  const { showSaveDialog, report, onCloseSaveChanges, reportConfiguration, companies, onSaveChangesSaved } =
    useVisualizationContext();

  if (showSaveDialog && report) {
    return (
      <SaveTemplateDialog
        title="Save Template"
        template={report as ReportTemplate}
        templateConfiguration={reportConfiguration}
        onClose={onCloseSaveChanges}
        onSaved={onSaveChangesSaved}
        companies={companies}
      />
    );
  }

  return null;
};

export default SaveTemplateChangesDialog;
