import { Grid, Select, Typography } from "@mui/material";
import { useMemo } from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { MenuItemStyled } from "../../../../../../shared/components/MenuItemStyled";
import { DateRangeType } from "../../../../../../shared/reporting/api/biClient.types";

interface Props {
  dateRange?: DateRangeType;
  onChange: (newDateRangeType: DateRangeType) => void;
}

export const DateRanges = ({ dateRange, onChange }: Props) => {
  const ranges = useMemo(() => {
    return [
      { value: DateRangeType.BeginningBalance, text: "Beginning Balance" },
      { value: DateRangeType.NetChange, text: "Net Change" },
      { value: DateRangeType.EndingBalance, text: "Ending Balance" },
      { value: DateRangeType.Mtd, text: "MTD" },
      { value: DateRangeType.Qtd, text: "QTD" },
      { value: DateRangeType.Ytd, text: "YTD" },
    ];
  }, []);

  return (
    <Grid container sx={{ alignItems: "center" }}>
      <Typography color="secondary">Period</Typography>
      <HorizontalFill />
      <Select
        value={dateRange}
        sx={{
          width: 208,
          ".MuiSelect-select": {
            py: 0.7,
          },
        }}
        onChange={(evt) => onChange(evt.target.value as DateRangeType)}
      >
        {ranges.map((value) => (
          <MenuItemStyled
            key={value.value}
            value={value.value}
            selected={value.value === dateRange}
            onClick={() => onChange(value.value)}
          >
            <Typography variant="caption">{value.text}</Typography>
          </MenuItemStyled>
        ))}
      </Select>
    </Grid>
  );
};

export default DateRanges;
