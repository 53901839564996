import { Button, Typography } from "@mui/material";
import { RedirectKeys } from "../auth/Auth";
import { useLocalization } from "../hooks/useLocalization";
import AuthenticationFailedIcon from "../icons/AuthenticationFailedIcon";
import InfoPage from "./infoPage/InfoPage";

const AuthenticationFailed = () => {
  const locale = useLocalization();
  const page = locale.pages.authentication_failed;

  return (
    <InfoPage
      Icon={<AuthenticationFailedIcon />}
      title={page.title}
      subtitle={<Typography color="text.secondary">{page.subtitle}</Typography>}
    >
      <Button href={`/?${RedirectKeys.Prompt}=select_account`} color="primary" variant="contained">
        {page.button}
      </Button>
    </InfoPage>
  );
};

export default AuthenticationFailed;
