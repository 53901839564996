import { SvgIcon, SvgIconProps } from "@mui/material";

export const SigmaIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 10, height: 14 }} viewBox="0 0 10 14" {...props}>
      <path d="M10 2.33341H2.35833L7.35833 7.33341L2.35833 12.3334H10V14.0001H0V12.3334L5 7.33341L0 2.33341V0.666748H10V2.33341Z" />
    </SvgIcon>
  );
};
export default SigmaIcon;
