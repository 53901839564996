import { useCallback, useState } from "react";
import { useNotificationContext } from "../../shared/contexts/NotificationContext";
import { downloadFileFromUrl } from "../../shared/services/downloadFile";
import { ExportResponse } from "../api/biApi.types";
import biClient from "../api/biApi";
import { useLocalization } from "./useLocalization";
import { ApiResponse } from "../../shared/api/types";

export const useDrillDownExport = <T>(request: (body: T) => Promise<ApiResponse<ExportResponse>>) => {
  const [exporting, setExporting] = useState(false);
  const { sendNotificationError } = useNotificationContext();
  const { common: locale } = useLocalization();

  const downloadExport = useCallback((token: string) => {
    const url = biClient.generateExportDownloadLink(token);
    downloadFileFromUrl(url);
  }, []);

  const waitExportReady = useCallback(
    (token: string, attempt: number) => {
      biClient
        .checkExportReady(token)
        .then((response) => {
          if (response.success) {
            if (response.data.fileProcessed) {
              downloadExport(token);
              setExporting(false);
            } else {
              if (attempt < 20) {
                setTimeout(() => waitExportReady(token, attempt + 1), 1500);
              } else {
                sendNotificationError(locale.export_file_not_generated);
                setExporting(false);
              }
            }
          } else {
            sendNotificationError(locale.export_file_error);
            setExporting(false);
          }
        })
        .catch(() => {
          sendNotificationError(locale.export_file_error);
          setExporting(false);
        });
    },
    [locale.export_file_error, locale.export_file_not_generated, setExporting, downloadExport, sendNotificationError]
  );

  const requestExport = useCallback(
    (drillDownData: T) => {
      setExporting(true);

      request(drillDownData)
        .then((response) => {
          if (response.success) {
            waitExportReady(response.data.token, 0);
          } else {
            sendNotificationError(locale.export_file_error);
            setExporting(false);
          }
        })
        .catch(() => {
          sendNotificationError(locale.export_file_error);
          setExporting(false);
        });
    },
    [request, waitExportReady, sendNotificationError, locale.export_file_error]
  );

  return { exporting, requestExport };
};
