import { Grid, Typography } from "@mui/material";
import React from "react";
import { useLocalization } from "../../../../hooks/useLocalization";

interface Props {
  caption: string;
  subCaption: string;
  captionColor?: string;
  subCaptionColor?: string;
  isMandatory?: boolean;
  captionVariant?: "caption" | "body1";
}

export default function FieldCaption({
  caption,
  subCaption,
  captionColor,
  subCaptionColor,
  children,
  isMandatory,
  captionVariant = "caption",
}: React.PropsWithChildren<Props>) {
  const { report: locale } = useLocalization();
  return (
    <Grid container sx={{ gap: 0.4, whiteSpace: "nowrap", maxWidth: "100%", alignItems: "center" }}>
      <Typography
        variant={captionVariant}
        sx={{ flexGrow: 0, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "60%" }}
        color={(theme) => captionColor || theme.palette.text.primary}
      >
        {caption}
        {isMandatory && (
          <Typography variant="caption" color={(theme) => theme.palette.error.main}>
            *
          </Typography>
        )}
      </Typography>
      {children}
      <Grid sx={{ position: "relative", flexGrow: 1, display: "flex", alignItems: "center" }}>
        {isMandatory && subCaption.length === 0 && (
          <SubCaption caption={locale.mandatory_filed_is_not_set} color="error.main" />
        )}
        {subCaption.length > 0 && <SubCaption caption={subCaption} color={subCaptionColor} />}
      </Grid>
    </Grid>
  );
}

function SubCaption({ caption, color }: { caption: string; color?: string }) {
  return (
    <Typography
      variant="caption"
      sx={{
        position: "absolute",
        width: "100%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        textAlign: "end",
      }}
      color={(theme) => color || theme.palette.primary.main}
    >
      {caption}
    </Typography>
  );
}
