import { Box, Typography, TypographyProps } from "@mui/material";
import TooltipWrapper from "../../../shared/components/TooltipWrapper";
import useMergedSx from "../../../shared/hooks/useMergedSx";

interface Props {
  label: string;
  tooltipText?: string;
  typographyProps?: TypographyProps;
}

const TypographyWithTooltip = ({ label, tooltipText, typographyProps }: Props) => {
  const hasTooltip = Boolean(tooltipText);
  const { sx: typographySx, ...other } = typographyProps || {};

  const mergedTypographySx = useMergedSx(typographySx, {
    textDecoration: "underline",
    textDecorationStyle: "dotted",
    cursor: "help",
  });

  return (
    <Box sx={{ gap: 1, display: "flex", alignItems: "center" }}>
      {hasTooltip ? (
        <TooltipWrapper
          sx={(theme) => ({ maxWidth: theme.spacing(22.5), textAlign: "center" })}
          title={tooltipText}
          placement="bottom"
        >
          <Typography {...other} sx={mergedTypographySx}>
            {label}
          </Typography>
        </TooltipWrapper>
      ) : (
        <Typography {...typographyProps}>{label}</Typography>
      )}
    </Box>
  );
};

export default TypographyWithTooltip;
