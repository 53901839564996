import { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { RedirectKeys } from "../../shared/auth/Auth";
import { requestToken } from "../../shared/auth/identity";
import AuthenticationFailed from "../../shared/components/AuthenticationFailed";
import FullScreenLoader from "../../shared/components/fullScreenLoader/FullScreenLoader";
import { logError } from "../../shared/logging";
import { defined } from "../../shared/utilities/typeHelper";
import { useLocalization } from "../hooks/useLocalization";

enum AuthenticationState {
  Authenticated = "Authenticated",
  NotAuthenticated = "NotAuthenticated",
  AuthenticationFailed = "AuthenticationFailed",
}

export const Authentication = () => {
  const navigate = useNavigate();
  const { authentication: locale } = useLocalization();
  const [authenticationState, setAuthenticationState] = useState(AuthenticationState.NotAuthenticated);

  const urlParams = new URLSearchParams(window.location.search);
  const authCode = urlParams.get(RedirectKeys.Code);
  const stateParam = urlParams.get(RedirectKeys.State);

  const authCodeProvided = () => !!authCode;
  const acquireToken = useCallback(async () => {
    try {
      await requestToken(defined(authCode));
      setAuthenticationState(AuthenticationState.Authenticated);
    } catch {
      setAuthenticationState(AuthenticationState.AuthenticationFailed);
    }
  }, [authCode]);

  useEffect(() => {
    if (authenticationState === AuthenticationState.NotAuthenticated) {
      acquireToken();
    } else if (authenticationState === AuthenticationState.Authenticated) {
      if (stateParam) {
        try {
          const state = JSON.parse(stateParam);
          const client = state.client;
          if (client) {
            navigate(`/${client}`);
            return;
          }
        } catch (error) {
          logError("Error while parsing state param", "[Authentication]");
        }
      }
      navigate(`/`);
    }
  }, [authenticationState, stateParam, acquireToken, navigate]);

  if (!authCodeProvided()) {
    return <Navigate to="/" />;
  }

  switch (authenticationState) {
    case AuthenticationState.NotAuthenticated: {
      return <FullScreenLoader title={locale.title} subtitle={locale.subtitle} />;
    }
    case AuthenticationState.AuthenticationFailed: {
      return <AuthenticationFailed />;
    }
    default:
      return <></>;
  }
};

export default Authentication;
