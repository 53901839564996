import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Typography } from "@mui/material";
import { getInitialsFromUserName } from "../utilities/stringHelper";

type AvatarSize = "small" | "medium" | "large" | number;

interface Props {
  userName: string;
  avatarImage?: string;
  size: AvatarSize;
}

const getSizes = (size: AvatarSize) => {
  switch (size) {
    case "small": {
      return { sizePx: 24, fontSize: 14 };
    }
    case "medium": {
      return { sizePx: 32, fontSize: 16 };
    }
    case "large": {
      return { sizePx: 40, fontSize: 20 };
    }
    default: {
      return { sizePx: size, fontSize: Math.max(size / 2, 12) };
    }
  }
};

const UserAvatar = ({ userName, avatarImage, size }: Props) => {
  const { sizePx, fontSize } = getSizes(size);
  const sx = { width: sizePx, height: sizePx };

  if (avatarImage) {
    return <Avatar src={avatarImage} alt={userName} sx={sx} />;
  }

  if (userName) {
    return (
      <Avatar alt={userName} sx={sx}>
        <Typography variant="subtitle1" color="text.secondary" sx={{ fontSize }}>
          {getInitialsFromUserName(userName)}
        </Typography>
      </Avatar>
    );
  }

  return (
    <Avatar alt={userName} sx={sx}>
      <PersonIcon color="secondary" />
    </Avatar>
  );
};

export default UserAvatar;
