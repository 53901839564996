import { SvgIcon, SvgIconProps } from "@mui/material";
import { grey } from "@mui/material/colors";

export const DataErrorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 54, height: 63, color: grey[50] }} viewBox="0 0 54 63" {...props}>
      <path
        d="M26.7237 61.8829C29.2112 61.8829 31.2277 59.8664 31.2277 57.3789C31.2277 54.8915 29.2112 52.875 26.7237 52.875C24.2363 52.875 22.2198 54.8915 22.2198 57.3789C22.2198 59.8664 24.2363 61.8829 26.7237 61.8829Z"
        fill="#BDBDBD"
      />
      <path
        d="M6.43488 55.2762C8.92234 55.2762 10.9388 53.2597 10.9388 50.7722C10.9388 48.2848 8.92234 46.2683 6.43488 46.2683C3.94743 46.2683 1.93095 48.2848 1.93095 50.7722C1.93095 53.2597 3.94743 55.2762 6.43488 55.2762Z"
        fill="#BDBDBD"
      />
      <path d="M26.7579 30.5073V56.5944" stroke="#BDBDBD" strokeWidth="3" strokeMiterlimit="10" />
      <path
        d="M15.102 30.5073V36.9099L6.43471 41.3713V49.2638"
        stroke="#BDBDBD"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M38.4136 30.5073V36.9099L47.0809 41.3713V49.2638"
        stroke="#BDBDBD"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path d="M41.7936 43.9768L52.3681 54.5513" stroke="#BDBDBD" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M52.3681 43.9768L41.7936 54.5513" stroke="#BDBDBD" strokeWidth="3" strokeMiterlimit="10" />
      <path
        d="M44.3223 12.918C44.0977 11.4747 43.5196 10.1094 42.6393 8.94368C41.7591 7.77801 40.6042 6.84819 39.2775 6.23709C37.9508 5.626 36.4935 5.3526 35.0355 5.44128C33.5775 5.52995 32.1641 5.97794 30.9212 6.74532C29.597 4.52868 27.5819 2.80793 25.1852 1.84727C22.7886 0.886611 20.1428 0.739148 17.6542 1.42752C15.1657 2.1159 12.9718 3.60205 11.4095 5.65781C9.8472 7.71357 9.00283 10.2253 9.00603 12.8073V12.935C6.7904 13.2727 4.78582 14.4396 3.39806 16.1995C2.0103 17.9594 1.34303 20.1808 1.5313 22.4141C1.71957 24.6474 2.74933 26.7258 4.41213 28.2285C6.07494 29.7312 8.24658 30.546 10.4875 30.508H42.9516C45.2033 30.5481 47.3854 29.7269 49.0522 28.2122C50.7189 26.6976 51.7445 24.6038 51.9195 22.3585C52.0944 20.1131 51.4056 17.8857 49.9936 16.1312C48.5817 14.3766 46.5531 13.2273 44.3223 12.918Z"
        stroke="#BDBDBD"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};
export default DataErrorIcon;
