import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { DimensionDescriptor, FunctionDescription } from "../../../../../api/biApi.types";
import { DimensionFormulaVariable, FunctionFormulaVariable, IFormulaVariable } from "./variables";

export const getDimensionVariables = (dimensions: DimensionDescriptor[]): IFormulaVariable[] => {
  return dimensions.map((dimension) => new DimensionFormulaVariable({ ...cloneDeep(dimension) }));
};

export const getFunctionVariables = (functions: FunctionDescription[]): IFormulaVariable[] => {
  return functions.map((func) => new FunctionFormulaVariable(func));
};

const availableDimensions = ["AccountCategory", "AccountName", "AccountNo"];

export const getAvailableDimensions = (dimensions: DimensionDescriptor[]) => {
  return dimensions.filter((d) => availableDimensions.includes(d.name));
};
