import objectHash from "object-hash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import biClient, { CancellationToken } from "../../../../api/biApi";
import { LedgerBasisRequest } from "../../../../api/biApi.types";
import { currentReportActions } from "../../../../store/currentReportSlice";
import useDimensionAndMeasuresSelection from "./useDimensionAndMeasuresSelection";

const useLedgerBasis = () => {
  const dispatch = useDispatch();
  const cancellationRef = useRef<CancellationToken>();
  const { dimensionNames, measureNames } = useDimensionAndMeasuresSelection();

  const dimensionHash = objectHash(dimensionNames);
  const measureHash = objectHash(measureNames);

  useEffect(() => {
    if (cancellationRef.current) {
      cancellationRef.current.cancel();
    }
    if (dimensionNames.length === 0 && measureNames.length === 0) {
      return;
    }
    const body: LedgerBasisRequest = {
      dimensionNames,
      measureNames,
    };
    cancellationRef.current = biClient.getLedgerBasis(
      body,
      (resp) => {
        if (resp.success && resp.data) {
          dispatch(currentReportActions.updateIsReportTransactional(resp.data.transactional));
        }
      },
      () => {
        //TODO: nothing
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionHash, measureHash]);
};

export default useLedgerBasis;
