import { TabularFieldType } from "../../../../../shared/reporting/api/biClient.types";
import { MetaItemType } from "../../../../api/biApi.types";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../../common/fields/types/dropField.types";
import { extractMeta } from "../../common/utilities/dropFieldContainerHelper";
import { TableField } from "../hooks/TableField";
import { referenceDateFields } from "./referenceDateFields";

export const canDropItem = (item: FieldWrapper<DraggableFieldType> | DraggableMetaType, fields: TableField[]) => {
  const meta = extractMeta(item);
  if (!meta) return false;
  if (meta.itemType === MetaItemType.DIMENSION) {
    if (referenceDateFields.includes(meta.name)) {
      return !fields.some((v) => referenceDateFields.includes(v.dimension?.meta.name ?? ""));
    }
    return !isFieldAlreadyAdded(meta.name, fields);
  }
  return true;
};

export const isFieldAlreadyAdded = (itemName: string, fields: TableField[]) => {
  return fields
    .filter((v) => v.fieldType === TabularFieldType.Dimension)
    .some((v) => v.dimension?.meta.name === itemName);
};
