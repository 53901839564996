import { useCallback, useEffect, useRef, useState } from "react";
import { OptionField } from "../ColumnsOptions.types";
import { HoveredFieldItemIndex, getInsertToIndex, hoveredIndexIsDefined } from "../../../../utils/dragDropHelper";
import { DimensionDescriptor } from "../../../../../../api/biApi.types";
import { generateGuid } from "../../../../../../../shared/utilities/generateGuid";
import useDragLeave from "../../../hooks/useDragLeave";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { Button, Grid } from "@mui/material";
import SortingItem from "./SortingItem";
import CustomDragLayer from "./CustomDragLayer";
import ColumnsPlaceHolder from "../ColumnsPlaceHolder";
import AddIcon from "@mui/icons-material/Add";
import { FieldWithSorting } from "../../../../../../hooks/FieldWithOrder";
import { SORTING_COLUMN_DD_ITEM_TYPE } from "../ColumnsOptions.types";

interface Props {
  selectedFields: FieldWithSorting[];
  options: OptionField[];
  onSelectionChanged: (selection: FieldWithSorting[]) => void;
}

const SortingContainer = (props: Props) => {
  const { selectedFields, options, onSelectionChanged } = props;

  const [hoveredIndex, setHoveredIndex] = useState<HoveredFieldItemIndex>("draggingIsOff");
  const parentRef = useRef<HTMLDivElement | null>(null);

  const hoveredIndexRef = useRef<HoveredFieldItemIndex>(hoveredIndex);
  hoveredIndexRef.current = hoveredIndex;

  const [currentFields, setCurrentFields] = useState(selectedFields);
  useEffect(() => setCurrentFields(selectedFields), [selectedFields]);

  const dragFieldsRef = useRef(currentFields);
  dragFieldsRef.current = currentFields;

  const updateSorting = useCallback(
    (orderField: FieldWithSorting, sortAsc: boolean) => {
      const fields = [...currentFields];
      const f = fields.find((field) => field.field.name === orderField.field.name);
      if (f) {
        f.sortAsc = sortAsc;
      }
      setCurrentFields(fields);
      onSelectionChanged(fields);
    },
    [currentFields, setCurrentFields, onSelectionChanged]
  );

  const availableOptions = useCallback(
    (field: DimensionDescriptor) => {
      return options.filter((op) => !currentFields.some((sf) => sf.field.name === op.id) || op.id === field.name);
    },
    [options, currentFields]
  );

  const replaceFieldWith = useCallback(
    (orderField: FieldWithSorting, newField: DimensionDescriptor) => {
      const fields = [...currentFields];
      const f = fields.find((field) => field.field.name === orderField.field.name);
      if (f) {
        f.field = newField;
      }
      onSelectionChanged(fields);
    },
    [currentFields, onSelectionChanged]
  );

  const removeField = useCallback(
    (index: number) => {
      const fields = [...currentFields];
      fields.splice(index, 1);
      fields.forEach((field, index) => {
        field.order = index;
      });
      onSelectionChanged(fields);
    },
    [currentFields, onSelectionChanged]
  );

  const addColumn = useCallback(() => {
    const fields = [...currentFields];
    fields.push({
      field: { name: generateGuid() },
      order: dragFieldsRef.current.length,
      sortAsc: true,
    } as FieldWithSorting);
    onSelectionChanged(fields);
  }, [currentFields, onSelectionChanged]);

  const onEndReordering = useCallback(
    (draggingItem: FieldWithSorting) => {
      const toIndex = getInsertToIndex(Array.from(parentRef.current?.children || []));

      const values = [...dragFieldsRef.current];
      values.splice(draggingItem.order, 1);
      values.splice(toIndex, 0, draggingItem);
      setCurrentFields(values);
      setHoveredIndex("draggingIsOff");

      setTimeout(() => {
        dragFieldsRef.current.forEach((field, index) => {
          field.order = index;
        });
        onSelectionChanged(dragFieldsRef.current);
      }, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [{ isOver, didDrop, draggingItem }, drop] = useDrop<any, any, any>(() => {
    return {
      accept: [SORTING_COLUMN_DD_ITEM_TYPE],
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        didDrop: monitor.didDrop(),
        draggingItem: monitor.getItem(),
      }),
    };
  }, []);

  const [fieldItemIndexesForShifting, setFieldItemIndexesForShifting] = useState<number[]>();
  const [draggingItemMovedFromInitialPosition, setDraggingItemMovedFromInitialPosition] = useState(false);

  const handleItemHovered = useCallback((hoveredItem: FieldWithSorting) => {
    const prevIndex = hoveredIndexRef.current;
    const newIndex = hoveredItem.order;
    if (prevIndex !== "draggingIsOff" && prevIndex !== newIndex) {
      setDraggingItemMovedFromInitialPosition(true);
    }
    setHoveredIndex(newIndex);
  }, []);

  useDragLeave(() => setHoveredIndex("draggingIsOff"), isOver, didDrop);

  useEffect(() => {
    const arr = dragFieldsRef.current
      .filter((v) => hoveredIndexIsDefined(hoveredIndex) && v.order >= hoveredIndex)
      .map((v) => v.order);
    setFieldItemIndexesForShifting(arr);
  }, [hoveredIndex]);

  useEffect(() => {
    //Dragging is over
    if (!draggingItem) {
      setFieldItemIndexesForShifting(undefined);
      setDraggingItemMovedFromInitialPosition(false);
    }
  }, [draggingItem]);

  return (
    <Grid ref={drop} container py={2} gap={1}>
      <Grid ref={parentRef} container sx={{ flexDirection: "column", px: 2, gap: 1 }}>
        {currentFields.map((item, index) => {
          const addShifting =
            fieldItemIndexesForShifting !== undefined && fieldItemIndexesForShifting.includes(item.order);
          const hideDraggingItem =
            draggingItem && item.order === draggingItem?.field?.order && fieldItemIndexesForShifting !== undefined;
          return (
            <SortingItem
              key={item.field.name + `-${index}`}
              options={availableOptions(item.field)}
              field={item}
              draggingItemMovedFromInitialPosition={draggingItemMovedFromInitialPosition}
              onItemHovered={handleItemHovered}
              addShifting={addShifting}
              hideDraggingItem={hideDraggingItem}
              onEndReordering={onEndReordering}
              onRemove={() => removeField(index)}
              onReplaceWith={(newField) => replaceFieldWith(item, newField)}
              onUpdateSorting={updateSorting}
            />
          );
        })}
        <CustomDragLayer />
      </Grid>
      {isOver && <ColumnsPlaceHolder hide={fieldItemIndexesForShifting === undefined && draggingItem} />}
      <Grid item sx={{ pl: 5 }} onDragEnter={() => setHoveredIndex("nonSortableItemHovered")}>
        <Button startIcon={<AddIcon />} onClick={addColumn}>
          Add Column
        </Button>
      </Grid>
    </Grid>
  );
};

export default SortingContainer;
