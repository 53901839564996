import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import UnsavedChangesIcon from "../../../icons/UnsavedChangesIcon";
import { LoadingButton } from "@mui/lab";

interface Props {
  name: string | undefined;
  type: "report" | "template";
  isLoading?: boolean;
  onSave: () => void;
  onContinue: () => void;
  onCancel: () => void;
}

export default function UnsavedChangesDialog({ name, type, onSave, onContinue, onCancel, isLoading }: Props) {
  const contentTitle = `Unsaved ${type === "report" ? "Report" : "Template"} ${name && `"${name}"`}`;

  return (
    <Dialog open={true} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogCloseButton onClick={onCancel} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <UnsavedChangesIcon sx={{ width: 80, height: 80 }} />
          <Stack alignItems="center" textAlign="center" spacing={1}>
            <Typography variant="h6">{contentTitle}</Typography>
            <Typography color="text.secondary">
              Your changes have not been saved. Do you want to save your changes, discard them, or stay on this page?
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onCancel} sx={{ mr: "auto" }}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" onClick={onSave} loading={isLoading}>
          Save Changes
        </LoadingButton>
        <Button variant="contained" onClick={onContinue}>
          Discard & Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
