import { Box } from "@mui/material";
import { DEFAULT_AREA_FIELD_ITEM_HEIGHT } from "./areaFields/AreaFieldsHelper";

interface Props {
  hide: boolean;
  height?: number;
}

const DropFieldPlaceholder = ({ hide, height }: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: hide ? "none" : "flex",
        boxSizing: "border-box",
        height: `${height || DEFAULT_AREA_FIELD_ITEM_HEIGHT}px`,
        pointerEvents: "none",
      }}
    />
  );
};

export default DropFieldPlaceholder;
