import { invalidResult, Validator, validResult } from "../../../../../../shared/utilities/validators";
import { MeasureDescriptor } from "../../../../../api/biApi.types";

export const duplicateMeasureNameValidator = (
  measures: MeasureDescriptor[],
  currentName?: string
): Validator<string> => {
  return (value: string) => {
    if (
      measures.some((measure) => measure.name !== currentName && measure.caption.toLowerCase() === value.toLowerCase())
    ) {
      return invalidResult(`${value} Measure already exists`);
    }

    return validResult();
  };
};
