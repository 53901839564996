import { Autocomplete, Box, ListItemText, MenuItem, Stack, SxProps, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { ClientInfo } from "../../api/biClient.types";

interface DataSourceProps {
  dataSource: string;
  companies: ClientInfo[];
  title: string | undefined;
  disclaimer: string;
  onChange: (company: string) => void;
  isLoading?: boolean;
  sx?: SxProps;
}
export default function DataSource({
  dataSource,
  companies,
  title,
  disclaimer,
  onChange,
  isLoading,
  sx,
}: DataSourceProps) {
  const [selectedCompany, setSelectedCompany] = useState<ClientInfo | null>(
    companies.find((c) => c.clientCode === dataSource) || null
  );

  return (
    <Stack gap={2} sx={{ ...sx }}>
      <Stack gap={1}>
        <Typography variant="subtitle2"> {title} </Typography>
        <Typography id="disclaimer">{disclaimer}</Typography>
      </Stack>
      <Stack gap={1}>
        <Autocomplete
          fullWidth
          disabled={isLoading}
          value={selectedCompany}
          onChange={(_, newValue) => {
            setSelectedCompany(newValue);
            onChange(newValue?.clientCode || "");
          }}
          getOptionLabel={(option) => option.title}
          options={companies}
          renderOption={(props, comp) => (
            <MenuItem value={comp?.clientCode} sx={{ py: 0.5 }} {...props} key={comp?.clientCode || ""}>
              <Box display="flex" justifyContent="center" alignItems="center" mr={1.5} bgcolor="white">
                <img alt="Client Logo" style={{ width: 24, height: 24 }} src={comp?.branding.logoMarkUrl} />
              </Box>
              <ListItemText primary={comp?.title} sx={{ py: 0.5 }} />
            </MenuItem>
          )}
          renderInput={(params) => {
            const logo = companies.find((c) => c.clientCode === selectedCompany?.clientCode)?.branding.logoMarkUrl;
            return (
              <TextField
                {...params}
                label="Data Source"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: logo && (
                    <Box display="flex" justifyContent="center" alignItems="center" bgcolor="white" ml={1} mr={0.5}>
                      <img
                        alt="Client Logo"
                        style={{ width: 24, height: 24 }}
                        src={selectedCompany?.branding.logoMarkUrl}
                      />
                    </Box>
                  ),
                }}
              />
            );
          }}
        />
      </Stack>
    </Stack>
  );
}
