import ViewContainer from "../common/ViewContainer";
import ViewerParameters from "../common/ViewerParameters";
import { ValueField } from "../Types";
import { useFieldsStateContext } from "./contexts/FieldsStateContext";
import PreviewActions from "../common/PreviewActions";
import PreviewComponent from "./PreviewComponent";
import { isValueFieldHasParameters } from "../common/utilities/valueFieldUtils";
import React from "react";
import { UserMeasureConfigurations } from "../../../../shared/reporting/api/biClient.types";
import useChartStateViewer from "./hooks/useChartStateViewer";
import withExport from "./hocs/withExport";

export default function ChartViewer() {
  const { conditionsArea, valuesArea } = useFieldsStateContext();
  const state = useChartStateViewer();

  const fields = React.useMemo(
    () => conditionsArea.values.filter((v) => v.config.parameter === true),
    [conditionsArea.values]
  );

  const measuresToShow = React.useMemo(() => {
    return valuesArea.values.filter(isValueFieldHasParameters).filter((m): m is ValueField => !!m);
  }, [valuesArea.values]);

  const showParameters = React.useMemo(() => {
    return fields.length > 0 || measuresToShow.length > 0;
  }, [fields, measuresToShow]);

  const updateMeasure = React.useCallback(
    (field: ValueField, change: Partial<UserMeasureConfigurations>) => {
      const tableField = valuesArea.values.find((v) => v.config.guid === field.config.guid);
      if (tableField !== undefined) {
        valuesArea.updateItemConfig(tableField, change);
      }
    },
    [valuesArea]
  );

  return (
    <ViewContainer
      parameters={
        showParameters ? (
          <ViewerParameters
            conditionsArea={conditionsArea}
            measuresWithConditions={measuresToShow}
            updateMeasure={updateMeasure}
          />
        ) : (
          <></>
        )
      }
      preview={<PreviewComponentWithExport state={state} />}
      actionPanel={<PreviewActions />}
    ></ViewContainer>
  );
}

const PreviewComponentWithExport = withExport(PreviewComponent);
