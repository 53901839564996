import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import CloseIconButton from "../CloseIconButton";

interface Props {
  initialValue?: string;
  placeholder?: string;
  autoFocus?: boolean;
  debounceTimeMs?: number;
  fullWidth?: boolean;
  disabled?: boolean;
  onSearch?: (text: string) => void;
  onKeyDown?: (evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const SearchField = ({
  initialValue,
  autoFocus,
  onSearch,
  placeholder,
  debounceTimeMs,
  fullWidth,
  disabled,
  onKeyDown,
}: Props) => {
  const [searchFieldValue, setSearchFieldValue] = useState(() => initialValue || "");

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    handleSearch(event.clipboardData.getData("text").trim());
    event.preventDefault();
  };

  const handleSearchDebounced = useDebounce((value: string) => {
    onSearch?.(value);
  }, debounceTimeMs);

  const handleSearch = (value: string) => {
    setSearchFieldValue(value);
    if (debounceTimeMs) {
      handleSearchDebounced(value);
      return;
    }
    onSearch?.(value);
  };

  return (
    <TextField
      placeholder={placeholder || "Search"}
      onPaste={handlePaste}
      autoFocus={autoFocus}
      disabled={disabled}
      autoComplete="off"
      sx={(theme) => ({
        width: fullWidth ? "100%" : theme.spacing(28),
        bgcolor: theme.palette.background.grey,
        borderRadius: "4px",
        "& .MuiOutlinedInput-root": {
          fieldset: {
            border: `1px solid ${theme.palette.divider}`,
          },
          "&:hover fieldset": {
            borderColor: theme.palette.text.primary,
          },
          "&.Mui-focused fieldset": {
            borderColor: theme.palette.primary.main,
            borderWidth: 2,
          },
          "& .MuiOutlinedInput-input": {
            color: theme.palette.text.primary,
          },
        },
        svg: {
          color: theme.palette.action.active,
        },
      })}
      InputProps={{
        autoFocus: !!autoFocus,
        value: searchFieldValue,
        onKeyDown: (e) => {
          e.stopPropagation();
          onKeyDown?.(e);
        },
        onChange: (evt) => {
          handleSearch(evt.target.value);
        },
        startAdornment: <SearchIcon sx={{ mr: 1 }} />,
        endAdornment: searchFieldValue && <CloseIconButton onClick={() => handleSearch("")} sx={{ p: 0 }} />,
      }}
    />
  );
};

export default SearchField;
