import { Box, Switch, SxProps, Theme, TypographyProps } from "@mui/material";
import useMergedSx from "../../../shared/hooks/useMergedSx";
import TypographyWithTooltip from "./TypographyWithTooltip";

interface Props {
  checked?: boolean;
  label: string;
  tooltipText?: string;
  typographyProps?: TypographyProps;
  containerSx?: SxProps<Theme>;
  onChange: (newValue: boolean) => void;
}

const SwitchField = ({ checked, onChange, label, tooltipText, typographyProps, containerSx }: Props) => {
  const mergedContainerSx = useMergedSx(containerSx, {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
  });

  return (
    <Box sx={mergedContainerSx}>
      <TypographyWithTooltip label={label} tooltipText={tooltipText} typographyProps={typographyProps} />
      <Switch checked={checked} onClick={() => onChange(!checked)}></Switch>
    </Box>
  );
};

export default SwitchField;
