export const defined = <T>(obj: T | null | undefined, errorMessage?: string): T => {
  if (obj === null || obj === undefined) {
    throw new TypeError(errorMessage || "Object is null or undefined");
  }
  return obj;
};

export const isDefined =
  <T>() =>
  (value: T | null | undefined): value is T =>
    value !== undefined && value !== null;

export function nameof<T>(name: keyof T) {
  return name;
}
