import { Chip, SxProps } from "@mui/material";
import { blue } from "@mui/material/colors";

interface Props {
  sx?: SxProps;
}
export default function NewLabel({ sx }: Props) {
  return (
    <Chip
      sx={{
        color: blue[500],
        bgcolor: blue[50],
        "& .MuiChip-label": {
          fontWeight: 500,
          px: 0.75,
        },
        height: 20,
        ...sx,
      }}
      color="info"
      size="small"
      label="New"
    />
  );
}
