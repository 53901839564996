import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";

interface Props {
  text?: string;
  sx?: SxProps<Theme> | undefined;
}

export const PropertyName = ({ text, sx }: Props) => {
  return (
    <Grid container sx={{ flexDirection: "column", flex: 1, position: "relative", height: "100%", ...sx }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="caption"
          sx={{ fontWeight: 700, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
        >
          {text}
        </Typography>
      </Box>
    </Grid>
  );
};
