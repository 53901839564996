import { ConditionField, ShowFieldOptionsSettings } from "../../../Types";
import { useCallback, useState } from "react";
import { formatConditionCaption } from "./conditionsHelper";
import ConditionItem from "./ConditionItem";
import ItemContainer from "../ItemContainer";

export const ConditionItemContainer = (props: {
  field: ConditionField;
  detailed: boolean;
  canBeRemoved: boolean;
  readonly?: boolean;
  disableEqualitySelection?: boolean;
  onRemoveItem?: (item: ConditionField) => void;
  showOptions: (settings: ShowFieldOptionsSettings<ConditionField>) => void;
}) => {
  const { field, detailed, canBeRemoved, readonly, onRemoveItem, showOptions, disableEqualitySelection } = props;

  const [containerElementRef, setContainerElementRef] = useState<HTMLElement | null>(null);

  const getCaption = useCallback(() => {
    return formatConditionCaption(field, detailed, undefined, disableEqualitySelection);
  }, [field, detailed, disableEqualitySelection]);

  const onShowOptions = useCallback(
    (settings: ShowFieldOptionsSettings<ConditionField>) => {
      if (!showOptions) return;
      const ref = settings.showOnlySelected ? containerElementRef : settings.ref;
      if (!ref) return;

      showOptions({ field, ref, showOnlySelected: settings.showOnlySelected });
    },
    [containerElementRef, field, showOptions]
  );

  return (
    <ItemContainer setRef={setContainerElementRef}>
      <ConditionItem
        field={field}
        canBeRemoved={canBeRemoved}
        readonly={readonly}
        detailedView={detailed}
        onShowOptions={onShowOptions}
        onRemoveItem={onRemoveItem}
        formatCaption={getCaption}
      />
    </ItemContainer>
  );
};

export default ConditionItemContainer;
