import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConditionField } from "../components/builder/Types";
import { generateGuid } from "../../shared/utilities/generateGuid";
import { FieldWithOrder, FieldWithSorting } from "./FieldWithOrder";

export type FieldsStateConfiguration = {
  conditions: ConditionField[];
  selectedFields: FieldWithOrder[];
  sortedFields: FieldWithSorting[];
  measureName: string | undefined;
  error: string | undefined;
};

export const fieldSateConfigurationInitialState: FieldsStateConfiguration = {
  conditions: [],
  selectedFields: [],
  sortedFields: [],
  measureName: undefined,
  error: undefined,
};

const fieldsStateConfigurationSlice = createSlice({
  name: "fieldsStateConfiguration",
  initialState: fieldSateConfigurationInitialState,
  reducers: {
    setConditions(state, action: PayloadAction<ConditionField[]>) {
      state.conditions = [...action.payload];
    },
    addConditions(state, action: PayloadAction<ConditionField[]>) {
      action.payload.forEach((field) => {
        if (field.config.guid === undefined) {
          field.config.guid = generateGuid();
        }
      });
      state.conditions = [...state.conditions, ...action.payload];
    },
    updateCondition(state, action: PayloadAction<{ field: ConditionField; changes: Partial<ConditionField> }>) {
      const { payload } = action;
      const existField = state.conditions.find((f) => f.config.guid === payload.field.config.guid);
      if (existField !== undefined) {
        const updatedField = { ...existField, ...payload.changes };
        const index = state.conditions.indexOf(existField);
        state.conditions[index] = updatedField;
      }
    },
    removeCondition(state, action: PayloadAction<ConditionField>) {
      state.conditions = state.conditions.filter((c) => c.config.guid !== action.payload.config.guid);
    },
  },
});

export const fieldStateActions = fieldsStateConfigurationSlice.actions;

export const fieldStateReducer = fieldsStateConfigurationSlice.reducer;
