import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import {
  AmountType,
  CalculateByField,
  DateRangeType,
  IrrCashFlowFrequency,
  MeasureConfigurationType,
  MeasureConfigurations,
} from "../../../../../../shared/reporting/api/biClient.types";
import {
  CustomMeasureDescription,
  CustomMeasureUnit,
  DimensionDescriptor,
  MeasureDescriptor,
  MeasureUnit,
} from "../../../../../api/biApi.types";
import { generateGuid } from "../../../../../../shared/utilities/generateGuid";
import { ConditionField } from "../../../Types";
import { CustomMeasureState } from "../hooks/customMeasureState";
import { FormulaNode } from "./fillNodes";
import { normalizeVariableName } from "./tokenizer";

export const convertToMeasure = (
  state: CustomMeasureState,
  basedMeasure?: MeasureDescriptor
): CustomMeasureDescription => {
  const calculateBy = state.calculateBy || CalculateByField.Lcy;
  const amountType = state.amountType || AmountType.Net;
  const units = buildUnits(state, calculateBy, amountType);
  const measure: CustomMeasureDescription = {
    name: basedMeasure?.name || calculateName(state.caption),
    caption: `${state.caption}`,
    group: `${state.group}`,
    formula: `${state.formula}`,
    calculateBy: calculateBy,
    units,
    defaultFormat: state.format,
    configuration: buildConfiguration(state),
    amountType,
  };

  return measure;
};

const calculateName = (name?: string) => {
  return `${name}_${generateGuid()}`;
};

const buildUnits = (state: CustomMeasureState, calculateBy: CalculateByField, amountType: AmountType) => {
  const units: CustomMeasureUnit[] = [];
  state.formulaNodes.forEach((fn) => buildUnit(units, fn, calculateBy, amountType));
  return units;
};

const buildUnit = (
  units: CustomMeasureUnit[],
  node: FormulaNode,
  calculateBy: CalculateByField,
  amountType: AmountType
) => {
  if (node.function !== undefined) {
    node.args?.forEach((arg) => buildUnit(units, arg, calculateBy, amountType));
  } else {
    const unit = { variable: node.value } as CustomMeasureUnit;

    unit.unit = {
      measureBasedUnit: false,
      filters: node.conditions?.map((c) => cloneDeep(c.config.filter)),
      table: node.table,
      calculateByField: calculateBy,
      amountType,
    };

    units.push(unit);
  }
};

export const toCalculateBy = (units: { [key: string]: MeasureUnit }): CalculateByField => {
  if (units === undefined) return CalculateByField.Lcy;
  const unit = Object.values(units).find((unit) => unit.calculateByField !== undefined);
  return unit?.calculateByField || CalculateByField.Lcy;
};

export const toAmountType = (units: { [key: string]: MeasureUnit }): AmountType => {
  if (units === undefined) return AmountType.Net;
  const unit = Object.values(units).find((unit) => unit.amountType !== undefined);
  return unit?.amountType || AmountType.Net;
};

export const toAmountCalculateByCaption = (calcBy: CalculateByField, amountType: AmountType): string => {
  switch (calcBy) {
    case CalculateByField.Lcy:
      return `${toAmountTypeCaption(amountType)} (LCY)`;
    case CalculateByField.Pcy:
      return `${toAmountTypeCaption(amountType)} (PCY)`;
    case CalculateByField.AdditionalLcy:
      return `Additional-Currency ${toAmountTypeCaption(amountType)}`;
    case CalculateByField.Shares:
      return "Shares";
    default:
      return "";
  }
};

const toAmountTypeCaption = (amountType: AmountType): string => {
  switch (amountType) {
    case AmountType.Net:
      return "Amount";
    case AmountType.Credit:
      return "Credit Amount";
    case AmountType.Debit:
      return "Debit Amount";
    default:
      return "";
  }
};

export const toAmountTypeControlCaption = (amountType: AmountType): string => {
  switch (amountType) {
    case AmountType.Net:
      return "Net";
    case AmountType.Credit:
      return "Credit";
    case AmountType.Debit:
      return "Debit";
    default:
      return "";
  }
};

export const toFormulaNodes = (
  units: { [key: string]: MeasureUnit },
  dimensions: DimensionDescriptor[]
): FormulaNode[] => {
  const result: FormulaNode[] = [];

  for (const variable in units) {
    const unit = units[variable];
    if (unit === undefined) continue;

    if (unit.filters !== undefined) {
      const formulaNode: FormulaNode = {
        key: variable.toLowerCase(),
        text: variable,
        value: normalizeVariableName(variable),
        conditions: unit.filters.map(
          (filter) =>
            ({
              meta: cloneDeep(dimensions.find((d) => d.name === filter.dimensionName)),
              config: { filter: cloneDeep(filter) },
            }) as ConditionField
        ),
        linkedNodes: [],
        table: unit.table,
      };
      result.push(formulaNode);
    }
  }
  return result;
};

const buildConfiguration = (state: CustomMeasureState): MeasureConfigurations => {
  const hasIrrFunction = state.formulaNodes.some((fn) => fn.function?.name === "XIRR");
  if (hasIrrFunction) {
    return {
      type: MeasureConfigurationType.Irr,
      dateRange: DateRangeType.EndingBalance,
      cashFlowFrequency: IrrCashFlowFrequency.Daily,
    };
  }
  return {
    type: MeasureConfigurationType.Default,
    dateRange: DateRangeType.EndingBalance,
  };
};
