import { PropsWithChildren, useMemo } from "react";
import { AccessPermission } from "../api/types";
import { useClientPermissionsContext } from "../contexts/ClientPermissionsContext";
import { ReportAccessType } from "../reporting/api/biClient.types";
import { checkAccess } from "../reporting/components/sharing/utils";

interface Props {
  permissions: AccessPermission[];
  access?: ReportAccessType;
  requiredAccess?: ReportAccessType;
  clientCode?: string;
}

const ReportAuthorizedBox = ({
  clientCode,
  permissions,
  requiredAccess,
  access,
  children,
}: PropsWithChildren<Props>) => {
  const { hasAnyPermission, hasAnyPermissionForClient } = useClientPermissionsContext();

  const hasPermissions = useMemo(() => {
    if (clientCode) {
      return hasAnyPermissionForClient(clientCode, permissions);
    }
    return hasAnyPermission(permissions);
  }, [clientCode, hasAnyPermission, hasAnyPermissionForClient, permissions]);

  const hasAccessType = useMemo(() => {
    if (requiredAccess === undefined || access === undefined) {
      return true;
    }
    return checkAccess(access, requiredAccess);
  }, [access, requiredAccess]);

  if (!hasPermissions || !hasAccessType) return null;

  return <>{children}</>;
};

export default ReportAuthorizedBox;
