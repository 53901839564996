import { SxProps, Theme, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

interface Props {
  value: string;
  variant: Variant;
  sx?: SxProps<Theme>;
}

export const MeasureCell = ({ value, variant, sx }: Props) => {
  return (
    <Typography
      variant={variant}
      sx={{
        ...sx,
      }}
    >
      {value}
    </Typography>
  );
};
