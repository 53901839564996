import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";

interface Props {
  expanded: boolean;
  onChange: (value: boolean) => void;
}

export default function ExpandCollapseButton({ expanded, onChange }: Props) {
  return (
    <>
      {expanded && (
        <IconButton onClick={() => onChange(false)}>
          <ExpandLessIcon />
        </IconButton>
      )}
      {!expanded && (
        <IconButton onClick={() => onChange(true)}>
          <ExpandMoreIcon />
        </IconButton>
      )}
    </>
  );
}
