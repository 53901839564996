import { SvgIcon, SvgIconProps } from "@mui/material";

export const EditReportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M2.16667 14.3333H3.33333L10.5208 7.14579L9.35417 5.97913L2.16667 13.1666V14.3333ZM14.0833 5.93746L10.5417 2.43746L11.7083 1.27079C12.0278 0.951348 12.4203 0.791626 12.8858 0.791626C13.3508 0.791626 13.7431 0.951348 14.0625 1.27079L15.2292 2.43746C15.5486 2.7569 15.7153 3.14246 15.7292 3.59413C15.7431 4.04524 15.5903 4.43051 15.2708 4.74996L14.0833 5.93746ZM1.33333 16C1.09722 16 0.899445 15.92 0.74 15.76C0.58 15.6005 0.5 15.4027 0.5 15.1666V12.8125C0.5 12.7013 0.520833 12.5938 0.5625 12.49C0.604167 12.3855 0.666667 12.2916 0.75 12.2083L9.33333 3.62496L12.875 7.16663L4.29167 15.75C4.20833 15.8333 4.11472 15.8958 4.01083 15.9375C3.90639 15.9791 3.79861 16 3.6875 16H1.33333Z" />
    </SvgIcon>
  );
};
export default EditReportIcon;
