import DataSource from "devextreme/data/data_source";
import React from "react";
import { useSelector } from "react-redux";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { AnyObject } from "../../../../shared/types";
import { ChartRowResponse } from "../../../api/biApi.types";
import { selectCurrentReport } from "../../../store/currentReportSlice";
import ValidationState from "../common/ValidationState";
import Chart from "./Chart";
import { ExportChartFormats } from "./ChartProps";
import { useFieldsStateContext } from "./contexts/FieldsStateContext";
import { PreviewChartStateType } from "./hooks/useChartStateBuilder";
import BiErrorCodeComponent from "../common/bi-error/BiErrorCodeComponent";
import PreviewComponentAlert from "../../common/PreviewComponentAlert";

interface Props {
  state: PreviewChartStateType;
  exportFuncCallback?: (callback: ((fileName: string, format: ExportChartFormats) => void) | undefined) => void;
}
export default function PreviewComponent({ state, exportFuncCallback }: Props) {
  const { argumentsArea, valuesArea } = useFieldsStateContext();
  const report = useSelector(selectCurrentReport);

  const dataSource = React.useMemo(() => {
    return new DataSource({
      paginate: false,
      store: state.data,
      map: (row: ChartRowResponse) => {
        const result: AnyObject = {};
        Object.keys(row).forEach((key) => {
          const cell = row[key];
          if (cell?.value !== undefined) {
            result[key] = cell?.value;
          } else {
            result[key] = "No Value";
          }
        });

        return result;
      },
    });
  }, [state.data]);

  return (
    <BiErrorCodeComponent errorMessage={state.error}>
      <PreviewComponentAlert error={state.error} failedMeasures={state.failedMeasures} />
      {!state.error &&
        !state.calculating &&
        state.fieldsConfigured &&
        state.configurationValidity.valid &&
        report?.reportType !== undefined && (
          <Chart
            type={report.reportType}
            data={state.data || []}
            dataSource={dataSource}
            args={argumentsArea.values}
            values={valuesArea.values}
            exportFunc={(callback) => exportFuncCallback?.(callback)}
          />
        )}
      {state.calculating && <InlineLoader />}
      <ValidationState state={state.configurationValidity} measures={valuesArea.values} />
    </BiErrorCodeComponent>
  );
}
