import {
  CalculateByField,
  ConditionConfiguration,
  MeasureUnitTable,
  TabularFieldType,
  UserMeasureConfigurations,
} from "../../../../shared/reporting/api/biClient.types";
import { checkUnitTable } from "../../../../shared/reporting/utils/checkUnitTable";
import { generateGuid } from "../../../../shared/utilities/generateGuid";
import { MeasureDescriptor } from "../../../api/biApi.types";
import { toAmountType, toCalculateBy } from "../common/customMeasure/utilities/measureConverter";
import { TableField } from "../tabular/hooks/TableField";
import { AreaItemType, ValueField } from "../Types";

export class MeasureUtils {
  static createValueField(field: MeasureDescriptor, areaItemType: AreaItemType): ValueField {
    const valueField: ValueField = {
      meta: field,
      areaItemType,
      config: <UserMeasureConfigurations>{
        ...field.configuration,
        guid: generateGuid(),
        name: field.name,
        customConditions: field.customConditions?.map(
          (cc): ConditionConfiguration => ({ filter: cc, guid: generateGuid() })
        ),
        useCustomConditions: (field.customConditions?.length || 0) > 0,
        calculateByField: toCalculateBy(field.units),
        amountType: toAmountType(field.units),
      },
    };

    return valueField;
  }
}

export const getCustomConditionsChanges = (useCustomConditions: boolean) => {
  const changes: Partial<UserMeasureConfigurations> = {
    useCustomConditions,
    customConditions: undefined,
    linkedConditions: undefined,
  };
  return changes;
};

export function updateValues<
  T extends {
    updateMeasure: (field: ValueField, changes: Partial<ValueField>) => void;
    updateMeasureConfig: (field: ValueField, changes: Partial<UserMeasureConfigurations>) => void;
    removeMeasure: (field: ValueField) => void;
    values: ValueField[];
  },
>(fieldsState: T, measures: MeasureDescriptor[]) {
  if (fieldsState.values.length === 0) return;
  fieldsState.values.forEach((field) => {
    const measure = measures.find((m) => m.name === field.meta.name && m.custom);
    if (measure !== undefined) {
      const calculatedBy = toCalculateBy(measure.units);
      if (field.config.calculateByField === undefined) {
        const unitsChanged = calculatedBy !== field.config.calculateByField;
        if (unitsChanged) {
          fieldsState.updateMeasureConfig(field, {
            calculateByField: calculatedBy,
          });
        }
      }
      fieldsState.updateMeasure(field, { meta: measure });
    } else {
      const measureWasRemoved = !measures.find((m) => m.name === field.meta.name);
      if (measureWasRemoved) {
        fieldsState.removeMeasure(field);
      }
    }
  });
}

export function updateMeasureFields<
  T extends {
    updateMeasureField: (field: TableField, changes: Partial<ValueField>) => void;
    updateMeasureFieldConfig: (field: TableField, changes: Partial<UserMeasureConfigurations>) => void;
    removeField: (field: TableField) => void;
    fields: TableField[];
  },
>(fieldsState: T, measures: MeasureDescriptor[]) {
  if (fieldsState.fields.length === 0) return;
  fieldsState.fields.forEach((field) => {
    if (field.fieldType === TabularFieldType.Measure && field.measure !== undefined) {
      const measure = measures.find((m) => m.name === field.measure?.meta.name);
      if (measure !== undefined) {
        const calculatedBy = toCalculateBy(measure.units);
        if (field.measure.config.calculateByField === undefined) {
          const unitsChanged = calculatedBy !== field.measure.config.calculateByField;
          if (unitsChanged) {
            fieldsState.updateMeasureFieldConfig(field, { calculateByField: calculatedBy });
          }
        }
        fieldsState.updateMeasureField(field, { meta: measure });
      } else {
        const measureWasRemoved = !measures.find((m) => m.name === field.measure?.meta.name);
        if (measureWasRemoved) {
          fieldsState.removeField(field);
        }
      }
    }
  });
}

export function isGlTableMeasure(meta: Pick<MeasureDescriptor, "units">) {
  return !Object.values(meta.units).some((unit) => checkUnitTable(unit.table, MeasureUnitTable.Memo));
}

export function isSharesRelatedMeasure(measure: ValueField) {
  return measure.config.calculateByField === CalculateByField.Shares;
}
