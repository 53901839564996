import { Grouping, PivotFieldConfiguration } from "../../../../../../shared/reporting/api/biClient.types";
import { RowField } from "../../../Types";
import GeneralFieldOptionPopup from "../columns/GeneralFieldOptionPopup";

interface Props {
  anchorEl: HTMLElement;
  field: RowField;
  saveChanges: (changes: Partial<PivotFieldConfiguration>, causeClosing: boolean) => void;
  cancel: () => void;
  onRemove: () => void;
  onUpdateCustomLabel: (name: string | undefined) => void;
}

const RowFieldOptionPopup = (props: Props) => {
  return (
    <GeneralFieldOptionPopup
      {...props}
      supportedDateGroupings={[Grouping.ByYear, Grouping.ByQuarter, Grouping.ByMonth, undefined]}
    />
  );
};

export default RowFieldOptionPopup;
