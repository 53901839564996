import { CustomMeasureReturnType } from "../../customMeasure/hooks/useCustomMeasure";
import { FormulaNode } from "../../customMeasure/utilities/fillNodes";
import { useChartOfAccountsContext } from "../contexts/ChartOfAccountsContext";
import FunctionPropertyItem from "./FunctionPropertyItem";

interface Props {
  formulaNode: FormulaNode;
  state: CustomMeasureReturnType;
  index: number;
}

export default function FunctionPropertyItemWrapper({ formulaNode, state, index }: Props) {
  const { root } = useChartOfAccountsContext();
  return <FunctionPropertyItem formulaNode={formulaNode} state={state} parentNode={root} index={index} />;
}
