import { Typography } from "@mui/material";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import GroupSummaryPlace from "./GroupSummaryPlace";
import StyleItem from "./StyleItem";
import { useLocalization } from "../../../../hooks/useLocalization";
import SwitchField from "../../../common/SwitchField";

export default function GeneralSettings() {
  const { settingsArea } = useFieldsStateContext();
  const { tabular: locale } = useLocalization();

  return (
    <StyleItem sx={{ p: 1 }}>
      <Typography variant="subtitle2" fontWeight={500} color={(theme) => theme.palette.text.primary} pb={1}>
        General
      </Typography>
      <SwitchField
        label="Hide Blank Rows"
        checked={settingsArea.settings.hideBlankRows === true}
        onChange={(hideBlankRows) => settingsArea.update({ hideBlankRows })}
        tooltipText={locale.hide_empty_rows_explanation}
        typographyProps={{ color: "text.secondary" }}
      />
      <SwitchField
        label="Show Grand Total"
        checked={settingsArea.settings.showGrandTotal === true}
        onChange={(showGrandTotal) => settingsArea.update({ showGrandTotal })}
        typographyProps={{ color: "text.secondary" }}
      />
      <GroupSummaryPlace
        value={settingsArea.settings.groupSummaryPlace}
        onChange={(groupSummaryPlace) => settingsArea.update({ groupSummaryPlace })}
      />
    </StyleItem>
  );
}
