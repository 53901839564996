import { Typography } from "@mui/material";
import StyleItem from "./StyleItem";
import ExpandeGroup from "./ExpandeGroup";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import React from "react";
import SwitchField from "../../../common/SwitchField";

export default function GroupSettings() {
  const { fieldsArea, groupingArea } = useFieldsStateContext();

  const grouping = React.useMemo(() => groupingArea.values, [groupingArea.values]);
  const fields = React.useMemo(() => fieldsArea.values, [fieldsArea.values]);

  return (
    <>
      {grouping.length > 0 && (
        <ExpandeGroup title="Grouped Columns">
          {grouping.map((group, index) => (
            <StyleItem key={group.name} showBorder={grouping.length > 1 && index < grouping.length - 1}>
              <Typography variant="subtitle2" fontWeight={500} color={(theme) => theme.palette.text.primary} pb={1}>
                {fields.find((f) => f.guid === group.name)?.dimension?.meta.caption}
              </Typography>
              <SwitchField
                label="Show Subtotals"
                checked={!group.hideSummaries}
                onChange={(hideSummaries) => groupingArea.updateGroup(group, { hideSummaries: !hideSummaries })}
                typographyProps={{ color: "text.secondary" }}
              />
            </StyleItem>
          ))}
        </ExpandeGroup>
      )}
    </>
  );
}
