import { FundType } from "../../../api/biClient.types";
import {
  combineValidators,
  requiredValidator,
  minCharactersValidator,
  validResult,
  invalidResult,
  maxCharactersValidator,
} from "../../../../utilities/validators";

export const MainInfoValidator = combineValidators(requiredValidator, minCharactersValidator(2));
export const FundTypeValidator = (value: FundType[]) =>
  value.length > 0 ? validResult() : invalidResult("Value cannot be empty");
export const TemplateDescriptionValidator = maxCharactersValidator(1000);
