import { ReactNode } from "react";
import { AnyObject } from "../../../../../../../shared/types";
import { PublicTabularResponse, RunTabularReportRequest } from "../../../../../../api/biApi.types";

const headersIdentValue = 3;
const payloadIdentValue = 7;

const headers = {
  accept: "application/json",
  "Content-Type": "application/json",
  "Ocp-Apim-Subscription-Key": "<your_subscription_key>",
};

export const codeItemStyles = {
  string: { color: "#C11574" },
  number: { color: "#e45649" },
  boolean: { color: "#4078f2" },
  null: { color: "#4078f2" },
  key: { color: "#50a14f" },
  command: { color: "#d500ff" },
  option: { color: "#383a42" },
  value: { color: "#ce9178" },
  bracket: { color: "#383a42" },
  undefined: { color: "#383a42" },
};

export const codeItemsRgx =
  /("(\\u[a-zA-Z0-9]{4}|\\[^u]|\\\\|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?|[{}[\]]|curl|--?\w+|\s+|,|\\|[^\s{}[\],]+)/g;

export const getHighlightedSyntax = (json: string, spanStyles: Record<string, { color: string }>) => {
  const result: ReactNode[][] = [];
  const rows = json.split("\n");

  result.push([
    [
      <span key={"beginning"} className="row__beginning">
        &nbsp;
      </span>,
    ],
  ]);

  let rowNumber = 1;
  rows.forEach((line) => {
    if (line) {
      const spans: ReactNode[] = [];
      spans.push(
        <span key={rowNumber} className="row__number">
          {rowNumber}
        </span>
      );
      rowNumber++;

      parseLine(line).forEach((match) => {
        const spanStyle = spanStyles[classifyMatch(match) as keyof typeof spanStyles];
        spans.push(<span style={{ color: spanStyle?.color }}>{match}</span>);
      });

      result.push(spans);
    }
  });

  result.push([
    [
      <span key={"end"} className="row__end">
        &nbsp;
      </span>,
    ],
  ]);
  return result;
};

const parseLine = (line: string): string[] => {
  const result: string[] = [];
  line.replace(codeItemsRgx, (match) => {
    result.push(match);
    return "";
  });

  return result;
};

export const calculateNumberColumnWidth = (number: number, fontSize = 13) => {
  const numberString = number.toString();
  const digitWidth = fontSize * 0.6;
  const totalWidth = numberString.length * digitWidth;

  return Math.max(60, totalWidth + 20);
};

export const classifyMatch = (match: string) => {
  switch (true) {
    case /^"/.test(match):
      return /:$/.test(match) ? "key" : "string";
    case /true|false/.test(match):
      return "boolean";
    case /null/.test(match):
      return "null";
    case /curl/.test(match):
      return "command";
    case /^--?[a-zA-Z]+\w*$/.test(match):
      return "option";
    case /[{}[\]]/.test(match):
      return "bracket";
    case /^-?\d+(\.\d+)?$/.test(match):
      return "number";
    default:
      return undefined;
  }
};

export const createCurlCommand = (payload: AnyObject, url: string) => {
  const headersIdent = getIdent(headersIdentValue);
  const payloadIdent = getIdent(payloadIdentValue);

  const headerStrings = Object.entries(headers)
    .map(([key, value]) => `-H "${key}: ${value}"`)
    .join(` \\\n${headersIdent}`);

  const payloadString = createJson(payload)
    .split("\n")
    .map((line, index) => (index === 0 ? line : `${payloadIdent}${line}`))
    .join("\n");

  return `
curl -X POST "${url}" \\
${headersIdent}${headerStrings} \\
${headersIdent}-d '${payloadString}'
`;
};

export const createJson = (requestConfig: AnyObject) => {
  return JSON.stringify(requestConfig, null, 2);
};

export const createRequestConfig = (requestConfig: RunTabularReportRequest | undefined) => {
  if (!requestConfig) {
    return {};
  }
  return {
    reportId: requestConfig.reportId || "<report_id>",
    parameters: requestConfig.conditions.map((c) => ({ id: c.guid, values: c.filter.values })),
  };
};

export const createResponseConfig = (response: PublicTabularResponse | undefined) => {
  return {
    error: {
      message: "string",
      code: 0,
      type: "General",
    },
    success: true,
    data: {
      ...response,
    },
  };
};

const getIdent = (level: number) => " ".repeat(level);
