import { SvgIcon, SvgIconProps } from "@mui/material";

export const ReportingBackIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 32, height: 32 }} viewBox="0 0 32 32" {...props}>
      <path
        d="M2.34315 29.6569C4.68629 32 8.45753 32 16 32C23.5425 32 27.3137 32 29.6569 29.6569C32 27.3137 32 23.5425 32 16C32 8.45753 32 4.68629 29.6569 2.34315C27.3137 0 23.5425 0 16 0C8.45753 0 4.68629 0 2.34315 2.34315C0 4.68629 0 8.45753 0 16C0 23.5425 0 27.3137 2.34315 29.6569Z"
        fill="#F6F8FA"
      />
      <path d="M28.8 16.5488H3.37781V18.1497H28.8V16.5488Z" fill="#C7DBE5" />
      <path
        d="M27.103 19.3023H25.5021C25.4701 16.2286 24.9258 11.1698 23.5811 9.82502C23.3249 9.56888 23.1328 9.53686 23.0047 9.56888C21.3718 9.56888 20.6995 12.9948 20.0911 16.0045C19.2907 19.9747 18.4902 24.073 15.3204 24.073C12.2467 24.073 11.0621 20.3269 10.0055 17.0611C9.20502 14.5637 8.37256 12.0022 7.05982 12.0022C6.45148 12.0022 6.00323 13.411 5.84314 14.6277H4.24225C4.37032 13.379 4.94664 10.4013 7.05982 10.4013C9.55722 10.4013 10.5498 13.5391 11.5103 16.5808C12.4388 19.4944 13.3994 22.4721 15.2884 22.5041C17.1455 22.5041 17.8178 19.0462 18.4902 15.7163C19.2586 11.9382 20.0271 8 22.9727 8C23.5811 8 24.1574 8.25614 24.6697 8.73641C26.8789 10.8176 27.103 17.5734 27.103 19.3023Z"
        fill="#00A866"
      />
    </SvgIcon>
  );
};
export default ReportingBackIcon;
