import { Typography } from "@mui/material";
import React from "react";
import { TabularFieldType } from "../../../../../shared/reporting/api/biClient.types";
import { ItemDataType, MeasureUsage } from "../../../../api/biApi.types";
import { getMeasureCaption } from "../../pivot/table/measures/helper";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import { TableField } from "../hooks/TableField";
import ExpandeGroup from "./ExpandeGroup";
import StyleItem from "./StyleItem";
import SwitchField from "../../../common/SwitchField";

export default function MeasureSettings() {
  const { fieldsArea } = useFieldsStateContext();

  const numericMeasures = React.useMemo(() => {
    return fieldsArea.values.filter(
      (f): f is TableField =>
        f.fieldType === TabularFieldType.Measure &&
        f.measure?.meta.type === ItemDataType.Numeric &&
        f.measure?.meta.usage !== MeasureUsage.TabularOnly
    );
  }, [fieldsArea.values]);

  if (numericMeasures.length === 0) {
    return <></>;
  }

  return (
    <ExpandeGroup title="Measure Columns">
      {numericMeasures.map((field, index) => (
        <StyleItem key={field.guid} showBorder={numericMeasures.length > 1 && index < numericMeasures.length - 1}>
          <Typography variant="subtitle2" fontWeight={500} color={(theme) => theme.palette.text.primary} pb={1}>
            {getMeasureCaption(field.measure)}
          </Typography>
          <SwitchField
            label="Calculate Subtotals"
            checked={!field.measure?.config.hideAggregation}
            onChange={(hideAggregation) =>
              fieldsArea.updateMeasureFieldConfig(field, { hideAggregation: !hideAggregation })
            }
            typographyProps={{ color: "text.secondary" }}
          />
        </StyleItem>
      ))}
    </ExpandeGroup>
  );
}
