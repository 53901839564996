import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import { DEFAULT_AREA_FIELD_ITEM_HEIGHT } from "./AreaFieldsHelper";

interface Props {
  onDragEnter: () => void;
}
export const AddField = ({ onDragEnter }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        height: `${DEFAULT_AREA_FIELD_ITEM_HEIGHT}px`,
        border: "1px dashed #E5E6E9",
        borderRadius: "2px",
        boxSizing: "border-box",
        color: theme.palette.text.disabled,
        alignItems: "center",
        pl: 0.5,
      })}
      onDragEnter={onDragEnter}
    >
      <AddIcon />
      <Typography variant="caption">Add Field...</Typography>
    </Box>
  );
};

export default AddField;
