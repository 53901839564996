import { useCallback, useState } from "react";
import { HandledApiResponse, withErrorHandling } from "../api/axiosHelper";
import { ApiError, ApiResponse } from "../api/types";
import { AsyncFunc } from "../types";

interface Result<TData> {
  request: () => Promise<HandledApiResponse<TData>>;
  error: ApiError | undefined;
  isLoading: boolean;
}

const useRequest = <TData>(apiCall: AsyncFunc<ApiResponse<TData>>): Result<TData> => {
  const [error, setError] = useState<ApiError>();
  const [isLoading, setIsLoading] = useState(false);

  const request: () => Promise<HandledApiResponse<TData>> = useCallback(async () => {
    setIsLoading(true);
    setError(undefined);

    const [responseData, responseError] = await withErrorHandling(apiCall)();

    if (responseError) {
      setError(responseError.isCanceledRequest ? undefined : responseError);
      setIsLoading(false);
      return [undefined, responseError];
    }

    setIsLoading(false);
    return [responseData, undefined];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCall]);

  return { request, error, isLoading };
};

export default useRequest;
