import { Report } from "../../../../shared/reporting/api/biClient.types";
import ChartContainer from "./ChartContainer";
import ChartViewer from "./ChartViewer";

interface Props {
  report: Report;
}

export default function ChartViewerContainer({ report }: Props) {
  return (
    <ChartContainer report={report}>
      <ChartViewer></ChartViewer>
    </ChartContainer>
  );
}
