import { Alert, Typography } from "@mui/material";
import { FailedMeasure } from "../../api/biApi.types";

interface Props {
  error: string | undefined;
  failedMeasures: FailedMeasure[];
  failedGroupTotalMeasures?: FailedMeasure[];
}

const alertSx = { mb: ".5rem", alignItems: "center" };

const PreviewComponentAlert = ({ error, failedMeasures, failedGroupTotalMeasures }: Props) => {
  if (error) {
    return (
      <Alert sx={alertSx} severity="error">
        {error}
      </Alert>
    );
  }
  if (failedMeasures.length > 0) {
    return (
      <MeasuresCalculationFailedAlert
        failedMeasures={failedMeasures}
        title={"We encountered a problem while calculating the following measure(s):"}
      />
    );
  }
  if (failedGroupTotalMeasures && failedGroupTotalMeasures.length > 0) {
    return (
      <MeasuresCalculationFailedAlert
        failedMeasures={failedGroupTotalMeasures}
        title={"We encountered a problem while calculating group totals for the following measure(s):"}
      />
    );
  }
  return null;
};

const MeasuresCalculationFailedAlert = ({
  failedMeasures,
  title,
}: {
  failedMeasures: FailedMeasure[];
  title: string;
}) => {
  return (
    <Alert sx={alertSx} severity="warning">
      <Typography>
        {title}
        <Typography
          variant="caption"
          sx={{ ml: 0.5, fontWeight: 500 }}
        >{`${failedMeasures.map((m) => m.name).join(", ")}`}</Typography>
      </Typography>
      <Typography>
        Please attempt to refresh the grid. Reach out to the support team for further assistance if the issue persists.
      </Typography>
    </Alert>
  );
};

export default PreviewComponentAlert;
