import { Chip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { blueGrey } from "@mui/material/colors";

const DraftStatus = () => {
  return (
    <Chip
      icon={<CircleIcon />}
      label="Draft"
      sx={{
        alignItems: "center",
        gap: "6px",
        padding: "1px 8px 1px 6px",
        fontWeight: 500,
        bgcolor: blueGrey[50],
        color: "secondary.main",
        "& .MuiChip-icon": { color: blueGrey[400], fontSize: "0.5rem", mx: 0 },
        "& .MuiChip-label": { px: 0, alignSelf: "center" },
      }}
    />
  );
};

export default DraftStatus;
