import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { BasicLineSeriesSettings, SeriesSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import { isLineBasedSerie } from "../../contexts/FieldsStateContext.types";

interface Props {
  area: SettingsArea;
  serie: SeriesSettings;
}

export default function ShowPointsItem({ area, serie }: Props) {
  const supported = React.useMemo(() => isLineBasedSerie(area.settings, serie), [area.settings, serie]);
  const showPoints = React.useMemo(() => getShowPoints(serie), [serie]);

  if (!supported) {
    return <></>;
  }

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography color={(theme) => theme.palette.text.secondary}>Show Points</Typography>
      <HorizontalFill />
      <Switch
        checked={showPoints}
        onChange={(_, value) => area.updateSerie<BasicLineSeriesSettings>(serie.name, { showPoints: value })}
      />
    </Stack>
  );
}

export function getShowPoints(serie: SeriesSettings | undefined) {
  const linearSerie = serie as BasicLineSeriesSettings;
  return linearSerie?.showPoints === true;
}
