import { BaseTextFieldProps } from "@mui/material";
import { DateValidationError } from "@mui/x-date-pickers-pro";
import { getYear, isAfter, isBefore, isMatch, isValid, parse } from "date-fns";

export const ISO_FORMAT = "yyyy-MM-dd";
export const AMERICAN_FORMAT = "M/d/yyyy";

export const handleIsoFormat = (dateStr: string) => {
  const date = parse(dateStr, ISO_FORMAT, new Date());
  const valid = isValid(date) && isDateLengthValid(dateStr) && isYearValid(date);
  if (valid) {
    return date;
  }
  return undefined;
};

export const handleAmericanFormat = (dateStr: string) => {
  let input = dateStr;
  const parts = input.split("/");
  const year = parts[parts.length - 1];
  if (year && parts.length === 3 && isShortYear(year)) {
    input = `${parts[0]}/${parts[1]}/${formatToFullYear(year)}`;
  }
  const date = parse(input, AMERICAN_FORMAT, new Date());
  const valid = isValid(date);
  if (valid) {
    return date;
  }
  return undefined;
};

const isShortYear = (year: string) => {
  return year.length < 3;
};

const formatToFullYear = (year: string) => {
  const yearNum = parseInt(year);
  if (yearNum < 50) {
    return 2000 + yearNum;
  } else {
    return 1900 + yearNum;
  }
};

export const isYearValid = (date: Date) => {
  const year = getYear(date);
  return year >= 1900 && year < 2100;
};

export const isDateLengthValid = (dateStr: string) => {
  return dateStr.length === 10;
};

interface HasTextField {
  textField?: object;
}

export const hasTextFieldAndRequired = (slotProps: unknown): slotProps is { textField: BaseTextFieldProps } => {
  return (
    slotProps !== undefined &&
    (slotProps as HasTextField)?.textField !== undefined &&
    ((slotProps as HasTextField).textField as BaseTextFieldProps)?.required !== undefined
  );
};

export const parseDate = (value: string) => {
  if (isMatch(value, ISO_FORMAT)) {
    return handleIsoFormat(value);
  }
  return handleAmericanFormat(value);
};

export const validateDateRange = (value: Date, minDate?: Date, maxDate?: Date): DateValidationError | null => {
  if (minDate && isBefore(value, minDate)) {
    return "minDate";
  }
  if (maxDate && isAfter(value, maxDate)) {
    return "maxDate";
  }
  return null;
};
