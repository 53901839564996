import equal from "fast-deep-equal";
import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";
import { ApiResponse } from "../../../../api/types";
import { logError } from "../../../../logging";
import cloneDeep from "../../../../utilities/cloneDeep";
import { defined } from "../../../../utilities/typeHelper";
import {
  ReportAccessType,
  ReportAuthorization,
  ReportAuthorizationInfo,
  ReportInfo,
  UpdateReportAuthorizationRequest,
  UserBasedAuthorization,
  UserForSharing,
} from "../../../api/biClient.types";
import RemoveOwnAccessDialog from "../RemoveOwnAccessDialog";
import { CurrentUser, ReportCompany } from "../ShareReport.types";
import { checkAccess } from "../utils";

export interface SharingReportContextType {
  report: ReportInfo;
  currentUser: CurrentUser;
  owner: UserBasedAuthorization | undefined;
  company: ReportCompany;
  sharedForCompanyAccess: ReportAccessType;
  isAuthorizationChanged: boolean;
  isSaving: boolean;
  setSharedForCompanyAccess: (access: ReportAccessType) => void;
  authorizedUsers: UserBasedAuthorization[];
  setAuthorizedUsers: (users: UserBasedAuthorization[]) => void;
  onUserSelected: (users: UserForSharing[], access: ReportAccessType) => void;
  onUserAccessChanged: (userId: string, access: ReportAccessType) => void;
  onRemoveUser: (userId: string) => void;
  onSave: () => void;
}

const SharingReportContext = React.createContext<SharingReportContextType | undefined>(undefined);

interface Props {
  report: ReportInfo;
  company: ReportCompany;
  currentUser: CurrentUser;
  owner: UserBasedAuthorization | undefined;
  defaultAuthorization: ReportAuthorization;
  onAuthorizationUpdated: (authorization: ReportAuthorizationInfo) => void;
  onClose: () => void;
  updateReportSharing: (
    reportId: string,
    request: UpdateReportAuthorizationRequest
  ) => Promise<ApiResponse<ReportAuthorizationInfo>>;
}
export const SharingReportContextProvider = ({
  report,
  company,
  currentUser,
  owner,
  defaultAuthorization,
  onClose,
  onAuthorizationUpdated,
  children,
  updateReportSharing,
}: PropsWithChildren<Props>) => {
  const authorization = useMemo(() => cloneDeep(defaultAuthorization), [defaultAuthorization]);
  const [isSaving, setIsSaving] = useState(false);

  const [showRemoveAccessDialog, setShowRemoveAccessDialog] = useState({
    maximumAccess: ReportAccessType.NoAccess,
    show: false,
  });
  const [sharedForCompanyAccess, setSharedForCompanyAccess] = useState<ReportAccessType>(
    defaultAuthorization.companyShared.access
  );

  const [authorizedUsers, setAuthorizedUsers] = useState<UserBasedAuthorization[]>(() => {
    return cloneDeep(defaultAuthorization.users);
  });

  const reportAuthorization = useMemo(() => {
    return buildAuthorizationModel(authorizedUsers, sharedForCompanyAccess);
  }, [authorizedUsers, sharedForCompanyAccess]);

  const isAuthorizationChanged = useMemo(() => {
    return !equal(reportAuthorization, defaultAuthorization);
  }, [defaultAuthorization, reportAuthorization]);

  const handleOnUsersSelected = useCallback(
    (users: UserForSharing[], access: ReportAccessType) => {
      const newUsers = users.map((u): UserBasedAuthorization => ({ userId: u.userId, access }));
      setAuthorizedUsers([...authorizedUsers, ...newUsers]);
    },
    [authorizedUsers, setAuthorizedUsers]
  );

  const handleSharedForCompanyAccessChanged = useCallback((access: ReportAccessType) => {
    setSharedForCompanyAccess(access);
  }, []);

  const handleUserAccessChanged = useCallback((userId: string, access: ReportAccessType) => {
    setAuthorizedUsers((prev) => {
      if (access === ReportAccessType.Owner) {
        const currentOwner = prev.find((u) => u.access === ReportAccessType.Owner);
        if (currentOwner) {
          return prev.map((u) => {
            if (u.userId === currentOwner.userId) {
              return {
                ...u,
                access: ReportAccessType.Read,
              };
            }
            if (u.userId === userId) {
              return { ...u, access };
            }
            return u;
          });
        }
      }
      return prev.map((u) => {
        if (u.userId === userId) {
          return { ...u, access };
        }
        return u;
      });
    });
  }, []);

  const handleRemoveUser = useCallback(
    (userId: string) => {
      setAuthorizedUsers(authorizedUsers.filter((u) => u.userId !== userId));
    },
    [authorizedUsers, setAuthorizedUsers]
  );

  const updateAuth = useCallback(async () => {
    setIsSaving(true);
    return updateReportSharing(report.reportId, {
      clientCode: company.clientCode,
      authorization: reportAuthorization,
    })
      .then((response) => {
        if (response.success && response.data) {
          onAuthorizationUpdated(response.data);
        }
      })
      .catch(() => {
        logError("Failed to update Report authorization", "[UpdateReportAuthorization]");
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [company.clientCode, onAuthorizationUpdated, report.reportId, reportAuthorization, updateReportSharing]);

  const getMaximumCurrentUserAccess = useCallback(() => {
    if (report.authorization.access === ReportAccessType.Supervisor) {
      return ReportAccessType.Supervisor;
    }

    const currentUserAccess = authorizedUsers.find((u) => u.userId === currentUser.id);
    if (currentUserAccess) {
      return currentUserAccess.access > sharedForCompanyAccess ? currentUserAccess.access : sharedForCompanyAccess;
    }

    return sharedForCompanyAccess;
  }, [authorizedUsers, currentUser.id, report.authorization.access, sharedForCompanyAccess]);

  const handleSave = useCallback(async () => {
    if (reportAuthorization) {
      if (!equal(reportAuthorization, authorization)) {
        const maximumAccess = getMaximumCurrentUserAccess();
        if (
          report.authorization.access !== ReportAccessType.Supervisor &&
          !checkAccess(maximumAccess, ReportAccessType.FullAccess)
        ) {
          setShowRemoveAccessDialog({
            maximumAccess,
            show: true,
          });
        } else {
          await updateAuth();
          onClose();
        }
      }
    }
  }, [
    authorization,
    getMaximumCurrentUserAccess,
    onClose,
    report.authorization.access,
    reportAuthorization,
    updateAuth,
  ]);

  const handleChangeAccessConfirmed = useCallback(async () => {
    try {
      await updateAuth();
      setShowRemoveAccessDialog({ ...showRemoveAccessDialog, show: false });
      onClose();
    } catch (e) {
      logError("Failed to update Report authorization", "[RemoveUserAccessConfirmed]");
      onClose();
    }
  }, [onClose, showRemoveAccessDialog, updateAuth]);

  const onCancelRemoveAccessConfirmation = useCallback(() => {
    setShowRemoveAccessDialog({
      maximumAccess: ReportAccessType.NoAccess,
      show: false,
    });
  }, []);

  return (
    <>
      <SharingReportContext.Provider
        value={{
          report,
          currentUser,
          owner,
          company,
          sharedForCompanyAccess,
          authorizedUsers,
          isAuthorizationChanged,
          isSaving,
          setSharedForCompanyAccess: handleSharedForCompanyAccessChanged,
          setAuthorizedUsers,
          onUserSelected: handleOnUsersSelected,
          onUserAccessChanged: handleUserAccessChanged,
          onRemoveUser: handleRemoveUser,
          onSave: handleSave,
        }}
      >
        {children}
      </SharingReportContext.Provider>
      {showRemoveAccessDialog.show && (
        <RemoveOwnAccessDialog
          report={report}
          access={showRemoveAccessDialog.maximumAccess}
          onCancel={onCancelRemoveAccessConfirmation}
          onRemove={handleChangeAccessConfirmed}
        />
      )}
    </>
  );
};

export const useSharingReportContext = () => {
  const context = useContext(SharingReportContext);
  return defined(context);
};

function buildAuthorizationModel(authorizedUsers: UserBasedAuthorization[], sharedForCompanyAccess: ReportAccessType) {
  const auth: ReportAuthorization = {
    users: authorizedUsers,
    companyShared: {
      access: sharedForCompanyAccess,
    },
  };
  return auth;
}
