import SaveReportDialog from "../../builder/common/saving/SaveReportDialog";
import { useVisualizationContext } from "./context/VisualizationContext";

const SaveReportChangesDialog = () => {
  const { showSaveDialog, report, onCloseSaveChanges, reportConfiguration } = useVisualizationContext();

  if (showSaveDialog) {
    return (
      <SaveReportDialog
        report={report}
        open={true}
        title="Save Report"
        saveBtnTitle="Save"
        reportConfiguration={reportConfiguration}
        onClose={onCloseSaveChanges}
      />
    );
  }

  return null;
};

export default SaveReportChangesDialog;
