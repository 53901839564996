import { Popover, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface ItemsListPopoverProps {
  anchorEl: HTMLElement | undefined;
  onClose: () => void;
  title?: string | undefined;
}

const ItemsListPopover = ({ anchorEl, onClose, title, children }: PropsWithChildren<ItemsListPopoverProps>) => {
  return (
    <Popover
      sx={{ mt: 0.5, "& .MuiPaper-root": { minWidth: "max-content" } }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Stack spacing={0.5} sx={{ p: 1 }}>
        {title && (
          <Typography color="textSecondary" variant="subtitle2">
            {title}
          </Typography>
        )}
        {children}
      </Stack>
    </Popover>
  );
};

export default ItemsListPopover;
