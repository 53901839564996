import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { JSXElementConstructor, ReactElement } from "react";
import { appInsightsReactPlugin } from "../logging";
import Error from "./Error";

interface Props {
  children: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
}

const ErrorBoundary = ({ children }: Props) => (
  <AppInsightsErrorBoundary
    onError={() => <Error title="Unexpected error occurred" />}
    appInsights={appInsightsReactPlugin}
  >
    {children}
  </AppInsightsErrorBoundary>
);

export default ErrorBoundary;
