import { Stack } from "@mui/material";
import ChangePreviewModeButton from "./ChangePreviewModeButton";
import RefreshGridButton from "./RefreshGridButton";

export default function PreviewActions() {
  return (
    <Stack flexDirection="row" gap={1}>
      <RefreshGridButton />
      <ChangePreviewModeButton />
    </Stack>
  );
}
