import { ApiResponse } from "../../../../../../shared/api/types";
import { FailedMeasure, PivotDataResponse } from "../../../../../api/biApi.types";
import { useLocalization } from "../../../../../hooks/useLocalization";
import React from "react";
import { useRequestConfigViewer } from "./useRequestConfigViewer";
import { useDispatch } from "react-redux";
import { update } from "../../../../../store/devToolsSlice";
import biClient from "../../../../../api/biApi";
import { currentReportActions } from "../../../../../store/currentReportSlice";

type CancellablePivotRequest = {
  cancel: () => void;
};

export default function useDataLoadingViewer() {
  const dispatch = useDispatch();
  const [cancellationToken, setCancellationToken] = React.useState<CancellablePivotRequest | undefined>(undefined);
  const { common: locale } = useLocalization();
  const { requestConfig } = useRequestConfigViewer();

  const [calculateCount, setCalculateCount] = React.useState(0);
  const [pivot, setPivot] = React.useState<PivotDataResponse>();
  const [error, setError] = React.useState<string>();
  const [failedMeasures, setFailedMeasures] = React.useState<FailedMeasure[]>([]);

  const calculating = calculateCount > 0;

  const calculatingRef = React.useRef<number>(calculateCount);
  calculatingRef.current = calculateCount;

  const cancellationTokenRef = React.useRef<CancellablePivotRequest | undefined>(cancellationToken);
  cancellationTokenRef.current = cancellationToken;

  const buildConfigRef = React.useRef(requestConfig);

  React.useEffect(() => {
    buildConfigRef.current = requestConfig;
  }, [requestConfig]);
  React.useEffect(() => {
    return () => {
      if (cancellationTokenRef.current !== undefined) {
        cancellationTokenRef.current.cancel();
      }
    };
  }, []);

  const onCalculated = React.useCallback((response: ApiResponse<PivotDataResponse>) => {
    if (response.success) {
      setError(undefined);
      setPivot(response.data);
      dispatch(update({ loggingItems: response.data.loggingItems }));
      dispatch(currentReportActions.setEtag({ etag: response.data.etag, cacheValid: undefined }));
      setFailedMeasures(response.data.failedMeasures);
    } else {
      setError(response.error?.message);
    }
    setCalculateCount(calculatingRef.current - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCalculationError = React.useCallback(
    (reason: string | undefined) => {
      if (reason === "canceled") {
        setError(undefined);
      } else {
        setError(reason || locale.calculation_error);
      }
      setCalculateCount(calculatingRef.current - 1);
    },
    [locale.calculation_error]
  );

  const calculate = React.useCallback(
    () => {
      setCalculateCount(calculatingRef.current + 1);
      if (cancellationTokenRef.current !== undefined) {
        cancellationTokenRef.current.cancel();
      }
      dispatch(currentReportActions.setEtag({ etag: undefined, cacheValid: undefined }));
      const cancellation = biClient.runPivotReport(buildConfigRef.current, onCalculated, onCalculationError);
      setCancellationToken(cancellation);
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [onCalculated]
  );

  return { pivot, calculating, error, calculate, failedMeasures };
}
