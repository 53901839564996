import { List } from "@mui/material";
import { buildTree } from "./MeasuresTree.Render";
import { MeasureDescriptor, MeasuresStructure } from "../../../../../api/biApi.types";

interface Props {
  measures: MeasureDescriptor[];
  structure: MeasuresStructure;
  onShowOptions: (x: number, y: number, measure: MeasureDescriptor, group: string) => void;
}

const MeasuresTree = ({ measures, structure, onShowOptions }: Props) => {
  return <List sx={{ p: 0 }}>{buildTree(measures, structure, onShowOptions)}</List>;
};

export default MeasuresTree;
