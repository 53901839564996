import { Button, DialogActions, Stack, Typography } from "@mui/material";
import PromptDialog from "../../../../shared/components/dialog/PromptDialog";
import PromptDialogHeader from "../../../../shared/components/dialog/PromptDialogHeader";
import { formatString } from "../../../../shared/utilities/stringFormatter";
import { useLocalization } from "../../../hooks/useLocalization";
import ReportWarningIcon from "../../../icons/ReportWarningIcon";

interface Props {
  open: boolean;
  templateName: string;
  onClose: () => void;
  onTryAgain: () => void;
}

export default function ErrorApplyingTemplateDialog({ open, onClose, onTryAgain, templateName }: Props) {
  const { report_templates: locale } = useLocalization();
  return (
    <PromptDialog open={open} sx={{ width: 514, height: 310, p: 0 }}>
      <PromptDialogHeader onClose={onClose} text="Error" />
      <Stack sx={{ p: 3, flex: 1, alignItems: "center", justifyContent: "center", gap: 2 }}>
        <ReportWarningIcon />
        <Stack gap={1} textAlign={"center"}>
          <Typography variant="h6">{formatString(locale.template_not_applied, templateName)}</Typography>
          <Typography color="text.secondary">{locale.apply_template_error_try_again}</Typography>
        </Stack>
      </Stack>
      <DialogActions sx={{ px: 3, py: 2, gap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={onTryAgain}>
          Try Again
        </Button>
      </DialogActions>
    </PromptDialog>
  );
}
