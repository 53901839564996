import { Box, List, Typography } from "@mui/material";
import BadgeDetached from "../../../components/BadgeDetached";
import { MenuItemStyled } from "../../../components/MenuItemStyled";
import NewLabel from "./NewLabel";
import { MenuItemRecord } from "./MenuItemRecord";

interface Props {
  items: MenuItemRecord[];
  selectedItem: MenuItemRecord;
  subHeader?: string;
  showNewLabel?: boolean;
  onClick: (item: MenuItemRecord) => void;
}
export default function TemplatesMenuItem({ items, selectedItem, subHeader, onClick, showNewLabel }: Props) {
  if (items.length === 0) return null;

  return (
    <List subheader={subHeader && <Typography variant="subtitle2">{subHeader}</Typography>}>
      {items.map((item, i) => {
        return (
          <MenuItemStyled
            key={i}
            sx={{ py: 0.5, borderRadius: "4px", letterSpacing: "0.17px" }}
            selected={item.label === selectedItem.label && item.fieldName === selectedItem.fieldName}
            onClick={() => onClick(item)}
          >
            <Typography sx={{ flex: 1, textAlign: "left", py: 0.5 }}>{item.label}</Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              {showNewLabel && item.isNew && <NewLabel />}
              <BadgeDetached
                showZero
                badgeContent={item.count}
                color={
                  item.label === selectedItem.label && item.fieldName === selectedItem.fieldName
                    ? "primary"
                    : "secondary"
                }
                sx={{ width: 32, justifyContent: "center" }}
              />
            </Box>
          </MenuItemStyled>
        );
      })}
    </List>
  );
}
