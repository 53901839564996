import { PropsWithChildren, useMemo } from "react";
import { AccessPermission } from "../api/types";
import { useClientPermissionsContext } from "../contexts/ClientPermissionsContext";

interface Props {
  permissions: AccessPermission[];
  clientCode?: string;
}

const AuthorizedBox = ({ clientCode, permissions, children }: PropsWithChildren<Props>) => {
  const { hasAnyPermission, hasAnyPermissionForClient } = useClientPermissionsContext();

  const hasPermissions = useMemo(() => {
    if (clientCode) {
      return hasAnyPermissionForClient(clientCode, permissions);
    }
    return hasAnyPermission(permissions);
  }, [clientCode, hasAnyPermission, hasAnyPermissionForClient, permissions]);

  if (!hasPermissions) return null;

  return <>{children}</>;
};

export default AuthorizedBox;
