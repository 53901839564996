import { createContext, PropsWithChildren, useContext } from "react";
import { AccessPermission } from "../api/types";
import { defined } from "../utilities/typeHelper";

interface ContextValue {
  permissions: AccessPermission[];
  permissionSets: { clientCode: string; permissions: AccessPermission[] }[];
  hasAnyPermission: (values: AccessPermission[]) => boolean;
  hasAnyPermissionForClient: (clientCode: string, values: AccessPermission[]) => boolean;
}

interface Props {
  permissions: AccessPermission[];
  permissionSets: { clientCode: string; permissions: AccessPermission[] }[];
}

const ClientPermissionsContext = createContext<ContextValue | undefined>(undefined);

export const ClientPermissionsContextProvider = ({
  permissions,
  permissionSets,
  children,
}: PropsWithChildren<Props>) => (
  <ClientPermissionsContext.Provider
    value={{
      permissions,
      permissionSets,
      hasAnyPermission: (values: string[]) => {
        return permissions.some((p) => values.includes(p));
      },
      hasAnyPermissionForClient: (clientCode: string, values: string[]) => {
        const clientPermissions = permissionSets.find((p) => p.clientCode === clientCode);
        if (!clientPermissions) return false;
        return clientPermissions.permissions.some((p) => values.includes(p));
      },
    }}
  >
    {children}
  </ClientPermissionsContext.Provider>
);

export const useClientPermissionsContext = () => {
  const client = useContext(ClientPermissionsContext);
  return defined(client);
};
