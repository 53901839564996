import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license";
import "devextreme/dist/css/dx.light.css";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import LoggedOut from "../shared/auth/LoggedOut";
import SignUp from "../shared/auth/SignUp";
import Error from "../shared/components/Error";
import { Licenses } from "../shared/licensing";
import { initAppInsights } from "../shared/logging";
import { lightThemeOptions } from "../shared/theme";
import { handleRedirectUrlState } from "../shared/utilities/handleRedirectUrlState";
import { PortalVariablesTarget, setPortalTarget } from "../shared/variables";
import App from "./App";
import Authentication from "./auth/Authentication";
import LogoutContainer from "./auth/LogoutContainer";
import UiReportsContainer from "./components/ReportsContainer";
import TemplatesContainer from "./components/TemplatesContainer";
import UiEditRoot from "./components/builder/EditRoot";
import ReportNotFoundOrAccessForbidden from "./components/builder/ReportNotFoundOrAccessForbidden";
import UiViewRoot from "./components/builder/ViewRoot";
import ApiEditRoot from "./components/builder/api-report/EditRoot";
import ApiReportsContainer from "./components/builder/api-report/ReportsContainer";
import ApiViewRoot from "./components/builder/api-report/ViewRoot";
import { getApiReportsUrl, getUiReportsUrl } from "./components/builder/common/utilities/editReportUrl";
import EditTemplatePage from "./components/template/EditTemplatePage";
import TemplateRoot from "./components/template/TemplateRoot";
import { Pages } from "./utilities/Pages";
import { dependencyInitializerHandler, setClientCode } from "./utilities/appInsights";
import setUpAxiosForBi from "./utilities/setupBiAxios";

LicenseInfo.setLicenseKey(Licenses.MUI_X_PREMIUM_KEY);

handleRedirectUrlState();
initAppInsights({ appId: "ReportingPortal", addRequestContext: setClientCode }, dependencyInitializerHandler);
setPortalTarget(PortalVariablesTarget.ReportingPortal);
setUpAxiosForBi();

const theme = createTheme(lightThemeOptions);

const Entry = () => {
  const { search } = useLocation();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path={`/${Pages.Oauth}`} element={<Authentication />} />
        <Route path={`/${Pages.SignUp}`} element={<SignUp />} />
        <Route path="/:client/*" element={<Outlet />}>
          <Route path={Pages.LoggedOut} element={<LoggedOut href="./" />} />
          <Route path={Pages.Logout} element={<LogoutContainer />} />
          <Route element={<App />}>
            <Route path="" element={<ReportNotFoundOrAccessForbidden getReportsUrl={getUiReportsUrl} />} />
            <Route element={<UiReportsContainer />}>
              <Route path={`${Pages.Reports}/:id`} element={<UiViewRoot />} />
              <Route path={`${Pages.Builder}/:id`} element={<UiEditRoot />} />
              <Route path="*" element={<ReportNotFoundOrAccessForbidden getReportsUrl={getUiReportsUrl} />} />
            </Route>
            <Route element={<ApiReportsContainer />}>
              <Route path={`${Pages.ApiReports}/:id`} element={<ApiViewRoot />} />
              <Route path={`${Pages.ApiBuilder}/:id`} element={<ApiEditRoot />} />
              <Route path="*" element={<ReportNotFoundOrAccessForbidden getReportsUrl={getApiReportsUrl} />} />
            </Route>
            <Route element={<TemplatesContainer />}>
              <Route
                path=":organization/template/:id"
                element={
                  <TemplateRoot>
                    <EditTemplatePage />
                  </TemplateRoot>
                }
              />
            </Route>
          </Route>
          <Route path={Pages.Forbidden} element={<ReportNotFoundOrAccessForbidden getReportsUrl={getUiReportsUrl} />} />
        </Route>
        <Route path={`/${Pages.Error}`} element={<Error />} />
        <Route path="" element={<Navigate to={`/entrilia${search}`} />} />
      </Routes>
    </ThemeProvider>
  );
};

export default Entry;
