import {
  BasicLinearSettings,
  LinearSeriesSettings,
  ValueAxis,
} from "../../../../../shared/reporting/api/biClient.types";
import { getValueAxisType } from "../style/serie/SerieValueAxis";

export default function updateValueAxis(settings: BasicLinearSettings): ValueAxis[] {
  const valueAxis = settings.series?.map((s) => s as LinearSeriesSettings).map((serie) => getValueAxisType(serie));
  const unique = [...new Set(valueAxis?.sort())];
  return unique.map((value) => {
    const valueAxis = settings.valueAxis?.find((va) => va.name === value);
    if (valueAxis === undefined) {
      return { name: value } as ValueAxis;
    }
    return valueAxis;
  });
}
