import { Box, IconButton, ListItem, Typography } from "@mui/material";
import { useEffect } from "react";
import { Ordering } from "../../../../../shared/reporting/api/biClient.types";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import SortAscIcon from "../../../../icons/SortAscIcon";
import SortDescIcon from "../../../../icons/SortDescIcon";
import { SortField } from "../../Types";

export const SortFieldItem = (props: {
  field: SortField;
  onRemoveItem: () => void;
  onChangeSorting: (order: Ordering) => void;
}) => {
  const { field, onRemoveItem, onChangeSorting } = props;

  useEffect(() => {
    if (!field.config.ordering) {
      onChangeSorting(Ordering.Ascending);
    }
  }, [field.config.ordering, onChangeSorting]);

  return (
    <ListItem
      sx={{
        dispaly: "flex",
        flexDirection: "row",
        gap: ".5rem",
        height: "32px",
        borderRadius: "2px",
        p: "5px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid rgba(33, 33, 33, 0.35)",
          borderRadius: "2px",
          backgroundColor: "#fff",
          p: "0 5px",
        }}
      >
        <Typography
          variant="caption"
          sx={{ display: "flex", flexGrow: 1 }}
          color={(theme) => theme.palette.text.primary}
        >
          {field.config.caption || field.meta.caption}
        </Typography>
        <CloseIconButton onClick={() => onRemoveItem()} iconProps={{ htmlColor: "rgba(33, 33, 33, 0.38)" }} />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid" + theme.palette.primary.main,
          backgroundColor: "rgba(0, 131, 143, 0.08)",
          borderRadius: "2px",
          p: 0,
          "&:hover": {
            cursor: "pointer",
          },
        })}
        onClick={() =>
          onChangeSorting(field.config.ordering === Ordering.Descending ? Ordering.Ascending : Ordering.Descending)
        }
      >
        <IconButton>
          {field.config.ordering === Ordering.Descending && <SortDescIcon color="primary" />}
          {field.config.ordering !== Ordering.Descending && <SortAscIcon color="primary" />}
        </IconButton>
      </Box>
    </ListItem>
  );
};

export default SortFieldItem;
