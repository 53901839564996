import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { IconButton } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentReportActions, selectPreviewMode } from "../../../store/currentReportSlice";

export default function ChangePreviewModeButton() {
  const dispatch = useDispatch();
  const previewMode = useSelector(selectPreviewMode);

  const handleUpdatePreviewMode = React.useCallback(() => {
    dispatch(currentReportActions.updatePreviewMode(previewMode === "default" ? "maximize" : "default"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewMode]);

  return (
    <IconButton onClick={handleUpdatePreviewMode}>
      {previewMode === "default" && (
        <OpenInFullIcon sx={(theme) => ({ "&:hover": { color: theme.palette.primary.main } })} />
      )}
      {previewMode === "maximize" && (
        <CloseFullscreenIcon sx={(theme) => ({ "&:hover": { color: theme.palette.primary.main } })} />
      )}
    </IconButton>
  );
}
