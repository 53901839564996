import { useGridApiContext } from "@mui/x-data-grid-premium";
import CollapseAllIcon from "../icons/CollapseAllIcon";
import ExpandAllIcon from "../icons/ExpandAllIcon";
import { isAnyGroupExpanded, setAllGroupsExpansion } from "../utilities/dataGridApiHelper";
import ExpandCollapseButton from "./ExpandCollapseButton";

interface Props {
  onToggle?: (expanded: boolean) => void;
}
const ExpandCollapseAllButton = ({ onToggle }: Props) => {
  const apiRef = useGridApiContext();

  const anyExpanded = isAnyGroupExpanded(apiRef);

  const handleToggle = () => {
    setAllGroupsExpansion(apiRef, !anyExpanded);
    onToggle?.(!anyExpanded);
  };

  return (
    <ExpandCollapseButton
      expanded={anyExpanded}
      onToggle={handleToggle}
      ExpandIcon={ExpandAllIcon}
      CollapseIcon={CollapseAllIcon}
    />
  );
};

export default ExpandCollapseAllButton;
