import { Stack, SxProps, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { PersistedTabPanel } from "../../common/PersistedTabPanel";

interface Props {
  GridView: JSX.Element;
  CodeView: JSX.Element;
}

const getTabSx = (tabNumber: number, selectedTab: number): SxProps => ({
  display: "flex",
  position: "absolute",
  visibility: tabNumber === selectedTab ? "visible" : "hidden",
  height: "100%",
  flexWrap: "nowrap",
});

const PreviewTabs = ({ GridView, CodeView }: Props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Stack width={"100%"} height={"100%"} overflow={"auto"}>
      <Tabs value={selectedTab} sx={{ px: "1.3rem", pt: "0.7rem" }} onChange={(_, value) => setSelectedTab(value)}>
        <Tab label="Report" />
        <Tab label="Code" />
      </Tabs>
      <Stack useFlexGap gap={2} height={"100%"} overflow={"auto"} position={"relative"}>
        <PersistedTabPanel value={0} selectedTab={selectedTab} sx={getTabSx(0, selectedTab)}>
          {GridView}
        </PersistedTabPanel>
        <PersistedTabPanel value={1} selectedTab={selectedTab} sx={getTabSx(1, selectedTab)}>
          {CodeView}
        </PersistedTabPanel>
      </Stack>
    </Stack>
  );
};

export default PreviewTabs;
