import { IconButton } from "@mui/material";
import TooltipWrapper from "../../../../../shared/components/TooltipWrapper";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";

interface Props {
  onClick: () => void;
  hidden: boolean;
}

const ChangeReportNameButton = ({ onClick, hidden }: Props) => {
  return (
    <TooltipWrapper title="Rename Report">
      <IconButton onClick={onClick} sx={{ visibility: hidden ? "hidden" : "visible" }}>
        <DriveFileRenameOutlineOutlinedIcon />
      </IconButton>
    </TooltipWrapper>
  );
};

export default ChangeReportNameButton;
