import { Grid, Typography } from "@mui/material";
import React from "react";
import { BuilderContext } from "../../types";
import { useLocalization } from "../../../../../hooks/useLocalization";
import SwitchField from "../../../../common/SwitchField";

export default function Settings() {
  const { settingsArea } = React.useContext(BuilderContext);
  const { pivot: locale } = useLocalization();

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        borderBottom: "1px dashed #E0E0E0",
        pb: 1,
        gap: 0.5,
      }}
    >
      <Typography variant="subtitle2" fontWeight={500} color={(theme) => theme.palette.text.primary}>
        General settings
      </Typography>
      <SwitchField
        label="Hide Blank Rows"
        checked={settingsArea.settings.hideBlankRows === true}
        onChange={(newValue) => settingsArea.update({ hideBlankRows: newValue })}
        tooltipText={locale.hide_empty_rows_explanation}
        typographyProps={{ color: "text.secondary" }}
      />
      <SwitchField
        label="Hide Empty Columns"
        checked={settingsArea.settings.hideEmptyColumns === true}
        onChange={(newValue) => settingsArea.update({ hideEmptyColumns: newValue })}
        tooltipText={locale.hide_empty_columns_explanation}
        typographyProps={{ color: "text.secondary" }}
      />
    </Grid>
  );
}
