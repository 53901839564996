import { Grid, Tab, Tabs } from "@mui/material";
import React, { useMemo } from "react";
import { DrillDownInfo, IrrDrillDownInfo } from "../../../../../api/biApi.types";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";
import { PersistedTabPanel } from "../../../../common/PersistedTabPanel";
import ChartOfAccountsPart from "../chartOfAccounts/ChartOfAccountsPart";
import LedgerPart from "../ledger/LedgerPart";
import { getLedgerTitle } from "../utils/getLedgerTitle";
import CashFlowPart from "./CashFlowPart";

enum IrrPageTab {
  CashFlow = "cashflow",
  ChartOfAccounts = "chartOfAccounts",
  Ledger = "ledger",
}

interface Props {
  configuration: DrillDownConfiguration;
  drillDownInfo: DrillDownInfo;
}

export default function IrrPage({ configuration, drillDownInfo }: Props) {
  const [selectedTab, setSelectedTab] = React.useState<IrrPageTab>(IrrPageTab.CashFlow);
  const combined = drillDownInfo.combined as IrrDrillDownInfo;
  const ledgers = useMemo(() => drillDownInfo.items.map(getLedgerTitle), [drillDownInfo.items]);

  return (
    <>
      <Tabs value={selectedTab} sx={{ pl: 2.5 }} onChange={(_, value) => setSelectedTab(value)}>
        <Tab key={IrrPageTab.CashFlow} label="Cash Flow" value={IrrPageTab.CashFlow} />
        <Tab key={IrrPageTab.ChartOfAccounts} label="Chart Of Accounts" value={IrrPageTab.ChartOfAccounts} />
        {ledgers.map((ledgerName, index) => (
          <Tab key={`${IrrPageTab.Ledger}_${index}`} label={ledgerName} value={`${IrrPageTab.Ledger}_${index}`} />
        ))}
      </Tabs>
      <Grid container sx={{ flex: 1, minHeight: 0 }}>
        <PersistedTabPanel key={IrrPageTab.CashFlow} value={IrrPageTab.CashFlow} selectedTab={selectedTab}>
          <CashFlowPart configuration={configuration} info={combined} />
        </PersistedTabPanel>
        <PersistedTabPanel
          key={IrrPageTab.ChartOfAccounts}
          value={IrrPageTab.ChartOfAccounts}
          selectedTab={selectedTab}
        >
          <ChartOfAccountsPart configuration={configuration} info={drillDownInfo} />
        </PersistedTabPanel>
        {drillDownInfo.items.map((item, index) => (
          <PersistedTabPanel
            key={`${IrrPageTab.Ledger}_${index}`}
            value={`${IrrPageTab.Ledger}_${index}`}
            selectedTab={selectedTab}
          >
            <LedgerPart configuration={configuration} info={item} />
          </PersistedTabPanel>
        ))}
      </Grid>
    </>
  );
}
