import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Report,
  ReportAuthorizationInfo,
  ReportConfiguration,
  ReportTemplate,
} from "../../shared/reporting/api/biClient.types";
import cloneDeep from "../../shared/utilities/cloneDeep";
import { generateGuid } from "../../shared/utilities/generateGuid";
import { BuildReportRequest } from "../api/biApi.types";
import { areConfigurationsEqual } from "../utilities/configurationComparer";
import { CurrentReportState, PreviewMode } from "./CurrentReportState";
import { RootState } from "./RootState";

const initialState: CurrentReportState = {
  refreshToken: "",
  previewMode: "maximize",
  isReportChanged: false,
  editMode: false,
  sessionId: generateGuid(),
  cacheSessionId: generateGuid(),
  transactional: true,
};

export const currentReportSlice = createSlice({
  name: "currentReport",
  initialState,
  reducers: {
    refreshSession: (state) => {
      state.cacheSessionId = generateGuid();
    },
    refresh: (state) => {
      state.refreshToken = generateGuid();
      state.cacheSessionId = generateGuid();
    },
    setReport: (state, action: PayloadAction<Report | ReportTemplate>) => {
      state.report = cloneDeep(action.payload);
      state.configuration = undefined;
      state.isReportChanged = false;
    },
    setAuthorizationInfo: (state, action: PayloadAction<ReportAuthorizationInfo>) => {
      state.authorizationInfo = action.payload;
    },
    updateReport: (state, action: PayloadAction<Partial<Report | ReportTemplate>>) => {
      state.report = Object.assign({}, state.report, action.payload);
    },
    updatePreviewMode: (state, action: PayloadAction<PreviewMode>) => {
      state.previewMode = action.payload;
    },
    updateReportConfiguration: (state, action: PayloadAction<ReportConfiguration>) => {
      state.configuration = action.payload;
      state.isReportChanged = !areConfigurationsEqual(state.report?.configuration || {}, state.configuration);
    },
    updateReportRequest: (state, action: PayloadAction<BuildReportRequest>) => {
      state.request = cloneDeep(action.payload);
    },
    updateReportMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    setAutoShowItemOptionsId: (state, action: PayloadAction<string | undefined>) => {
      state.autoShowItemOptionsId = action.payload;
    },
    setEtag(state, action: PayloadAction<{ etag: string | undefined; cacheValid: boolean | undefined }>) {
      state.etag = action.payload.etag;
      state.cacheValid = action.payload.cacheValid;
    },
    applyTemplateOnReport: (state, action: PayloadAction<ReportTemplate>) => {
      if (state.report) {
        state.report.reportType = action.payload.reportType;
        state.report.configuration = cloneDeep(action.payload.configuration);
        state.cacheSessionId = generateGuid();
        state.resetSessionId = generateGuid();
        state.configuration = cloneDeep(action.payload).configuration;
      }
    },
    updateBuildProgress: (state, action: PayloadAction<number | undefined>) => {
      state.currentBuildProgress = action.payload;
    },
    resetReportConfiguration: (state) => {
      state.configuration = undefined;
      state.isReportChanged = false;
    },
    updateIsReportTransactional: (state, action: PayloadAction<boolean>) => {
      state.transactional = action.payload;
    },
  },
});

export const currentReportActions = currentReportSlice.actions;

export const selectRefreshToken = (state: RootState) => state.currentReport.refreshToken;
export const selectSessionId = (state: RootState) => state.currentReport.sessionId;
export const selectCacheSessionId = (state: RootState) => state.currentReport.cacheSessionId;
export const selectCurrentReport = (state: RootState) => state.currentReport.report;
export const selectCurrentReportAuthorizationInfo = (state: RootState) => state.currentReport.authorizationInfo;
export const selectPreviewMode = (state: RootState) => state.currentReport.previewMode;
export const selectReportConfiguration = (state: RootState) => state.currentReport.configuration;
export const selectReportRequest = (state: RootState) => state.currentReport.request;
export const selectIsReportChanged = (state: RootState) => state.currentReport.isReportChanged;
export const selectReportEditMode = (state: RootState) => state.currentReport.editMode;
export const selectAutoShowItemOptionsId = (state: RootState) => state.currentReport.autoShowItemOptionsId;
export const selectResetId = (state: RootState) => state.currentReport.resetSessionId;
export const selectEtag = (state: RootState) => state.currentReport.etag;
export const selectDrillDownCacheValid = (state: RootState) => state.currentReport.cacheValid;
export const selectCurrentBuildProgress = (state: RootState) => state.currentReport.currentBuildProgress;
export const selectIsReportTransactional = (state: RootState) => state.currentReport.transactional;

export default currentReportSlice.reducer;
