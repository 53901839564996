import { Menu } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
}

export default function ExportMenu(props: PropsWithChildren<Props>) {
  const { anchorEl, open, children, onClose } = props;

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={() => onClose()}>
      {children}
    </Menu>
  );
}
