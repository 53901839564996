import { Divider, Grid, Typography } from "@mui/material";
import { useCallback } from "react";
import { UserMeasureConfigurations } from "../../../../../../shared/reporting/api/biClient.types";
import FieldOptionDeleteButton from "../../../../common/FieldOptionDeleteButton";
import CustomLabel from "../../../../common/customLabel/CustomLabel";
import { ValueField } from "../../../Types";
import FieldPopover from "../../../common/fields/FieldPopover";
import { SuppressNoValueMeasureComponent } from "../../../common/fields/SuppressNoValueComponent";
import AmountTypeMenuItem from "../../../common/measures/menuItems/AmountTypeMenuItem";
import BlankZeroMenuItem from "../../../common/measures/menuItems/BlankZeroMenuItem";
import CashFlowFrequencyMenuItem from "../../../common/measures/menuItems/CashFlowFrequencyMenuItem";
import ChartOfAccountsItem from "../../../common/measures/menuItems/ChartOfAccountsItem";
import CustomConditionsMenuItem from "../../../common/measures/menuItems/CustomConditionsMenuItem";
import ExplainMenuItem from "../../../common/measures/menuItems/ExplainMenuItem";
import PeriodMenuItem from "../../../common/measures/menuItems/PeriodMenuItem";
import ReverseSignMenuItem from "../../../common/measures/menuItems/ReverseSignMenuItem";
import TransferFieldMenuItem from "../../../common/measures/menuItems/TransferFieldMenuItem";
import ValueFieldsMenuItem from "../../../common/measures/menuItems/ValueFieldsMenuItem";
import { isGlTableMeasure, isSharesRelatedMeasure } from "../../../utils/MeasureUtils";
import PivotFieldMenuItem from "./menuItems/PivotFieldMenuItem";

interface Props {
  anchorEl: HTMLElement | null;
  measure: ValueField;
  cancel: () => void;
  saveChanges: (changes: Partial<UserMeasureConfigurations>, causeClosing: boolean) => void;
  saveAllowCustomConditions: (useCustomConditions: boolean) => void;
  onRemove: () => void;
}

export default function MeasureFieldOptionPopup(props: Props) {
  const { anchorEl, measure, cancel, saveChanges, saveAllowCustomConditions, onRemove } = props;

  const amountTypeChangeDisabled = !isGlTableMeasure(measure.meta);
  const hideAmountRelatedControls = isSharesRelatedMeasure(measure);

  const handleOnRemove = useCallback(() => {
    onRemove();
    cancel();
  }, [onRemove, cancel]);

  return (
    <FieldPopover open={true} anchorEl={anchorEl || undefined} sx={{ width: 400, p: 0 }} onClose={cancel}>
      <CustomLabel
        customLabelValue={measure.config.customLabel}
        originalName={measure.meta.caption}
        updateCustomLabel={(label) => saveChanges({ customLabel: label }, false)}
      />
      <OptionDivider />
      <ExplainMenuItem measure={measure} onClose={cancel} />
      <ChartOfAccountsItem measure={measure} onClose={cancel} />
      <OptionDivider />
      <Grid sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 1.5, px: 2, py: 1.5 }}>
        <PeriodMenuItem measure={measure} saveChanges={saveChanges} onClose={cancel} />
        <TransferFieldMenuItem measure={measure} onUpdate={saveChanges} />
        {!hideAmountRelatedControls && (
          <>
            <AmountTypeMenuItem measure={measure} saveChanges={saveChanges} disabled={amountTypeChangeDisabled} />
            <ValueFieldsMenuItem measure={measure} saveChanges={saveChanges} />
          </>
        )}
        <CashFlowFrequencyMenuItem measure={measure} saveChanges={saveChanges} onClose={cancel} />
      </Grid>
      <OptionDivider />
      <Typography variant="subtitle1" sx={{ pl: 2, py: 1 }}>
        Other
      </Typography>
      <CustomConditionsMenuItem measure={measure} saveAllowCustomConditions={saveAllowCustomConditions} />
      <SuppressNoValueMeasureComponent
        field={measure}
        onChange={(value) => saveChanges({ suppressEmptyValues: value }, false)}
      />
      <BlankZeroMenuItem measure={measure} saveChanges={saveChanges} />
      <ReverseSignMenuItem measure={measure} saveChanges={saveChanges} />
      <PivotFieldMenuItem measure={measure} saveChanges={saveChanges} />
      <OptionDivider />
      <FieldOptionDeleteButton onRemove={handleOnRemove} />
    </FieldPopover>
  );
}

const OptionDivider = () => <Divider style={{ marginBottom: 0, marginTop: 0 }} />;
