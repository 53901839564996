import { Autocomplete, Box, Button, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { OptionField } from "../ColumnsOptions.types";
import { DimensionDescriptor } from "../../../../../../api/biApi.types";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import CloseIconButton from "../../../../../../../shared/components/CloseIconButton";
import { makeLighterBackgroundFromColor } from "../../../../../../../shared/utilities/colorHelper";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import SortAscIcon from "../../../../../../icons/SortAscIcon";
import SortDescIcon from "../../../../../../icons/SortDescIcon";
import { SORTING_COLUMN_DD_ITEM_TYPE } from "../ColumnsOptions.types";
import { FieldWithSorting } from "../../../../../../hooks/FieldWithOrder";

interface Props {
  field: FieldWithSorting;
  options: OptionField[];
  sx?: SxProps<Theme>;
  addShifting: boolean;
  draggingItemMovedFromInitialPosition: boolean;
  hideDraggingItem: boolean;
  onRemove: () => void;
  onEndReordering: (field: FieldWithSorting) => void;
  onItemHovered: (field: FieldWithSorting) => void;
  onReplaceWith: (newField: DimensionDescriptor) => void;
  onUpdateSorting: (field: FieldWithSorting, sortAsc: boolean) => void;
}

const SortingItem = (props: Props) => {
  const {
    field,
    options,
    sx,
    onItemHovered,
    onRemove,
    addShifting,
    draggingItemMovedFromInitialPosition,
    hideDraggingItem,
    onEndReordering,
    onReplaceWith,
    onUpdateSorting,
  } = props;

  const itemRef = useRef<HTMLDivElement>(null);

  const selectedOption = useMemo(() => options.find((o) => o.id === field.field.name), [options, field.field]);

  const hover = useCallback(
    (_: { field: FieldWithSorting }, monitor: DropTargetMonitor<{ field: FieldWithSorting }>) => {
      if (monitor.isOver()) {
        onItemHovered?.(field);
      }
    },
    [field, onItemHovered]
  );

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: SORTING_COLUMN_DD_ITEM_TYPE,
    item: { field, options },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: (item) => onEndReordering(item.field),
  }));

  const [, drop] = useDrop(() => ({ accept: [SORTING_COLUMN_DD_ITEM_TYPE], hover }), []);

  drag(drop(itemRef));

  useEffect(() => {
    dragPreview(getEmptyImage());
  }, [dragPreview]);

  return (
    <Stack
      component="div"
      role="Handle"
      ref={itemRef}
      data-index={field.order}
      data-is-dragging={isDragging}
      data-is-shifted={addShifting}
      sx={{
        display: hideDraggingItem ? "none" : "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 0.7,
        borderRadius: "4px",
        ":hover": {
          cursor: "pointer",
        },
        height: "37px",
        ...(!draggingItemMovedFromInitialPosition && {
          "&:hover": {
            bgcolor: !isDragging ? "rgba(0, 0, 0, 0.04)" : "inherit",
            "& .remove": {
              visibility: "visible",
            },
          },
        }),
        ...sx,
      }}
      style={{
        ...(addShifting && {
          transform: "translate(0px, 37px)",
        }),
        ...(draggingItemMovedFromInitialPosition
          ? { transition: "transform 0.2s cubic-bezier(0.2, 0, 0, 1)" }
          : { transition: "none 0s ease 0s" }),
      }}
    >
      <DragIndicatorIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
      <Autocomplete
        disablePortal
        autoSelect
        sx={{
          flex: 1,
          fieldset: {
            borderColor: "#DDDFE2",
          },
        }}
        options={options}
        value={selectedOption}
        groupBy={(option) => option.group}
        disableClearable={true}
        onChange={(_, value) => {
          if (value?.field) {
            onReplaceWith?.call(this, value.field);
          }
        }}
        renderInput={(params) => <TextField {...params} />}
        renderOption={(props, op) => (
          <Box {...props} key={op.id} component="li">
            <Typography>{op.label}</Typography>
          </Box>
        )}
      />
      <Button
        variant="outlined"
        sx={(theme) => ({
          minWidth: "30px",
          px: "11px",
          py: "7px",
          ".MuiButton-startIcon": {
            mx: "-4px",
          },
          backgroundColor: makeLighterBackgroundFromColor(theme.palette.primary.main),
        })}
        startIcon={field.sortAsc ? <SortAscIcon /> : <SortDescIcon />}
        onClick={() => onUpdateSorting?.call(this, field, !field.sortAsc)}
      />
      <CloseIconButton className="remove" onClick={onRemove} sx={{ visibility: "hidden" }} />
    </Stack>
  );
};

export default SortingItem;
