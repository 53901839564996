import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ReportWarningIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 80, height: 80 }} fill="none" viewBox="0 0 80 80" {...props}>
      <g clipPath="url(#clip0_2477_167278)">
        <path d="M43 44H27V49H43V44Z" fill="#C7DBE5" />
        <path
          d="M63.6629 68.2246H15.4629V8.72461H51.3629L63.6629 21.0246V68.2246ZM20.4629 63.2246H58.5629V23.1246L49.2629 13.8246H20.4629V63.2246Z"
          fill="#C7DBE5"
        />
        <path d="M61.1625 26.3246H46.5625V11.2246H51.5625V21.3246H61.1625V26.3246Z" fill="#C7DBE5" />
        <path d="M52.4631 34.125H26.6631V39.125H52.4631V34.125Z" fill="#C7DBE5" />
        <path
          d="M58.7077 48.2335C59.8754 46.3066 62.6712 46.3066 63.8389 48.2335L77.8022 71.2738C79.0138 73.2731 77.5744 75.8286 75.2365 75.8286H47.3101C44.9723 75.8286 43.5328 73.2731 44.7445 71.2738L58.7077 48.2335Z"
          fill="#FF9800"
        />
        <path
          d="M63.4742 70.9923C64.1294 69.777 63.6753 68.2606 62.4599 67.6054C61.2446 66.9502 59.7282 67.4043 59.073 68.6197C58.4178 69.8351 58.872 71.3514 60.0873 72.0066C61.3027 72.6618 62.8191 72.2077 63.4742 70.9923Z"
          fill="white"
        />
        <path d="M64.0518 54.9922L62.9405 65.2187H59.6991L58.4951 54.9922H64.0518Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_2477_167278">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
