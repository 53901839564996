import { ItemDataType } from "../../../api/biApi.types";
import { GeneralField } from "../Types";
import FieldCaption from "../pivot/table/FieldCaption";
import { getDateGroupingLabel } from "./captionsHelper";

export const formatDimensionFieldCaptions = (field: GeneralField, getSubCaptions?: (field: GeneralField) => string) => {
  return (
    <FieldCaption caption={field.config.customLabel || field.meta.caption} subCaption={getSubCaptions?.(field) || ""} />
  );
};

export const getGroupingCaption = (field: GeneralField) => {
  if (field?.meta.type === ItemDataType.Date) {
    return getDateGroupingLabel(field.config.grouping);
  }
  return "";
};
