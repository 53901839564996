import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { IconButton, SvgIconProps } from "@mui/material";
import { ComponentType } from "react";

interface Props {
  expanded: boolean;
  onClick: (expanded: boolean) => void;
}

export function ExpandCollapseButton({ expanded, onClick }: Props) {
  return expanded ? (
    <ExpandCollapseButtonControl Icon={ArrowDropDownRoundedIcon} onClick={() => onClick(false)} />
  ) : (
    <ExpandCollapseButtonControl Icon={ArrowRightRoundedIcon} onClick={() => onClick(true)} />
  );
}

function ExpandCollapseButtonControl({ Icon, onClick }: { Icon: ComponentType<SvgIconProps>; onClick: () => void }) {
  return (
    <IconButton onClick={onClick}>
      <Icon sx={{ color: "secondary.light" }} />
    </IconButton>
  );
}
