import {
  TabularFieldConfiguration,
  GroupingField,
  SortConfiguration,
  Ordering,
  TabularFieldType,
} from "../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { TableField } from "../../tabular/hooks/TableField";
import { ColumnField, SortField } from "../../Types";

export const setGroupingFlag = (
  fields: TabularFieldConfiguration[],
  groups: GroupingField[],
  sorts: SortConfiguration[]
) => {
  const groupIds = new Set(groups.map((g) => g.name));
  const copy = cloneDeep(sorts);

  copy.forEach((sorting) => {
    const field = fields.find((f) => f.dimension?.name === sorting.name);
    if (field && groupIds.has(field.guid)) {
      sorting.isGroupField = true;
    }
  });

  return copy;
};

export const addMissingGroupSorts = (
  fields: TabularFieldConfiguration[],
  groups: GroupingField[],
  sorts: SortConfiguration[]
) => {
  const groupIds = new Set(groups.map((g) => g.name));
  const sortMetaNames = new Set(sorts.map((s) => s.name));

  const missingSorts = fields
    .filter((field) => field.dimension && groupIds.has(field.guid) && !sortMetaNames.has(field.dimension.name))
    .map(
      (field) =>
        ({
          name: field.dimension?.name,
          isGroupField: true,
          ordering: Ordering.Ascending,
          caption: field.dimension?.customLabel ?? field.dimension?.name,
        }) as SortConfiguration
    );

  return [...cloneDeep(sorts), ...missingSorts];
};

export const sortSortingFields = (sorts: SortField[], groupMetaNames: string[]) => {
  const groupedFields = sorts.filter((field) => field.config.isGroupField);
  const regularFields = sorts.filter((field) => !field.config.isGroupField);

  groupedFields.sort(
    (a, b) => groupMetaNames.findIndex((g) => g === a.meta.name) - groupMetaNames.findIndex((g) => g === b.meta.name)
  );

  return [...groupedFields, ...regularFields];
};

export const getAvailableSortFields = (values: TableField[], sortingAreaValues: SortField[]) => {
  return values
    .filter((f) => f.fieldType === TabularFieldType.Dimension)
    .map((f) => f.dimension)
    .filter((f): f is ColumnField => !!f)
    .filter((f) => !sortingAreaValues.find((v) => v.meta.name === f.meta.name));
};
