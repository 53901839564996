import { Box, Grid, SxProps, Theme } from "@mui/material";
import { PropsWithChildren, UIEvent } from "react";

interface Props {
  sx?: SxProps<Theme>;
  scrollContainerSx?: SxProps<Theme>;
  onScroll?: (e: UIEvent<HTMLDivElement>) => void;
}

const ScrollableFlexContainer = ({ sx, scrollContainerSx, onScroll, children }: PropsWithChildren<Props>) => {
  return (
    <Grid container sx={{ flexDirection: "column", flex: 1, position: "relative", ...sx }}>
      <Box
        onScroll={onScroll}
        sx={{ position: "absolute", height: "100%", width: "100%", overflowY: "auto", ...scrollContainerSx }}
      >
        {children}
      </Box>
    </Grid>
  );
};

export default ScrollableFlexContainer;
