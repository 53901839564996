import { SvgIcon, SvgIconProps } from "@mui/material";

export const DeleteDocumentIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 52, height: 62, fill: "none" }} viewBox="0 0 52 62" {...props}>
      <path
        d="M48.6629 60.2246H0.462891V0.724609H36.3629L48.6629 13.0246V60.2246ZM5.46289 55.2246H43.5629V15.1246L34.2629 5.82461H5.46289V55.2246Z"
        fill="#C7DBE5"
      />
      <path d="M46.1625 18.3246H31.5625V3.22461H36.5625V13.3246H46.1625V18.3246Z" fill="#C7DBE5" />
      <path d="M48.0019 40.2032L30.4658 57.7393L34.0013 61.2748L51.5374 43.7387L48.0019 40.2032Z" fill="#F44336" />
      <path d="M34.0013 40.2028L30.4658 43.7383L48.0019 61.2744L51.5374 57.7389L34.0013 40.2028Z" fill="#F44336" />
      <path d="M37.4631 26.125H11.6631V31.125H37.4631V26.125Z" fill="#C7DBE5" />
    </SvgIcon>
  );
};

export default DeleteDocumentIcon;
