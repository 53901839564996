import React from "react";
import { selectReportEditMode } from "../../../../../store/currentReportSlice";
import { useSelector } from "react-redux";
import useRequestConfigBuilder from "./useRequestConfigBuilder";
import useExport from "../../../../../hooks/useExport";
import { useRequestConfigViewer } from "./useRequestConfigViewer";
import biClient from "../../../../../api/biApi";
import { selectDimensions } from "../../../../../store/metaDataSlice";
import { createBuildPivotExportRequest, createRunPivotExportRequest } from "../../../tabular/utilities/exportHelper";

export const usePivotExport = () => {
  const editMode = useSelector(selectReportEditMode);
  const { requestConfig: buildConfig } = useRequestConfigBuilder();
  const { requestConfig: viewConfig } = useRequestConfigViewer();
  const dimensions = useSelector(selectDimensions);

  const buildConfigRef = React.useRef(buildConfig);
  const viewConfigRef = React.useRef(viewConfig);

  const pivotExportRequest = React.useCallback(
    () =>
      editMode === true
        ? biClient.requestBuildPivotExport(createBuildPivotExportRequest(buildConfigRef.current, dimensions, false))
        : biClient.requestRunPivotExport(createRunPivotExportRequest(viewConfigRef.current, dimensions, false)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMode]
  );

  const pivotExportWithParametersRequest = React.useCallback(
    () =>
      editMode === true
        ? biClient.requestBuildPivotExport(createBuildPivotExportRequest(buildConfigRef.current, dimensions, true))
        : biClient.requestRunPivotExport(createRunPivotExportRequest(viewConfigRef.current, dimensions, true)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMode]
  );

  const { requestExport } = useExport({ makeRequest: pivotExportRequest });
  const { requestExport: requestExportWithParameters } = useExport({ makeRequest: pivotExportWithParametersRequest });

  React.useEffect(() => {
    buildConfigRef.current = buildConfig;
  }, [buildConfig]);

  React.useEffect(() => {
    viewConfigRef.current = viewConfig;
  }, [viewConfig]);

  return { requestExport, requestExportWithParameters };
};
