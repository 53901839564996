import { PropsWithChildren, createContext, useContext, useState } from "react";
import { defined } from "../../../../../../shared/utilities/typeHelper";

export type ChartOfAccountsContextItem = {
  parent: ChartOfAccountsContextItem | null;
  children: ChartOfAccountsContextItem[];
  identifier: string;
  checked: boolean;
  indeterminate: boolean;
  accountNo?: string | undefined;
};

interface ContextValue {
  appendChild: (key: string, node: ChartOfAccountsContextItem, accountNo?: string) => void;
  getNode: (key: string, node: ChartOfAccountsContextItem | null) => ChartOfAccountsContextItem | null;
  onSelect: (key: string, selected: boolean) => void;
  resetSelectedAccounts: () => void;
  scrollToAccountNo: (node: ChartOfAccountsContextItem) => void;
  root: ChartOfAccountsContextItem;
  selectedAccounts: string[];
  scrollTargetAccount: { accountNo: string } | undefined;
}

const ChartOfAccountsContext = createContext<ContextValue | undefined>(undefined);

export const ChartOfAccountsContextProvider = (props: PropsWithChildren) => {
  const [root, setData] = useState<ChartOfAccountsContextItem>({
    parent: null,
    identifier: "root",
    children: [],
    checked: false,
    indeterminate: false,
  });
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [scrollTargetAccount, setScrollTargetAccount] = useState<{ accountNo: string } | undefined>(undefined);
  const appendChild = (key: string, parentNode: ChartOfAccountsContextItem, accountNo?: string) => {
    if (!root) {
      return;
    }
    const tempRoot = { ...root };
    if (parentNode.children.find((child) => child.identifier === key)) {
      return;
    }
    const newNode: ChartOfAccountsContextItem = {
      parent: parentNode,
      children: [],
      identifier: key,
      checked: false,
      indeterminate: false,
      accountNo,
    };
    parentNode.children.push(newNode);
    setData({ ...tempRoot });
  };

  const getNode = (key: string, node: ChartOfAccountsContextItem | null): ChartOfAccountsContextItem | null => {
    let newNode = node;
    if (!newNode) {
      newNode = root;
    }
    if (newNode.identifier.toLowerCase() === key.toLowerCase()) {
      return newNode;
    }

    for (const child of newNode.children) {
      const result = getNode(key, child);
      if (result) {
        return result;
      }
    }

    return null;
  };

  const onSelect = (key: string, selected: boolean) => {
    const tempRoot = { ...root };
    const node = getNode(key, tempRoot);
    if (!node) return;
    if (node.indeterminate && selected) {
      selected = false;
    }
    updateNode(selected, node);
    updateParent(node);
    setSelectedAccounts(getSelectedAccounts());
    setData({ ...tempRoot });
  };

  const getSelectedAccounts = () => {
    const arr: string[] = [];
    const findSelectedAccounts = (node: ChartOfAccountsContextItem) => {
      if (node.accountNo) {
        if (node.checked) {
          arr.push(node.accountNo);
        }
      }
      node.children.forEach((child) => {
        findSelectedAccounts(child);
      });
    };
    findSelectedAccounts(root);

    return arr;
  };

  const updateNode = (selected: boolean, node: ChartOfAccountsContextItem) => {
    node.checked = selected;
    node.indeterminate = false;
    node.children.forEach((child) => {
      updateNode(selected, child);
    });
  };

  const updateParent = (node: ChartOfAccountsContextItem) => {
    const { parent } = node;
    if (parent === null) {
      return;
    }
    const allChecked = parent.children.every((child) => child.checked);
    const someChecked = parent.children.some((child) => child.checked);

    parent.checked = allChecked;
    parent.indeterminate = someChecked && !allChecked;
    updateParent(parent);
  };

  const resetSelectedAccounts = () => {
    setData({ parent: null, identifier: "root", children: [], checked: false, indeterminate: false });
    setSelectedAccounts([]);
  };

  const scrollToAccountNo = (node: ChartOfAccountsContextItem) => {
    const doScroll = (node: ChartOfAccountsContextItem) => {
      if (node.accountNo) {
        setScrollTargetAccount({ accountNo: node.accountNo });
        return true;
      }
      return false;
    };
    if (!doScroll(node)) {
      node.children.forEach((child) => {
        if (doScroll(child)) {
          return;
        }
      });
    }
  };

  return (
    <ChartOfAccountsContext.Provider
      value={{
        root,
        appendChild,
        getNode,
        onSelect,
        selectedAccounts,
        resetSelectedAccounts,
        scrollToAccountNo,
        scrollTargetAccount,
      }}
    >
      {props.children}
    </ChartOfAccountsContext.Provider>
  );
};

export const useChartOfAccountsContext = () => {
  const context = useContext(ChartOfAccountsContext);
  return defined(context);
};
