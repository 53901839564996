import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNotificationContext } from "../../../shared/contexts/NotificationContext";
import { logError } from "../../../shared/logging";
import { Report } from "../../../shared/reporting/api/biClient.types";
import { useEnhancedBiApiClientProvider } from "../../contexts/ApiClientProviderContext";
import { useLocalization } from "../../hooks/useLocalization";
import { currentReportActions } from "../../store/currentReportSlice";
import Visualization from "../common/visualization/Visualization";
import useReportTypeChange from "./common/hooks/useReportTypeChange";

interface Props {
  report: Report;
}

const ReportTypeSelector = ({ report }: Props) => {
  const { updateReport } = useEnhancedBiApiClientProvider();
  const { report: locale } = useLocalization();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { onReportTypeChanged } = useReportTypeChange({ report });
  const dispatch = useDispatch();

  const update = useCallback(async (report: Report): Promise<Report | undefined> => {
    try {
      const response = await updateReport(report);
      if (response.success) {
        sendNotification(locale.saved);
        dispatch(currentReportActions.setReport(response.data));

        return response.data;
      }
      sendNotificationError(locale.saving_error);

      return undefined;
    } catch (error) {
      sendNotificationError(locale.saving_error);
      logError(error, "[ReportTypeSelector] update");

      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Visualization report={report} onSelect={onReportTypeChanged} update={update}>
      <Visualization.Label />
      <Visualization.ReportTypesContainer />
      <Visualization.UnsavedChangesPopover reportKind="report" />
      <Visualization.SaveReportDialog />
    </Visualization>
  );
};

export default ReportTypeSelector;
