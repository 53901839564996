import { Stack } from "@mui/material";
import { useCallback, useMemo } from "react";
import {
  PivotFieldConfiguration,
  TabularFieldType,
  UserMeasureConfigurations,
} from "../../../../../shared/reporting/api/biClient.types";
import MeasureFieldsContainer from "../../common/fields/format/MeasureFieldsContainer";
import { GeneralField, ValueField } from "../../Types";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import TextFieldContainer from "./TextFieldsContainer";
import DimensionFormattingContainer from "./DimensionFormattingContainer";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";

const FormatContainer = () => {
  const { fieldsArea } = useFieldsStateContext();

  const measures = useMemo(() => {
    return fieldsArea.values
      .filter((f) => f.fieldType === TabularFieldType.Measure)
      .map((v) => v.measure)
      .filter((m): m is ValueField => !!m);
  }, [fieldsArea.values]);

  const dimensions = useMemo(() => {
    return fieldsArea.values
      .filter((f) => f.fieldType === TabularFieldType.Dimension)
      .map((v) => v.dimension)
      .filter((d): d is GeneralField => !!d);
  }, [fieldsArea.values]);

  const handleMeasureChange = useCallback(
    (measure: ValueField, changes: Partial<UserMeasureConfigurations>) => {
      const field = fieldsArea.values.find((f) => f.measure?.config.guid === measure.config.guid);
      if (field !== undefined) {
        fieldsArea.updateMeasureFieldConfig(field, changes);
      }
    },
    [fieldsArea]
  );

  const handleDimensionChange = useCallback(
    (dimension: GeneralField, changes: Partial<PivotFieldConfiguration>) => {
      const field = fieldsArea.values.find((f) => f.dimension?.meta.name === dimension.meta.name);
      if (field !== undefined) {
        fieldsArea.updateDimensionFieldConfig(field, changes);
      }
    },
    [fieldsArea]
  );

  return (
    <ScrollableFlexContainer scrollContainerSx={{ px: "1.3rem" }}>
      <Stack gap={1}>
        <TextFieldContainer fields={dimensions} onUpdateConfig={handleDimensionChange} />
        <DimensionFormattingContainer dimensions={dimensions} onUpdateConfig={handleDimensionChange} />
        <MeasureFieldsContainer measures={measures} onChange={handleMeasureChange} />
      </Stack>
    </ScrollableFlexContainer>
  );
};

export default FormatContainer;
