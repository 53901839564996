import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./RootState";
import { MeasureDatasetsState } from "./MeasureDatasetsState";

const initialState: MeasureDatasetsState = {
  datasets: [],
  loaded: false,
  loading: false,
};

export const measureDatasetsSlice = createSlice({
  name: "measureDatasets",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<MeasureDatasetsState>>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { update } = measureDatasetsSlice.actions;

export const selectMeasureDatasets = (state: RootState) => state.measureDatasets;

export default measureDatasetsSlice.reducer;
