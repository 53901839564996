import axios from "axios";
import { clientCodeHeader } from "../../api/clientApiContext";
import { ApiResponse } from "../../api/types";
import { AnyObject } from "../../types";
import { getBackendBaseUrl } from "../../variables";
import {
  AddReportGroupRequest,
  AddReportTemplateRequest,
  AddReportTemplateResponse,
  ChangeReportGroupRequest,
  DeleteReportTemplatesRequest,
  DeleteReportsRequest,
  TemplateInfoFieldsResponse,
  GetReportTemplateResponse,
  GetReportTemplatesResponse,
  GetTemplateFieldsDataRequest,
  NameValidationResponse,
  RenameReportRequest,
  ReorderReportGroupsRequest,
  ReplaceReportGroupRequest,
  ReportGroup,
  ReportGroupsResponse,
  ReportResponse,
  TemplateNameValidationResponse,
  UpdateReportGroupRequest,
  UpdateReportTemplateDataSourceRequest,
  UpdateReportTemplateDataSourceResponse,
  UpdateReportTemplateRequest,
  UpdateReportTemplateResponse,
  UpdateReportTemplateStatusRequest,
  UpdateReportTemplateStatusResponse,
  ValidateReportNameRequest,
  ValidateTemplateNameRequest,
  ClientsResponse,
  ReportTemplate,
  DuplicateTemplateRequest,
  GetReportTemplateForCompanyRequest,
  GetReportTemplateForOrganizationRequest,
  ReportInfo,
  GetReportAuthorizationResponse,
  UpdateReportAuthorizationRequest,
  ReportAuthorizationInfo,
  ReportUsage,
} from "./biClient.types";

export const uiReports = "UiReports";
export const apiReports = "ApiReports";
const ReportTemplateGroups = "ReportTemplateGroups";

const baseUri = getBackendBaseUrl("reporting");

const getReportGroupApi = (endpoint: typeof uiReports | typeof ReportTemplateGroups) => {
  const addReportGroup = async (request: AddReportGroupRequest) => {
    const { data } = await axios.post<ApiResponse<ReportGroup>>(`${baseUri}/${endpoint}/groups`, request, {
      headers: { ...clientCodeHeader() },
    });
    return data;
  };

  const updateReportGroup = async (request: UpdateReportGroupRequest) => {
    const { data } = await axios.put<ApiResponse<ReportGroup>>(`${baseUri}/${endpoint}/groups`, request, {
      headers: { ...clientCodeHeader() },
    });
    return data;
  };

  const deleteReportGroup = async (clientCode: string, groupId: string) => {
    const { data } = await axios.delete<ApiResponse<ReportGroup>>(
      `${baseUri}/${endpoint}/groups/${clientCode}/${groupId}`,
      {
        headers: { ...clientCodeHeader() },
      }
    );
    return data;
  };

  const replaceReportGroup = async (request: ReplaceReportGroupRequest) => {
    const { data } = await axios.put<ApiResponse<AnyObject>>(`${baseUri}/${endpoint}/groups/replace`, request, {
      headers: { ...clientCodeHeader() },
    });
    return data;
  };

  const reorderReportGroups = async (request: ReorderReportGroupsRequest) => {
    const { data } = await axios.put<ApiResponse<ReportGroupsResponse>>(
      `${baseUri}/${endpoint}/groups/reorder`,
      request,
      {
        headers: { ...clientCodeHeader() },
      }
    );
    return data;
  };

  const getReportGroups = async (clientCode: string) => {
    const { data } = await axios.get<ApiResponse<ReportGroupsResponse>>(`${baseUri}/${endpoint}/groups/${clientCode}`, {
      headers: { ...clientCodeHeader() },
    });
    return data;
  };
  return {
    addReportGroup,
    updateReportGroup,
    deleteReportGroup,
    replaceReportGroup,
    reorderReportGroups,
    getReportGroups,
  };
};

const getReportsApi = (endpoint: typeof uiReports | typeof apiReports) => {
  const getReports = async () => {
    const { data } = await axios.get<ApiResponse<ReportInfo[]>>(`${baseUri}/${endpoint}`, {
      headers: { ...clientCodeHeader() },
    });
    return data;
  };

  const renameReport = async (request: RenameReportRequest) => {
    const { data } = await axios.put<ApiResponse<ReportResponse>>(`${baseUri}/${endpoint}/rename`, request, {
      headers: { ...clientCodeHeader() },
    });
    return data;
  };

  const changeReportGroup = async (request: ChangeReportGroupRequest) => {
    const { data } = await axios.put<ApiResponse<ReportResponse>>(`${baseUri}/${endpoint}/change-group`, request, {
      headers: { ...clientCodeHeader() },
    });
    return data;
  };

  const deleteReports = async (reports: DeleteReportsRequest) => {
    const { data } = await axios.patch<ApiResponse<boolean>>(
      `${baseUri}/${endpoint}/delete-reports`,
      { reports },
      {
        headers: { ...clientCodeHeader() },
      }
    );
    return data;
  };

  const validateReportName = async (request: ValidateReportNameRequest) => {
    const { data } = await axios.post<ApiResponse<NameValidationResponse>>(
      `${baseUri}/${endpoint}/validate-report-name`,
      request,
      {
        headers: { ...clientCodeHeader() },
      }
    );
    return data;
  };

  const getReportSharing = async (reportId: string) => {
    const { data } = await axios.get<ApiResponse<GetReportAuthorizationResponse>>(
      `${baseUri}/${endpoint}/${reportId}/sharing`,
      {
        headers: { ...clientCodeHeader() },
      }
    );
    return data;
  };

  const updateReportSharing = async (reportId: string, request: UpdateReportAuthorizationRequest) => {
    const { data } = await axios.put<ApiResponse<ReportAuthorizationInfo>>(
      `${baseUri}/${endpoint}/${reportId}/sharing`,
      request,
      {
        headers: { ...clientCodeHeader() },
      }
    );
    return data;
  };

  const getReportAuthorizationInfo = async (reportId: string) => {
    const { data } = await axios.get<ApiResponse<ReportAuthorizationInfo>>(
      `${baseUri}/${endpoint}/${reportId}/sharing/authorization`,
      {
        headers: { ...clientCodeHeader() },
      }
    );
    return data;
  };

  return {
    getReports,
    renameReport,
    changeReportGroup,
    deleteReports,
    validateReportName,
    getReportSharing,
    updateReportSharing,
    getReportAuthorizationInfo,
  };
};

const validateUniqueReportNameByUsage = async (request: ValidateReportNameRequest, usage: ReportUsage) => {
  const { data } = await axios.post<ApiResponse<NameValidationResponse>>(
    `${baseUri}/${usage === "Api" ? apiReports : uiReports}/validate-report-name`,
    request,
    {
      headers: { ...clientCodeHeader() },
    }
  );
  return data;
};

const validateTemplateName = async (request: ValidateTemplateNameRequest) => {
  const { data } = await axios.post<ApiResponse<TemplateNameValidationResponse>>(
    `${baseUri}/ReportTemplates/validate-template-name`,
    request,
    {
      headers: { ...clientCodeHeader() },
    }
  );
  return data;
};

const addReportTemplate = async (request: AddReportTemplateRequest) => {
  const { data } = await axios.post<ApiResponse<AddReportTemplateResponse>>(`${baseUri}/ReportTemplates`, request, {
    headers: { ...clientCodeHeader() },
  });
  return data;
};

const getOrganizationReportTemplates = async () => {
  const { data } = await axios.get<ApiResponse<GetReportTemplatesResponse>>(`${baseUri}/ReportTemplates/organization`, {
    headers: { ...clientCodeHeader() },
  });
  return data;
};

const getCompanyReportTemplates = async () => {
  const { data } = await axios.get<ApiResponse<GetReportTemplatesResponse>>(`${baseUri}/ReportTemplates/company`, {
    headers: { ...clientCodeHeader() },
  });
  return data;
};

const getReportTemplateForCompany = async (request: GetReportTemplateForCompanyRequest) => {
  const { data } = await axios.get<ApiResponse<GetReportTemplateResponse>>(
    `${baseUri}/ReportTemplates/${request.templateId}/company`,
    {
      headers: { ...clientCodeHeader() },
    }
  );
  return data;
};

const getReportTemplateForOrganization = async (request: GetReportTemplateForOrganizationRequest) => {
  const { data } = await axios.get<ApiResponse<GetReportTemplateResponse>>(
    `${baseUri}/ReportTemplates/${request.templateId}/${request.organization}/organization`,
    {
      headers: { ...clientCodeHeader() },
    }
  );
  return data;
};

const updateReportTemplate = async (request: UpdateReportTemplateRequest) => {
  const { data } = await axios.put<ApiResponse<UpdateReportTemplateResponse>>(
    `${baseUri}/ReportTemplates/${request.template.reportId}`,
    request,
    {
      headers: { ...clientCodeHeader() },
    }
  );
  return data;
};

const updateReportTemplateStatus = async (request: UpdateReportTemplateStatusRequest) => {
  const { data } = await axios.put<ApiResponse<UpdateReportTemplateStatusResponse>>(
    `${baseUri}/ReportTemplates/${request.templateId}/status`,
    { status: request.status },
    {
      headers: { ...clientCodeHeader() },
    }
  );
  return data;
};

const updateReportTemplateDataSource = async (request: UpdateReportTemplateDataSourceRequest) => {
  const { data } = await axios.put<ApiResponse<UpdateReportTemplateDataSourceResponse>>(
    `${baseUri}/ReportTemplates/${request.templateId}/datasource`,
    { dataSource: request.dataSource },
    {
      headers: { ...clientCodeHeader() },
    }
  );
  return data;
};

const deleteReportTemplates = async (request: DeleteReportTemplatesRequest) => {
  const { data } = await axios.patch<ApiResponse<boolean>>(`${baseUri}/ReportTemplates/bulk-delete`, request, {
    headers: { ...clientCodeHeader() },
  });
  return data;
};

const getClients = async () => {
  const { data } = await axios.get<ApiResponse<ClientsResponse>>(`${baseUri}/Clients`, {
    headers: { ...clientCodeHeader() },
  });
  return data;
};

const getTemplateFieldsData = async (request: GetTemplateFieldsDataRequest) => {
  const { data } = await axios.get<ApiResponse<TemplateInfoFieldsResponse>>(
    `${baseUri}/ReportTemplates/${request.templateId}/info-fields`,
    {
      headers: { ...clientCodeHeader() },
    }
  );
  return data;
};

const duplicateReportTemplate = async (request: DuplicateTemplateRequest) => {
  const { data } = await axios.post<ApiResponse<ReportTemplate | undefined>>(
    `${baseUri}/ReportTemplates/duplicate/${request.templateId}`,
    { name: request.name, groupId: request.groupId, organization: request.organization },
    {
      headers: { ...clientCodeHeader() },
    }
  );
  return data;
};

const biClient = {
  reportGroup: getReportGroupApi(uiReports),
  reportTemplateGroup: getReportGroupApi(ReportTemplateGroups),
  addReportTemplate,
  getOrganizationReportTemplates,
  getCompanyReportTemplates,
  getReportTemplateForCompany,
  getReportTemplateForOrganization,
  updateReportTemplateStatus,
  deleteReportTemplates,
  validateTemplateName,
  updateReportTemplate,
  updateReportTemplateDataSource,
  getClients,
  getTemplateFieldsData,
  duplicateReportTemplate,
  validateUniqueReportNameByUsage,
  uiReports: getReportsApi(uiReports),
  apiReports: getReportsApi(apiReports),
} as const;

export default biClient;
