import { lighten } from "@mui/material";

export const getColorBrightness = (colorHex: string): number => {
  let hex = colorHex;
  if (colorHex.length === 4) {
    const [s, rr, gg, bb] = colorHex;
    hex = `${s}${rr}${rr}${gg}${gg}${bb}${bb}`;
  }

  if (hex.length !== 7) {
    return NaN;
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return (0.3 * r + 0.5 * g + 0.2 * b) / 255;
};

const validHexColor = (hexStr: string) => {
  return /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexStr);
};

export const rgbToHex = (r: number, g: number, b: number): string => {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export const hexStringToRgb = (hexStr: string): [number, number, number] => {
  let hex = hexStr;

  if (hexStr.length === 4) {
    hex = hexStr.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_, r, g, b) => r + r + g + g + b + b);
  }

  const validHEX = validHexColor(hex);
  if (!validHEX) {
    return [-1, -1, -1];
  }

  const r = validHEX[1];
  const g = validHEX[2];
  const b = validHEX[3];

  return r && g && b ? [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)] : [-1, -1, -1];
};

export const isValidHexColor = (color: string): boolean => {
  const validHex = validHexColor(color);
  return !!(validHex && validHex[1] && validHex[2] && validHex[3]);
};

export const makeLighterBackgroundFromColor = (color: string) => lighten(color, 0.9);
