import { Box, Divider, Grid } from "@mui/material";
import React, { Fragment } from "react";
import ChangeSizeButton from "../../../../../../shared/components/ChangeSizeButton";
import TooltipWrapper from "../../../../../../shared/components/TooltipWrapper";
import { ConditionDescriptor, ConditionEqualityType } from "../../../../../../shared/reporting/api/biClient.types";
import { DimensionDescriptor, DimensionDictionary, ItemDataType } from "../../../../../api/biApi.types";
import { useLocalization } from "../../../../../hooks/useLocalization";
import FieldOptionDeleteButton from "../../../../common/FieldOptionDeleteButton";
import CustomLabel from "../../../../common/customLabel/CustomLabel";
import { ConditionField } from "../../../Types";
import FieldPopover from "../../../common/fields/FieldPopover";
import DateFilter from "./DateFilter";
import TextFilterCondition from "./TextFilterCondition";
import { getCrossFilterDictionary, isCrossFilterToBeRefreshed } from "./conditionsHelper";
import SwitchField from "../../../../common/SwitchField";

interface Props {
  anchorEl: HTMLElement;
  field: ConditionField;
  showParameter?: boolean;
  readonlyCustomLabel?: boolean;
  showOnlySelectedByDefault?: boolean;
  readonly?: boolean;
  canBeRemoved?: boolean;
  resizable?: boolean;
  dictionary: DimensionDictionary[];
  disableEqualitySelection?: boolean;
  updateParameter?: (field: ConditionField, newValue: boolean) => void;
  updateMandatory?: (field: ConditionField, newValue: boolean) => void;
  updateCustomLabel?: (customLabel: string | undefined) => void;
  save: (filter: ConditionDescriptor) => void;
  onRemoveItem?: (item: ConditionField) => void;
  close: () => void;
}

export const ConditionFieldOptionPopup = (props: Props) => {
  const {
    anchorEl,
    field,
    showParameter,
    readonlyCustomLabel,
    showOnlySelectedByDefault,
    readonly,
    canBeRemoved,
    resizable,
    dictionary,
    disableEqualitySelection,
    updateParameter,
    updateMandatory,
    updateCustomLabel,
    save,
    onRemoveItem,
    close,
  } = props;
  const { report: locale } = useLocalization();

  const [newFilter, setNewFilter] = React.useState<ConditionDescriptor>(
    field.config.filter || { dimensionName: field.meta.name, values: [], equalityType: ConditionEqualityType.Equal }
  );
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [editing, setIsEditing] = React.useState(false);

  const meta = React.useMemo(() => {
    return { ...field.meta, dictionary: dictionary } as DimensionDescriptor;
  }, [dictionary, field.meta]);

  const onBeforeClose = React.useCallback(() => {
    if (isCrossFilterToBeRefreshed(field)) {
      close();
      return;
    }
    if (field.meta.type === ItemDataType.General) {
      const dictionary = getCrossFilterDictionary(field, meta || field.meta);
      newFilter.values = newFilter.values.filter((value) =>
        dictionary.some((row) => row[field.meta.keyFieldName] === value)
      );
    }
    save(newFilter);
  }, [field, meta, newFilter, save, close]);

  const updateAsParameter = React.useCallback(
    (useAsParameter: boolean) => {
      updateParameter?.call(this, field, useAsParameter);
    },
    [field, updateParameter]
  );

  const updateAsMandatory = React.useCallback(
    (useAsMandatory: boolean) => {
      updateMandatory?.call(this, field, useAsMandatory);
    },
    [field, updateMandatory]
  );

  const handleOnRemove = React.useCallback(() => {
    onRemoveItem?.call(null, field);
  }, [field, onRemoveItem]);

  return (
    <FieldPopover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => onBeforeClose()}
      sx={{ display: "flex", flexDirection: "column", p: 0, ...getSizeParameters(isExpanded) }}
    >
      <CustomLabel
        customLabelValue={field.config.customLabel}
        originalName={field.meta.caption}
        updateCustomLabel={updateCustomLabel}
        readonly={readonlyCustomLabel || false}
        onEditMode={setIsEditing}
        sx={{ gap: 0.5 }}
      >
        {resizable && !editing && (
          <TooltipWrapper key={+isExpanded} title={isExpanded ? "Collapse" : "Expand"} placement="bottom">
            <Box sx={{ display: "flex", marginLeft: "auto" }}>
              <ChangeSizeButton isExpanded={isExpanded} toggle={() => setIsExpanded((prev) => !prev)} />
            </Box>
          </TooltipWrapper>
        )}
      </CustomLabel>
      <Divider />
      {field.meta.type === ItemDataType.Date ? (
        <DateFilter
          field={field}
          onFilterUpdated={setNewFilter}
          sx={{ width: "100%" }}
          disableEqualitySelection={disableEqualitySelection}
        />
      ) : (
        <Grid container sx={{ display: "flex", flexDirection: "column", width: "100%", flex: 1 }}>
          <Grid container sx={{ display: "flex", flex: 1, p: "1rem" }}>
            <TextFilterCondition
              field={field}
              meta={meta}
              readonly={readonly}
              showOnlySelectedByDefault={showOnlySelectedByDefault}
              onFilterUpdated={setNewFilter}
            />
          </Grid>
        </Grid>
      )}
      {showParameter && [
        <Divider key="dividerTop" />,
        <Grid key="menu-items" container py={0.5} flexDirection={"column"}>
          <SwitchField
            key="parameter"
            label="Set as Parameter"
            checked={field.config.parameter || false}
            tooltipText={locale.set_parameter_info}
            onChange={() => updateAsParameter(!field.config.parameter)}
            containerSx={{ px: 2, py: 0.75 }}
          />
          {field.config.parameter && (
            <SwitchField
              key="mandatory"
              label="Set as Mandatory"
              checked={field.config.mandatory || false}
              onChange={() => updateAsMandatory(!field.config.mandatory)}
              containerSx={{ px: 2, py: 0.75 }}
            />
          )}
        </Grid>,
      ]}
      {canBeRemoved && !readonly && (
        <Fragment key={"RemoveItem"}>
          <Divider sx={{ m: 0 }} />
          <FieldOptionDeleteButton onRemove={handleOnRemove} />
        </Fragment>
      )}
    </FieldPopover>
  );
};

export default ConditionFieldOptionPopup;

const expandedSize = {
  width: "600px",
  height: "660px",
};
const collapsedSize = {
  width: "370px",
  height: "auto",
};

function getSizeParameters(isExpanded: boolean) {
  return isExpanded ? expandedSize : collapsedSize;
}
