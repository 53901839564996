import { SvgIcon, SvgIconProps } from "@mui/material";

export const PieChartIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M17.8015 10.7927L11.7641 10.2139C11.5002 10.1886 11.2645 10.3831 11.2392 10.6469C11.2264 10.7812 11.2719 10.9077 11.3525 11.0036L15.4615 14.9677C15.5398 15.0701 15.6603 15.1411 15.7981 15.1543C15.9644 15.1703 16.1186 15.1002 16.2166 14.9808C17.3781 13.8677 17.9327 13.086 18.2162 11.4022C18.2257 11.3742 18.2318 11.346 18.2345 11.3174C18.2599 11.0537 18.067 10.8181 17.8015 10.7927Z" />
      <path d="M11.0439 2.91992C10.7788 2.92143 10.5645 3.12072 10.5645 3.36699L10.6076 9.01476C10.6076 9.26264 10.8219 9.46344 11.087 9.46344L17.5732 9.44579C17.8382 9.44579 18.0542 9.245 18.0542 8.99712C17.8162 5.39229 14.9246 3.14115 11.0439 2.91992Z" />
      <path d="M14.4935 15.7888L9.52052 10.8159C9.43614 10.7298 9.38553 10.6099 9.38553 10.4799L9.38374 3.45597C9.38374 3.19096 9.1694 2.97656 8.90434 2.97656C4.86487 3.22304 1.66602 6.57882 1.66602 10.6792C1.66602 14.9415 5.12151 18.3986 9.38374 18.3986C11.3284 18.3986 13.1042 17.6795 14.4614 16.4927C14.5661 16.4049 14.6319 16.2733 14.6319 16.1264C14.6319 15.9964 14.5795 15.8766 14.4935 15.7888Z" />
    </SvgIcon>
  );
};
export default PieChartIcon;
