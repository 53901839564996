import PromptDialog from "../../../../shared/components/dialog/PromptDialog";
import SaveReportFormWrapper from "../../../../shared/reporting/components/reports/SaveReportFormWrapper";
import useSaveReportDialog from "../common/hooks/useSaveReportDialog";
import { SaveReportDialogProps } from "../Types";

const SaveReportDialog = ({
  report,
  open,
  title,
  saveBtnTitle,
  reportConfiguration,
  onClose,
}: SaveReportDialogProps) => {
  const { error, onSaveReport, saving } = useSaveReportDialog({
    report,
    reportConfiguration,
    onClose,
  });

  return (
    <PromptDialog open={open}>
      <SaveReportFormWrapper
        report={report}
        error={error}
        title={title}
        saveBtnTitle={saveBtnTitle}
        showName
        saving={saving}
        onClose={onClose}
        onSave={(name) => onSaveReport(name, undefined)}
      />
    </PromptDialog>
  );
};

export default SaveReportDialog;
