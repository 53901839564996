import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import FunctionIcon from "../../../../../shared/reporting/icons/FunctionIcon";
import HashIcon from "../../../../../shared/reporting/icons/HashIcon";
import SigmaIcon from "../../../../../shared/reporting/icons/SigmaIcon";
import { MetaDescriptorBase, MetaIcons } from "../../../../api/biApi.types";

export const getIcon = (fieldBase: MetaDescriptorBase) => {
  switch (fieldBase.icon) {
    case MetaIcons.Hash:
      return <HashIcon />;
    case MetaIcons.Calendar:
      return <CalendarTodayRoundedIcon />;
    case MetaIcons.Sigma:
      return <SigmaIcon />;
    case MetaIcons.Function:
      return <FunctionIcon />;
    default:
      return <SigmaIcon />;
  }
};
