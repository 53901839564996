import { GridTreeNode } from "@mui/x-data-grid-premium";

export const isAutoGeneratedRowNode = (rowNode: GridTreeNode) =>
  (rowNode.type === "group" || rowNode.type === "pinnedRow") && rowNode.isAutoGenerated;

export const isPinnedRowNode = (rowNode: GridTreeNode) =>
  rowNode.type === "pinnedRow" || (rowNode.type === "group" && rowNode.isPinned);

export const isGroupOrPinnedRowNodeType = (rowNode: GridTreeNode) =>
  rowNode.type === "group" || rowNode.type === "pinnedRow";
