import { AnyObject } from "../../../../../shared/types";
import { PivotRowDimensionCell } from "../../../../api/biApi.types";
import { GeneralField } from "../../Types";

export default function formatArgumentLabel(fields: GeneralField[], value: string, data: AnyObject[]): string {
  const fieldId = fields[0]?.config.name;
  if (fieldId !== undefined) {
    const cell = data.find((c) => {
      const rowCell = c[fieldId] as PivotRowDimensionCell;
      return rowCell?.value === value;
    });
    if (cell !== undefined) {
      const rowCell = cell[fieldId] as PivotRowDimensionCell;
      return rowCell?.formattedValues.join(" ");
    }
  }
  return value;
}
