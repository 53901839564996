import { ComponentType, useLayoutEffect } from "react";
import { useExportStateContext } from "../../../common/contexts/ExportStateContext";
import { selectCurrentReport } from "../../../../../store/currentReportSlice";
import { useSelector } from "react-redux";
import { useTabularExport } from "../../hooks/useTabularExport";
import { PreviewGridStateType } from "../../hooks/useGridStateBuilder";
import useUpdateExportState from "../hooks/useUpdateExportState";
import ExportMenuItem from "../../../common/exports/ExportMenuItem";
import ExcelIcon from "../../../../../icons/ExcelIcon";

interface Props {
  state: PreviewGridStateType;
}

export default function withExport<T extends Props>(WrappedComponent: ComponentType<T>) {
  return function useExport(props: T) {
    const { state } = props;
    const { requestExport, requestExportWithParameters } = useTabularExport();
    const exportState = useExportStateContext();
    const report = useSelector(selectCurrentReport);

    useUpdateExportState(state);

    useLayoutEffect(() => {
      let cancelled = false;
      exportState.actions.setExportMenuCallback((renderMenuCallback: () => void) => [
        <ExportMenuItem
          key={"withoutParameters"}
          title="Excel"
          Icon={<ExcelIcon />}
          onClick={() => {
            if (cancelled) return;
            requestExport();
            renderMenuCallback();
          }}
        />,
        <ExportMenuItem
          key={"withParameters"}
          title="Excel including Parameters"
          Icon={<ExcelIcon />}
          onClick={() => {
            if (cancelled) return;
            requestExportWithParameters();
            renderMenuCallback();
          }}
        />,
      ]);
      return () => {
        cancelled = true;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report?.name, requestExport, requestExportWithParameters]);

    return <WrappedComponent {...props} />;
  };
}
