import { SvgIcon, SvgIconProps } from "@mui/material";

export const NumberIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 18, height: 10 }} viewBox="0 0 18 10" {...props}>
      <path d="M2.33366 9.16683V2.50016H0.666992V0.833496H4.00033V9.16683H2.33366ZM17.3337 7.50016C17.3337 8.42516 16.5837 9.16683 15.667 9.16683H12.3337V7.50016H15.667V5.8335H14.0003V4.16683H15.667V2.50016H12.3337V0.833496H15.667C16.109 0.833496 16.5329 1.00909 16.8455 1.32165C17.1581 1.63421 17.3337 2.05814 17.3337 2.50016V3.75016C17.3337 4.08168 17.202 4.39963 16.9675 4.63405C16.7331 4.86847 16.4152 5.00016 16.0837 5.00016C16.4152 5.00016 16.7331 5.13186 16.9675 5.36628C17.202 5.6007 17.3337 5.91864 17.3337 6.25016V7.50016ZM10.667 7.50016V9.16683H5.66699V5.8335C5.66699 4.9085 6.41699 4.16683 7.33366 4.16683H9.00033V2.50016H5.66699V0.833496H9.00033C9.44235 0.833496 9.86628 1.00909 10.1788 1.32165C10.4914 1.63421 10.667 2.05814 10.667 2.50016V4.16683C10.667 5.09183 9.91699 5.8335 9.00033 5.8335H7.33366V7.50016H10.667Z" />
    </SvgIcon>
  );
};
export default NumberIcon;
