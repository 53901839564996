import React from "react";
import { useUserContext } from "../../../../contexts/UserContext";
import { useClientContext } from "../../../../contexts/ClientContext";

export default function useEntityLevelAccess() {
  const { entityLevelAccess } = useUserContext();
  const { clientCode } = useClientContext();

  const isLevelAccessApplied = React.useMemo(
    () => entityLevelAccess[clientCode] === true,
    [entityLevelAccess, clientCode]
  );

  return isLevelAccessApplied;
}
