import { Stack } from "@mui/material";
import { ReportType } from "../../../../shared/reporting/api/biClient.types";
import AreaChartIcon from "../../../../shared/reporting/icons/reportTypes/AreaChartIcon";
import BarChartIcon from "../../../../shared/reporting/icons/reportTypes/BarChartIcon";
import DoughnutChartIcon from "../../../../shared/reporting/icons/reportTypes/DoughnutChartIcon";
import LineChartIcon from "../../../../shared/reporting/icons/reportTypes/LineChartIcon";
import PieChartIcon from "../../../../shared/reporting/icons/reportTypes/PieChartIcon";
import PivotIcon from "../../../../shared/reporting/icons/reportTypes/PivotIcon";
import TabularIcon from "../../../../shared/reporting/icons/reportTypes/TabularIcon";
import { useVisualizationContext } from "./context/VisualizationContext";
import ReportTypeBadge from "./ReportTypeBadge";

const ReportTypesContainer = () => {
  const { isReportChanged, onReportTypeClicked, report } = useVisualizationContext();
  const selectedReportType = report.reportType || ReportType.Tabular;

  return (
    <Stack flexDirection="row">
      <ReportTypeBadge
        reportType={ReportType.Tabular}
        isReportChanged={isReportChanged}
        onSelected={onReportTypeClicked}
        tooltipText="Tabular Report"
        ReactTypeIcon={TabularIcon}
        selectedReportType={selectedReportType}
      />
      <ReportTypeBadge
        reportType={ReportType.Pivot}
        isReportChanged={isReportChanged}
        onSelected={onReportTypeClicked}
        tooltipText="Pivot Report"
        ReactTypeIcon={PivotIcon}
        selectedReportType={selectedReportType}
      />
      <ReportTypeBadge
        reportType={ReportType.BarChart}
        isReportChanged={isReportChanged}
        onSelected={onReportTypeClicked}
        tooltipText="Bar Chart Report"
        ReactTypeIcon={BarChartIcon}
        selectedReportType={selectedReportType}
      />
      <ReportTypeBadge
        reportType={ReportType.LineChart}
        isReportChanged={isReportChanged}
        onSelected={onReportTypeClicked}
        tooltipText="Line Chart Report"
        ReactTypeIcon={LineChartIcon}
        selectedReportType={selectedReportType}
      />
      <ReportTypeBadge
        reportType={ReportType.AreaChart}
        isReportChanged={isReportChanged}
        onSelected={onReportTypeClicked}
        tooltipText="Area Chart Report"
        ReactTypeIcon={AreaChartIcon}
        selectedReportType={selectedReportType}
      />
      <ReportTypeBadge
        reportType={ReportType.PieChart}
        isReportChanged={isReportChanged}
        onSelected={onReportTypeClicked}
        tooltipText="Pie Chart Report"
        ReactTypeIcon={PieChartIcon}
        selectedReportType={selectedReportType}
      />
      <ReportTypeBadge
        reportType={ReportType.DoughnutChart}
        isReportChanged={isReportChanged}
        onSelected={onReportTypeClicked}
        tooltipText="Doughnut Chart Report"
        ReactTypeIcon={DoughnutChartIcon}
        selectedReportType={selectedReportType}
      />
    </Stack>
  );
};

export default ReportTypesContainer;
