import { SvgIcon, SvgIconProps } from "@mui/material";

const CollapseAllIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <mask id="mask0_3429_210757" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3429_210757)">
        <path d="M9.41722 11.9167L7.25055 14.0833C6.98666 14.3472 6.92763 14.6493 7.07347 14.9896C7.2193 15.3299 7.47972 15.5 7.85472 15.5H12.1464C12.5214 15.5 12.7818 15.3299 12.9276 14.9896C13.0735 14.6493 13.0144 14.3472 12.7505 14.0833L10.5839 11.9167C10.5005 11.8333 10.4103 11.7708 10.313 11.7292C10.2158 11.6875 10.1117 11.6667 10.0005 11.6667C9.88944 11.6667 9.78527 11.6875 9.68805 11.7292C9.59083 11.7708 9.50055 11.8333 9.41722 11.9167Z" />
        <path d="M10.5838 7.75033L12.7504 5.58366C13.0143 5.31977 13.0733 5.01769 12.9275 4.67741C12.7817 4.33713 12.5213 4.16699 12.1463 4.16699H7.85459C7.47959 4.16699 7.21918 4.33713 7.07334 4.67741C6.92751 5.01769 6.98654 5.31977 7.25043 5.58366L9.41709 7.75033C9.50043 7.83366 9.59071 7.89616 9.68793 7.93783C9.78515 7.97949 9.88932 8.00033 10.0004 8.00033C10.1115 8.00033 10.2157 7.97949 10.3129 7.93783C10.4101 7.89616 10.5004 7.83366 10.5838 7.75033Z" />
      </g>
    </SvgIcon>
  );
};

export default CollapseAllIcon;
