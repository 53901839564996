import { createContext, useContext } from "react";
import { ApiResponse } from "../../api/types";
import {
  GetReportAuthorizationResponse,
  NameValidationResponse,
  ReportAuthorizationInfo,
  UpdateReportAuthorizationRequest,
  ValidateReportNameRequest,
} from "../api/biClient.types";
import { defined } from "../../utilities/typeHelper";

export interface BiApiClientBase {
  validateName: (request: ValidateReportNameRequest) => Promise<ApiResponse<NameValidationResponse>>;
  getReportSharing: (reportId: string) => Promise<ApiResponse<GetReportAuthorizationResponse>>;
  updateReportSharing: (
    reportId: string,
    request: UpdateReportAuthorizationRequest
  ) => Promise<ApiResponse<ReportAuthorizationInfo>>;
}

const BiApiClientContext = createContext<BiApiClientBase | undefined>(undefined);

export const BiApiClientProvider = <T extends BiApiClientBase>({
  api,
  children,
}: {
  api: T;
  children: React.ReactNode;
}) => <BiApiClientContext.Provider value={api}>{children}</BiApiClientContext.Provider>;

export const useBiApiClientProvider = <T extends BiApiClientBase = BiApiClientBase>(): T => {
  const client = useContext(BiApiClientContext) as T | undefined;
  return defined(client);
};
