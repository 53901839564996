import { useCallback, useMemo } from "react";
import { useFieldsStateContext } from "../../tabular/contexts/FieldsStateContext";
import useGridStateViewer from "../../tabular/hooks/useGridStateViewer";
import { TabularFieldType, UserMeasureConfigurations } from "../../../../../shared/reporting/api/biClient.types";
import { isValueFieldHasParameters } from "../../common/utilities/valueFieldUtils";
import { ValueField } from "../../Types";
import ViewerParameters from "../../common/ViewerParameters";
import PreviewActions from "../../common/PreviewActions";
import PreviewComponent from "../../tabular/preview/PreviewComponent";
import ViewContainer from "./ViewContainer";

const ReportViewer = () => {
  const state = useGridStateViewer();

  const { conditionsArea, fieldsArea } = useFieldsStateContext();

  const fields = useMemo(
    () => conditionsArea.values.filter((v) => v.config.parameter === true),
    [conditionsArea.values]
  );

  const measuresToShow = useMemo(() => {
    return fieldsArea.values
      .filter((v) => v.fieldType === TabularFieldType.Measure && v.measure !== undefined)
      .map((f) => f.measure)
      .filter(isValueFieldHasParameters)
      .filter((m): m is ValueField => !!m);
  }, [fieldsArea.values]);

  const showParameters = useMemo(() => {
    return fields.length > 0 || measuresToShow.length > 0;
  }, [fields, measuresToShow]);

  const updateMeasure = useCallback(
    (field: ValueField, change: Partial<UserMeasureConfigurations>) => {
      const tableField = fieldsArea.values.find((v) => v.measure === field);
      if (tableField !== undefined) {
        fieldsArea.updateMeasureFieldConfig(tableField, change);
      }
    },
    [fieldsArea]
  );

  return (
    <ViewContainer
      parameters={
        showParameters ? (
          <ViewerParameters
            conditionsArea={conditionsArea}
            measuresWithConditions={measuresToShow}
            updateMeasure={updateMeasure}
          />
        ) : (
          <></>
        )
      }
      preview={<PreviewComponent state={state} />}
      actionPanel={<PreviewActions />}
      isLoading={state.loading}
    />
  );
};

export default ReportViewer;
