import { SvgIcon, SvgIconProps } from "@mui/material";

export const PercentageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 14, height: 14 }} viewBox="0 0 14 14" {...props}>
      <path d="M3.25 6.16683C2.44445 6.16683 1.75695 5.88211 1.1875 5.31266C0.618056 4.74322 0.333334 4.05572 0.333334 3.25016C0.333334 2.44461 0.618056 1.75711 1.1875 1.18766C1.75695 0.618218 2.44445 0.333496 3.25 0.333496C4.05556 0.333496 4.74306 0.618218 5.3125 1.18766C5.88195 1.75711 6.16667 2.44461 6.16667 3.25016C6.16667 4.05572 5.88195 4.74322 5.3125 5.31266C4.74306 5.88211 4.05556 6.16683 3.25 6.16683ZM3.25 4.50016C3.59722 4.50016 3.89222 4.3785 4.135 4.13516C4.37833 3.89239 4.5 3.59738 4.5 3.25016C4.5 2.90294 4.37833 2.60794 4.135 2.36516C3.89222 2.12183 3.59722 2.00016 3.25 2.00016C2.90278 2.00016 2.60778 2.12183 2.365 2.36516C2.12167 2.60794 2 2.90294 2 3.25016C2 3.59738 2.12167 3.89239 2.365 4.13516C2.60778 4.3785 2.90278 4.50016 3.25 4.50016ZM10.75 13.6668C9.94444 13.6668 9.25694 13.3821 8.6875 12.8127C8.11806 12.2432 7.83333 11.5557 7.83333 10.7502C7.83333 9.94461 8.11806 9.25711 8.6875 8.68766C9.25694 8.11822 9.94444 7.8335 10.75 7.8335C11.5556 7.8335 12.2431 8.11822 12.8125 8.68766C13.3819 9.25711 13.6667 9.94461 13.6667 10.7502C13.6667 11.5557 13.3819 12.2432 12.8125 12.8127C12.2431 13.3821 11.5556 13.6668 10.75 13.6668ZM10.75 12.0002C11.0972 12.0002 11.3922 11.8785 11.635 11.6352C11.8783 11.3924 12 11.0974 12 10.7502C12 10.4029 11.8783 10.1079 11.635 9.86516C11.3922 9.62183 11.0972 9.50016 10.75 9.50016C10.4028 9.50016 10.1078 9.62183 9.865 9.86516C9.62167 10.1079 9.5 10.4029 9.5 10.7502C9.5 11.0974 9.62167 11.3924 9.865 11.6352C10.1078 11.8785 10.4028 12.0002 10.75 12.0002ZM0.916667 13.0835C0.763889 12.9307 0.687501 12.7363 0.687501 12.5002C0.687501 12.2641 0.763889 12.0696 0.916667 11.9168L11.9167 0.916829C12.0694 0.764051 12.2639 0.687663 12.5 0.687663C12.7361 0.687663 12.9306 0.764051 13.0833 0.916829C13.2361 1.06961 13.3125 1.26405 13.3125 1.50016C13.3125 1.73627 13.2361 1.93072 13.0833 2.0835L2.08333 13.0835C1.93056 13.2363 1.73611 13.3127 1.5 13.3127C1.26389 13.3127 1.06944 13.2363 0.916667 13.0835Z" />
    </SvgIcon>
  );
};
export default PercentageIcon;
