import { Box } from "@mui/material";

export const ConditionMarker = () => {
  return (
    <Box
      sx={(theme) => ({
        left: 0,
        position: "absolute",
        width: "2px",
        height: "30px",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "2px",
      })}
    ></Box>
  );
};
export default ConditionMarker;
