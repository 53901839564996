import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { MenuItemStyled } from "../../../shared/components/MenuItemStyled";

interface Props {
  onRemove: () => void;
}
export default function FieldOptionDeleteButton({ onRemove }: Props) {
  return (
    <MenuItemStyled
      sx={(theme) => ({
        justifyContent: "center",
        gap: 1,
        bgcolor: theme.palette.background.grey,
        borderBottomLeftRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
        color: theme.palette.error.main,
        height: theme.spacing(4.5),
      })}
      onClick={onRemove}
    >
      <CloseIcon sx={(theme) => ({ fontSize: theme.spacing(2) })} />
      <Typography sx={{ fontWeight: 500 }}>Remove Field</Typography>
    </MenuItemStyled>
  );
}
