import { Report } from "../../../../shared/reporting/api/biClient.types";
import TabularContainer from "./TabularContainer";
import TabularViewer from "./TabularViewer";
interface Props {
  report: Report;
}
export default function TabularViewerContainer({ report }: Props) {
  return (
    <TabularContainer report={report}>
      <TabularViewer></TabularViewer>
    </TabularContainer>
  );
}
