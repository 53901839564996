import { Box, LinearProgress, Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { selectCurrentBuildProgress } from "../../store/currentReportSlice";

const BuildProgressWrapper = ({ children }: PropsWithChildren) => {
  const buildProgress = useSelector(selectCurrentBuildProgress);

  return (
    <Stack width={"100%"} height={"100%"}>
      <Box visibility={buildProgress === undefined ? "hidden" : "visible"}>
        {
          <LinearProgress
            variant="determinate"
            value={buildProgress || 0}
            sx={{
              height: "2px",
              background: "none",
              opacity: 0.6,
              ...(buildProgress === undefined && {
                "& .MuiLinearProgress-bar": {
                  transition: "none",
                },
              }),
            }}
          />
        }
      </Box>
      {children}
    </Stack>
  );
};

export default BuildProgressWrapper;
