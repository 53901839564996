import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { ConditionDescriptor } from "../../../../../shared/reporting/api/biClient.types";
import { ConditionField } from "../../Types";

export const getValidConditionDescriptors = (
  conditions: ConditionField[],
  withLinked = false
): ConditionDescriptor[] => {
  return getValidConditions(conditions, withLinked)
    .map((vc) => vc.config.filter)
    .filter((descriptor): descriptor is ConditionDescriptor => !!descriptor);
};

export const getValidConditions = (conditions: ConditionField[], withLinked = false): ConditionField[] => {
  return conditions
    .filter((c) => {
      if (c.invalid) return false;
      if (!withLinked && c.hasLinks) return false;
      if (!c.config?.filter || !c.config.filter?.values || c.config.filter?.values.length === 0) return false;
      return true;
    })
    .filter((f): f is ConditionField => !!f)
    .map((f) => cloneDeep(f));
};
