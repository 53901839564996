import { Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { MutableRefObject } from "react";
import { Grouping, TabularDataCell } from "../../../../../../shared/reporting/api/biClient.types";
import { ItemDataType, TabularRow } from "../../../../../api/biApi.types";
import { DataGridTooltipWrapper } from "../../../common/DataGridTooltipWrapper";
import DimensionBooleanValue from "../../../common/DimensionBooleanValue";
import DimensionStartIcon from "../../../common/DimensionStartIcon";

export type DimensionColumnField = {
  title: string;
  guid: string;
  fieldType: ItemDataType | undefined | null;
  groupingDateBy?: Grouping | undefined | null;
};

export function buildDimensionColumn(
  api: MutableRefObject<GridApiPremium>,
  field: DimensionColumnField,
  getFieldTitle: (guid: string) => string
) {
  return {
    field: field.guid,
    headerName: field.title,
    minWidth: 175,
    groupable: field.fieldType === ItemDataType.General || field.fieldType === ItemDataType.Date,
    aggregable: false,
    type: "string",
    align: field.fieldType === ItemDataType.Numeric ? "right" : "left",
    headerAlign: field.fieldType === ItemDataType.Numeric ? "right" : "left",
    filterable: false,
    hideable: false,
    pinnable: false,
    renderHeader: () => <Typography variant="subtitle2">{getFieldTitle(field.guid)}</Typography>,
    sortComparator: (v1: TabularDataCell, v2: TabularDataCell, cell1, cell2) => {
      const isGroupRow = cell1.rowNode.type === "group";

      if (isGroupRow && !!api.current?.getRowNode) {
        const cell1value = api.current.getRowNode(cell1.id);
        const cell2value = api.current.getRowNode(cell2.id);

        if (cell1value?.type === "group" && cell2value?.type === "group") {
          const comparisonResult =
            cell1value.groupingKey?.toString()?.localeCompare(cell2value.groupingKey?.toString() || "") ?? 0;

          if (!isTopLevelGroup(cell1value.depth)) {
            const sortModel = api.current.getSortModel();
            const currentGroupSorting =
              sortModel.find((sort) => sort.field === cell1value.groupingField)?.sort ?? "asc";
            const topLevelGroupSorting = sortModel.find((sort) => sort.field === field.guid)?.sort ?? "asc";

            return refineComparisonResult(currentGroupSorting, topLevelGroupSorting, comparisonResult);
          }

          return comparisonResult;
        }
      }

      return (v1?.value?.toString() ?? "").localeCompare(v2?.value?.toString() ?? "");
    },
    renderCell: (params) => {
      if (params.rowNode.type === "group") {
        if (params.rowNode.groupingField === field.guid) {
          let childRowId = params.rowNode.children[0];
          let cell: TabularDataCell | undefined;
          while (!cell && childRowId) {
            const node = params.api.getRowNode(childRowId);
            if (node?.type === "leaf") {
              const childRow = params.api.getRow<TabularRow>(childRowId);
              if (childRow) {
                cell = getCellFromRow(childRow, field.guid);
              }
            } else if (node?.type === "group") {
              childRowId = node?.children[0];
            } else {
              return "";
            }
          }

          if (cell) {
            return <DimensionCell cell={cell} />;
          }

          return params.formattedValue;
        }
        return null;
      } else {
        return <DimensionCell cell={getCellFromRow(params?.row, params?.field)} />;
      }
    },
    groupingValueGetter: (_, row, column) => getGroupingValue(getCellFromRow(row, column.field)),
    valueGetter: (_, row, column) => getCellFromRow(row, column.field),
  } as GridColDef;
}

const getCellFromRow = (row: TabularRow, fieldGuid: string): TabularDataCell | undefined => {
  return row?.data?.[fieldGuid] as TabularDataCell;
};

const DimensionCell = ({ cell }: { cell: TabularDataCell | undefined }) => {
  return (
    <DataGridTooltipWrapper tooltip={cell?.toolTip}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {cell?.formattedValue && <DimensionStartIcon cell={cell} />}
        {isBoolean(cell?.value) ? (
          <DimensionBooleanValue value={cell.value} />
        ) : (
          <Typography sx={{ textDecoration: cell?.toolTip ? "underline dotted" : "inherit" }}>
            {cell?.formattedValue}
          </Typography>
        )}
      </Stack>
    </DataGridTooltipWrapper>
  );
};

const isBoolean = (value?: unknown): value is boolean => typeof value === "boolean";

const isTopLevelGroup = (depth: number) => depth === 0;

export const getGroupingValue = (cell: TabularDataCell | undefined) => {
  return cell?.value;
};

export const refineComparisonResult = (
  currentGroupSorting: "asc" | "desc",
  topLevelGroupSorting: "asc" | "desc",
  comparisonResult: number
) => {
  const ascMultiplier = topLevelGroupSorting === "asc" ? 1 : -1;
  const descMultiplier = ascMultiplier * -1;

  return comparisonResult * (currentGroupSorting === "asc" ? ascMultiplier : descMultiplier);
};
