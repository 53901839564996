import { SettingsArea } from "../contexts/FieldsStateContext";
import { getGridMode } from "../style/chart/GridModeItem";

export function getArgumentAxisGrid(area: SettingsArea) {
  const gridMode = getGridMode(area);
  return { visible: gridMode === "all" };
}

export function getValueAxisGrid(area: SettingsArea) {
  const gridMode = getGridMode(area);
  return { visible: gridMode === "horizontal" || gridMode === "all" };
}
